import axios from "axios";
import cookie from "react-cookies";
import { HOST_URL, APP_ID } from "../../config/constans";
import { returnValue,customerId,getReferenceID} from "../../config/supporters";
export const GET_DRAFT_ORDERS = "settings/GET_DRAFT_ORDERS";

const initialState = {
  returnData: [],
  isFetching: true,
  isError: null,
  isStatus: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_DRAFT_ORDERS:
      return {
        ...state,
        returnData: action.returnData,
        isStatus: action.isStatus,
        isFetching: false
      };

    default:
      return state;
  }
}
export function getdraftOrders(pageslug = null) {
  let appId = returnValue(cookie.load(APP_ID));
  let API_URL = cookie.load(HOST_URL) + "api/orders/draftorder_history";
  let reference_id =getReferenceID();
    let customer_Id =customerId();

  return function(dispatch) {
    axios
      .get(
        `${API_URL}/?app_id=` +
          appId + "&except_availability=yes"
      )
      .then(response => {
        dispatch({
          type: GET_DRAFT_ORDERS,
          returnData: response.data.result_set,
          isStatus: response.data.status,
          isFetching: false
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
}
