import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import axios from 'axios';
import { getPromotions, getPromotionDetails } from "../../stores/rewards";
import { HOST_URL, APP_ID } from "../../config/constans";
import cookie from "react-cookies";
import { showInfo, returnValue, customerId } from "../../config/supporters";
import { history } from "../../store";
import cedeleLogo from "../../assets/img/icons/no-image.png";
import redemPopImg from "../../assets/img/product-popup-image.jpg";
import moment from "moment";
import {
  Input,
  Button,
  Modal,
  ModalBody,
  Container,
  Row,
  Col,
  ButtonGroup,
  Alert
} from "reactstrap";


var _ = require("lodash");
var Parser = require("html-react-parser");
var qs = require("qs");
const appId = returnValue(cookie.load(APP_ID));

const $ = window.$;
const API_URL = cookie.load(HOST_URL);
class Promotions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      viewPromoData: {},
      cartDetails: [],
      cartItems: [],
      promocodevalue: "",
      openPromotionModal: false,
    };
    this.handleChangeFilterRedeem = this.handleChangeFilterRedeem.bind(this);
    this.viewPromotion = this.viewPromotion.bind(this);
    this.applyPromotionCode = this.applyPromotionCode.bind(this);
    this.handleChangePromoFormField = this.handleChangePromoFormField.bind(this);
    this.closeAll = this.closeAll.bind(this);
  }
  componentDidMount() {
    axios.get(API_URL + 'api/cartver2/contents?status=A&app_id=' + appId + "&customer_id=" + customerId())
      .then(res => {
        if (res.data.status === "ok") {

          this.setState({ cartDetails: res.data.result_set.cart_details, cartItems: res.data.result_set.cart_items });

        }
      });
  }
  componentWillMount() {

    this.props.getPromotions(1);

  }
  handleChangeFilterRedeem(e) {
    this.props.getPromotions(e.target.value);
  }
  handleChangePromoFormField(e) {
    this.setState({ promocodevalue: e.target.value });
  }
  viewPromotion(promo, promotion_id) {

    this.setState({ viewPromoData: promo, openPromotionModal: true });
    this.props.getPromotionDetails(promotion_id);
  }
  closeAll() {
    this.setState({
      openPromotionModal: false
    });
  }
  /*apply coupon  function*/
  applyPromotionCode(promocode) {

    var postObject = {};
    var promocode = (promocode !== "" ? promocode : this.state.promocodevalue)


    var cartSubToatl = parseFloat(this.state.cartDetails.cart_sub_total);
    var cartItemsCount = parseInt(this.state.cartDetails.cart_total_items);

    postObject = {
      /* cart details */
      'app_id': appId,
      'reference_id': customerId(),
      'promo_code': promocode,
      'cart_amount': parseFloat(cartSubToatl),
      'cart_quantity': cartItemsCount,
      'category_id': this.getPromoCategory(),
    }

    axios.post(API_URL + 'api/promotion_api_v1/apply_promotion', qs.stringify(postObject))
      .then(res => {
        if (res.data.status === "success") {

		cookie.save('myaccount-promocode', promocode);
          showInfo("Congratulations! you are eligible for this promotion. promo code copied", "", "reload");
         /* cookie.save('promotion_id', res.data.result_set.promotion_id);
          cookie.save('promotion_applied', "Yes");
          cookie.save('promotion_code', res.data.result_set.promotion_code);
          cookie.save('promotion_delivery_charge_applied', res.data.result_set.promotion_delivery_charge_applied);
          cookie.save('promotion_amount', res.data.result_set.promotion_amount);
          cookie.save('promotion_category', res.data.result_set.promotion_category);
          cookie.save('prmo_type', res.data.result_set.prmo_type); */

          /*Remove if voucher applied 
          cookie.remove('voucher_applied');
          cookie.remove('voucher_code');
          cookie.remove('voucher_amount'); */

          //history.push("/");

        } else {

          if (typeof res.data.show_message !== 'undefined' && res.data.show_message === "Yes") {

            showInfo(res.data.message, "", "redirect", '/');
          }
          else if (typeof res.data.form_error !== 'undefined') {

            showInfo(res.data.form_error, "", "reload");
          } else {

            showInfo(res.data.message, "", "reload");
          }
          if (res.data.clear_offer === 'Yes') {

		 
		  cookie.remove('myaccount-promocode');
          /*  cookie.remove('promotion_id');
            cookie.remove('promotion_applied');
            cookie.remove('promotion_code');
            cookie.remove('promotion_delivery_charge_applied');
            cookie.remove('promotion_amount');
            cookie.remove('promotion_category');
            cookie.remove('prmo_type'); */

          }


        }
      });
  }
  getPromoCategory() {

    var product_cartid = "";
    var cartItems = this.state.cartItems;
    var cartCount = cartItems.length;
    if (cartCount > 0) {
      for (var p = 0; p < cartCount; p++) {
        if (product_cartid !== '' && cartItems[p].cart_item_product_id !== '') {
          product_cartid += ";";
        }
        product_cartid += cartItems[p].cart_item_product_id + "|" + cartItems[p].cart_item_total_price;
      }
    }

    return product_cartid;
  }
  render() {
    const promotionsList = this.props.customerPromotions;
    const viewPromoData = this.state.viewPromoData;
    const promotionDetails = this.props.promotionDetails;
    const promoURL = cookie.load(HOST_URL)+"/media/dev_team/promotion/";
    const closeAllBtn = (
      <button className="close" onClick={this.closeAll}>
        &times;
            </button>
    );
    return (
      <React.Fragment>
        <Container fluid>
          <Row>
            <Col className="p0">

              <div className="redeemMain">
                <h2>Redeem Promotion</h2>
                <div className="redeemSearch">
                  <ButtonGroup>
                    <Input type="text" name="text" id="text" onChange={this.handleChangePromoFormField.bind(this)} value={this.state.promocodevalue} placeholder="Add Prome/Invite Code Here" />
                    <Button onClick={this.applyPromotionCode.bind(this, '')}>Apply</Button>
                  </ButtonGroup>
                </div>
              </div>
              {_.isEmpty(promotionsList) === true &&
                  <Alert color="warning" className="mt-3">
                        No Records Found !
                  </Alert>
              }
              <div className="address promo">

                <Row>
                 
                  {_.isEmpty(promotionsList) === false && promotionsList.map((promo, promoIndex) =>
                    <Col xs="12" sm="6" key={promoIndex}>
                      <div className="promoWrapper">
                        <Row >                                <Col xs="5">
                          <div className="promotionBrandLogo">
                            <img className= {promo.promotion_image  ? "promoListImg" : "promoListNoImg"}  src={promo.promotion_image  ? promoURL + promo.promotion_image : cedeleLogo} alt="Promo Image" />{" "}
                          </div>
                        </Col>
                          <Col xs="7">
                            <div className="promoContent">
                              <div className="div1">
                                <p className="txt1">{promo.promo_code}</p>
									{ promo.promotion_type === "fixed"  &&   <p className="txt2"><sup>$</sup>{promo.promotion_max_amt} OFF</p> }
									{ promo.promotion_type === "percentage"  &&   <p className="txt2">{promo.promotion_percentage}% OFF</p> }
                                <p className="txt3">{'VALID TILL'}  { moment(promo.promotion_end_date).format("MMM Do YY hh:ss a")}</p>
                              </div>
							  {/* <p className="txt4">Maximum upto: ${promo.promotion_max_amt}</p> */}
<p className="txt4">&nbsp;</p>                             
							 <div className="promoBtn">
                                <Button color="default" onClick={this.viewPromotion.bind(this, promo, promo.promotion_id)}>VIEW</Button>{' '}
                                <Button color="primary" onClick={this.applyPromotionCode.bind(this, promo.promo_code)}>REDEEM</Button>{' '}
                              </div>
                            </div>
                          </Col>

                        </Row>
                      </div>
                    </Col>
                  )}


                </Row>
              </div>
            </Col>
          </Row>
        </Container>
        {/* View Receipt modal */}
        <Modal
          size="lg"
          isOpen={this.state.openPromotionModal}
          className="callcentre-modal order-details-modal"
        >
          <div className="modal-header">
            <div className="modal-top-icon">{closeAllBtn}</div>
          </div>
          <ModalBody>
            <div><Col xs="12" sm="12">
              <div className="promoWrapper">
                <Row >

                  <Col xs="5">
                    <div className="promotionBrandLogo">
    <img className= {viewPromoData.promotion_image  ? "promoViewImg" : "promoViewNoImg"} src={viewPromoData.promotion_image  ? promoURL + viewPromoData.promotion_image : cedeleLogo} alt="Promo Image" />{" "}
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="promoContent popPromo">
                      <div className="div1">
                        <p className="txt1">{ viewPromoData.promo_code} </p>
                      
					  { viewPromoData.promotion_type === "fixed"  &&   <p className="txt2"><sup>$</sup>{viewPromoData.promotion_max_amt} OFF</p> }
									{ viewPromoData.promotion_type === "percentage"  &&   <p className="txt2">{viewPromoData.promotion_percentage}% OFF</p> }
									
                        <p className="txt3">VALID TILL { moment(viewPromoData.promotion_end_date).format("MMM Do YY hh:ss a")}</p>
                        <p className="txt4 text-uppercase">Maximum upto ${viewPromoData.promotion_max_amt}</p>
						
                      </div>

                    </div>
                  </Col>

                </Row>
				{/* <img src={redemPopImg} alt="cedeleLogo" />{" "} */}
                <Row>
                  <Col xs="12">
                    <div className="redemPopTxt">
                      {_.isEmpty(viewPromoData.promo_desc) === false &&
                        <p>{Parser(viewPromoData.promo_desc)}</p>
                      }
                    </div>

                  </Col>
                </Row>
                <Row>    <Col xs="12">
                  <div className="redemPopbtn">
				  <p className="txt4 text-uppercase">&nbsp;</p>
                    <Button color="primary" onClick={this.applyPromotionCode.bind(this, viewPromoData.promo_code)}>REDEEM</Button>{' '}
                  </div>
                </Col>
                </Row>
              </div>



            </Col>
            </div>
          </ModalBody>

        </Modal>
      </React.Fragment>
    );
  }

}

const mapStateToProps = ({ rewards }) => ({
  customerPromotions: rewards.customerPromotions,
  promotionDetails: rewards.promotionDetails

});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getPromotions,
      getPromotionDetails

    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Promotions);
