import React, { Component } from "react";
import axios from "axios";
import cookie from "react-cookies";
import { connect } from "react-redux";
import Header from "../layout/header";
import { getCartItems } from "../../stores/cart";
import {
  Button,
  Modal,
  Input,
  ModalBody,
  ModalFooter,
  CardGroup,
  Card,
  InputGroup,
  Form,
  FormGroup,
  CardBody,
  Col,
  Row  
} from "reactstrap";
import {
  appId,
  hostUrl,
  customerId,
  getCookies,
  getReferenceID,
  getAliasName,
  stripslashes
} from "../../config/supporters";
import { Scrollbars } from "react-custom-scrollbars";
import ProductPopupImage from "../../assets/img/product-popup-image.jpg";
var _ = require("lodash");
var qs = require("qs");
const $ = window.$;
var Select = require('react-select');

class Customerpopup extends Component {
  constructor(props) {
    super(props);
   
    this.openAddMenuForm = this.openAddMenuForm.bind(this);
    this.productName = this.productName.bind(this);	 
    this.productPrice = this.productPrice.bind(this);	
    this.ProductNotes = this.ProductNotes.bind(this);	   
    this.closeAll = this.closeAll.bind(this); 
	this.onsubmitForm = this.onsubmitForm.bind(this); 
    this.state = {
      open_addmenuform: false,
	  productName : "",
	  productPrice: "",
	  ProductQty : "",
	  ProductNotes : "",
	  defaultQty : 1,
	  customProducts : [],
	  customProductsList : [],
    };
  }

  openAddMenuForm() {
    this.setState({
      open_addmenuform: !this.state.open_addmenuform
     
    });
  }

  productName(e) {
	  this.setState({productName : e.target.value});
  }
  
  productPrice(e) {
	  this.setState({productPrice : e.target.value});
  }
  
  ProductNotes(e) {
	  this.setState({ProductNotes : e.target.value});
  }
  
  decQty() {
    if (this.state.defaultQty !== 1) {
      this.setState({ defaultQty: this.state.defaultQty - 1 });
    }
  }

  incQty() {
    console.log(this.state.defaultQty);
    this.setState({ defaultQty: this.state.defaultQty + 1 });
  }
  
  closeAll() {
    this.setState({
      open_addmenuform: false
    });
  }
  
  componentDidMount() {
	
	const HOST_URL = hostUrl();
	const APP_ID = appId();
	
	axios.get(HOST_URL + 'apiv2/cart/call_products_list?app_id=' + APP_ID +"&availability=" + cookie.load('defaultAvilablityId')).then((response) => {
      this.setState({ customProducts: response.data.result_set,customProductsList:response.data.result_set });
    });
	  
  }
  
  componentWillReceiveProps(nextProps) {
		console.log(nextProps.open_addmenuform);
    if ( (nextProps.open_addmenuform !== this.props.open_addmenuform)   ) {
		if(nextProps.open_addmenuform === true) { 
          this.setState({ open_addmenuform: true  });
		}
    }
    
    if (nextProps.cartStatus ) {
       this.setState({cartStatus :nextProps.cartStatus, cartItems : nextProps.cartItems  });
    }
    
  }
  
  __loadcallProducts() {
    if (this.state.customProducts) {
      return this.state.customProducts.map((loaddata, index) =>
      <li key={index} ><a href="javascript:void(0);" onClick={this.fetchProduct.bind(this,loaddata.call_product_primary_id,loaddata.call_product_name,loaddata.call_product_price,loaddata.call_product_special_notes)}>{loaddata.call_product_name}</a></li>
        
      );
    }
  }
  
  fetchProduct(product_id,product_name,product_price,product_notes){
	  
	  this.setState({cproduct_id:product_id});
	  this.setState({productName:product_name});
	  this.setState({productPrice:product_price});
	  this.setState({ProductNotes:product_notes});
	  $('.callproduct_sec').hide();
	  
  }
  
  handleKeyPress = (event) => {
        var matches ='';
        var value = event.target.value.toLowerCase();   
        if(this.state.customProductsList){
         var matches = this.state.customProductsList.filter(function (item) {
            return item.call_product_name.substring(0, value.length).toLowerCase() === value;
        });
       } 
        this.setState({customProducts:matches})
        
        if(value.length > 0){
			$('.callproduct_sec').show();
		}else{
			$('.callproduct_sec').hide();
		}
        
  }

  
  onsubmitForm(){
	 
	  const productName = this.state.productName;
	  const productPrice = this.state.productPrice;
	  const ProductNotes = this.state.ProductNotes;
	  const defaultQty = this.state.defaultQty;
	  const APP_ID = appId();
      const HOST_URL = hostUrl();
      var customerID = customerId();
	  var postObject = {};
	  
	  if( productName !==  null && productPrice !== "" &&  defaultQty !== "" ) {
		 
		 postObject = {
			 app_id: APP_ID,
			 availability_id: getCookies("orderAvailability"),
			 product_type: 1,
			 outletzone_id: getCookies("orderOutletId"),
			 outlet_id: getCookies("orderZoneID"),
			 customer_id: customerID,
			 reference_id: getReferenceID(),
		  }
	  
      if(typeof productName !== "undefined" && productName !=="") {
		   postObject['call_product_name'] = productName;
       
	  }
	  if(typeof productPrice !== "undefined" && productPrice !=="") {
		      postObject['call_product_price'] = productPrice;
	  }
	  
	/*  if(typeof customer_email !== "undefined" && customer_email !=="") {
		   postObject['customer_email'] = customer_email;
  
	  }  */
	  
	  if(typeof ProductNotes !== "undefined" && ProductNotes !=="") {
		   postObject['product_remarks'] = ProductNotes;
	  }
	  
	  if(typeof defaultQty !== "undefined" && defaultQty !=="") {
		   postObject['product_qty'] = defaultQty;
	  }
	  
	  axios
      .post(HOST_URL + "apiv2/cart/addmenuproductcart", qs.stringify(postObject))
      .then(res => {
        if (res.data.status === "ok") {
			const result = res.data.result_set;
			
			this.setState({open_addmenuform : false});
			
			this.props.getCartItems();
        
        } else if (res.data.status === "error") {
            alert(res.data.message);
        }
      });
	  }else {

	  }
  }
	
	
   ViewProducts(event) {
	var productSlug = event.value;
	//hashHistory.push("/product/" + productSlug);
   }
	  
  componentUnMount() {}

  render() {
    const closeAllBtn = (
      <button className="close" onClick={this.closeAll}>
        &times;
      </button>
    );
  
    return (
      <React.Fragment>
        {/* 1st modal */}
        <Modal
          size="lg"
          isOpen={this.state.open_addmenuform}
          toggle={this.openAddMenuForm}
          className="callcentre-modal "
          modalClassName="addnewmenu_modal customer-form-modal"
        >
          <ModalBody>
           
          <div id="login-screen" className="app login-screen flex-row align-items-center">          
          {closeAllBtn}
        <Row className="justify-content-center">
          <Col md="12">
            <CardGroup>
            {/*<Card className="text-white bg-gray py-5 d-md-down-none login-left-block">
                <CardBody className="text-center">
                  <div className="signup-block">
                   <img src={'img/table-peoples.png'} alt="image" />
                    <h1>Start Ordering <br/>Your Food Today</h1>
                    <h4>Let us know about you<i className="fa fa-arrow-right fa-sm"></i></h4>
                    </div>
                </CardBody>
              </Card> */}
              <Card className="p-4 login-right-block">
                <CardBody className="form-block">
                   
                  <div className="signup-logo-block">
                    <h4>Add New Item</h4>
                  </div>
                    <p className="text-muted">{/*Create your account */}</p>
                    <FormGroup>
                    <div className="controls">
                    <InputGroup>
						
						{/*<Select.Async
							name="form-field-name"
							placeholder="Go ahead search for a product..."
							noResultsText="No Products Found"
							autoload={false}
							loadOptions={this.getOptions.bind(this)}
							onChange={this.ViewProducts.bind(this)}
						  /> */ } 
						  
                       <Input type="text" value={this.state.productName} onKeyUp={this.handleKeyPress} onInput={this.productName} placeholder="Product Name" autoComplete="productname" />                       
                    </InputGroup>
                    <ul className="callproduct_sec" style={{display:"none"}}>
						{this.__loadcallProducts()}
                    </ul>
                    </div>
                    </FormGroup>
                    <InputGroup className="mb-3">
                        <Input type="text" value={this.state.productPrice} onInput={this.productPrice}  placeholder="Price (per 1 Qty)" autoComplete="price" />
                    </InputGroup>
                    <div className="prod-chosen-qty-block">
					  <div className="qty-box">
						<span
						  className="qty-minus"
						  onClick={this.decQty.bind(this)}
						>
						  _
						</span>
						<input
						  type="text"
						  readOnly
						  value={this.state.defaultQty}
						/>
						<span
						  className="qty-plus"
						  onClick={this.incQty.bind(this)}
						>
						  +
						</span>
					  </div>
					</div>
                    <Input type="textarea" name="text" onChange={this.ProductNotes} value={this.state.ProductNotes} id="productDescription" placeholder="Please enter your special message here..." />
                    <Row>
                      <Col xs="12">
                        <Button color="primary" onClick={this.onsubmitForm} className="px-4">Add To Cart</Button>
                      </Col>
					  {/*    <Col xs="12" className="text-right">
                        <Button color="link" className="px-0">Forgot password?</Button>
                      </Col> */}
                    </Row>
                
                </CardBody>
              </Card>
            
            </CardGroup>
          </Col>
        </Row>     
    </div>

	  </ModalBody>
        
	</Modal>

        
      </React.Fragment>
    );
  }
}

const mapStateToProp = state => ({
  cartItems: state.cart.returnData,
  cartStatus: state.cart.isStatus,
 
});
export default connect(
  mapStateToProp,
  { getCartItems }
)(Customerpopup);

