import React, { Component } from "react";
import { Nav, NavItem, NavLink, Button } from "reactstrap"; /* Badge */
import { ADMIN_ID, HOST_URL, APP_ID,CLIENT_LOGO,DELIVERY_ID,DINEIN_ID,PICKUP_ID } from "../../config/constans";
import { LOGO_URL } from "../../config/config";
import { getLogo,hostUrl,deleteOrderCookie } from "../../config/supporters";
import cookie from "react-cookies";
import store,{ history } from '../../store';
import { Link } from 'react-router-dom';
import Deliverypopup from './deliverypopup';
import Dineinpopup from './dineinpopup';
import Pickupflow from './pickupflow';
import Commomdatepickerpopup from './Commomdatepickerpopup';


/* import images */
import DeliveryMenuIcon from "../../assets/img/icons/delivery-menu-icon.png";
import TakeawayMenuIcon from "../../assets/img/icons/take-away-menu-icon.png";
import DineinMenuIcon from "../../assets/img/icons/dinein-menu-icon.png";
//import CateringMenuIcon from "../../assets/img/icons/catering-menu-icon.png";
//import ReservationMenuIcon from "../../assets/img/icons/reservation-menu-icon.png";
import OrdersMenuIcon from "../../assets/img/icons/orders-menu-icon.png";
import ReportsMenuIcon from "../../assets/img/icons/reports-menu-icon.png";
import SiteSettingsIcon from "../../assets/img/icons/site-settings.png";
import LogOutIcon from "../../assets/img/icons/logout-icon.png";
import MenuIcon from "../../assets/img/icons/menu-icon.png";
var _ = require('lodash');



class Sidenav extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggleNavbar.bind(this);
	this.onLogout = this.onLogout.bind(this)
	this.deliveryStepOne = this.deliveryStepOne.bind(this);
    this.openPickupPopup = this.openPickupPopup.bind(this);
    this.openDineinPopup = this.openDineinPopup.bind(this);
    this.state = {
      collapse: false,
	  deliveryOne :false,
    pickupPopup : false,
    dineinPopup : false,
    navigationActive : '',
    };
  }
  toggleNavbar() {
    this.setState({ collapse: !this.state.collapse });
  }
 
  onLogout() {
	const apId =  cookie.load(APP_ID);
    cookie.remove('callAdminId', { path: '/' });
	cookie.remove('hostUrl', { path: '/' });
	cookie.remove('app_id', { path: '/' });
	cookie.remove('client_logo', { path: '/' }); 
	cookie.remove("referenceId");
	cookie.remove("callAdminName", { path: '/' }); 
    cookie.remove("call_permission_outlet", { path: '/' });
	deleteOrderCookie();
	history.push("/access/"+apId);
  }

deliveryStepOne() {
   this.setState({ deliveryOne: !this.state.deliveryOne});
   this.navaigationActive(DELIVERY_ID);
}

openPickupPopup() {
   this.setState({ pickupPopup: !this.state.pickupPopup});
   this.navaigationActive(PICKUP_ID);
}
openDineinPopup() {
  this.setState({ dineinPopup: !this.state.dineinPopup});
  this.navaigationActive(DINEIN_ID);
}
  componentWillReceiveProps(nextProps) {
    if (nextProps.orderType !== this.props.orderType) {
	    if(nextProps.orderType === DELIVERY_ID) {
      this.deliveryStepOne();
      
		}
		  if(nextProps.orderType === PICKUP_ID) {
      this.openPickupPopup();
		}
		
		 if(nextProps.orderType === DINEIN_ID) {
      this.openDineinPopup();
		}
	}
	
  }

  navaigationActive(param){
     //  let path = (window.location.pathname !== "/dashboard" ? window.location.pathname : param  );
     this.setState({navigationActive : param });
  }
  
 
  render() {
 
    console.log(cookie.load('orderAvailability'));
	const logoName = getLogo();
    const hostURL = hostUrl();
	const allData =  store.getState();
    const sideNavbarClass = this.state.collapse ? "active" : "";
    const settings = (typeof allData.settings.returnData === "object" ? allData.settings.returnData : [] );
    const availability = (typeof settings.availability === "object" ? settings.availability : [] );

   const delMenu = _.filter(availability, ['availability_id', DELIVERY_ID]);
   const pickMenu = _.filter(availability, ['availability_id', PICKUP_ID]);
   const dineMenu = _.filter(availability, ['availability_id', DINEIN_ID]);
   const tempshow = true;
	 
   const openDelpopup = this.state.deliveryOne;
   const openPickupPopup = this.state.pickupPopup;
   const openDineInPopup = this.state.dineinPopup;
   
 
    return (

	 <React.Fragment>
	 <Deliverypopup deliveryOne={openDelpopup} />
     <Pickupflow openPickupPopup={openPickupPopup} />
     <Dineinpopup openDineinPopup={openDineInPopup} />
	 <Commomdatepickerpopup />
      <div id="sidebar" className={sideNavbarClass}>
        <div className="sidebar-header">
          <a href="/" className="navbar-brand">
            {logoName ==="" && <img  alt="Ninja OS" className="logo_sidebar" src="http://ccpl.ninjaos.com/lib/theme/images/logo.png"   /> }
          {logoName !=="" && <img alt="Ninja OS" className="logo_sidebar" src={hostURL+ LOGO_URL+logoName} style={{ width : "60px", height : "auto" }}  /> }
          </a>
          <Button
            color="primary"
            className="btn-sidenav-toggle"
            onClick={this.toggle}
            style={{ marginBottom: "1rem" }}
          >
          <img src={MenuIcon} alt="ninja-menu"/>           
          </Button>
        </div>

        <Nav vertical pills className="side-nav-main">
		{  (( typeof delMenu === "object" && _.isEmpty(delMenu) === false ) || tempshow === true ) && 
		  <NavItem>
		  {/*active*/}
            <NavLink className={(this.state.navigationActive === DELIVERY_ID? "cursor active" : "cursor"  )}   onClick={this.deliveryStepOne}>
              <span className="nav-icon">
                <img src={DeliveryMenuIcon} alt="Delivery" />
              </span>
              <span className="nav-txt"> Delivery</span>
            </NavLink>
		</NavItem> }
		
		  	{  ( typeof pickMenu === "object" && _.isEmpty(pickMenu) === false ) && 
          <NavItem>
            <NavLink  className={(this.state.navigationActive === PICKUP_ID? "cursor active" : "cursor"  )}  onClick={this.openPickupPopup}>
              <span className="nav-icon">
                <img src={TakeawayMenuIcon} alt="Delivery" />
              </span>
              <span className="nav-txt">Takeaway</span>
            </NavLink>
          </NavItem>
			}
		  
		  	{  ( typeof dineMenu === "object" && _.isEmpty(dineMenu) === false ) && 
          <NavItem>
            <NavLink  className={(this.state.navigationActive === DINEIN_ID? "cursor active" : "cursor"  )}  onClick={this.openDineinPopup}>
              <span className="nav-icon">
                <img src={DineinMenuIcon} alt="Delivery" />
              </span>
              <span className="nav-txt">Dine-in</span>
            </NavLink>
          </NavItem>
		  
			}
		 
		  {/*   <NavItem>
            <NavLink href="#">
              <span className="nav-icon">
                <img src={CateringMenuIcon} alt="Delivery" />
              </span>
              <span className="nav-txt">Catering</span>
            </NavLink>
          </NavItem>
		  
          <NavItem>
            <NavLink href="#">
              <span className="nav-icon">
                <img src={ReservationMenuIcon} alt="Delivery" />
              </span>
              <span className="nav-txt">Reservation</span>
            </NavLink>
          </NavItem> */}
          <NavItem  onClick={this.navaigationActive.bind(this,'')}>
            <Link to="/orders" className={(this.state.navigationActive === "" && window.location.pathname === "/orders"? "orders nav-link active" : "orders nav-link"  )}  >
              <span className="nav-icon" >
                <img src={OrdersMenuIcon} alt="Delivery" />
              </span>
              <span className="nav-txt">Orders</span>
			  {/* <Badge color="primary" pill>
                0
              </Badge> */}
            </Link>
          </NavItem>
		  {/* <NavItem>
             <Link to="/reports" className="nav-link">
              <span className="nav-icon">
                {" "}
                <img src={ReportsMenuIcon} alt="Reports" />
              </span>
              <span className="nav-txt">Reports</span>
            </Link >
          </NavItem> */}
        </Nav>
        <Nav className="side-nav-bottom-fixed" vertical pills>
        {/*  <NavItem>
            <NavLink  >
              <span className="nav-icon">
                <img src={SiteSettingsIcon} alt="Settings" />
              </span>
              <span className="nav-txt"> Settings</span>
            </NavLink>
		</NavItem> */}
          <NavItem>
            <NavLink className="cursor"  >
              <span className="nav-icon">
                <img src={LogOutIcon} alt="Logout" onClick={this.onLogout} />
              </span>
              <span className="nav-txt">Logout</span>
            </NavLink>
          </NavItem>
        </Nav>
      </div>
	     </React.Fragment>
    );
  }
}


export default Sidenav;
