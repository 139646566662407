var _ = require("lodash");
export function getStripeRef(appID) {
  var paymentObj = {
    "DB72C391-60BA-4176-8AB0-5CA0FEBC7BD1": "cedele",
    "37480D40-8F3F-46AA-8693-7EF507D1B60E": "springcourt",
    "8FB9C67F-E9B8-40A3-BC1C-51624DEC45B7": "pizzakfc",
    "F77D467A-F552-4BB3-ABBF-C491DB6661A0": "leanbento",
    "5F2E6A5D-25B6-498F-AEBA-6EC8171F9625": "evertop",
    "C2533ED5-3B72-41B4-91AC-F57E7DE5422B": "saladbox",
    "B254C662-6518-4B4D-BE5F-30ADFD38FBA9": "alhussain",
    "2E5B22E3-D685-4693-AD4C-B1765A80EA75": "mrprata",
    "1E7CC2BB-01F7-47A7-B128-404EECDECE0C": "pitatree",
    "AD8BDEE2-92AA-461A-AEAF-47E921C99235": "bushido",
    "9F55B4B0-A88F-4074-9598-D0AD61F22985": "konarmess",
    "6665D5E0-F716-4208-85AD-D062AE89B61B": "tiptopbriyani",
    "59ADA2AD-499C-4B0B-841F-452B754324ED": "brownie",
    "0A3FDFD4-07AD-44BB-8D86-1988AA200D97": "udders",
    "80D71B04-C58D-4D3A-8619-687DDBC382FE": "bushido",
    "E5381958-8EF3-4971-A845-87BFDC94C604": "everestkitchen",
    "0DD7BB89-B71D-4A81-B253-65CB90DBF08B": "yishi",
    "A9F98030-897B-4057-BD62-33883AA65FF0": "khan",
    "67A7C5CF-6EA2-4AF7-9414-BB4E2CE689C2": "tabbouleh",
    "EBE69618-422B-4B43-ABB1-A23CDC59E9FE": "ahhuakelong",
    "329428F3-CB23-4961-968C-68775ADD8ACE": "saladchef",
    "9571C6D1-6D44-4888-9DF1-F048650B4881": "machans",
    "F189BE15-851E-42F8-9750-45D13C2CC1B7": "gokul",
    "30E6FB69-4E50-4331-9BE8-883D413CA457": "deannas",
    "CC804508-EB5E-4148-BF76-8A9D40D945FF" : "lasaigon",
    "bushido": "bushido",
  };
  if (_.isEmpty(paymentObj[appID]) === false) {
    return paymentObj[appID];
  }
}
