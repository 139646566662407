import axios from 'axios'
export const FETCH_RESTAURANTS_DETAILS = 'restaurantdetails/FETCH_RESTAURANTS_DETAILS'
const API_URL =  'https://ccpl.ninjaos.com/api/'; 
const initialState = {
  items:[],
  restaurantlogopath: null,
  coverimagepath: null,
  loading: true,
  error: null,
  returnSts : null
}

export default function(state = initialState , action) {
   switch (action.type){
       case FETCH_RESTAURANTS_DETAILS:
        return {
            ...state,
            items: action.payload,
            restaurantlogopath: action.restaurantlogopath,
            coverimagepath: action.coverimagepath,
			returnSts : action.returnSts,
			loading: false,
            
        }
              
       default:
       
        return state;
   }
}
export function fetchRestaurants(pageslug = null){
	
	pageslug = (typeof pageslug !== "undefined" && pageslug !== null? pageslug : "")
    return function (dispatch) {
        axios.get(`${API_URL}/promobuddy/getClientList?pageslug=`+pageslug)
        .then(response => {   
 		
          dispatch({
            type: FETCH_RESTAURANTS_DETAILS,
            payload: response.data.result_set,
			returnSts: response.data.status,
			coverimagepath: response.data.cover_image_path,
			restaurantlogopath: response.data.logo_path,
            
          });
        })
        .catch((error) => {
          console.log(error);
        })
       
    }
}

