import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { history } from "../../store";
import { getCartItems } from "../../stores/cart";
import cookie from "react-cookies";
import {
  Button,
  Modal,
  Input,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Col,
  Row,
  Media,
  Jumbotron,
  Container,
  Badge,
  Spinner
} from "reactstrap";
import {
  appId,
  hostUrl,
  getCookies,
  stripslashes,
  showInfo,
  getReferenceID,
  customerId,
  getAliasName
} from "../../config/supporters";
import {
  DELIVERY_ID,
  PICKUP_ID,
  SUCCESS_ALERT,
  FAILURE_ALERT
} from "../../config/constans";
import { Scrollbars } from "react-custom-scrollbars";
import ProductPopupImage from "../../assets/img/product-popup-image.jpg";
const $ = window.$;
var _ = require("lodash");
var qs = require("qs");
var parse = require("html-react-parser");

 class Addtocartpopup extends Component {
	 
  constructor(props) {
    super(props);
    this.handleChangeDescription = this.handleChangeDescription.bind(this);
    this.openProductAddtoCart = this.openProductAddtoCart.bind(this);
    this.closeAll = this.closeAll.bind(this);
    this.state = {
      openproduct_addtocart: false,
      tagsInputValue: "",
      tags: [],
      detailstatus: "loading",
      prDetails: {},
      prCommon: {},
      defaultQty: 1,
      allowCart: "Yes",
      currentProdcut: "",
      product_remarks: "",
	  modifierLenght : 0,
	  remaining : 30
    };
	cookie.save("modiferCount", 0,{ path: '/' });
 
  }
      handleChangeDescription(event) {
    
	   this.setState({ product_remarks: event.target.value });
		this.setState({ remaining: 30 - event.target.value.length });
  }
  openProductAddtoCart() {
    this.setState({
      openproduct_addtocart: !this.state.openproduct_addtocart
    });
  }

  closeAll() {
    this.setState({
      openproduct_addtocart: false
    });
  }

  addTag = tag => {
    if (tag === "") return;

    tag = tag.trim();

    if (!(this.state.tags.indexOf(tag) > -1)) {
      let tags = this.state.tags.concat([tag]);
      this.updateTags(tags);
    }

    this.updateTagValue("");
  };

  updateTagValue = value => {
    if (value === " ") {
      return;
    }
    this.setState({
      tagsInputValue: value
    });
  };

  removeTag = removeTag => {
    let tags = this.state.tags.filter(tag => tag !== removeTag);
    this.updateTags(tags);
  };

  updateTags = tags => {
    this.setState({
      tags
    });
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.isOpen !== this.props.isOpen &&
      nextProps.isOpen === true &&
      nextProps.isproductId !== ""
    ) {
      this.setState({
        openproduct_addtocart: true
      });

      const productSlug = nextProps.isproductId;
      const appID = appId();
      const apiUrl = hostUrl();
      const availabilityId = getCookies("orderAvailability");
      axios
        .get(
          apiUrl +
            "api/products/products_list?app_id=" +
            appID +
            "&availability=" +
            availabilityId +
            "&product_slug=" +
            productSlug
        )
        .then(res => {
          if (res.data.status === "ok") {
            this.setState({
              detailstatus: "ok",
              prDetails: res.data.result_set[0],
              prCommon: res.data.common,
              productPrice: res.data.result_set[0].product_price
            });
              this.validateModifier("",res.data.result_set[0].product_id,"initial" ); 
             
          } else {
            this.props.history.push("/");
          }
        });
    }
  }

  showModifiers(modVal, productId) {
 
    if (modVal) {
      var modLen = modVal.length;
      return modVal.map((item, index) => (
      
	 
		 <FormGroup className="" key={index}>
          <Label for="productSize">{stripslashes(item.pro_modifier_name)}</Label>
          <div className="custome-select-control">
            <Input
              type="select"
              name={"modVal_" + index}
              id={"modVal_" + index}
              onChange={this.validateModifier.bind(this, modLen, productId)}
			  className="form-control"
             >
			      <option value="">{"Select " + stripslashes(item.pro_modifier_name)} </option>
              {this.showModifierItems(
                item.modifiers_values,
                index,
                modLen,
                item.pro_modifier_name,
                item.pro_modifier_id
              )}
            </Input>
          </div>
        </FormGroup>
      ));
    }
  }

  isDefault(select)
{
   return (select === "Yes"? "selected" : "");
}

  showModifierItems(modVal, mIndex, length, modName, modId) {
 
		cookie.save("modiferCount", length,{ path: '/' });
				cookie.save("itemType", "Modifier",{ path: '/' });
    return modVal.map((item, malIndex) => (
      <option
        key={malIndex}
		selected={this.isDefault(item.pro_modifier_value_is_default)}
        value={
          modName +
          "~" +
          modId +
          "~" +
          item.pro_modifier_value_name +
          "~" +
          item.pro_modifier_value_id +
          "~" +
          item.pro_modifier_value_price
        }
        
      >
	  {/*id={item.pro_modifier_value_id}*/}
        {item.pro_modifier_value_name}
      </option>
    ));
  }

  /* checkActive */
  checkActive() {}

  /* this function used to validate modifer values */
  validateModifier(ModifierLenght, productID,initial = null) {
    const productId = productID;
    const APPID = appId();
    const apiUrl = hostUrl();
    const ModifierLen = (initial === "initial") ? getCookies("modiferCount") :  ModifierLenght;
    var allModVal = "";
    var commaVal = ModifierLen - 1;
    var sts = "Fail";
 
    for (var i = 0, length = ModifierLen; i < length; i++) {
      var inputName = "modVal_" + i;

      if ($("#" + inputName).val()) {
        var modVal = $("#" + inputName).val();
        var res = modVal.split("~");
        if (modVal === "") {
          sts = "Fail";
          showInfo("Please select all modifiers");
		   document.getElementById("addToCart").disabled = true;
          break;
        } else {
          sts = "Success";
          document.getElementById("addModCart").disabled = false;;
          var strt = i === commaVal ? res[3] : res[3] + ";";
          allModVal += strt;
          /* post data */
        }
      } else {
        sts = "Fail";
        document.getElementById("addModCart").disabled = true;
        showInfo("Please select all modifiers");
        break;
      }
    }
    if (sts === "Success") {
      /* for normal modifiers get state value, set meal product pass param */

      $("#modLoading").show();
      axios
        .get(
          apiUrl +
            "api/products/validate_product?app_id=" +
            APPID +
            "&product_id=" +
            productId +
            "&modifier_value_id=" +
            allModVal
        )
        .then(res => {
          /* Success response */
		   $("#modLoading").hide();
          if (res.data.status === "ok") {
            this.setState({ sts: "No" });
            this.setState({ allowCart: "Yes" });
            this.setState({
              productPrice: res.data.result_set[0].product_price,
              productUnitPrice: res.data.result_set[0].product_price,
              currentProdcut: res.data.result_set[0].alias_product_primary_id
            });
          }
          /* Error response */
          if (res.data.status === "error") {
            showInfo(res.data.message);
            this.setState({ allowCart: "No" });
            document.getElementById("addModCart").disabled = true;
          }
        });
    }
  }

  decQty() {
    if (this.state.defaultQty !== 1) {
      this.setState({ defaultQty: this.state.defaultQty - 1 });
    }
  }

  incQty() {
 
    this.setState({ defaultQty: this.state.defaultQty + 1 });
  }

  addToCartAction(productId) {
    const host = hostUrl();
    const APP_ID = appId();
    var postObject = {};

	  if(this.state.allowCart === "No")
  {
      showInfo("Product combination is wrong. Please check your combination");
	return false;
  }
    /* Post Modifier part */

    var modifier = [];
    //console.log(cookie.load('itemType') +"<---->"+ cookie.load('modiferCount'));
    if (
      cookie.load("itemType") === "Modifier" &&
      cookie.load("modiferCount") > 0
    ) {
      //  console.log('is allow');
      for (
        var i = 0, lengthMod = cookie.load("modiferCount");
        i < lengthMod;
        i++
      ) {
        var inputName = "modVal_" + i;
        if ($("#" + inputName).val()) {
          var modVal = $("#" + inputName).val();
          if (modVal !== "") {
            var res = modVal.split("~");
            var modifierVal = [];
            modifierVal.push({
              modifier_value_id: res[3],
              modifier_value_qty: "1",
              modifier_value_name: res[2],
              modifier_value_price: res[4]
            });
            modifier.push({
              modifier_id: res[1],
              modifier_name: res[0],
              modifiers_values: modifierVal
            });
          } else {
            showInfo("some modifier values missing");
            return false;
          }
        } else {
          showInfo("some modifier values missing");
          return false;
        }
      }
    }
	
 //const avilablitText = 	

    postObject = {
      app_id: APP_ID,
      product_id: productId,
      product_qty: this.state.defaultQty,
      pageToken: "$%%&(ZCWRT#^FG$%^#D$",
      reference_id: getReferenceID(),
      customer_id: customerId(),
      availability_name: "Delivery",
      availability_id: getCookies("orderAvailability"),
      product_type: 4,
      product_modifier_parent_id: this.state.currentProdcut,
      outletzone_id: getCookies("orderOutletId"),
      outlet_id: getCookies("orderZoneID"),
      modifiers: JSON.stringify(modifier),
	  product_remarks : this.state.product_remarks
    };
    $("#proLoading").show();
    axios
      .post(host + "apiv2/cart/simpleCartInsert", qs.stringify(postObject))
      .then(res => {
        $("#proLoading").hide();

      /*  this.setState({ cartAction: true });
        setTimeout(
          function() {
            this.setState({ cartAction: false });
          }.bind(this),
          1000
        ); */

        if (res.data.status === "ok") {
          $(".pro_qty_btn").hide();
          $(".add_to_btn_cart").show();
		  cookie.remove("modiferCount");
          cookie.remove("itemType");
          this.setState({
            defaultQty: 1,
            cartAlert: true,
            cartAlertMsg: res.data.message,
            alertType: SUCCESS_ALERT,
            openproduct_addtocart: false
          });
	 		  
		   this.props.getCartItems();
          showInfo(res.data.message);
        } else if (res.data.status === "error") {
          this.setState({
            cartAlert: true,
            cartAlertMsg: res.data.message,
            alertType: FAILURE_ALERT
          });
        }
        showInfo(res.data.message);
      });
  }
  render() {
    const closeAllBtn = (
      <button className="close" onClick={this.closeAll}>
        &times;
      </button>
    );
    var proImsgsrc = hostUrl() + "media/dev_team/products/main-image/";
    const { tagsInputValue, tags } = this.state;
    const details = this.state.prDetails;
 
    return (
      <React.Fragment>
        {/* 1st modal */}
        <Modal
          size="lg"
          isOpen={this.state.openproduct_addtocart}
          toggle={this.openProductAddtoCart}
          className="callcentre-modal addtocart-modal"
        >
          <div className="modal-header">
            <div className="modal-top-icon">{closeAllBtn}</div>
          </div>

          <ModalBody>
            <Media>
              {(details.product_thumbnail !== "" && typeof details.product_thumbnail !== "undefined" ) && (
                <Media left href="#">
                  <img
                    style={{ width: "300px", height: "auto" }}
                    src={proImsgsrc + details.product_thumbnail}
                    alt=""
                  />
                </Media>
              )}
              <Media body>
                <Media heading>
                  {stripslashes(
                    getAliasName(details.product_alias, details.product_name)
                  )}
                </Media>
                {parse(stripslashes(details.product_long_description))}
              </Media>
            </Media>
            <Jumbotron fluid>
              <Container fluid>
                {/* <Scrollbars style={ {width: '100%', height: '100%', minHeight: 400} }> */}

                <Row className="addtocart-form-block">
                  <Col xs="6" key="4">
                    {this.showModifiers(details.modifiers, details.product_id)}
                    {/*    <FormGroup className="">
          <Label for="productSize">Choose Product Size</Label>
          <div className="custome-select-control">
          <Input type="select" name="select" id="productSize">
            <option>8"</option>
            <option>9"</option>
            <option>10"</option>
            <option>12"</option>
            <option>14"</option>
          </Input>
          </div>
					   </FormGroup> */}

                    {/*  <FormGroup>
          <Label for="productPlatters">Choose Platters</Label>
          <div className="custome-select-control">
          <Input type="select" name="select" id="productPlatters" value={tagsInputValue} onChange={(e) => {
            this.addTag(e.target.value);
			    this.updateTagValue(e.target.value);
}} >
            <option>Platter 1</option>
            <option>Platter 2</option>
            <option>Platter 3</option>
            <option>Platter 4</option>
            <option>Platter 5</option>
          </Input>
          </div>
          <div>
        {tags && tags.map((tag, index) => 
        
        <Badge className="platter-tag" key={index} href="#" color="danger" onClick={() => this.removeTag(tag)} pill>{tag}</Badge>
          
		)} 
</div>
        </FormGroup> */}
                    {/* <FormGroup>
          <Label for="productPlatters">Choose Addons</Label>
        </FormGroup>
        <FormGroup row className="candles-form-group">
          <Label for="bigCandles" className="pl-5 text-capitalize" sm={7}>Big Candles</Label>
          <Col sm={5}>
          <div className="prod-chosen-qty-block">
            <div className="qty-box"><span className="qty-minus">_</span>
            <input type="text" id="bigCandles" readOnly value="99" /><span className="qty-plus">+</span>
            </div>
            </div>
          </Col>
        </FormGroup>
        <FormGroup row className="candles-form-group">
          <Label for="exampleEmail" className="pl-5 text-capitalize" sm={7}>Small Candles</Label>
          <Col sm={5}>
          <div className="prod-chosen-qty-block">
            <div className="qty-box"><span className="qty-minus">_</span>
            <input type="text" readOnly value="1" /><span className="qty-plus">+</span>
            </div>
            </div>
          </Col>
        </FormGroup> */}
                  </Col>
                  <Col xs="6">
                    <FormGroup className="product-description-block">
                      <Label for="productDescription">
                        Product description
                      </Label>
                      <Input
                        type="textarea"
                        name="text"
                        id="productDescription"
                        maxLength="30"
                        value={this.state.product_remarks}
					 
                        onChange={this.handleChangeDescription}
                        placeholder="Enter your special message here"
                      />
                      <span className="float-right text-uppercase font-italic">
                     {this.state.remaining} Characters remaining
                      </span>
                    </FormGroup>
                  </Col>
                </Row>

                {/*    </Scrollbars> */}
              </Container>
            </Jumbotron>
          </ModalBody>
          <ModalFooter>
            <div className="product-price-tag">
              <h3>
                <sup>$</sup>
                {this.state.productPrice}
              </h3>
            </div>
            <div className="prod-chosen-qty-block">
              <div className="qty-box">
                <span className="qty-minus" onClick={this.decQty.bind(this)}>
                  _
                </span>
                <input type="text" readOnly value={this.state.defaultQty} />
                <span className="qty-plus" onClick={this.incQty.bind(this)}>
                  +
                </span>
              </div>
            </div>
            <Button
              color="primary"
			  id="addModCart"
              onClick={this.addToCartAction.bind(this, details.product_id)}
            >
              Add to cart
            </Button>
          </ModalFooter>
        </Modal>
		<div
              id="modLoading"
              style={{
                display: "none",
                bottom: 0,
                left: 0,
                position: "fixed",
                right: 0,
                top: 0,
                margin: "auto",
                zIndex: 9999,
                background: "rgba(255, 255, 255, 0.8)"
              }}
            >
              <Spinner
                color="secondary"
                style={{
                  width: "5rem",
                  height: "5rem",
                  bottom: 0,
                  left: 0,
                  position: "absolute",
                  right: 0,
                  top: 0,
                  margin: "auto"
                }}
              />
            </div>
      </React.Fragment>
    );
  }
}
const mapStateToProp = state => ({
  
});
export default connect(
  mapStateToProp,
  { getCartItems }
)(Addtocartpopup);
 
 