import axios from "axios";
import { HOST_URL, APP_ID } from "../config/constans";
import cookie from "react-cookies";
import { customerId, returnValue, showInfo } from "../config/supporters";
export const GETUSERDATA = "GETUSERDATA";
export const GETPROMOCOUNT = "GETPROMOCOUNT";
export const GET_USER_SECONDARY_ADDRESS = "GET_USER_SECONDARY_ADDRESS";
export const REMOVE_CUSTOMER_ADDRESS = "REMOVE_CUSTOMER_ADDRESS";
var qs = require("qs");

const initialState = {
  returnData: [],
  isFetching: true,
  isError: null,
  isStatus: null,
  rewardCount: 0,
  promoCount: 0,
  secondaryAddressList: [],
  addressDeleted: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GETUSERDATA:
      return {
        ...state,
        returnData: action.returnData,
        isStatus: action.isStatus,
        isFetching: false,
        commoData: action.commoData
      };

    case GETPROMOCOUNT:
      return {
        ...state,
        rewardCount: action.rewardCount,
        promoCount: action.promoCount
      };
    case GET_USER_SECONDARY_ADDRESS:
      return {
        ...state,
        secondaryAddressList: action.secondaryAddressList,
       
      };
      case REMOVE_CUSTOMER_ADDRESS:
      return {
        ...state,
        addressDeleted: action.addressDeleted,
       
      };
    default:
      return state;
  }
}

export function getUserData(pageslug = null) {

  let appId = returnValue(cookie.load(APP_ID));
  let API_URL = cookie.load(HOST_URL) + "api/customer/customerdetail";
  const customer_Id = customerId();

  return function (dispatch) {
    axios
      .get(
        `${API_URL}/?app_id=` +
        appId +
        "&status=A" +
        "&refrence=" +
        customer_Id
      )
      .then(response => {
        dispatch({
          type: GETUSERDATA,
          returnData: response.data.result_set,
          commoData: response.data.common,
          isStatus: response.data.status,
          isFetching: false
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
}

export function getPromoCount(pageslug = null) {
  let customer_Id = customerId();
  let app_id = returnValue(cookie.load(APP_ID));
  let API_URL = cookie.load(HOST_URL);
  const inputKeys = [
    "notify",
    "reward",
    "promotion",
    "order",
    "order_all",
    "reward_monthly"
  ];
  var postObject = {};
  postObject = {
    app_id: app_id,
    customer_id: customer_Id,
    act_arr: JSON.stringify(inputKeys)
  };

  return function (dispatch) {
    axios
      .post(API_URL + "api/reports/activity_counts", qs.stringify(postObject))
      .then(response => {
        dispatch({
          type: GETPROMOCOUNT,
          rewardCount: response.data.count.reward_ponits,
          promoCount: response.data.count.promotion

        });
      })
      .catch(error => {
        console.log(error);
      });

  };
}

/* Get user secondary address list */
export function getSecondaryAddress() {
  let appId = returnValue(cookie.load(APP_ID));
  let API_URL = cookie.load(HOST_URL) + "api/customer/get_user_secondary_address";
  const customer_Id = customerId();

  return function (dispatch) {
    axios
      .get(
        `${API_URL}/?app_id=` +
        appId +
        "&status=A" +
        "&refrence=" +
        customer_Id
      )
      .then(response => {
        dispatch({
          type: GET_USER_SECONDARY_ADDRESS,
          secondaryAddressList: response.data.result_set,         
          isStatus: response.data.status,
          isFetching: false
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
}
/* Remove the address */
export function removeCustomerAddress(addressId = null) {
  let customer_Id = customerId();
  let app_id = returnValue(cookie.load(APP_ID));
  let API_URL = cookie.load(HOST_URL);
  if(addressId !== null){      
      var postObject = {};
      postObject = {
        app_id: app_id,
        refrence: customer_Id,
        secondary_address_id: addressId
      };

      return function (dispatch) {
        axios
          .post(API_URL + "api/customer/secondary_address_remove", qs.stringify(postObject))
          .then(response => {
            console.log(response);
            dispatch({
              type: REMOVE_CUSTOMER_ADDRESS,
              addressDeleted: response.data.message,         
              isStatus: response.data.status,

            });
            showInfo(response.data.message, "success")
          })
          .catch(error => {
            console.log(error);
          });

      };
  }
}