import React, { Component } from "react";
import axios from "axios";
import cookie from "react-cookies";
import {
  Button,
  Modal,
  Input,
  ModalBody,
  ModalFooter,
  CardGroup,
  Card,
  InputGroup,
  FormFeedback,
  FormGroup,
  CardBody,
  Col,
  Row
} from "reactstrap";
import {
  appId,
  hostUrl,
  getReferenceID,
  getCookies
} from "../../config/supporters";
import { Scrollbars } from "react-custom-scrollbars";
import ProductPopupImage from "../../assets/img/product-popup-image.jpg";
var _ = require("lodash");
var qs = require("qs");
class Customerpopup extends Component {
  constructor(props) {
    super(props);

    this.openAddCustomerForm = this.openAddCustomerForm.bind(this);
    this.fetchCustomer = this.fetchCustomer.bind(this);
    this.changeFname = this.changeFname.bind(this);
    this.changeLname = this.changeLname.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.closeAll = this.closeAll.bind(this);
    this.onsubmitForm = this.onsubmitForm.bind(this);
    this.state = {
      open_customerform: false,
      customerMobile: "",
      customerFname: "",
      customerLname: "",
      customerEmail: "",
      customerExists: "",
      errors: {},

    };
  }

  openAddCustomerForm() {
    this.setState({
      open_customerform: !this.state.open_customerform

    });
  }

  fetchCustomer(e) {
    const mobile = e.target.value;
    let errors = {}
    this.setState({ customerMobile: mobile, errors:{} });
    if (mobile.length > 7) {
      const APP_ID = appId();
      const HOST_URL = hostUrl();
      axios
        .get(
          HOST_URL +
          "api/settings/get_customerdetail_phone?&status=A&app_id=" +
          APP_ID +
          "&phone_no=" +
          mobile
        )
        .then(res => {
          if (res.data.status === "ok") {
            const result = res.data.result_set;

            this.setState({
              customerMobile: result.customer_phone,
              customerEmail: result.customer_email,
              customerFname: result.customer_first_name,
              customerLname: result.customer_last_name,
              customerExists: "Yes"
            });
          } else {
            this.setState({ customerExists: "No" });
          }
        });

    }
  }

  changeFname(e) {
    this.setState({ customerFname: e.target.value, errors:{} });
  }

  changeLname(e) {
    this.setState({ customerLname: e.target.value, errors:{} });
  }

  changeEmail(e) {
    this.setState({ customerEmail: e.target.value, errors:{} });
  }
  validateForm() {

    let errors = {};
    let formIsValid = true;
    const mobile = this.state.customerMobile;
    const firstName = this.state.customerFname;
    const lastName = this.state.customerLname;
    const customer_email = this.state.customerEmail;
    //Phone Number
    if (!mobile) {
      formIsValid = false;
      errors["customerMobile"] = "*This field is required.";
    } else if (typeof mobile !== "undefined") {
      if (!mobile.match(/^[0-9]+$/)) {
        formIsValid = false;
        errors["customerMobile"] = "*Invalid Contact number";
      } else if (mobile.length < 8) {
        formIsValid = false;
        errors["customerMobile"] = "*Contact number must be of minimum 8 characters length.";
      }
    }
    //First Name
    if (!firstName) {
      formIsValid = false;
      errors["customerFname"] = "*This field is required.";
    } /* else if (typeof firstName !== "undefined") {
      if (!firstName.match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors["customerFname"] = "*First Name must contain only letters.";
      } 
    } */
    //Last Name
    if (!lastName) {
      formIsValid = false;
      errors["customerLname"] = "*This field is required.";
    } /* else if (typeof lastName !== "undefined") {
      if (!lastName.match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors["customerLname"] = "*Last Name must contain only letters.";
      }
    } */
    //Email
    if (!customer_email) {
     // formIsValid = false;
     // errors["customerEmail"] = "*This field is required.";
    } else if (typeof customer_email !== "undefined") {
      let lastAtPos = customer_email.lastIndexOf('@');
      let lastDotPos = customer_email.lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && customer_email.indexOf('@@') === -1 && lastDotPos > 2 && (customer_email.length - lastDotPos) > 2)) {
        formIsValid = false;
        errors["customerEmail"] = "*Email is not valid.";
      }
    }
    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  closeAll() {
    this.setState({
      open_customerform: false
    });
  }

  componentWillReceiveProps(nextProps) {
   // console.log(nextProps.open_customerform);
    if ((nextProps.open_customerform !== this.props.open_customerform)) {
      if (nextProps.open_customerform === true) {
        this.setState({ open_customerform: true });
      }
    }
  }


  onsubmitForm() {
    const mobile = this.state.customerMobile;
    const firstName = this.state.customerFname;
    const lastName = this.state.customerLname;
    const customer_email = this.state.customerEmail;
    const APP_ID = appId();
    const HOST_URL = hostUrl();
    if (this.validateForm()) {
     // console.log(this.state);

      var postObject = {};
      if (mobile !== null && firstName !== "" && lastName !== "") {
        postObject = {
          app_id: APP_ID,
        }

        if (typeof firstName !== "undefined" && firstName !== "") {
          postObject['customer_first_name'] = firstName;

        }
        if (typeof lastName !== "undefined" && lastName !== "") {
          postObject['customer_last_name'] = lastName;
        }

           if(typeof customer_email !== "undefined" && customer_email !=="") {
             postObject['customer_email'] = customer_email;
        
          }  

        if (typeof mobile !== "undefined" && mobile !== "") {
          postObject['customer_phone'] = mobile;
        }

        axios
          .post(HOST_URL + "apiv2/customer/callcenter_updateprofile", qs.stringify(postObject))
          .then(res => {
            if (res.data.status === "ok") {
              const result = res.data.result_set;
              cookie.save("customerId", result.customer_id, { path: '/' });
              cookie.save("orderCustomerfName", firstName, { path: '/' });
              cookie.save("orderCustomerlName", lastName, { path: '/' });
              cookie.save("orderCustomerEmail", customer_email, { path: '/' });
              cookie.save("orderCustomerMobile", mobile, { path: '/' });
              cookie.save("orderValid", 0);
              this.setState({ open_customerform: false });
			  
			       var qs = require("qs");
            var postObject = {
              app_id: APP_ID,
              reference_id: getReferenceID(),
              customer_id: result.customer_id,
              availability_id: getCookies("orderAvailability")
            };

            axios
              .post(
                HOST_URL + "api/cart/update_customer_info",
                qs.stringify(postObject)
              )
              .then(res => {
               //   window.location.reload();
			   this.props.onCustomerSuccess(true);  
              });

            } else if (res.data.status === "error") {
              alert(res.data.message);
            }
          });
      } else {


      }
    }

  }


  componentUnMount() { }

  render() {
    const closeAllBtn = (
      <button className="close" onClick={this.closeAll}>
        &times;
      </button>
    );

    return (
      <React.Fragment>
        {/* 1st modal */}
        <Modal
          size="lg"
          isOpen={this.state.open_customerform}
          toggle={this.openAddCustomerForm}
          className="callcentre-modal "
          modalClassName="customer-form-modal"
        >
          <ModalBody>

            <div id="login-screen" className="app login-screen flex-row align-items-center">
              {closeAllBtn}
              <Row className="justify-content-center">
                <Col md="12">
                  <CardGroup>
                    <Card className="text-white bg-gray py-5 d-md-down-none login-left-block">
                      <CardBody className="text-center">
                        <div className="signup-block">
                          <img src={'/img/table-peoples.png'} alt="image" />
                          <h1>Start Ordering <br />Your Food Today</h1>
                          <h4>Let us know about you<i className="fa fa-arrow-right fa-sm"></i></h4>
                        </div>
                      </CardBody>
                    </Card>
                    <Card className="p-4 login-right-block">
                      <CardBody className="form-block">

                        <div className="signup-logo-block">
                          <img src={'/img/ninja-logo.png'} alt="image" />
                        </div>
                        <p className="text-muted">{/*Create your account */}</p>
                        <FormGroup>
                          <div className="controls">
                            <InputGroup>
                              <Input type="text" value={this.state.customerMobile}  maxLength="8" onInput={this.fetchCustomer} placeholder="Contact number" autoComplete="mobilenumber" />
                              <FormFeedback>
                                {this.state.errors.customerMobile}
                              </FormFeedback>
                            </InputGroup>
                            <p className="help-block">New Customer - Please fill below info</p>
                          </div>
                        </FormGroup>
                        <InputGroup className="mb-3">
                          <Input type="text" value={this.state.customerFname} onInput={this.changeFname} placeholder="First Name" autoComplete="first-name" />
                          <FormFeedback>
                            {this.state.errors.customerFname}
                          </FormFeedback>
                        </InputGroup>
                        <InputGroup className="mb-3">
                          <Input type="text" value={this.state.customerLname} onInput={this.changeLname} placeholder="Last Name" autoComplete="last-name" />
                          <FormFeedback>
                            {this.state.errors.customerLname}
                          </FormFeedback>
                        </InputGroup>
                        <InputGroup className="mb-4">
                          <Input type="email" value={this.state.customerEmail} onInput={this.changeEmail} placeholder="Email Address" autoComplete="email-address" />
                          <FormFeedback>
                            {this.state.errors.customerEmail}
                          </FormFeedback>
                        </InputGroup>
                        <Row>
                          <Col xs="12">
                            <Button color="primary" onClick={this.onsubmitForm} className="px-4">Submit</Button>
                          </Col>
                          {/*    <Col xs="12" className="text-right">
                        <Button color="link" className="px-0">Forgot password?</Button>
                      </Col> */}
                        </Row>

                      </CardBody>
                    </Card>

                  </CardGroup>
                </Col>
              </Row>
            </div>

          </ModalBody>

        </Modal>


      </React.Fragment>
    );
  }
}
export default Customerpopup;
