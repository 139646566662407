import React, { Component } from "react";
import { 
  addressFormat,  
  stripslashes  
} from "../../config/supporters";
import {
  Modal,
  ModalBody,
  Container,
  Jumbotron,
  Row,
  Col
} from "reactstrap";
import {
  DELIVERY_ID,
  PICKUP_ID
} from "../../config/constans";
/* import { Scrollbars } from "react-custom-scrollbars";
var striptags = require("striptags");
const $ = window.$;

 */
 
var _ = require("lodash");

var parse = require("html-react-parser");
var moment = require("moment");
class Vieworderspopup extends Component {
  constructor(props) {
    super(props);
    this.viewOrderModalOpen = this.viewOrderModalOpen.bind(this);
    this.closeAll = this.closeAll.bind(this);
    this.state = {
      viewOrderModalOpen: false,
      stepOne: false,
      currentOrders: []
    };
  }
  viewOrderModalOpen(ordersList) {
    this.setState({
      stepOne: !this.state.stepOne,
      currentOrders: ordersList
    });
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.openViewordersPopup !== this.props.openViewordersPopup &&
      nextProps.viewOrderList !== this.props.viewOrderList
    ) {
      this.viewOrderModalOpen(nextProps.viewOrderList);
    }
  }
  closeAll() {
    this.setState({
      stepOne: false
    });
    this.props.onSelectLanguage(this);   
  }

/* this function used to load modifer items */
loadModifierItems(itemType, modifiers, combo) {
  var len = modifiers.length;
  var comboLen = combo.length;
  var html = "<ul className='table_list cart_sub_items mod'>";

  if (len > 0) {
    for (var i = 0, length = len; i < length; i++) {
      var modName = modifiers[i]["cart_modifier_name"];
      var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
      html +=
        "<li className='sub-item'>" + modName + " : " + modval + "</li> ";
    }
    html += "</ul>";
    var reactElement = parse(html);
    return reactElement;
  } else if (comboLen > 0) {
    for (var k = 0, length = comboLen; k < length; k++) {
      var comboName = combo[k]["menu_component_name"];
      //var comboVal =combo[k]['product_details'][0]['cart_menu_component_product_name'];
      var comboVal = this.showComboProducts(combo[k]["product_details"]);
      html +=
        " <li className='sub-item'><b>" +
        comboName +
        " :</b>" +
        comboVal +
        " " +
        this.showComboModifiers(combo[k]["product_details"][0]["modifiers"]) +
        "</li> ";

      //html += "<li className='sub-item'><b>"+comboName+" :</b>"+comboVal+" "+ "</li> ";
    }
    html += "</ul>";
    var reactElement = parse(html);
    return reactElement;
  }
}
/* show combo products  list */
showComboProducts(combos) {
  var lenCombo = combos.length;
  var html = " ";
  if (lenCombo > 0) {
    for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
      //console.log(combos[0]['modifiers']);
      var comboPro = combos[r]['menu_product_name'];
      var comboQty = combos[r]['menu_product_qty'];
       var comboPrice = combos[r]['menu_product_price'];
      var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : "";
      html +=
        "<li className='sub-item'>" +
        comboQty +
        " X " +
        comboPro +
        newPrice +
        " </li> ";
    }
    return html;
  }
  return "";
}
showComboModifiers(modifiers) {
  //console.log(modifiers);
  var lenMod = modifiers.length;
  var html = "<ul className='table_list cart_sub_items combo'>";
  if (lenMod > 0) {
    for (var i = 0, length = lenMod; i < length; i++) {
      var modName = modifiers[i]["cart_modifier_name"];
      var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
      var modValPrice =
        modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
      var newModValPrice = modValPrice > 0 ? " (+" + modValPrice + ")" : "";

      html +=
        "<li className='sub-item'>" +
        modName +
        ": " +
        modval +
        newModValPrice +
        "</li> ";
    }
    html += "</ul>";
    // var reactElement = htmlToReactParserTo.parse(html );
    return html;
  }

  return "";
}
  
  
  
  render() {
    const current_orders = this.state.currentOrders;
    const cartItems =
      typeof current_orders.items !== "undefined" ? current_orders.items : [];
    const closeAllBtn = (
      <button className="close" onClick={this.closeAll}>
        &times;
      </button>
    );

    return (
      <React.Fragment>
        {/* 1st modal */}
        <Modal
          size="lg"
          isOpen={this.state.stepOne}
          className="callcentre-modal order-details-modal"
        >
          <div className="modal-header">
            <div className="modal-top-icon">{closeAllBtn}</div>
            <div className="modal-title-block">
              <h1>Order Details</h1>
            </div>
          </div>
          <ModalBody>
		   <Jumbotron
                      fluid
                      className="customer-delivery-info-jumbotron"
                    >
                      <h6 className="title">Order Details</h6>
                      <Container fluid>
                        <Row>
                          <Col sm="6">
                            <Row>
                              <div className="customer-delivery-address col-10 pr-0">
                                {current_orders.order_availability_id  === DELIVERY_ID && (
                                  <h6>Delivery Address</h6>
                                )}
                                {current_orders.order_availability_id  === PICKUP_ID && (
                                  <h6>Pickup Address</h6>
                                )}
                               {current_orders.order_availability_id  === DELIVERY_ID && (     <h5>
                                  {addressFormat(
                                    current_orders.order_customer_unit_no1,
                                   current_orders.order_customer_unit_no2,
                                    current_orders.order_customer_address_line1,
                                  current_orders.order_customer_address_line2,
                                   current_orders.order_customer_postal_code
                                  )}
                                </h5>     )}
								
								 {current_orders.order_availability_id  === PICKUP_ID && (     <h5>
                                  {addressFormat(
                                    current_orders.outlet_name,
                                   current_orders.outlet_unit_number1,
                                    current_orders.outlet_address_line1,
                                  current_orders.outlet_address_line2,
                                   current_orders.outlet_postal_code
                                  )}
                                </h5>     )}
								
                                <span className="order-handled-by">
                                  Order handled by :{" "}
                                  {current_orders.outlet_name}
                                </span>
                                
                              </div>
                             
                            </Row>
                          </Col>
                          <Col sm="6">
                            <Row>
                              <div className="customer-delivery-time col-10 pr-0">
                                {current_orders.order_availability_id  === DELIVERY_ID && (
                                  <h6>Delivery Date & Time</h6>
                                )}
                                {current_orders.order_availability_id  === PICKUP_ID && (
                                  <h6>Pickup Date & Time</h6>
                                )}
                                <h5>
                                  <span className="cd-date">
								  {moment(current_orders.order_date).format("DD-MM-YYYY")}
							 
                                  </span>
                                  <span className="cd-time">
                                        {moment(current_orders.order_date).format("hh:mm A")}
                                  </span>
                                </h5>
                              </div>
                      
                            </Row>
                          </Col>
                        </Row>
                      </Container>
                    </Jumbotron>
            <Jumbotron
              fluid
              className="customer-order-info-jumbotron order-info-jumbotron"
            >
              <h6 className="title">Order Details </h6>

              <Container fluid>
                <div className="calctrcart_table">
                  <div className="calctrcart_body">
                    {_.isEmpty(cartItems) === false &&
                      cartItems.map((cart, cartIndex) => (
                        <div className="calctrcart_row" key={cartIndex}>
                          <div className="row">
                            <div className="col-7 col-sm-7 calctrcart_left">
                              {cart.item_image !== "" && (
                                <div className="calctrcart_img">
                                  <img
                                    className="img-responsive"
                                    src={cart.item_image}
                                    alt="Call center"
                                  />
                                </div>
                              )}
                              <div className="calctrcart_info">
                                <h5>{stripslashes(cart.item_name)}</h5>
                                <div className="calctrcart_extrainfo">
                                {this.loadModifierItems(
                                            cart.item_type,
                                            cart.modifiers,
                                            cart.set_menu_component
                                          )}
                                  {/* <span className="product-attr">Size:&nbsp; Large</span>    
                            <span className="product-attr">Add on:&nbsp; Name..</span>  */}
                                </div>
                              </div>
                              {cart.cart_item_special_notes !== "" && (
                                <p className="product-instruction">
                                  {stripslashes(cart.cart_item_special_notes)}
                                </p>
                              )}
                            </div>
                            <div className="col-2 col-sm-3 calctrcart_right text-right">
                              <div className="prod-chosen-qty-block">
                                <div className="qty-box font-weight-bold">Qty: {cart.item_qty}</div>
                              </div>
                            </div>

                            <div className="col-3 col-sm-2 calctrcart_price text-right">
                              <span className="font-weight-bold">$ {cart.item_total_amount}</span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>

                  <div className="calctrcart_footer">
                            <div className="calctrcart_row">
                              <div className="row">
                                <div className="col-8">
                                  <p className="text-uppercase">SUBTOTAL</p>
                                </div>
                                <div className="col-4 text-right">
                                  <span>$ {current_orders.order_sub_total}</span>
                                </div>
                              </div>
                            </div>
                            {current_orders.order_availability_id === DELIVERY_ID && (
                              <div className="calctrcart_row">
                                <div className="row">
                                  <div className="col-8">
                                    <p className="text-uppercase">
                                      Delivery Charges
                                    </p>
                                  </div>
                                  <div className="col-4 text-right">
                                   ${current_orders.order_delivery_charge}
                                  </div>
                                </div>
                              </div>
                            )}

                            {current_orders.order_availability_id === DELIVERY_ID && (
                              <div className="calctrcart_row">
                                <div className="row">
                                  <div className="col-8">
                                    <p className="text-uppercase">
                                      Additional Delivery Charges
                                    </p>
                                  </div>
                                  <div className="col-4 text-right">
                                   {current_orders.order_additional_delivery}
                                   
                                  </div>
                                </div>
                              </div>
                            )}

                            {current_orders.order_discount_applied === "Yes" && (
                                <div className="calctrcart_row">
                                  <div className="row">
                                    <div className="col-8">
                                      <p className="text-uppercase">
                                        Discount   
                                      </p>
                                    </div>
                                    <div className="col-4 text-right">
                                      <span>${current_orders.order_discount_amount}</span>
                                    </div>
                                  </div>
                                </div>
                              )}

                            <div className="calctrcart_row">
                              <div className="row">
                                <div className="col-8">
                                  <p className="text-uppercase">
                                    GST (Inclusive)
                                  </p>
                                </div>
                                <div className="col-4 text-right">
                                  <span>$ {current_orders.order_tax_calculate_amount}</span>
                                </div>
                              </div>
                            </div>
                            <div className="calctrcart_row calctrcart_footer_totrow">
                              <div className="row">
                                <div className="col-8">
                                  <p className="text-uppercase">Total</p>
                                </div>
                                <div className="col-4 text-right">
                                  <span>${current_orders.order_total_amount}</span>
                                </div>
                              </div>
                            </div>
                            
                              </div> 
                </div>
              </Container>
            </Jumbotron>
          </ModalBody>
           
        </Modal>
      </React.Fragment>
    );
  }
}

export default Vieworderspopup;
