import axios from "axios";
import cookie from "react-cookies";
import { HOST_URL, APP_ID, MARS_BASE_URL } from "../../config/constans";
import { returnValue,customerId,getReferenceID} from "../../config/supporters";
export const GET_CURRENT_ORDERS = "settings/GET_CURRENT_ORDERS";

const initialState = {
  returnData: [],
  isFetching: true,
  isError: null,
  isStatus: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_CURRENT_ORDERS:
      return {
        ...state,
        returnData: action.returnData,
        isStatus: action.isStatus,
        isFetching: false
      };

    default:
      return state;
  }
}
export function getcurrentOrders(search_key , search_value , a_from_date , a_to_date , order_source,outlet_id, outlet_type,date_type_from) {
  let appId = returnValue(cookie.load(APP_ID));
  let API_URL = cookie.load(HOST_URL) + "apiv2/reports/order_history";
 
  if(MARS_BASE_URL ===  cookie.load(HOST_URL))  {  console.log(cookie.load(HOST_URL), MARS_BASE_URL);
      API_URL = cookie.load(HOST_URL) + "call-center/reports/order_history";
  }

  var searchStirng1 = "";
    var searchStirng2 = "";
	  var searchStirng3 = "";
	  var searchStirng4 =  "";
	  var searchStirng5 = "";
  if( (search_key !== "" && typeof search_key !== "undefined" ) && (search_value !== ""  && search_value !==  null && typeof search_value !== "undefined" )) {
	   searchStirng1=  "&"+search_key + "=" + search_value;
  }
  
    if( ( a_from_date !== ""  && typeof a_from_date !== "undefined" )  && (a_to_date !== "" && typeof a_to_date !== "undefined" )) {
	  searchStirng2=  "&from_date=" + a_from_date + "&to_date="+a_to_date;
  }
  
 
   if( typeof order_source !== "undefined" &&  order_source !== "" &&  order_source !== null) {
	 searchStirng3=  "&order_source=" + order_source;
  }

   if( (typeof outlet_id !== "undefined" &&  outlet_id !== "" &&  outlet_id !== null) && (typeof outlet_type !== "undefined" &&  outlet_type !== "" &&  outlet_type !== null) ) {
	 searchStirng4=  "&outlet_id=" + outlet_id+"&outlet_type="+outlet_type;
  }
  
     if( ( date_type_from !== ""  && typeof date_type_from !== "undefined" )) {
	  searchStirng5=  "&date_filter_type=" + date_type_from ;
  }

  return function(dispatch) {
    axios
      .get(
        `${API_URL}/?app_id=` +
          appId + "&order_type=Currentday&except_availability=yes"+searchStirng1 + searchStirng2 + searchStirng3 + searchStirng4 + searchStirng5
      )
      .then(response => {
        dispatch({
          type: GET_CURRENT_ORDERS,
          returnData: response.data.result_set,
          isStatus: response.data.status,
          isFetching: false
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
}
