import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import store, { history } from "../../store";
import { DELIVERY_ID, HOST_URL, APP_ID } from "../../config/constans";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";

//import { getSetings } from "../../stores/settings";

import cookie from "react-cookies";

import { Alert, Spinner } from "reactstrap";
import {
  Button,
  Modal,
  Input,
  ModalBody,
  ModalFooter,
  ListGroup,
  ListGroupItem,
  InputGroup,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
} from "reactstrap";

/* two */

import ClockIcon from "../../assets/img/icons/clock.png";
import OrderdatetimeSlot from "./OrderdatetimeSlot";
var _ = require("lodash");
var moment = require("moment");
var striptags = require("striptags");

class Commomdatepickerpopup extends Component {
  constructor(props) {
    super(props);

    this.closeAll = this.closeAll.bind(this);

    this.state = {getDateTimeFlg:'yes', seleted_ord_date: '',seleted_ord_time: '', seletedAvilablityId : DELIVERY_ID, seletedOutletId : 242, order_tat_time : 60 };
  }

  closeAll() {
    this.setState({});
  }


	  setdateTimeFlg = (field, value) => {
		  if(field == 'tmflg') {
				this.setState({getDateTimeFlg:value});
		  }	
		  else if(field == 'ordDate') {
				var ordTime = '';
				//$('.ordrdatetime_error').html('');
				this.setState({seleted_ord_date:value,seleted_ord_time:ordTime});
		  }		
		  else if(field == 'ordTime') {
			  //  $('.ordrdatetime_error').html('');
				this.setState({seleted_ord_time:value});
		  }
		  else if(field == 'triggerErrorPopup') {
			     
		  }	  
	  }
	  
  render() {
    const closeAllBtn = (
      <button className="close" onClick={this.closeAll}>
        &times;
      </button>
    );

    return (
      <React.Fragment>
        {/* fourth  modal */}
        <Modal
          size="lg"
          isOpen={false}
          className="callcentre-modal deliverytime-modal"
        >
          <div className="modal-header">
            <div className="modal-top-icon">
              <img src={ClockIcon} alt={"Shopping Bag"} />
              {closeAllBtn}
            </div>
            <div className="modal-title-block">
              <h1>Delivery Time</h1>
              <h5>Earliest Delivery Date & Time </h5>
            </div>
          </div>
          <ModalBody>
            <Form>
              <FormGroup>
                <Row>
				
			  <OrderdatetimeSlot {...this.props} hdrState={this.state} setdateTimeFlg={this.setdateTimeFlg} />
                  <Col xs={6}>Date</Col>
                  <Col xs={6}>Time</Col>
                </Row>
              </FormGroup>
            </Form>
            <p>Click time section to pick advanced order date & time</p>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary">Close</Button>
            <Button color="primary">Update</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };
}

const mapStateToProp = (state) => ({});
export default connect(mapStateToProp, {})(withCookies(Commomdatepickerpopup));
