module.exports = { 
  ADMIN_ID : "callAdminId",
  ADMIN_NAME : "callAdminName",
  HOST_URL : "hostUrl",
  APP_ID : "app_id",
  CLIENT_LOGO : "client_logo",
  DELIVERY_ID : "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11",
  PICKUP_ID : "718B1A92-5EBB-4F25-B24D-3067606F67F0",
  DINEIN_ID : "EF9FB350-4FD4-4894-9381-3E859AB74019",
  CEDELE_APP_ID : "DB72C391-60BA-4176-8AB0-5CA0FEBC7BD1",
  SUCCESS_ALERT : "success",
  FAILURE_ALERT : "danger",
  stripeKey     : "pk_test_gOgyc4KtNAk48z5WOuqFQWdP",
  stripeEmail    : "vignesh@jankosoft.com",
  stripeReference    : "ninjapro",
  stripeDesc    : "My Checkout",
  stripeCurrency    : "SGD",
  dineInPercentage : 25,
  MARS_BASE_URL : 'https://mars.ninjaos.com/',
  
 }
