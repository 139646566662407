import React, { Component } from "react";
import { connect } from "react-redux";
//import { checkAuth } from "../../config/supporters";
import {
  Container,
  TabContent,
  Badge,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Row,
  Col,
  Form,
  FormGroup,
  Table,
  Input,
  ButtonGroup,
  Modal,
  ModalFooter,
  ModalBody,
  Spinner,
  Alert,
} from "reactstrap";
import { getSetings } from '../../stores/settings'
import { getCartItems } from "../../stores/cart";
import { getcurrentOrders } from "../../stores/orders/current";
import { getadvancedOrders } from "../../stores/orders/advanced";
import { getdraftOrders } from "../../stores/orders/draftorders";
import { getAllOutlets } from "../../stores/delivery/deliveryoutlets";
import SideBar from "../layout/sidebar";
import Header from "../layout/header";
import classnames from "classnames";
import SearchIconImage from "../../assets/img/icons/search-icon-white.png";
import RefreshIconImage from "../../assets/img/icons/refresh-icon.png";
import axios from "axios";
import cookie from "react-cookies";
import { Link } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  SPRING_APP_ID,
} from "../../config/apps";

import {
  checkAuth,
  appId,
  hostUrl,
  stripslashes,
  getAliasName,
  getReferenceID,
  customerId,
  getCookies,
  addressFormat,
  encode_value,
  showStatus,
  showInfo
} from "../../config/supporters";

import {
  DELIVERY_ID,
  PICKUP_ID,
  SUCCESS_ALERT,
  FAILURE_ALERT,
  CEDELE_APP_ID,
} from "../../config/constans";

import Vieworderspopup from "./vieworderspopup";

var _ = require("lodash");
var qs = require("qs");
var moment = require("moment");
const $ = window.$;

class Orders extends Component {
  constructor(props) {
    super(props);

    /* validate auth token */
    if (checkAuth() === false) {
      this.props.history.push("/");
    }
    this.toggle = this.toggle.bind(this);
    this.openViewordersPopup = this.openViewordersPopup.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitAddPayment = this.submitAddPayment.bind(this);
    this.submitRequestPayment = this.submitRequestPayment.bind(this);
   // this.closeViewordersPopup = this.closeViewordersPopup(this);
    this.state = {
      activeTab: "1",
      orderSts: "",
      orderResult: [],
      advancedorderSts: "",
      advancedorderResult: [],
      order_refer_id: "",
      draft_prim_id: "",
      confirmationAlert: false,
      draftSts: "",
      draftorderResult: [],
      order_edit_avail: "",
      alertType: FAILURE_ALERT,
      cartAlert: false,
      cartAlertMsg: "",
      sendconfirmationAlert: false,
      draftsendconfirmationAlert: false,
      do_order_id: "",
      orderremarkAlert: false,
      remark_order_id: "",
      order_remarks: "",
      kitchen_remarks: "",
      kitchen_order_id: "",
      kitchenremarkAlert: false,
      track_remarks: "",
      track_order_id: "",
      trackremarkAlert: false,
      order_status: { value: "1" },
      statusAlert: false,
      status_order_id: "",
      cancel_remarks: "",
      modal: false,
      vieworderspopup: false,
      draftOrderLocalId: "",
      openPaymentRequest: false,
      paymentRequestId: false,
      activeTabPayment: "1",
      addPaymentFields: {},
      addPaymentErrors: {},
      paymentstartDate: new Date(),
      addPaymentList : [],
      paymentSuccess : '',
      requestPaymentFields: {},
      requestPaymentErrors: {},
    };
  }

  /* For request Method */
  submitAddPayment(e) {
    if (this.handleValidation()) {

        /*add loading */
        $("#cartLoading").show();

       const appID = appId();
      const apiUrl = hostUrl();
      var postObject = {};
      postObject = {
        app_id: appID,
        date : this.state.paymentstartDate,
        admin_id : getCookies('callAdminId'),
        amount : this.state.addPaymentFields['payment_amount'],
        type : this.state.addPaymentFields['payment_via'],
        emailid : this.state.addPaymentFields['payment_email'],
        order_id : this.state.paymentRequestId,
        chekid : (typeof this.state.addPaymentFields['payment_cheque_no'] !=="undefined" ? this.state.addPaymentFields['payment_cheque_no'] : '') , 
        bank_name :  (typeof this.state.addPaymentFields['payment_bank_name'] !=="undefined" ? this.state.addPaymentFields['payment_bank_name'] : ''),
        translationNumber :  (typeof this.state.addPaymentFields['payment_transaction_no'] !=="undefined" ? this.state.addPaymentFields['payment_transaction_no'] : '')

      }

 

        axios
          .post(apiUrl + "call-center/paymentrequest/add_payment", qs.stringify(postObject))
          .then((res) => {
            $("#cartLoading").hide();
            if (res.data.status === "success") {
 
              this.setState({
                addPaymentList :  res.data.result_set,
                 activeTabPayment : '1', 
                 addPaymentFields : { payment_via : '', payment_amount : '',  payment_cheque_no : '', payment_bank_name : '', payment_transaction_no : ''},
                });

                showInfo(res.data.message,'Success');
            } else if (res.data.status === "error") {
               showInfo(res.data.form_error,'Warning' );
            }
          });
       
    }  
  }
  handleValidation() {
    let fields = this.state.addPaymentFields;
    let addPaymentErrors = {};
    let formIsValid = true;

    if (!fields["payment_amount"]) {
      formIsValid = false;
      addPaymentErrors["payment_amount"] = "Payment Amount field is required";
    }

    if (!fields["payment_email"]) {
      formIsValid = false;
      addPaymentErrors["payment_email"] = "Payment Email field is required";
    }

    if (!fields["payment_via"]) {
      formIsValid = false;
      addPaymentErrors["payment_via"] = "Payment Mode field is required";
    } else if (fields["payment_via"] === "2" && !fields["payment_cheque_no"]) {
		if(!fields["payment_cheque_no"]) {
	      formIsValid = false;
         addPaymentErrors["payment_cheque_no"] = "Cheque no field is required";
		}
     
	  if( !fields["payment_bank_name"]) {
		  formIsValid = false;
      addPaymentErrors["payment_bank_name"] = "Bank Name field is required";
		  
	  }
    }
	 
    if (typeof fields["payment_email"] !== "undefined") {
      let lastAtPos = fields["payment_email"].lastIndexOf("@");
      let lastDotPos = fields["payment_email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["payment_email"].indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          fields["payment_email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        addPaymentErrors["payment_email"] = "Payment Email is not valid";
      }
    }

    /* payment_cheque_no */

    this.setState({ addPaymentErrors: addPaymentErrors });
    return formIsValid;
  }

  handleChange(field, e) {
    let addPaymentFields = this.state.addPaymentFields;

    addPaymentFields[field] = e.target.value;
    this.setState({ addPaymentFields, addPaymentErrors: {} });
  }

  handleDateChange(date) {
   // let dateVal = new Date(date);
    // dateVal = moment(date).format("dd-mm-yyyy h:is:s");
 //  console.log(dateVal);
    this.setState({
      paymentstartDate: date,
    });
  }


  /* request payment  */
  submitRequestPayment(e) {
    if (this.handleValidationRequestPayment()) {

        /*add loading */
        $("#cartLoading").show();

       const appID = appId();
      const apiUrl = hostUrl();
      var postObject = {};
      postObject = {
        app_id: appID,
        requestdate : this.state.paymentstartDate,
        admin_id : getCookies('callAdminId'),
        requestamount : this.state.requestPaymentFields['payment_amount'],
        requestemail : this.state.requestPaymentFields['payment_email'],
        order_id : this.state.paymentRequestId,

      }

    // console.log(postObject); return false;

        axios
          .post(apiUrl + "call-center/paymentrequest/advance_payment_request", qs.stringify(postObject))
          .then((res) => {
            $("#cartLoading").hide();
            if (res.data.status === "success") {
 
              this.setState({
 
                 requestPaymentFields : {   payment_amount : ''},
                });

                showInfo(res.data.message,'Success');
            } else if (res.data.status === "error") {
               showInfo(res.data.form_error,'Warning' );
            }
          });
       
    }  
  }
  handleValidationRequestPayment() {
    let fields = this.state.requestPaymentFields;
    let addPaymentErrors = {};
    let formIsValid = true;

    if (!fields["payment_amount"]) {
      formIsValid = false;
      addPaymentErrors["payment_amount"] = "Payment Amount field is required";
    }

    if (!fields["payment_email"]) {
      formIsValid = false;
      addPaymentErrors["payment_email"] = "Payment Email field is required";
    }

   
    
	 
    if (typeof fields["payment_email"] !== "undefined") {
      let lastAtPos = fields["payment_email"].lastIndexOf("@");
      let lastDotPos = fields["payment_email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["payment_email"].indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          fields["payment_email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        addPaymentErrors["payment_email"] = "Payment Email is not valid";
      }
    }

    /* payment_cheque_no */

    this.setState({ requestPaymentErrors: addPaymentErrors });
    return formIsValid;
  }

  handleChangeRequestPayment(field, e) {
    let requestPaymentFields = this.state.requestPaymentFields;

    requestPaymentFields[field] = e.target.value;
    this.setState({ requestPaymentFields, requestPaymentErrors: {} });
  }


  componentWillMount() {
    this.props.getcurrentOrders();
    this.props.getadvancedOrders();
    this.props.getdraftOrders();
    this.props.getCartItems();
    this.props.getAllOutlets();
    this.props.getSetings();
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  toggleactiveTabPayment(tab) {
    if (this.state.activeTabPayment !== tab) {
      this.setState({
        activeTabPayment: tab,
      });
    }
  }
  openViewordersPopup(getOrderInfo) {
    this.setState({
      vieworderspopup: !this.state.vieworderspopup,
      ordersList: getOrderInfo,
    });
  }

  closeViewordersPopup() {
    this.setState({
      vieworderspopup: false,
      ordersList: {},
    });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.currentOrdersSts !== this.props.currentOrdersSts) {
      this.setState({
        orderSts: nextProps.currentOrdersSts,
        orderResult: nextProps.currentOrders,
      });
    }

    if (nextProps.advancedOrdersSts !== this.props.advancedOrders) {
      this.setState({
        advancedOrders: nextProps.advancedOrdersSts,
        advancedorderResult: nextProps.advancedOrders,
      });
    }

    if (nextProps.draftOrdersSts !== this.props.draftOrdersSts) {
      this.setState({
        draftSts: nextProps.draftOrdersSts,
        draftorderResult: nextProps.draftOrders,
      });
    }

    if (nextProps.cartStatus) {
      this.setState({
        cartStatus: nextProps.cartStatus,
        cartItems: nextProps.cartItems,
      });
    }
  }

  applySearch(action) {
    const search_key = $("#" + action + "_search_key").val();
    const search_value = $("#" + action + "_search_value").val();
    var a_from_date = $("#" + action + "_from_date").val();
    var a_to_date = $("#" + action + "_to_date").val();
    const order_source =
      $("#" + action + "_order_source").val() !== ""
        ? $("#" + action + "_order_source").val()
        : "";
    const outlet_id = $("#" + action + "_outlet_id").val();
    const outlet_type = $("#" + action + "_outlet_type").val();
    const date_type_from = $("#" + action + "_date_filter_type_from").val();
    const date_type_to = $("#" + action + "_date_filter_type_to").val();

    if (action === "a") {
      this.props.getadvancedOrders(
        search_key,
        search_value,
        a_from_date,
        a_to_date,
        order_source,
        outlet_id,
        outlet_type,
        date_type_from
      );
    }

    if (action === "c") {
      this.props.getcurrentOrders(
        search_key,
        search_value,
        a_from_date,
        a_to_date,
        order_source,
        outlet_id,
        outlet_type,
        date_type_from
      );
    }
  }

  resetSearch(action) {
    $("#" + action + "_search_key").val("");
    $("#" + action + "_search_value").val("");
    $("#" + action + "_from_date").val("");
    $("#" + action + "_to_date").val("");
    $("#" + action + "_order_source").val("");
    $("#" + action + "_outlet_id").val("");
    $("#" + action + "_outlet_type").val(1);

    if (action === "a") {
      this.props.getadvancedOrders();
    }

    if (action === "c") {
      this.props.getcurrentOrders();
    }
  }

  /*Get Draft Order*/
  getdraftorder(order_refer_id) {
    const APP_ID = appId();
    const HOST_URL = hostUrl();
    let customer_id = customerId();
    this.setState({ order_refer_id: order_refer_id });
    let cartItems = this.state.cartItems;

    if (cartItems) {
      this.setState({ confirmationAlert: true });
    } else {
      $("#proLoading").show();
      axios
        .get(
          HOST_URL +
            "/api/orders/get_draft_order?app_id=" +
            APP_ID +
            "&order_refer_id=" +
            order_refer_id
        )
        .then((res) => {
          $("#proLoading").hide();

          if (res.data.status === "ok") {
            let order_detail = res.data.order_detail;
            let item_details = res.data.item_details;

            cookie.save("customerId", order_detail.order_customer_id, {
              path: "/",
            });
            cookie.save(
              "orderCustomerfName",
              order_detail.order_customer_fname,
              { path: "/" }
            );
            cookie.save(
              "orderCustomerlName",
              order_detail.order_customer_lname,
              { path: "/" }
            );
            cookie.save(
              "orderCustomerEmail",
              order_detail.order_customer_email,
              { path: "/" }
            );
            cookie.save(
              "orderCustomerMobile",
              order_detail.order_customer_mobile_no,
              { path: "/" }
            );
            cookie.save(
              "orderAddressOne",
              order_detail.order_customer_address_line1,
              { path: "/" }
            );
            cookie.save(
              "orderAddressTwo",
              order_detail.order_customer_address_line2,
              { path: "/" }
            );
            cookie.save("orderUnitOne", order_detail.order_customer_unit_no1.replace('#',''), {
              path: "/",
            });
            cookie.save("orderUnitTwo", order_detail.order_customer_unit_no2.replace('#',''), {
              path: "/",
            });
            this.setState({ open_customerform: false });

            let availability_id =
              order_detail.order_availability_name == "Delivery"
                ? DELIVERY_ID
                : PICKUP_ID;
            let order_postal_code = order_detail.order_customer_postal_code;
            let order_outletId = order_detail.order_outlet_id;

            var deliveryDate = moment(order_detail.order_date).format(
              "DD/MM/YYYY"
            );
            var deliveryTime = moment(order_detail.order_date).format(
              "hh:mm A"
            );
            var deliveryTimeOrder = moment(order_detail.order_date).format(
              "HH:mm"
            );

            const OUTLET_URL =
              HOST_URL +
              "apiv2/outlets/findOutletZone/?app_id=" +
              APP_ID +
              "&availability_id=" +
              availability_id +
              "&postal_code=" +
              order_postal_code +
              "&outlet_id=" +
              order_outletId +
              "&skip_timing=Yes";
            this.setState({ checkpostalCode: true });

            axios.get(OUTLET_URL).then((res) => {
              this.setState({ checkpostalCode: false });
              if (res.data.status === "ok") {
                const postalcodeInfo =
                  res.data.result_set.postal_code_information;

                this.setState({ status: "Loading" }, function () {}.bind(this));

                this.setState(
                  {
                    outletDetails: res.data.result_set,
                    stepThree: !this.state.stepThree,
                    stepOne: false,
                    stepTwo: false,
                    stepFour: false,
                    CHOOSE_POSTALCODE: false,
                    ordertakenOutlet: res.data.result_set.outlet_name,
                    ordertakenOutletAddress:
                      postalcodeInfo.zip_buno +
                      " " +
                      postalcodeInfo.zip_sname +
                      ", Singapore " +
                      postalcodeInfo.zip_code,
                  },
                  function () {
                    this.setoutletinfo();
                  }.bind(this)
                );
              } else {
                this.setState({
                  CHOOSE_POSTALCODE: true,
                  dyZoneError: res.data.message,
                });
              }
            });

            cookie.save("orderOutletId", order_outletId, { path: "/" });
            cookie.save("orderPostalCode", order_postal_code, { path: "/" });
            cookie.save("orderDeliveryDate", deliveryDate, { path: "/" });
            cookie.save("orderDeliveryTime", deliveryTime, { path: "/" });
            cookie.save("orderDeliveryTimeRequest", deliveryTimeOrder, {
              path: "/",
            });
            cookie.save("orderAvailability", availability_id, { path: "/" });

            var _this = this;

            _this.apicallrecursive(item_details, 0);

            $(".pro_qty_btn").hide();

            //window.location.reload();
            _this.props.getCartItems();
            //this.props.getCartItems();
            this.setState({
              defaultQty: 1,
              cartAlert: true,
              cartAlertMsg: res.data.message,
              alertType: SUCCESS_ALERT,
            });
          } else if (res.data.status === "error") {
          }
        });
    }
  }

  closeAlert() {
    this.setState((prevState) => ({
      confirmationAlert: !prevState.confirmationAlert,
    }));
  }

  apicallrecursive(draftitem, r_index) {
    var item = draftitem[r_index];
    var prev_index = !(r_index in draftitem) ? "" : 1;
    if (prev_index === 1) {
      var productId = item.item_product_id;
      var proName = item.item_name;
      var prosku = item.item_sku;
      var proPrice = item.item_unit_price;
      var proQty = item.item_qty;
      var proImage = item.item_image;
      var allowCart = "Yes";
      var product_remarks = item.item_specification;

      this.setState({ productId: productId });
      this.setState({ proName: proName });
      this.setState({ prosku: prosku });
      this.setState({ proPrice: proPrice });
      this.setState({ proQty: proQty });
      this.setState({ proImage: proImage });
      this.setState({ allowCart: allowCart });
      this.setState({ product_remarks: product_remarks });

      var totalPrice = proQty * proPrice;

      var modifier = item.modifiers;
      var combo = item.set_menu_component;

      const appID = appId();
      const apiUrl = hostUrl();
      const availabilityId = getCookies("orderAvailability");
      var postObject = {};
      postObject = {
        app_id: appID,
        product_id: productId,
        subquantity: cookie.load("subquantity"),
        notsubquantity: cookie.load("notsubquantity"),
        product_name: stripslashes(proName),
        product_sku: prosku,
        reference_id: getReferenceID(),
        customer_id: customerId(),
        product_image: proImage,
        availability_id: availabilityId,
        product_unit_price: proPrice,
        product_qty: proQty,
        product_total_price: totalPrice,
        modifiers: JSON.stringify(modifier),
        menu_set_component: JSON.stringify(combo),
        product_remarks: this.state.product_remarks,
        callcenter_draft: "Yes",
      };

      /* 	"cart_item_min_qty" : this.state.cart_minimum_qty, */

      var qs = require("qs");
      /*add loading */
      $("#proLoading").show();

      axios
        .post(apiUrl + "api/cart/insert", qs.stringify(postObject))
        .then((res) => {
          $("#proLoading").hide();
          if (res.data.status === "ok") {
            this.setState({
              cartCount: 1,
              cartAlert: true,
              cartAlertMsg: res.data.message,
              alertType: SUCCESS_ALERT,
              openproduct_addtocart: false,
            });

            this.props.getCartItems();

            //showInfo(res.data.message);
            var u_index = parseInt(r_index) + 1;
            this.apicallrecursive(draftitem, u_index);
          } else if (res.data.status === "error") {
            //showInfo('Warning', res.data.message);
          }
        });
    }
  }

  /*delete draft order*/
  deletedraftorder(draft_prim_id, d_index) {
    const APP_ID = appId();
    const HOST_URL = hostUrl();
    this.setState({ draft_prim_id: draft_prim_id });

    $("#proLoading").show();
    axios
      .get(
        HOST_URL +
          "/api/orders/delete_draft_order?app_id=" +
          APP_ID +
          "&draft_prim_id=" +
          draft_prim_id
      )
      .then((res) => {
        $("#proLoading").hide();

        if (res.data.status === "ok") {
          $("#" + d_index).remove();
        } else if (res.data.status === "error") {
        }
      });
  }

  orderEdit(order_primary_id, order_local_no) {
    const APP_ID = appId();
    const HOST_URL = hostUrl();
    this.setState({ order_primary_id: order_primary_id });
    this.setState({ order_local_no: order_local_no });
    this.setState({ order_edit_avail: "Yes" });
    cookie.save("order_edit_avail", "Yes", { path: "/" });
    cookie.save("order_edit_localno", order_local_no, { path: "/" });
    cookie.save("order_edit_primid", order_primary_id, { path: "/" });
    var cartItems = this.state.cartItems;
    if (cartItems) {
      this.setState({ confirmationAlert: true });
    } else {
      $("#proLoading").show();
      axios
        .get(
          HOST_URL +
            "/api/orders/get_edit_order?app_id=" +
            APP_ID +
            "&order_primary_id=" +
            order_primary_id
        )
        .then((res) => {
          $("#proLoading").hide();

          if (res.data.status === "ok") {
            let order_detail = res.data.order_detail;
            let item_details = res.data.item_details;

            cookie.save("customerId", order_detail.order_customer_id, {
              path: "/",
            });
            cookie.save(
              "orderCustomerfName",
              order_detail.order_customer_fname,
              { path: "/" }
            );
            cookie.save(
              "orderCustomerlName",
              order_detail.order_customer_lname,
              { path: "/" }
            );
            cookie.save(
              "orderCustomerEmail",
              order_detail.order_customer_email,
              { path: "/" }
            );
            cookie.save(
              "orderCustomerMobile",
              order_detail.order_customer_mobile_no,
              { path: "/" }
            );
            cookie.save(
              "orderAddressOne",
              order_detail.order_customer_address_line1,
              { path: "/" }
            );
            cookie.save(
              "orderAddressTwo",
              order_detail.order_customer_address_line2,
              { path: "/" }
            );
            cookie.save("orderUnitOne", order_detail.order_customer_unit_no1, {
              path: "/",
            });
            cookie.save("orderUnitTwo", order_detail.order_customer_unit_no2, {
              path: "/",
            });
            this.setState({ open_customerform: false });

            let availability_id =
              order_detail.order_availability_name == "Delivery"
                ? DELIVERY_ID
                : PICKUP_ID;
            let order_postal_code = order_detail.order_customer_postal_code;
            let order_outletId = order_detail.order_outlet_id;

            var deliveryDate = moment(order_detail.order_date).format(
              "DD/MM/YYYY"
            );
            var deliveryTime = moment(order_detail.order_date).format(
              "hh:mm A"
            );
            var deliveryTimeOrder = moment(order_detail.order_date).format(
              "HH:mm"
            );

            if (availability_id == DELIVERY_ID) {
              const OUTLET_URL =
                HOST_URL +
                "apiv2/outlets/findOutletZone/?app_id=" +
                APP_ID +
                "&availability_id=" +
                availability_id +
                "&postal_code=" +
                order_postal_code +
                "&outlet_id=" +
                order_outletId +
                "&skip_timing=Yes";
              this.setState({ checkpostalCode: true });

              axios.get(OUTLET_URL).then((res) => {
                this.setState({ checkpostalCode: false });
                if (res.data.status === "ok") {
                  const postalcodeInfo =
                    res.data.result_set.postal_code_information;

                  this.setState(
                    { status: "Loading" },
                    function () {}.bind(this)
                  );

                  this.setState(
                    {
                      outletDetails: res.data.result_set,
                      stepThree: !this.state.stepThree,
                      stepOne: false,
                      stepTwo: false,
                      stepFour: false,
                      CHOOSE_POSTALCODE: false,
                      ordertakenOutlet: res.data.result_set.outlet_name,
                      ordertakenOutletAddress:
                        postalcodeInfo.zip_buno +
                        " " +
                        postalcodeInfo.zip_sname +
                        ", Singapore " +
                        postalcodeInfo.zip_code,
                    },
                    function () {
                      this.setoutletinfo();
                    }.bind(this)
                  );
                } else {
                  this.setState({
                    CHOOSE_POSTALCODE: true,
                    dyZoneError: res.data.message,
                  });
                }
              });
            } else {
              //cookie.save("orderOutletName", outletDetails.outlet_name,{ path: '/' });
              //cookie.save("orderAddressOne", outletDetails.postal_code_information.zip_buno,{ path: '/' });
              // cookie.save("orderAddressTwo", outletDetails.postal_code_information.zip_sname,{ path: '/' });

              cookie.save("orderOutletName", order_detail.outlet_name, {
                path: "/",
              });
              cookie.save(
                "orderAddressOne",
                order_detail.outlet_address_line1,
                { path: "/" }
              );
              cookie.save(
                "orderAddressTwo",
                order_detail.outlet_address_line2,
                { path: "/" }
              );
            }

            cookie.save("orderOutletId", order_outletId, { path: "/" });
            cookie.save("orderPostalCode", order_postal_code, { path: "/" });
            cookie.save("orderDeliveryDate", deliveryDate, { path: "/" });
            cookie.save("orderDeliveryTime", deliveryTime, { path: "/" });
            cookie.save("orderDeliveryTimeRequest", deliveryTimeOrder, {
              path: "/",
            });
            cookie.save("orderAvailability", availability_id, { path: "/" });

            var _this = this;

            _this.apicallrecursive(item_details, 0);

            $(".pro_qty_btn").hide();

            //window.location.reload();
            _this.props.getCartItems();

            setTimeout(function () {
              _this.setState({ modal: true });
            }, 2000);
          } else if (res.data.status === "error") {
          }
        });
    }
  }

  setoutletinfo() {
    const outletDetails = this.state.outletDetails;

    cookie.save("orderZoneID", outletDetails.zone_id, { path: "/" });
    cookie.save("orderOutletName", outletDetails.outlet_name, { path: "/" });
    cookie.save(
      "orderAddressOne",
      outletDetails.postal_code_information.zip_buno,
      { path: "/" }
    );
    cookie.save(
      "orderAddressTwo",
      outletDetails.postal_code_information.zip_sname,
      { path: "/" }
    );
  }

  pdfview(order_id, type) {
    if (type == "order") {
      var url =
        hostUrl() +
        "/businesspanel/orders/generate_callcenter_invoice/" +
        encode_value(order_id);
    } else if (type == "do") {
      var url =
        hostUrl() +
        "/businesspanel/orders/generate_callcenter_do/" +
        encode_value(order_id);
    } else if (type == "kitchen") {
      var url =
        hostUrl() +
        "/businesspanel/orders/generate_callcenter_kitchen_request/" +
        encode_value(order_id);
    }

    window.location.href = url;
  }

  /*send do popup open*/
  senddopopup(order_prim_id) {
    this.setState({ do_order_id: encode_value(order_prim_id) });
    this.setState((prevState) => ({
      sendconfirmationAlert: !prevState.sendconfirmationAlert,
    }));
  }

  /*send do alert popup close*/
  sendcloseAlert() {
    this.setState({ do_order_id: "" });
    this.setState((prevState) => ({
      sendconfirmationAlert: !prevState.sendconfirmationAlert,
    }));
  }

  /*send do alert*/
  senddoAlert() {
    let order_prim_id = this.state.do_order_id;
    /* get customer points */
    const hostURL = hostUrl();
    const app = appId();
    axios
      .get(
        hostURL +
          "api/orders/send_order_do_email?app_id=" +
          app +
          "&order_id=" +
          order_prim_id
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState((prevState) => ({
            sendconfirmationAlert: !prevState.sendconfirmationAlert,
          }));
          this.setState({
            cartCount: 1,
            cartAlert: true,
            cartAlertMsg: res.data.msg,
            alertType: SUCCESS_ALERT,
          });
        }
      });
  }

  /*order remark popup open*/
  orderremarkpopup(order_id, order_remark) {
    let order_pri_id = this.setState({ remark_order_id: order_id });
    let order_remarks = this.setState({ order_remarks: order_remark });

    this.setState((prevState) => ({
      orderremarkAlert: !prevState.orderremarkAlert,
    }));
  }

  openPaymentRequest(order_id,email) {
    let addPaymentFields = this.state.addPaymentFields;
    let requestPaymentFields = this.state.requestPaymentFields;
    addPaymentFields['payment_email'] = email;
    requestPaymentFields['payment_email'] = email;
     this.setState({ paymentRequestId: order_id, addPaymentFields, requestPaymentFields});
    const APP_ID = appId();
    const HOST_URL = hostUrl();
    /* console.log(HOST_URL +
      "call-center/paymentrequest/get_advance_payment_orders_payment?app_id=" +
      APP_ID +
      "&request_id=" +
      order_id);
    return false; */
    $("#proLoading").show();
    axios
      .get(
        HOST_URL +
          "call-center/paymentrequest/get_advance_payment_orders_payment?app_id=" +
          APP_ID +
          "&request_id=" +
          order_id
      )
      .then((res) => {
        $("#proLoading").hide();

        if (res.data.status === "ok") {
          this.setState((prevState) => ({
            openPaymentRequest: !prevState.openPaymentRequest, addPaymentList :  res.data.result_set
          }));
           
        } else if (res.data.status === "error") {
        }
      });
  }

  remark_change(event) {
    let order_remarks = event.target.value;
    this.setState({ order_remarks: order_remarks });
  }
  /*order remark alert*/
  orderremarkAlert() {
    this.setState((prevState) => ({
      orderremarkAlert: !prevState.orderremarkAlert,
    }));
  }

  /*update order remarks*/
  updateorderremark() {
    let remarkorderId = this.state.remark_order_id;
    let order_remarks = this.state.order_remarks;
    /* get customer points */

    const hostURL = hostUrl();
    const app = appId();
    axios
      .get(
        hostURL +
          "api/orders/update_orderremark?app_id=" +
          app +
          "&order_id=" +
          remarkorderId +
          "&order_remarks=" +
          encodeURIComponent(order_remarks)
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState((prevState) => ({
            orderremarkAlert: !prevState.orderremarkAlert,
          }));
          this.setState({
            cartCount: 1,
            cartAlert: true,
            cartAlertMsg: res.data.message,
            alertType: SUCCESS_ALERT,
          });

          this.props.getcurrentOrders();
          this.props.getadvancedOrders();
        }
      });
  }

  /*order remark popup open*/
  kitchenremarkpopup(order_id, kitchen_remark) {
    let order_pri_id = this.setState({ kitchen_order_id: order_id });
    let order_remarks = this.setState({ kitchen_remarks: kitchen_remark });

    this.setState((prevState) => ({
      kitchenremarkAlert: !prevState.kitchenremarkAlert,
    }));
  }

  kitremark_change(event) {
    let kitchen_remarks = event.target.value;
    this.setState({ kitchen_remarks: kitchen_remarks });
  }
  /*order remark alert*/
  kitchenremarkAlert() {
    this.setState((prevState) => ({
      kitchenremarkAlert: !prevState.kitchenremarkAlert,
    }));
  }

  /*update order remarks*/
  updatekitchenremark() {
    let kremarkorderId = this.state.kitchen_order_id;
    let order_remarks = this.state.kitchen_remarks;
    /* get customer points */

    const hostURL = hostUrl();
    const app = appId();
    axios
      .get(
        hostURL +
          "api/orders/update_kitchenremark?app_id=" +
          app +
          "&order_id=" +
          kremarkorderId +
          "&kitchen_remarks=" +
          order_remarks
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState((prevState) => ({
            kitchenremarkAlert: !prevState.kitchenremarkAlert,
          }));
          this.setState({
            cartCount: 1,
            cartAlert: true,
            cartAlertMsg: res.data.message,
            alertType: SUCCESS_ALERT,
          });

          this.props.getcurrentOrders();
          this.props.getadvancedOrders();
        }
      });
  }

  /*order remark popup open*/
  trackremarkpopup(order_id, track_remark) {
    let order_pri_id = this.setState({ track_order_id: order_id });
    let order_remarks = this.setState({ track_remarks: track_remark });

    this.setState((prevState) => ({
      trackremarkAlert: !prevState.trackremarkAlert,
    }));
  }

  trackremark_change(event) {
    let track_remarks = event.target.value;
    this.setState({ track_remarks: track_remarks });
  }
  /*order remark alert*/
  trackremarkAlert() {
    this.setState((prevState) => ({
      trackremarkAlert: !prevState.trackremarkAlert,
    }));
  }

  /*update order remarks*/
  updatetrackremark() {
    let tremarkorderId = this.state.track_order_id;
    let track_remarks = this.state.track_remarks;
    /* get customer points */

    const hostURL = hostUrl();
    const app = appId();
    axios
      .get(
        hostURL +
          "api/orders/update_trackremark?app_id=" +
          app +
          "&order_id=" +
          tremarkorderId +
          "&track_remarks=" +
          track_remarks
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState((prevState) => ({
            trackremarkAlert: !prevState.trackremarkAlert,
          }));
          this.setState({
            cartCount: 1,
            cartAlert: true,
            cartAlertMsg: res.data.message,
            alertType: SUCCESS_ALERT,
          });

          this.props.getcurrentOrders();
          this.props.getadvancedOrders();
        }
      });
  }

  statusAlert() {
    this.setState((prevState) => ({
      statusAlert: !prevState.statusAlert,
    }));
  }

  statuspopup(order_id, order_status) {
    let order_pri_id = this.setState({ status_order_id: order_id });
    this.setState({ order_status: order_status });

    this.setState((prevState) => ({
      statusAlert: !prevState.statusAlert,
    }));
  }

  handleSelectChange(option, actionMeta) {
    this.setState({
      order_status: option.value,
    });

    if (option.value == "5") {
      $("#cancelremarktxt").show();
      $(".cancelremarktxt").show();
    } else {
      $("#cancelremarktxt").hide();
      $(".cancelremarktxt").hide();
    }
  }

  updatestatus() {
    let sorderId = this.state.status_order_id;
    let order_status = this.state.order_status;

    let order_cancel_remark = this.state.cancel_remarks;
    /* get customer points */

    const hostURL = hostUrl();
    const app = appId();
    axios
      .get(
        hostURL +
          "api/orders/change_order_status?app_id=" +
          app +
          "&order_id=" +
          sorderId +
          "&order_status=" +
          order_status +
          "&order_remarks=" +
          order_cancel_remark
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState((prevState) => ({
            statusAlert: !prevState.statusAlert,
          }));
          this.setState({
            cartCount: 1,
            cartAlert: true,
            cartAlertMsg: res.data.message,
            alertType: SUCCESS_ALERT,
          });

          this.props.getcurrentOrders();
          this.props.getadvancedOrders();
        } else {
          alert(res.data.message);
          this.setState((prevState) => ({
            statusAlert: !prevState.statusAlert,
          }));
        }
      });
  }

  cancelremark_change(event) {
    let cancelremarks = event.target.value;
    this.setState({ cancel_remarks: cancelremarks });
  }

  /*send do alert*/
  draftsenddoAlert() {
    let order_prim_id = this.state.draftOrderLocalId;
    /* get customer points */
    const hostURL = hostUrl();
    const app = appId();
    axios
      .get(
        hostURL +
          "api/orders/draft_order_send_pdf?app_id=" +
          app +
          "&order_refer_id=" +
          order_prim_id
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState((prevState) => ({
            draftsendconfirmationAlert: !prevState.draftsendconfirmationAlert,
          }));
          this.setState({
            cartCount: 1,
            cartAlert: true,
            cartAlertMsg: res.data.msg,
            alertType: SUCCESS_ALERT,
          });
        }
      });
  }

  draftOrderDoSend(orderLocalId) {
    this.setState({ draftOrderLocalId: orderLocalId });
    this.setState((prevState) => ({
      draftsendconfirmationAlert: !prevState.draftsendconfirmationAlert,
    }));
  }

  draftsendcloseAlert() {
    this.setState({ draftOrderLocalId: "" });
    this.setState((prevState) => ({
      draftsendconfirmationAlert: !prevState.draftsendconfirmationAlert,
    }));
  }

  draftorder_pdfview(orderLocalId) {
    const APP_ID = appId();

    if (orderLocalId !== "") {
      var pdf_url =
        hostUrl() +
        "/api/orders/draft_order_pdffiles?app_id=" +
        APP_ID +
        "&order_refer_id=" +
        orderLocalId;
      window.location.href = pdf_url;
    }
  }

  sendPaymentRequest() {
    // alert();
  }

  render() {
    const currentOrders = this.props.currentOrders;
    const advancedOrders = this.props.advancedOrders;
    const draftOrders = this.props.draftOrders;
    
    const settings = this.props.setStatus === 'ok' ? this.props.settings : [];    
    let advancePaymentRequestEnabled = settings.client_advance_payment_request_enabled ? parseInt(settings.client_advance_payment_request_enabled) : 0;

    let status_drop = [];

    if (appId() === CEDELE_APP_ID) {
      status_drop = [
        { value: "1", label: "Received" },
        { value: "2", label: "Delivered" },
        { value: "3", label: "Processing" },
        { value: "4", label: "Completed" },
      ];
    } else {
      status_drop = [
        { value: "1", label: "Received" },
        { value: "2", label: "Delivered" },
        { value: "3", label: "Processing" },
        { value: "4", label: "Completed" },
        { value: "5", label: "Cancelled" },
      ];
    }

    return (
      <div>
        <Header cartshow={this.state.modal} />
        <SideBar />
        <Alert color={this.state.alertType} isOpen={this.state.cartAlert}>
          {this.state.cartAlertMsg}
        </Alert>
        <Container fluid className="main-container orders-list-page">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "1" })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                Today's Orders
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "2" })}
                onClick={() => {
                  this.toggle("2");
                }}
              >
                Advanced orders
              </NavLink>
            </NavItem>
            {/* <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '3' })}
              onClick={() => { this.toggle('3'); }}
            >
             Catering
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '4' })}
              onClick={() => { this.toggle('4'); }}
            >
             Reservation
            </NavLink>
          </NavItem> */}
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "5" })}
                onClick={() => {
                  this.toggle("5");
                }}
              >
                Draft Orders
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane className="orders-list-tab" tabId="1">
              <Form>
                <Row className="filter-bar">
                  <Col sm="6" className="filter-bar-left pr-0">
                    <Row form>
                      <Col md={4}>
                        <FormGroup>
                          <div className="custome-select-control">
                            <Input
                              type="select"
                              name="select"
                              id="c_search_key"
                            >
                              <option value="">Select</option>
                              <option value="local_order_no">
                                {" "}
                                Local Order No
                              </option>
                              <option value="mobile_no">Mobile No</option>
                            </Input>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Input
                            type="text"
                            placeholder="Type here.."
                            name="search"
                            id="c_search_value"
                          />
                        </FormGroup>
                      </Col>

                      <Col md={4}>
                        <FormGroup>
                          <div className="custome-select-control">
                            <Input
                              type="select"
                              name="select"
                              id="c_order_source"
                            >
                              <option value="">Order source</option>
                              {appId() !== CEDELE_APP_ID && (
                                <React.Fragment>
                                  <option value="Web">Ecommerce</option>
                                  <option value="Mobile">App</option>
                                  <option value="Pos">Pos</option>
                                </React.Fragment>
                              )}
                              <option value="CallCenter">Call Center</option>
                            </Input>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>

                  <Col sm="6" className="filter-bar-right pl-3">
                    <Row form>
                      <Col md={3}>
                        <FormGroup>
                          <div className="custome-select-control">
                            <Input
                              type="select"
                              name="c_outlet_type"
                              id="c_outlet_type"
                            >
                              <option value="1">Pickup outlet</option>
                              <option value="2">Order outlet</option>
                            </Input>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <div className="custome-select-control">
                            <Input
                              type="select"
                              name="c_outlet_id"
                              id="c_outlet_id"
                            >
                              <option value="">Outlet</option>
                              {_.isEmpty(this.props.outletsData) === false &&
                                this.props.outletsData.map(
                                  (outlet, outletIndex) => (
                                    <option
                                      value={outlet.outlet_id}
                                      key={outletIndex}
                                    >
                                      {outlet.outlet_name}
                                    </option>
                                  )
                                )}
                            </Input>
                          </div>
                        </FormGroup>
                      </Col>

                      <Col md={3}>
                        <ButtonGroup>
                          <Button onClick={() => this.applySearch("c")}>
                            <img src={SearchIconImage} alt="Search" />
                          </Button>
                          <Button onClick={() => this.resetSearch("c")}>
                            <img src={RefreshIconImage} alt="Refresh" />
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Table responsive>
                  <thead>
                    <tr className="text-uppercase">
                      <th>Order No</th>
                      <th>FULFILMENT DATE</th>
                      <th>Placed At</th>
                      <th>Customer Name</th>
                      <th>Amount</th>
                      <th>Outlet</th>
                      <th>Status</th>
                      {/*  <th>Rider</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {_.isEmpty(currentOrders) === true && (
                      <React.Fragment>
                        {" "}
                        <tr className="order-info-row">
                          {" "}
                          <td scope="row" className="order-no-clm" colSpan="10">
                            {" "}
                            No Order Found{" "}
                          </td>{" "}
                        </tr>{" "}
                      </React.Fragment>
                    )}
                    {_.isEmpty(currentOrders) === false &&
                      currentOrders.map((list, cIndex) => (
                        <React.Fragment>
                          <tr className="order-info-row">
                            <td scope="row" className="order-no-clm">
                              <div className="order-info-colm">
                                <Badge href="#" color="dark">
                                  {list.order_availability_name}
                                </Badge>
                                <span>{list.order_local_no}</span>
                                Created by - {list.callcenter_admin_name}
                              </div>
                            </td>
                            <td>
                              <div className="order-info-colm">
                                {moment(list.order_date).format("DD-MM-YYYY")}{" "}
                                <br />
                                {moment(list.order_date).format("hh:mm A")}
                              </div>
                            </td>
                            <td>
                              {" "}
                              <div className="order-info-colm">
                                {" "}
                                {moment(list.order_created_on).format(
                                  "DD-MM-YYYY"
                                )}{" "}
                                <br />
                                {moment(list.order_created_on).format(
                                  "hh:mm A"
                                )}
                              </div>
                            </td>
                            <td>
                              {" "}
                              <div className="order-info-colm">
                                {" "}
                                {list.order_customer_fname +
                                  " " +
                                  list.order_customer_lname}{" "}
                                <br />
                                {list.order_customer_mobile_no}
								   <br />
                                {list.order_customer_email}
                              </div>
                            </td>
                            <td>
                              {" "}
                              <div className="order-info-colm">
                                ${list.order_total_amount}
                                <br />
           
                              </div>
                            </td>
                            <td>
                              {" "}
                              <div className="order-info-colm">
                                {list.outlet_name}
                              </div>
                            </td>
                            <td>
                              {" "}
                              <div className="order-info-colm">
                                <Button color="primary" size="sm">
                                  {showStatus(list.order_status)}
                                </Button>
                              </div>
                            </td>
                            {/* list.order_availability_id === DELIVERY_ID  &&  <td> <div className="order-info-colm">John Tan<br/>
			9834 3567</div></td> }
			{ list.order_availability_id !== DELIVERY_ID  &&  <td> <div className="order-info-colm">-</div></td>*/}
                          </tr>
                          <tr className="order-action-row">
                            <td colSpan="8">
                              <div className="order-btn-colm">
                                {appId() === CEDELE_APP_ID ? (
                                  <div className="cedele_order_buttons">
                                    <Button
                                      outline
                                      className="btn-view-order"
                                      onClick={this.openViewordersPopup.bind(
                                        this,
                                        list
                                      )}
                                      color="info"
                                    >
                                      View Order
                                    </Button>
                                    <Button
                                      outline
                                      className="btn-delivery-order"
                                      color="info"
                                      onClick={this.pdfview.bind(
                                        this,
                                        list.order_primary_id,
                                        "do"
                                      )}
                                    >
                                      Delivery Order
                                    </Button>
                                    <Button
                                      outline
                                      className="btn-view-status"
                                      onClick={this.statuspopup.bind(
                                        this,
                                        list.order_id,
                                        list.order_status
                                      )}
                                      color="info"
                                    >
                                      Status
                                    </Button>
                                  </div>
                                ) : (
                                  <div className="order_buttons">
                                  
                                 { (SPRING_APP_ID === getCookies("app_id") || advancePaymentRequestEnabled === 1) && (   <Button
                                      outline
                                      className="btn-view-order"
                                      onClick={this.openPaymentRequest.bind(
                                        this,
                                        list.order_id,
                                        list.order_customer_email
                                      )}
                                      color="info"
                                    >
                                      Request Payment 
                                    </Button> ) }
                                    <Button
                                      outline
                                      className="btn-view-order"
                                      onClick={this.openViewordersPopup.bind(
                                        this,
                                        list
                                      )}
                                      color="info"
                                    >
                                      View Order
                                    </Button>
                                    <Button
                                      outline
                                      className="btn-send-do"
                                      onClick={this.senddopopup.bind(
                                        this,
                                        list.order_primary_id
                                      )}
                                      color="info"
                                    >
                                      Send Do
                                    </Button>
                                    <Button
                                      outline
                                      className="btn-invoice"
                                      color="info"
                                      onClick={this.pdfview.bind(
                                        this,
                                        list.order_primary_id,
                                        "order"
                                      )}
                                    >
                                      Invoice
                                    </Button>
                                    <Button
                                      outline
                                      className="btn-kitchen-request"
                                      color="info"
                                      onClick={this.pdfview.bind(
                                        this,
                                        list.order_primary_id,
                                        "kitchen"
                                      )}
                                    >
                                      Kitchen Request
                                    </Button>
                                    <Button
                                      outline
                                      className="btn-order-remark"
                                      onClick={this.orderremarkpopup.bind(
                                        this,
                                        list.order_primary_id,
                                        list.order_remarks
                                      )}
                                      color="info"
                                    >
                                      Order Remark
                                    </Button>
                                    <Button
                                      outline
                                      className="btn-kitchen-remark"
                                      onClick={this.kitchenremarkpopup.bind(
                                        this,
                                        list.order_primary_id,
                                        list.order_kitchen_remarks
                                      )}
                                      color="info"
                                    >
                                      Kitchen Remark
                                    </Button>
                                    <Button
                                      outline
                                      className="btn-track-remark"
                                      onClick={this.trackremarkpopup.bind(
                                        this,
                                        list.order_primary_id,
                                        list.order_tracking_remarks
                                      )}
                                      color="info"
                                    >
                                      Track Remark
                                    </Button>
                                  </div>
                                )}

                                {list.order_status == "1" &&
                                  appId() !== CEDELE_APP_ID && (
                                    <Button
                                      outline
                                      className="btn-order-remark"
                                      color="info"
                                      onClick={() =>
                                        this.orderEdit(
                                          list.order_primary_id,
                                          list.order_local_no
                                        )
                                      }
                                    >
                                      Edit Order
                                    </Button>
                                  )}
                              </div>
                            </td>
                          </tr>{" "}
                        </React.Fragment>
                      ))}
                  </tbody>
                </Table>
              </Form>
            </TabPane>
            <TabPane className="orders-list-tab" tabId="2">
              <Form>
                <Row className="filter-bar">
                  <Col sm="6" className="filter-bar-left pr-0">
                    <Row form>
                      <Col md={4}>
                        <FormGroup>
                          <div className="custome-select-control">
                            <Input
                              type="select"
                              name="select"
                              id="a_search_key"
                            >
                              <option value="">Select</option>
                              <option value="local_order_no">
                                {" "}
                                Local Order No
                              </option>
                              <option value="mobile_no">Mobile No</option>
                            </Input>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Input
                            type="text"
                            placeholder="Type here.."
                            name="search"
                            id="a_search_value"
                          />
                        </FormGroup>
                      </Col>

                      <Col md={4}>
                        <FormGroup>
                          <div className="custome-select-control">
                            <Input
                              type="select"
                              name="select"
                              id="a_order_source"
                            >
                              <option value="">Order source</option>
                              {appId() !== CEDELE_APP_ID && (
                                <React.Fragment>
                                  <option value="Web">Ecommerce</option>
                                  <option value="Mobile">App</option>
                                  <option value="Pos">Pos</option>
                                </React.Fragment>
                              )}
                              <option value="CallCenter">Call Center</option>
                            </Input>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>

                  <Col sm="6" className="filter-bar-left pr-0">
                    <Row form>
                      <Col md={6}>
                        <FormGroup>
                          <div className="custome-select-control">
                            <Input
                              type="select"
                              name="a_date_filter_type_from"
                              id="a_date_filter_type_from"
                            >
                              <option value="1">Fulfilment Date From</option>
                              <option value="2">Order Date From</option>
                            </Input>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Input
                            type="date"
                            placeholder="From date"
                            name="a_from_date"
                            id="a_from_date"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="6" className="filter-bar-left pr-0">
                    <Row form>
                      <Col md={6}>
                        <FormGroup>
                          <div className="custome-select-control">
                            <Input
                              type="select"
                              name="a_date_filter_type_to"
                              id="a_date_filter_type_to"
                            >
                              <option value="1">Fulfilment Date to</option>
                              <option value="2">Order Date to</option>
                            </Input>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Input
                            type="date"
                            placeholder="To Date"
                            id="a_to_date"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="6" className="filter-bar-right pl-3">
                    <Row form>
                      <Col md={3}>
                        <FormGroup>
                          <div className="custome-select-control">
                            <Input
                              type="select"
                              name="a_outlet_type"
                              id="a_outlet_type"
                            >
                              <option value="1">Pickup outlet</option>
                              <option value="2">Order outlet</option>
                            </Input>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <div className="custome-select-control">
                            <Input
                              type="select"
                              name="a_outlet_id"
                              id="a_outlet_id"
                            >
                              <option value="">Outlet</option>
                              {_.isEmpty(this.props.outletsData) === false &&
                                this.props.outletsData.map(
                                  (outlet, outletIndex) => (
                                    <option
                                      value={outlet.outlet_id}
                                      key={outletIndex}
                                    >
                                      {outlet.outlet_name}
                                    </option>
                                  )
                                )}
                            </Input>
                          </div>
                        </FormGroup>
                      </Col>

                      <Col md={3}>
                        <ButtonGroup>
                          <Button onClick={() => this.applySearch("a")}>
                            <img src={SearchIconImage} alt="Search" />
                          </Button>
                          <Button onClick={() => this.resetSearch("a")}>
                            <img src={RefreshIconImage} alt="Refresh" />
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Table responsive>
                  <thead>
                    <tr className="text-uppercase">
                      <th>Order No</th>
                      <th>FULFILMENT DATE</th>
                      <th>Placed At</th>
                      <th>Customer Name</th>
                      <th>Amount</th>
                      <th>Outlet</th>
                      <th>Status</th>
                      {/*  <th>Rider</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {_.isEmpty(advancedOrders) === true && (
                      <React.Fragment>
                        {" "}
                        <tr className="order-info-row">
                          {" "}
                          <td scope="row" className="order-no-clm" colSpan="10">
                            {" "}
                            No Order Found{" "}
                          </td>{" "}
                        </tr>{" "}
                      </React.Fragment>
                    )}
                    {_.isEmpty(advancedOrders) === false &&
                      advancedOrders.map((list, cIndex) => (
                        <React.Fragment>
                          <tr className="order-info-row">
                            <td scope="row" className="order-no-clm">
                              <div className="order-info-colm">
                                <Badge href="#" color="dark">
                                  {list.order_availability_name}
                                </Badge>
                                <span>{list.order_local_no}</span>
                                Created by - {list.callcenter_admin_name}
                              </div>
                            </td>
                            <td>
                              <div className="order-info-colm">
                                {moment(list.order_date).format("DD-MM-YYYY")}{" "}
                                <br />
                                {moment(list.order_date).format("hh:mm A")}
                              </div>
                            </td>
                            <td>
                              {" "}
                              <div className="order-info-colm">
                                {" "}
                                {moment(list.order_created_on).format(
                                  "DD-MM-YYYY"
                                )}{" "}
                                <br />
                                {moment(list.order_created_on).format(
                                  "hh:mm A"
                                )}
                              </div>
                            </td>
                            <td>
                              {" "}
                              <div className="order-info-colm">
                                {" "}
                                {list.order_customer_fname +
                                  " " +
                                  list.order_customer_lname}{" "}
                                <br />
                                {list.order_customer_mobile_no}
										   <br />
                                {list.order_customer_email}
                              </div>
                            </td>
                            <td>
                              {" "}
                              <div className="order-info-colm">
                                ${list.order_total_amount}
                           
                              </div>
                            </td>
                            <td>
                              {" "}
                              <div className="order-info-colm">
                                {list.outlet_name}
                              </div>
                            </td>
                            <td>
                              {" "}
                              <div className="order-info-colm">
                                <Button color="primary" size="sm">
                                  {showStatus(list.order_status)}
                                </Button>
                              </div>
                            </td>
                            {/* list.order_availability_id === DELIVERY_ID  &&  <td> <div className="order-info-colm">John Tan<br/>
			9834 3567</div></td> }
			{ list.order_availability_id !== DELIVERY_ID  &&  <td> <div className="order-info-colm">-</div></td> */}
                          </tr>
                          <tr className="order-action-row">
                            <td colSpan="8">
                              <div className="order-btn-colm">
                                {appId() === CEDELE_APP_ID ? (
                                  <div className="advc_cedele_order_buttons">
                                    <Button
                                      outline
                                      className="btn-view-order"
                                      onClick={this.openViewordersPopup.bind(
                                        this,
                                        list
                                      )}
                                      color="info"
                                    >
                                      View Order
                                    </Button>
                                    <Button
                                      outline
                                      className="btn-delivery-order"
                                      color="info"
                                      onClick={this.pdfview.bind(
                                        this,
                                        list.order_primary_id,
                                        "do"
                                      )}
                                    >
                                      Delivery Order
                                    </Button>
                                    <Button
                                      outline
                                      className="btn-view-status"
                                      onClick={this.statuspopup.bind(
                                        this,
                                        list.order_id,
                                        list.order_status
                                      )}
                                      color="info"
                                    >
                                      Status
                                    </Button>
                                  </div>
                                ) : (
                                  <div className="advc_order_buttons">
{ (SPRING_APP_ID === getCookies("app_id") || advancePaymentRequestEnabled === 1) && ( <Button
                                      outline
                                      className="btn-view-order"
                                      onClick={this.openPaymentRequest.bind(
                                        this,
                                        list.order_id,
                                        list.order_customer_email
                                      )}
                                      color="info"
                                    >
                                      Request Payment
                                    </Button> )}

                                    <Button
                                      outline
                                      className="btn-send-do"
                                      onClick={this.senddopopup.bind(
                                        this,
                                        list.order_primary_id
                                      )}
                                      color="info"
                                    >
                                      Send Do
                                    </Button>
                                    <Button
                                      outline
                                      className="btn-invoice"
                                      color="info"
                                      onClick={this.pdfview.bind(
                                        this,
                                        list.order_primary_id,
                                        "order"
                                      )}
                                    >
                                      Invoice
                                    </Button>
                                    <Button
                                      outline
                                      className="btn-kitchen-request"
                                      color="info"
                                      onClick={this.pdfview.bind(
                                        this,
                                        list.order_primary_id,
                                        "kitchen"
                                      )}
                                    >
                                      Kitchen Request
                                    </Button>
                                    <Button
                                      outline
                                      className="btn-order-remark"
                                      onClick={this.orderremarkpopup.bind(
                                        this,
                                        list.order_primary_id,
                                        list.order_remarks
                                      )}
                                      color="info"
                                    >
                                      Order Remark
                                    </Button>
                                    <Button
                                      outline
                                      className="btn-kitchen-remark"
                                      onClick={this.kitchenremarkpopup.bind(
                                        this,
                                        list.order_primary_id,
                                        list.order_kitchen_remarks
                                      )}
                                      color="info"
                                    >
                                      Kitchen Remark
                                    </Button>
                                    <Button
                                      outline
                                      className="btn-track-remark"
                                      onClick={this.trackremarkpopup.bind(
                                        this,
                                        list.order_primary_id,
                                        list.order_tracking_remarks
                                      )}
                                      color="info"
                                    >
                                      Track Remark
                                    </Button>
                                  </div>
                                )}

                                {list.order_status == "1" &&
                                  appId() !== CEDELE_APP_ID && (
                                    <Button
                                      outline
                                      className="btn-order-remark"
                                      color="info"
                                      onClick={() =>
                                        this.orderEdit(
                                          list.order_primary_id,
                                          list.order_local_no
                                        )
                                      }
                                    >
                                      Edit Order
                                    </Button>
                                  )}
                              </div>
                            </td>
                          </tr>{" "}
                        </React.Fragment>
                      ))}
                  </tbody>
                </Table>
              </Form>
            </TabPane>
            <TabPane tabId="3">
              <Row>
                <h1>coming soon </h1>
              </Row>
            </TabPane>
            <TabPane tabId="4">
              <Row>
                <h1>coming soon</h1>
              </Row>
            </TabPane>

            <TabPane className="orders-list-tab" tabId="5">
              <Form>
                <Row className="filter-bar">
                  <Col sm="6" className="filter-bar-left pr-0">
                    <Row form>
                      <Col md={4}>
                        <FormGroup>
                          <Input
                            type="text"
                            placeholder="Type here.."
                            name="search"
                            id="exampleCity"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                          <Input
                            type="date"
                            placeholder="From"
                            name="fromdate"
                            id="fromDate"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                          <Input type="date" placeholder="To" id="toDate" />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <div className="custome-select-control">
                            <Input
                              type="select"
                              name="select"
                              id="exampleSelect"
                            >
                              <option>Payment Type</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                            </Input>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="6" className="filter-bar-right pl-3">
                    <Row form>
                      <Col md={3}>
                        <FormGroup>
                          <div className="custome-select-control">
                            <Input
                              type="select"
                              name="select"
                              id="exampleSelect"
                            >
                              <option>Source</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                            </Input>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <div className="custome-select-control">
                            <Input
                              type="select"
                              name="select"
                              id="exampleSelect"
                            >
                              <option>Delivery</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                            </Input>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <div className="custome-select-control">
                            <Input
                              type="select"
                              name="select"
                              id="exampleSelect"
                            >
                              <option>Payment Type</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                            </Input>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <ButtonGroup>
                          <Button>
                            <img src={SearchIconImage} alt="Search" />
                          </Button>
                          <Button>
                            <img src={RefreshIconImage} alt="Refresh" />
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Table responsive>
                  <thead>
                    <tr className="text-uppercase">
                      <th>Order No</th>
                      <th>FULFILMENT DATE</th>
                      <th>Placed At</th>
                      <th>Customer Name</th>
                      <th>Amount</th>
                      <th>Outlet</th>
                      <th>Status</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.isEmpty(draftOrders) === false &&
                      draftOrders.map((list, dIndex) => (
                        <React.Fragment>
                          <tr
                            className="order-info-row"
                            id={dIndex}
                            key={dIndex}
                          >
                            <td scope="row" className="order-no-clm">
                              <div className="order-info-colm">
                                <Badge href="#" color="dark">
                                  {list.order_availability_name}
                                </Badge>
                                <span>{list.order_local_no}</span>
                              </div>
                            </td>
                            <td>
                              <div className="order-info-colm">
                                {moment(list.order_date).format("DD-MM-YYYY")}{" "}
                                <br />
                                {moment(list.order_date).format("hh:mm A")}
                              </div>
                            </td>
                            <td>
                              {" "}
                              <div className="order-info-colm">
                                {" "}
                                {moment(list.order_created_on).format(
                                  "DD-MM-YYYY"
                                )}{" "}
                                <br />
                                {moment(list.order_created_on).format(
                                  "hh:mm A"
                                )}
                              </div>
                            </td>
                            <td>
                              {" "}
                              <div className="order-info-colm">
                                {" "}
                                {list.order_customer_fname +
                                  " " +
                                  list.order_customer_lname}{" "}
                                <br />
                                {list.order_customer_mobile_no}
										   <br />
                                {list.order_customer_email}
                              </div>
                            </td>
                            <td>
                              {" "}
                              <div className="order-info-colm">
                                ${list.order_total_amount}
                                <br />
                              </div>
                            </td>
                            <td>
                              {" "}
                              <div className="order-info-colm">
                                {list.outlet_name}
                              </div>
                            </td>
                            <td>
                              {" "}
                              <div className="order-info-colm">
                                <Button
                                  color="primary"
                                  size="sm"
                                  onClick={this.getdraftorder.bind(
                                    this,
                                    list.order_local_no
                                  )}
                                >
                                  Proceed Checkout
                                </Button>
                              </div>
                            </td>
                            <td>
                              {" "}
                              <div className="order-info-colm">
                                <a
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "none",
                                  }}
                                  onClick={this.deletedraftorder.bind(
                                    this,
                                    list.order_primary_id,
                                    dIndex
                                  )}
                                >
                                  <i
                                    className="fa fa-fw fa-trash-o"
                                    style={{ fontSize: "1.25rem" }}
                                  />
                                </a>
                                <br />
                              </div>
                            </td>
                            {list.order_availability_id !== DELIVERY_ID && (
                              <td>
                                {" "}
                                <div className="order-info-colm">-</div>
                              </td>
                            )}
                          </tr>

                          <tr className="order-action-row">
                            <td colSpan="8">
                              <div className="order-btn-colm">
                                {/* <Button outline className="btn-view-order" onClick={this.openViewordersPopup.bind(this, list)} color="info">View Order</Button> */}
                               
                               
                                <Button
                                  outline
                                  className="btn-send-do"
                                  onClick={this.draftOrderDoSend.bind(
                                    this,
                                    list.order_local_no
                                  )}
                                  color="info"
                                >
                                  Send Do
                                </Button>{" "}
                                <Button
                                  outline
                                  className="btn-invoice"
                                  color="info"
                                  onClick={this.draftorder_pdfview.bind(
                                    this,
                                    list.order_local_no
                                  )}
                                >
                                  Invoice
                                </Button>{" "}
                              </div>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                </Table>
              </Form>
            </TabPane>
          </TabContent>
        </Container>

        <Modal
          size="lg"
          isOpen={this.state.confirmationAlert}
          className="callcentre-modal pickup-confirmation-modal"
        >
          <div className="modal-header">
            <div className="modal-top-icon"></div>
            <div className="modal-title-block">
              <h2>Alert</h2>
            </div>
          </div>
          <ModalBody>
            <h5>
              {" "}
              Item in cart. Please save order as draft or clear the cart.
            </h5>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={this.closeAlert.bind(this)}
              style={{
                display: this.state.checkpostalCode === true ? "none" : "block",
              }}
            >
              OK
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          size="lg"
          isOpen={this.state.sendconfirmationAlert}
          className="callcentre-modal pickup-confirmation-modal"
        >
          <div className="modal-header">
            <div className="modal-top-icon"></div>
            <div className="modal-title-block">
              <h2>Confirmation</h2>
            </div>
          </div>
          <ModalBody>
            <h5>Are you sure want to send order details to customer? </h5>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={this.sendcloseAlert.bind(this)}
              style={{
                display: this.state.checkpostalCode === true ? "none" : "block",
              }}
            >
              No
            </Button>
            <Button
              color="primary"
              onClick={this.senddoAlert.bind(this)}
              style={{
                display: this.state.checkpostalCode === true ? "none" : "block",
              }}
            >
              Yes
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          size="lg"
          isOpen={this.state.draftsendconfirmationAlert}
          className="callcentre-modal pickup-confirmation-modal"
        >
          <div className="modal-header">
            <div className="modal-top-icon"></div>
            <div className="modal-title-block">
              <h2>Confirmation</h2>
            </div>
          </div>
          <ModalBody>
            <h5>Are you sure want to send order details to customer? </h5>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={this.draftsendcloseAlert.bind(this)}
              style={{
                display: this.state.checkpostalCode === true ? "none" : "block",
              }}
            >
              No
            </Button>
            <Button
              color="primary"
              onClick={this.draftsenddoAlert.bind(this)}
              style={{
                display: this.state.checkpostalCode === true ? "none" : "block",
              }}
            >
              Yes
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          size="lg"
          isOpen={this.state.orderremarkAlert}
          className="callcentre-modal"
        >
          <div className="modal-header">
            <div className="modal-top-icon">
              <button
                className="close"
                onClick={this.orderremarkAlert.bind(this)}
              >
                &times;
              </button>
            </div>
            <div className="modal-title-block">
              <h2>Order Remarks</h2>
            </div>
          </div>
          <ModalBody>
            <Input
              type="textarea"
              name="text"
              id="productDescription"
              value={this.state.order_remarks}
              onChange={this.remark_change.bind(this)}
              placeholder="Please enter your order remarks"
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={this.updateorderremark.bind(this)}
              style={{
                display: this.state.checkpostalCode === true ? "none" : "block",
              }}
            >
              Update
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          size="lg"
          isOpen={this.state.openPaymentRequest}
          className="callcentre-modal"
        >
          <div className="modal-header">
            <div className="modal-top-icon">
              <button
                className="close"
                onClick={this.openPaymentRequest.bind(this)}
              >
                &times;
              </button>
            </div>
            <div className="modal-title-block">
              <h2> REQUEST PAYMENT</h2>
             
            </div>
          </div>
          <ModalBody>
            {/*  */}

            <div>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTabPayment === "1",
                    })}
                    onClick={() => {
                      this.toggleactiveTabPayment("1");
                    }}
                  >
                    Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTabPayment === "2",
                    })}
                    onClick={() => {
                      this.toggleactiveTabPayment("2");
                    }}
                  >
                    Request Payment
                  </NavLink>

                  
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTabPayment === "3",
                    })}
                    onClick={() => {
                      this.toggleactiveTabPayment("3");
                    }}
                  >
                    Add Payment
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTabPayment}>
                <TabPane tabId="1">
                  <Table responsive>
                    <thead>
                      <tr className="text-uppercase">
                        <th>DATE ISSUED</th>
                        <th>PAYMENT RECIEVED</th>
                        <th>MODE</th>
                        <th>AMOUNT</th>
                        <th>BALANCE</th>
                      </tr>
                    </thead>
                    <tbody>
                    {_.isEmpty(this.state.addPaymentList) === false && this.state.addPaymentList.map((list, index) => 
                      <tr className="payment-request-row">
                        <td scope="row" className="order-no-clm">
                        {moment(list.ap_created_on).format("DD-MM-YYYY")}
  
                        </td>
                        <td scope="row" className="order-no-clm">
                        {moment(list.ap_date).format("DD-MM-YYYY")}
                        </td>
                        <td scope="row" className="order-no-clm">
                        {list.ap_payment_getway_type} 
                        </td>
                        <td scope="row" className="order-no-clm">
                        {list.ap_amount}
                        </td>
                        <td scope="row" className="order-no-clm">
                        {list.ap_remaining_amount}
                        </td>
                      </tr> )}

                      {_.isEmpty(this.state.addPaymentList) === true && (    <tr className="payment-request-row">  <td colSpan="5"><center> No records found </center> </td>
                      </tr> ) }
                    </tbody>
                  </Table>
                </TabPane>
                <TabPane tabId="2">
                <Form>
                    <Row>
                      <Col md={6}>
                        <Row className="request-forn-row">
                          <Col md={12} className="request-forn-col">
                            <DatePicker
                              className="form-control"
                              minDate={this.state.paymentstartDate}
                              onChange={this.handleDateChange}
                              selected={this.state.paymentstartDate}
                       
                            />
                          </Col>

                          <Col md={12} className="">
                            <Input
                              type="text"
                              name="payment_amount"
                              id="payment_amount"
                              onChange={this.handleChangeRequestPayment.bind(
                                this,
                                "payment_amount"
                              )}
                              value={
                                this.state.requestPaymentFields["payment_amount"]
                              }
                              placeholder="Enter Amount"
                            />
                            <span className="form-error">
                              {this.state.requestPaymentErrors["payment_amount"]}
                            </span>
                          </Col>

                           

                           
						  
						  
						   
							  

                          <Col md={12} className="">
                            <Input
                              type="text"
                              name="payment_email"
                              id="payment_email"
                              onChange={this.handleChangeRequestPayment.bind(
                                this,
                                "payment_email"
                              )}
                              value={
                                this.state.requestPaymentFields["payment_email"]
                              }
                              placeholder="Email address"
                            />
                            <span className="form-error">
                              {this.state.requestPaymentErrors["payment_email"]}
                            </span>
                          </Col>

                          <Col md={12} className="">
                            <Button
                              color="primary"
                              onClick={this.submitRequestPayment.bind(this)}
                            >
                             REQUEST PAYMENT
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                </TabPane>

                <TabPane tabId="3">
                  <Form>
                    <Row>
                      <Col md={6}>
                        <Row className="request-forn-row">
                          <Col md={12} className="request-forn-col">
                            <DatePicker
                              className="form-control"
                              minDate={this.state.paymentstartDate}
                              onChange={this.handleDateChange}
                              selected={this.state.paymentstartDate}
                       
                            />
                          </Col>

                          <Col md={12} className="">
                            <Input
                              type="text"
                              name="payment_amount"
                              id="payment_amount"
                              onChange={this.handleChange.bind(
                                this,
                                "payment_amount"
                              )}
                              value={
                                this.state.addPaymentFields["payment_amount"]
                              }
                              placeholder="Enter Amount"
                            />
                            <span className="form-error">
                              {this.state.addPaymentErrors["payment_amount"]}
                            </span>
                          </Col>

                          <Col md={12} className="">
                            <div className="custome-select-control">
                              <Input
                                type="select"
                                name="payment_via"
                                id="payment_via"
                                onChange={this.handleChange.bind(
                                  this,
                                  "payment_via"
                                )}
                                value={
                                  this.state.addPaymentFields["payment_via"]
                                }
                              >
                                <option value="">Select</option>
                                <option value="1">Cash</option>
                                <option value="2">Cheque No</option>
                                <option value="3">VISA</option>
                                <option value="4">Master</option>
                                <option value="5">AMAX</option>
                                <option value="6">GIRO</option>
                              </Input>
                            </div>
                            <span className="form-error">
                              {this.state.addPaymentErrors["payment_via"]}
                            </span>
                          </Col>

                          {this.state.addPaymentFields["payment_via"] ===
                            "2" && (
                            <React.Fragment>
                              {" "}
                              <Col md={12} className="">
                                <Input
                                  type="text"
                                  name="payment_cheque_no"
                                  id="payment_cheque_no"
                                  onChange={this.handleChange.bind(
                                    this,
                                    "payment_cheque_no"
                                  )}
                                  value={
                                    this.state.addPaymentFields[
                                      "payment_cheque_no"
                                    ]
                                  }
                                  placeholder=" Enter Cheque no"
                                />
                                <span className="form-error">
                                  {
                                    this.state.addPaymentErrors[
                                      "payment_cheque_no"
                                    ]
                                  }
                                </span>
                              </Col>
                              <Col md={12} className="">
                                <Input
                                  type="text"
                                  name="payment_bank_name"
                                  id="payment_bank_name"
                                  onChange={this.handleChange.bind(
                                    this,
                                    "payment_bank_name"
                                  )}
                                  value={
                                    this.state.addPaymentFields[
                                      "payment_bank_name"
                                    ]
                                  }
                                  placeholder="Enter Bank name"
                                />
                                <span className="form-error">
                                  {
                                    this.state.addPaymentErrors[
                                      "payment_bank_name"
                                    ]
                                  }
                                </span>
                              </Col>{" "}
                            </React.Fragment>
                          )}
						  
						  
						    { (this.state.addPaymentFields["payment_via"] !==
                            "2" &&  this.state.addPaymentFields["payment_via"] !==
                            "1") && (   <Col md={12} className="">
                                <Input
                                  type="text"
                                  name="payment_transaction_no"
                                  id="payment_transaction_no"
                                  onChange={this.handleChange.bind(
                                    this,
                                    "payment_transaction_no"
                                  )}
                                  value={
                                    this.state.addPaymentFields[
                                      "payment_transaction_no"
                                    ]
                                  }
                                  placeholder=" Enter Transaction no"
                                />
                                <span className="form-error">
                                  {
                                    this.state.addPaymentErrors[
                                      "payment_transaction_no"
                                    ]
                                  }
                                </span>
							</Col> )}
							  

                          <Col md={12} className="">
                            <Input
                              type="text"
                              name="payment_email"
                              id="payment_email"
                              onChange={this.handleChange.bind(
                                this,
                                "payment_email"
                              )}
                              value={
                                this.state.addPaymentFields["payment_email"]
                              }
                              placeholder="Email address"
                            />
                            <span className="form-error">
                              {this.state.addPaymentErrors["payment_email"]}
                            </span>
                          </Col>

                          <Col md={12} className="">
                            <Button
                              color="primary"
                              onClick={this.submitAddPayment.bind(this)}
                            >
                              ADD PAYMENT
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                </TabPane>
              </TabContent>
            </div>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>

        <Modal
          size="lg"
          isOpen={this.state.kitchenremarkAlert}
          className="callcentre-modal"
        >
          <div className="modal-header">
            <div className="modal-top-icon">
              <button
                className="close"
                onClick={this.kitchenremarkAlert.bind(this)}
              >
                &times;
              </button>
            </div>
            <div className="modal-title-block">
              <h2>Kitchen Remarks</h2>
            </div>
          </div>
          <ModalBody>
            <Input
              type="textarea"
              name="text"
              id="productDescription"
              value={this.state.kitchen_remarks}
              onChange={this.kitremark_change.bind(this)}
              placeholder="Please enter your kitchen remarks"
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={this.updatekitchenremark.bind(this)}
              style={{
                display: this.state.checkpostalCode === true ? "none" : "block",
              }}
            >
              Update
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          size="lg"
          isOpen={this.state.trackremarkAlert}
          className="callcentre-modal"
        >
          <div className="modal-header">
            <div className="modal-top-icon">
              <button
                className="close"
                onClick={this.trackremarkAlert.bind(this)}
              >
                &times;
              </button>
            </div>
            <div className="modal-title-block">
              <h2>Tracking Remarks</h2>
            </div>
          </div>
          <ModalBody>
            <Input
              type="textarea"
              name="text"
              id="productDescription"
              value={this.state.track_remarks}
              onChange={this.trackremark_change.bind(this)}
              placeholder="Please enter your tracking remarks"
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={this.updatetrackremark.bind(this)}
              style={{
                display: this.state.checkpostalCode === true ? "none" : "block",
              }}
            >
              Update
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          size="lg"
          isOpen={this.state.statusAlert}
          className="callcentre-modal"
        >
          <div className="modal-header">
            <div className="modal-top-icon">
              <button className="close" onClick={this.statusAlert.bind(this)}>
                &times;
              </button>
            </div>
            <div className="modal-title-block">
              <h2>Change Status</h2>
            </div>
          </div>
          <ModalBody>
            <Select
              Value={this.state.order_status}
              onChange={this.handleSelectChange.bind(this)}
              options={status_drop}
            />
            <br />
            <br />

            <Input
              type="textarea"
              name="text"
              id="cancelremarktxt"
              class="cancelremarktxt"
              style={{ display: "none" }}
              value={this.state.cancel_remarks}
              onChange={this.cancelremark_change.bind(this)}
              placeholder="Please enter your cancel remarks"
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={this.updatestatus.bind(this)}
              style={{
                display: this.state.checkpostalCode === true ? "none" : "block",
              }}
            >
              Update
            </Button>
          </ModalFooter>
        </Modal>
        <Vieworderspopup
          openViewordersPopup={this.state.vieworderspopup}
          viewOrderList={this.state.ordersList}
          onSelectLanguage={this.closeViewordersPopup.bind(this)} 
        />
      </div>
    );
  }
}

const mapStateToProp = (state) => ({
  currentOrders: state.current.returnData,
  currentOrdersSts: state.current.isStatus,
  advancedOrders: state.advanced.returnData,
  advancedOrdersSts: state.advanced.isStatus,
  cartItems: state.cart.returnData,
  cartStatus: state.cart.isStatus,
  draftOrders: state.draftorders.returnData,
  draftOrdersSts: state.draftorders.isStatus,
  outletsData: state.outlets.allOutlets,
  settings: state.settings.returnData,
  setStatus: state.settings.isStatus,
});
export default connect(mapStateToProp, {
  getcurrentOrders,
  getadvancedOrders,
  getCartItems,
  getdraftOrders,
  getAllOutlets,
  getSetings,
})(Orders);
