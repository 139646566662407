import React, { Component } from 'react';
import axios from 'axios';
import cookie from 'react-cookies';
import moment from "moment";
import {
  APP_ID, DINEIN_ID, HOST_URL, DELIVERY_ID,
  PICKUP_ID
} from "../../config/constans";

import {
  Button,
  Container,
  Row,
  Col,
  Table,
  Modal,
  ModalBody,
  Jumbotron,
  Alert
} from "reactstrap";
import {
  customerId, returnValue, addressFormat, stripslashes
} from "../../config/supporters";
var _ = require("lodash");
var parse = require("html-react-parser");
const filterCurrentOrder = "currentorder";
const filterPastOrder = "pastorder";
/*const appId = returnValue(cookie.load(APP_ID)); */
const API_URL = cookie.load("hostUrl");
class Myorders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultAvilablity: "",
      orders: [], status: "Loading",
      pastorders: [], orderscount: 0,
      pastorderscount: 0, openRecieptModal: false, windowHeightNormal: 0, windowHeight: 0, currentOrders: [], currentorderlimit: 25, pastorderslimit: 25
    };
    this.viewOrderDetails = this.viewOrderDetails.bind(this);
    this.closeAll = this.closeAll.bind(this);
    this.handleChangeOdersLimit = this.handleChangeOdersLimit.bind(this);
    this.changeAv = this.changeAv.bind(this);

  }

  changeAv(avId) {

    if (avId !== this.state.defaultAvilablity) {

      this.setState({ defaultAvilablity: avId });
      this.showAllOrders(avId);
    }

  }
  viewOrderDetails(orders) {

    this.setState({ currentOrders: orders, openRecieptModal: true });
  }
  closeAll() {
    this.setState({
      openRecieptModal: false
    });
  }
  componentWillMount() {

    this.showAllOrders(DINEIN_ID);
  }
  handleChangeOdersLimit(field, e) {
    if (field === filterPastOrder) {
      this.setState({ pastorderslimit: e.target.value });
    }
    if (field === filterCurrentOrder) {
      this.setState({ currentorderlimit: e.target.value });
    }
    this.showAllOrders(this.state.defaultAvilablity, e.target.value, field);

  }
  /* show all orders */
  showAllOrders(avIdValue, limit, field) {
    const avId = (avIdValue !== "" ? avIdValue : this.state.defaultAvilablity);
    const orderLimit = ((limit !== "" && field === filterCurrentOrder) ? limit : this.state.currentorderlimit);
    const appId = returnValue(cookie.load(APP_ID));
    axios.get(API_URL + 'api/reports/order_history?except_availability=yes&app_id=' + appId + "&customer_id=" + customerId() + "&limit=" + orderLimit)

      .then(res => {
        if (res.data.status === "ok") {
          this.setState({ orders: res.data.result_set, orderscount: 1 });
          this.setState({ status: "ok" });
        } else {
          this.setState({ orderscount: 1 });
          this.setState({ status: "ok" });

        }
      });

  }

  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html = "<ul className='table_list cart_sub_items mod'>";

    if (len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]['cart_modifier_name'];
        var modval = modifiers[i]['modifiers_values'][0]['cart_modifier_name'];
        html += "<li className='sub-item'>" + modName + " : " + modval + "</li> ";
      }
      html += "</ul>";
      var reactElement = parse(html);
      return reactElement;

    } else if (_.isEmpty(comboLen) === true && comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]['menu_component_name'];
        //var comboVal =combo[i]['product_details'][0]['cart_menu_component_product_name'];
        var comboVal = this.showComboProducts(combo[i]['product_details']);
        html += " <li className='sub-item'><b>" + comboName + " :</b>" + comboVal + " " + this.showComboModifiers(combo[i]['product_details'][0]['modifiers']) + "</li> ";

        //html += "<li className='sub-item'><b>"+comboName+" :</b>"+comboVal+" "+ "</li> ";
      }
      html += "</ul>";
      var reactElement = parse(html);
      return reactElement;

    }
  }
  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        //console.log(combos[0]['modifiers']);
        var comboPro = combos[r]['menu_product_name'];
        var comboQty = combos[r]['menu_product_qty'];
        var comboPrice = combos[r]['menu_product_price'];
        var newPrice = (comboPrice > 0) ? " (+" + comboPrice + ")" : "";
        html += "<li className='sub-item'>" + comboQty + " X " + comboPro + newPrice + " </li> ";
      }
      return html;
    }
    return "";
  }
  showComboModifiers(modifiers) {
    //console.log(modifiers);
    var lenMod = modifiers.length;
    var html = "<ul className='table_list cart_sub_items combo'>";
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]['cart_modifier_name'];
        var modval = modifiers[i]['modifiers_values'][0]['cart_modifier_name'];
        var modValPrice = modifiers[i]['modifiers_values'][0]['cart_modifier_price'];
        var newModValPrice = (modValPrice > 0) ? " (+" + modValPrice + ")" : "";

        html += "<li className='sub-item'>" + modName + ": " + modval + newModValPrice + "</li> ";
      }
      html += "</ul>";
      // var reactElement = htmlToReactParserTo.parse(html );
      return html;

    }

    return "";
  }

  render() {

    const cOrders = this.state.orders;
    const current_orders = this.state.currentOrders;
    const cartItems = (typeof current_orders.items !== "undefined" ? current_orders.items : [])
    const closeAllBtn = (
      <button className="close" onClick={this.closeAll}>
        &times;
      </button>
    );

    return (
      <React.Fragment>
        <Container fluid>
          <Row>
            <Col className="p0">
              <div className="personal">
                <div className="orderBtns">
                  <Button color="primary">DELIVERY, TAKEAWAY & DINE IN</Button>
                  {/*  <Button color="default">RESERVATION</Button>
    <Button color="default">CATERING</Button>*/}

                </div>
                <div className="col-12 order-item-filter-block">
                  <div className="order-filter-option"><span className="filter-text-lbl">Show</span>
                    <div className="custom-select-control orders-item-filter-dropdown">
                      <select className="form-control" id="filtercurrentordercount" onChange={this.handleChangeOdersLimit.bind(this, filterCurrentOrder)}>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <span className="filter-text-lbl">Per Page</span></div>
                </div>
              </div>
              <div className="orderPanel">
              {_.isEmpty(cOrders) === true &&
                  <Alert color="warning" className="mt-3">
                        No Records Found !
                  </Alert>
              }
                {_.isEmpty(cOrders) === false && cOrders.map((corders, cindex) =>
                  <div className="orderList" key={cindex}>
                    <div className="orderList-head">
                      <Container fluid>
                        <Row>
                          <Col xs="12" sm="6">
                            <Button color="default btn-delivery text-uppercase">{corders.order_availability_name}</Button>
                            <span className="calender"><i className="calender"></i>{moment(corders.order_created_on).format("DD/MM/YYYY")}</span>
                            <span className="deliveryTime"><i className="time"></i>{moment(corders.order_created_on).format("hh:mm A")}</span>
                          </Col>
                          <Col xs="12" sm="6">
                            <div className="viewReceipt">
                              <div>
                                <a href="#" onClick={this.viewOrderDetails.bind(this, corders)}>VIEW RECEIPT</a>
                                {/* <span>|</span>
                                <a href="#">PRINT</a> */}
                              </div>
                              <div className="clickTxt">Click View Receipt for order details</div>

                            </div>
                          </Col>

                        </Row>
                      </Container>

                    </div>
                    <div className="orderList-body">
                      <Container fluid>
                        <Row>
                          <Col xs="12">
                            <h5 className="m0">Order No - {corders.order_local_no}</h5>
                            <p className="deliveryAddress">{corders.outlet_name} - {addressFormat(
                              corders.outlet_unit_number1,
                              corders.outlet_unit_number2,
                              corders.outlet_address_line1,
                              corders.outlet_address_line2,
                              corders.outlet_postal_code,

                            )} </p>
                          </Col>
                          <Col xs="12">
                            <Table borderless className="orderListTable m0">

                              <tbody>
                                {corders.items.map((item, index) =>
                                  <tr>

                                    <td>{item.item_name}</td>
                                    <td>{item.item_qty}</td>
                                    <td>{item.item_total_amount}</td>
                                  </tr>

                                )}
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                    <div className="orderList-footer">
                      <Container fluid>
                        <Row>
                          <Col xs="12" sm="6">
                            <h5 className="orderAmt">Order Amount <span><sup>$</sup>{corders.order_total_amount}</span></h5>
                          </Col>
                          <Col xs="12" sm="6" className="text-right">
                            <Button color="default btn-received">{corders.status_name}</Button>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
        {/* View Receipt modal */}
        <Modal
          size="lg"
          isOpen={this.state.openRecieptModal}
          className="callcentre-modal order-details-modal"
        >
          <div className="modal-header">
            <div className="modal-top-icon">{closeAllBtn}</div>
            <div className="modal-title-block">
              <h1>Order Details</h1>
            </div>
          </div>
          <ModalBody>
            <Jumbotron
              fluid
              className="customer-delivery-info-jumbotron"
            >
              <h6 className="title">Order Details</h6>
              <Container fluid>
                <Row>
                  <Col sm="6">
                    <Row>
                      <div className="customer-delivery-address col-10 pr-0">
                        {current_orders.order_availability_id === DELIVERY_ID && (
                          <h6>Delivery Address</h6>
                        )}
                        {current_orders.order_availability_id === PICKUP_ID && (
                          <h6>Pickup Address</h6>
                        )}
                        {current_orders.order_availability_id === DELIVERY_ID && (<h5>
                          {addressFormat(
                            current_orders.order_customer_unit_no1,
                            current_orders.order_customer_unit_no2,
                            current_orders.order_customer_address_line1,
                            current_orders.order_customer_address_line2,
                            current_orders.order_customer_postal_code
                          )}
                        </h5>)}

                        {current_orders.order_availability_id === PICKUP_ID && (<h5>
                          {addressFormat(
                            current_orders.outlet_name,
                            current_orders.outlet_unit_number1,
                            current_orders.outlet_address_line1,
                            current_orders.outlet_address_line2,
                            current_orders.outlet_postal_code
                          )}
                        </h5>)}

                        <span className="order-handled-by">
                          Order handled by :{" "}
                          {current_orders.outlet_name}
                        </span>

                      </div>

                    </Row>
                  </Col>
                  <Col sm="6">
                    <Row>
                      <div className="customer-delivery-time col-10 pr-0">
                        {current_orders.order_availability_id === DELIVERY_ID && (
                          <h6>Delivery Date & Time</h6>
                        )}
                        {current_orders.order_availability_id === PICKUP_ID && (
                          <h6>Pickup Date & Time</h6>
                        )}
                        <h5>
                          <span className="cd-date">
                            {moment(current_orders.order_date).format("DD/MM/YYYY")}

                          </span>
                          <span className="cd-time">
                            {moment(current_orders.order_date).format("HH:ss")}
                          </span>
                        </h5>
                      </div>

                    </Row>
                  </Col>
                </Row>
              </Container>
            </Jumbotron>
            <Jumbotron
              fluid
              className="customer-order-info-jumbotron order-info-jumbotron"
            >
              <h6 className="title">Order Details </h6>

              <Container fluid>
                <div className="calctrcart_table">
                  <div className="calctrcart_body">
                    {_.isEmpty(cartItems) === false &&
                      cartItems.map((cart, cartIndex) => (
                        <div className="calctrcart_row" key={cartIndex}>
                          <div className="row">
                            <div className="col-7 col-sm-7 calctrcart_left">
                              {cart.item_image !== "" && (
                                <div className="calctrcart_img">
                                  <img
                                    className="img-responsive"
                                    src={cart.item_image}
                                    alt="Call center"
                                  />
                                </div>
                              )}
                              <div className="calctrcart_info">
                                <h5>{stripslashes(cart.item_name)}</h5>
                                <div className="calctrcart_extrainfo">
                                  {this.loadModifierItems(
                                    cart.item_type,
                                    cart.modifiers,
                                    cart.set_menu_component
                                  )}
                                  {/* <span className="product-attr">Size:&nbsp; Large</span>    
                            <span className="product-attr">Add on:&nbsp; Name..</span>  */}
                                </div>
                              </div>
                              {cart.cart_item_special_notes !== "" && (
                                <p className="product-instruction">
                                  {stripslashes(cart.cart_item_special_notes)}
                                </p>
                              )}
                            </div>
                            <div className="col-2 col-sm-3 calctrcart_right text-right">
                              <div className="prod-chosen-qty-block">
                                <div className="qty-box font-weight-bold">Qty: {cart.item_qty}</div>
                              </div>
                            </div>

                            <div className="col-3 col-sm-2 calctrcart_price text-right">
                              <span className="font-weight-bold">$ {cart.item_total_amount}</span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>

                  <div className="calctrcart_footer">
                    <div className="calctrcart_row">
                      <div className="row">
                        <div className="col-8">
                          <p className="text-uppercase">SUBTOTAL</p>
                        </div>
                        <div className="col-4 text-right">
                          <span>$ {current_orders.order_sub_total}</span>
                        </div>
                      </div>
                    </div>
                    {current_orders.order_availability_id === DELIVERY_ID && (
                      <div className="calctrcart_row">
                        <div className="row">
                          <div className="col-8">
                            <p className="text-uppercase">
                              Delivery Charges
                                    </p>
                          </div>
                          <div className="col-4 text-right">
                            ${current_orders.order_delivery_charge}
                          </div>
                        </div>
                      </div>
                    )}

                    {current_orders.order_availability_id === DELIVERY_ID && (
                      <div className="calctrcart_row">
                        <div className="row">
                          <div className="col-8">
                            <p className="text-uppercase">
                              Additional Delivery Charges
                                    </p>
                          </div>
                          <div className="col-4 text-right">
                            {current_orders.order_additional_delivery}

                          </div>
                        </div>
                      </div>
                    )}

                    {current_orders.order_discount_applied === "Yes" && (
                      <div className="calctrcart_row">
                        <div className="row">
                          <div className="col-8">
                            <p className="text-uppercase">
                              Discount
                                      </p>
                          </div>
                          <div className="col-4 text-right">
                            <span>${current_orders.order_discount_amount}</span>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="calctrcart_row">
                      <div className="row">
                        <div className="col-8">
                          <p className="text-uppercase">
                            GST (Inclusive)
                                  </p>
                        </div>
                        <div className="col-4 text-right">
                          <span>$ {current_orders.order_tax_calculate_amount}</span>
                        </div>
                      </div>
                    </div>
                    <div className="calctrcart_row calctrcart_footer_totrow">
                      <div className="row">
                        <div className="col-8">
                          <p className="text-uppercase">Total</p>
                        </div>
                        <div className="col-4 text-right">
                          <span>${current_orders.order_total_amount}</span>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </Container>
            </Jumbotron>
          </ModalBody>

        </Modal>
      </React.Fragment>
    );
  }

}

const DropdownIndicator = () => (
  <div className="react-select-dropdown">
    <div className="react-select-arrow"></div>
  </div>
);
export default Myorders;
