import React, { Component } from "react";
import { connect } from "react-redux";
import { getSetings } from "../../stores/settings";
import { getCartItems } from "../../stores/cart";
import { instanceOf } from "prop-types";
import { history }  from '../../store';
import { withCookies, Cookies } from "react-cookie";
import { Container, Row, Col, Button } from "reactstrap";
import CreateOrderImage from "../../assets/img/icons/create-order-icon.PNG";
import SideBar from "../layout/sidebar";
import Header from "../layout/header";
import axios from "axios";
import {
  DELIVERY_ID,
  PICKUP_ID,
  DINEIN_ID
} from "../../config/constans";
import {
  SPRING_APP_ID,
  CEDELE_APP_ID
} from "../../config/apps";
import {
getCookies
} from "../../config/supporters";
/* import images */
import DeliveryMenuIcon from "../../assets/img/icons/delivery-icon-white.png";
import TakeawayMenuIcon from "../../assets/img/icons/take-away-icon-white.png";
import DineinMenuIcon from "../../assets/img/icons/dinein-icon-white.png";
/* import CateringMenuIcon from "../../assets/img/icons/catering-icon-white.png";
import ReservationMenuIcon from "../../assets/img/icons/reservation-icon-white.png"; */
import { checkAuth,customerId,getReferenceID,hostUrl,appId,deleteOrderCookie } from "../../config/supporters";
import { Alert,Modal,ModalBody,ListGroup,ListGroupItem,ModalFooter,  Spinner } from 'reactstrap';
var _ = require("lodash");
var qs = require("qs");
class Dashboard extends Component {
  constructor(props) {
    super(props);
	this.chooseOrderType = this.chooseOrderType.bind(this);   
	this.ordertypeToggle = this.ordertypeToggle.bind(this);  	   
    this.closeAll = this.closeAll.bind(this);
	
	  this.state = {
      visible: false, destroyCart: "No", orderId : "",draftvisible: false,  modalOrderType: false, orderType : "", orderValue : "", isCart : "No", confirmationAlert : false
    };

    this.onDismiss = this.onDismiss.bind(this);
    /* validate auth token */
    if (checkAuth() === false) {
      this.props.history.push("/");
    }


  }
  
    ordertypeToggle() {
    this.setState({
      modalOrderType: !this.state.modalOrderType
     
    });
  }
  
  
  onDismiss() {
    this.setState({ visible: false });
	history.push("/dashboard");

  }
    closeAlert() {
	  this.setState(prevState => ({
      confirmationAlert: !prevState.confirmationAlert
    }));
  }
  
    proceedAlert() {
	   this.destroyCart("Yes");
  }
  
    destroyCart() {
    const customId = customerId();
    const refId = getReferenceID();
    const host = hostUrl();
    const app = appId();
    var postObject = {};
    postObject = { app_id: app };

    if (typeof customId === "undefined") {
      postObject["reference_id"] = refId;
    } else {
      postObject["customer_id"] = customId;
    }

    axios
      .post(host + "api/cart/destroy", qs.stringify(postObject))
      .then(res => {
        //cookie.remove('promotion_code');
        if (res.data.status === "ok") {
          deleteOrderCookie();
		 this.setState({ modalOrderType: false, confirmationAlert : false , isCart : "No",destroyCart : "Yes" });
		  // this.closeAll();
		  this.props.getCartItems();
       
		 // history.push("dashboard");  
		   
        } else if (res.data.status === "error") {
          this.setState({ modalOrderType: false, confirmationAlert : false , isCart : "No" });
        }
      });
  }
  
    componentWillReceiveProps(nextProps) {
		
		console.log("componentWillReceiveProps");
		    const cartItems = this.props.cartItems;
			
			console.log(cartItems);
			   if (
       
      _.isEmpty(cartItems) === false &&
      _.isEmpty(cartItems.cart_details) === false &&
      _.isEmpty(cartItems.cart_items) === false &&
	  
	  this.state.destroyCart === "No"
    ) {
		console.log("is cart");
		 
		 this.setState({isCart : "Yes"});
		
	}
		
	}
  componentDidMount() {
  this.props.getCartItems();  
    
   
	const orderId  =  (typeof this.props.match.params.orderId !== "undefined" && this.props.match.params.orderId !== ""? this.props.match.params.orderId : "");
	const orderdraft  =  (typeof this.props.match.params.orderdraft !== "undefined" && this.props.match.params.orderdraft === "draft"? 'draft' : "");
	
	 if(orderdraft === "draft") {
		 this.setState({draftvisible : true,orderId : orderId});
	 }else{
		 if(orderId !== "") {
			 this.setState({visible : true,orderId : orderId});
		 }
	 }
	 
  }
  chooseOrderType(orderId) {
 
 
 if(this.state.isCart === "Yes") { 
	 
	  this.setState({modalOrderType:false, confirmationAlert : true});
 }else { 
	  this.setState({orderType : orderId, modalOrderType : false});
	       setTimeout(
        function() {
          this.setState({ orderType: "" });
        }.bind(this),
        1000
      );
 }
	  
  }
  
  closeAll() {
    this.setState({
      modalOrderType: false, 
    });
  }
  
  render() {
	
  const settings = (typeof this.props.settings === "object" ? this.props.settings : [] );
   const availability = (typeof settings.availability === "object" ? settings.availability : [] );
   const delMenu = _.filter(availability, ['availability_id', DELIVERY_ID]);
   const pickMenu = _.filter(availability, ['availability_id', PICKUP_ID]);
   const dineMenu = _.filter(availability, ['availability_id', DINEIN_ID]);
	 
    return (
      <div>
        <Header />
        <SideBar orderType={this.state.orderType} />
	 <Modal
          size="xl"
          isOpen={this.state.modalOrderType}
         
          className="callcentre-modal order-type-modal"
        >
          <div className="modal-header">
            <div className="modal-top-icon">            
             <button className="close" onClick={this.closeAll}>
        &times;
      </button>
            </div>
           
          </div>
         
          <ModalBody>
          <div className="modal-title-block">
            <h1>Choose Ordering Type</h1>      
            </div>
          <ListGroup className="order-type-list">
		 	{  ( typeof delMenu === "object" && _.isEmpty(delMenu) === false ) &&  <li> <ListGroupItem  tag="a" onClick={this.chooseOrderType.bind(this,DELIVERY_ID)}><img src={DeliveryMenuIcon} alt=""/></ListGroupItem>Delivery</li>   }           
          	{  ( typeof pickMenu === "object" && _.isEmpty(pickMenu) === false ) &&   <li><ListGroupItem tag="a"  onClick={this.chooseOrderType.bind(this,PICKUP_ID)}><img src={TakeawayMenuIcon} alt=""/> </ListGroupItem>Takeaway</li>  }
         	{  ( typeof dineMenu === "object" && _.isEmpty(dineMenu) === false ) &&getCookies("app_id") !== CEDELE_APP_ID &&     <li><ListGroupItem tag="a"  onClick={this.chooseOrderType.bind(this,DINEIN_ID)}><img src={DineinMenuIcon} alt=""/> </ListGroupItem>Dine in</li>  }
		  {/* SPRING_APP_ID   <li><ListGroupItem tag="a"  ><img src={DineinMenuIcon} alt=""/></ListGroupItem>Dine in</li>   <li><ListGroupItem tag="a"><img src={CateringMenuIcon} alt=""/></ListGroupItem>Catering</li>
            <li><ListGroupItem tag="a"><img src={ReservationMenuIcon} alt=""/></ListGroupItem>Reservation</li>
				*/}           
		   </ListGroup>
          </ModalBody>
         
         
        </Modal>
		
		<Modal
          size="lg"
          isOpen={this.state.confirmationAlert}
          className="callcentre-modal pickup-confirmation-modal"
        >
          <div className="modal-header">
            <div className="modal-top-icon">
              
            </div>
             <div className="modal-title-block">
       
              <h2>Warning</h2>
            </div>
          </div>
          <ModalBody>
            <h5>By switching you are about to clear your cart. <br/> Do you wish to proceed ?</h5>
           
          </ModalBody>
          <ModalFooter>
		    <Spinner color="secondary" style={{ display: (this.state.checkpostalCode ===  true? "block" : "none" )}} />
           <Button color="secondary"  onClick={this.closeAlert.bind(this)}  style={{ display: (this.state.checkpostalCode ===  true? "none" : "block" )}}>
             No
            </Button>
            <Button color="primary" onClick={this.proceedAlert.bind(this)} style={{ display: (this.state.checkpostalCode ===  true? "none" : "block" )}}>
              Yes
            </Button>  
          </ModalFooter>
        </Modal>
        <Container fluid className="main-container">
          <Row className="m-0">
            <Col xs="12" className="pl-0 create-order-flex-container">
			   <Alert color="warning" isOpen={this.state.visible} toggle={this.onDismiss}>
        Your order has been placed successfully. Your order id is  {this.state.orderId}
      </Alert>
      <Alert color="warning" isOpen={this.state.draftvisible} toggle={this.onDismiss}>
        Your draft order has been created successfully. Your draft refer id is  {this.state.orderId}
      </Alert>
              <div className="card">
			
                <img
                  className="card-img-top"
                  src={CreateOrderImage}
                  alt="Card images cap"
                />
                <div className="card-body">
                  <Button color="primary" className="" block onClick={this.ordertypeToggle.bind()}>
                    Create a Order
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
}

const mapStateToProp = state => ({
  settings: state.settings.returnData,
  setStatus: state.settings.isStatus,
   cartItems: state.cart.returnData,

});
export default connect(
  mapStateToProp,
  { getSetings, getCartItems }
)(withCookies(Dashboard));
