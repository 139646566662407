import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import store, { history } from "../../store";
import { DELIVERY_ID, HOST_URL, APP_ID } from "../../config/constans";
import { CEDELE_APP_ID , SPRING_APP_ID, PIE_APP_ID} from "../../config/apps";
import {
  CHOOSE_OUTLET,
  CHOOSE_POSTALCODE,
  UNIT_NUMBER_ERROR
} from "../../config/messages";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import { fetchalloutlets } from "../../stores/delivery/deliveryoutlets";
//import { getSetings } from "../../stores/settings";
import { findoutlet } from "../../stores/delivery/findzone";
import cookie from "react-cookies";
import { returnValue, getCookies } from "../../config/supporters";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Alert, Spinner } from "reactstrap";
import { addDays, setHours, setMinutes } from "date-fns";
import {
  Button,
  Modal,
  Input,
  ModalBody,
  ModalFooter,
  ListGroup,
  ListGroupItem,
  InputGroup,
  Form,
  FormGroup,
  Label,
  Row,
  Col
} from "reactstrap";
import { Scrollbars } from "react-custom-scrollbars";
/* two */
import CheckedMarkIcon from "../../assets/img/icons/checked-mark.png";
import DeliveryBoyIcon from "../../assets/img/icons/delivery-scooter-boy.png";
import ClockIcon from "../../assets/img/icons/clock.png";
//import { fetchAllOutlets } from "../../stores/delivery/deliveryoutlets";
var _ = require("lodash");
var moment = require("moment");
var striptags = require("striptags");

class Deliverypopup extends Component {
  constructor(props) {
    super(props);
    this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
    this.postalInput = this.postalInput.bind(this);
    this.deliveryStepOne = this.deliveryStepOne.bind(this);
    this.deliveryStepTwo = this.deliveryStepTwo.bind(this);
    this.deliveryStepThree = this.deliveryStepThree.bind(this);
    this.deliveryStepFour = this.deliveryStepFour.bind(this);
    this.closeAll = this.closeAll.bind(this);
    this.unitOneChange = this.unitOneChange.bind(this);
    this.unitTwoChange = this.unitTwoChange.bind(this);
    this.setDeliveryData = this.setDeliveryData.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);

        /* Rounding to the nearest 15 minute interval */
    const start = moment();
    const remainder = 15 - (start.minute() % 15);

    /* To change the date after 2 PM */
    var CurrentDate = this.calcTime("Singapore", "+8");
    var currentHrs = CurrentDate.getHours();
    if (currentHrs >= 16) {
      cookie.save("minDay", 3,{ path: "/" });
    } else {
      cookie.save("minDay", 2,{ path: "/" });
    }
    const appid = getCookies("app_id");
    let dateStart = addDays(new Date(),0);
    let timeStart = setHours(setMinutes(new Date(), 0), currentHrs);
    if (CEDELE_APP_ID === appid) {
      dateStart = addDays(new Date(), (cookie.load("minDay") !== "" ? cookie.load("minDay") : 0));
      timeStart = setHours(setMinutes(new Date(), 15), 9);
    } else if (SPRING_APP_ID === appid) {
    
      dateStart = currentHrs >= 14 ? addDays(new Date(), 0) : addDays(new Date(), 0);
      timeStart = setHours(setMinutes(new Date(), 0), 11);
    }
  

    this.state = {
      stepOne: false,
      stepTwo: false,
      stepThree: false,
      stepFour: false,
      rSelected: "",
      postalCode: "",
      CHOOSE_OUTLET: false,
      CHOOSE_POSTALCODE: false,
      UNIT_NUMBER_ERROR: false,
      dyZoneError: "",
      ordertakenOutlet: "",
      ordertakenOutletAddress: "",
      unitOne: "",
      unitTwo: "",
            startDate: dateStart,
    minDate: dateStart,
      startTime: timeStart,
      checkpostalCode: false,
      outletDetails: []
    };
  }

  /* Convert Timezone */
  calcTime(city, offset) {
    var d = new Date();
    var utc = d.getTime() + d.getTimezoneOffset() * 60000;
    var nd = new Date(utc + 3600000 * offset);
    return nd;
  }

  componentWillMount() {
    this.props.fetchalloutlets();
  }
  deliveryStepOne() {
    this.setState({
      stepOne: !this.state.stepOne,
      stepTwo: false,
      stepThree: false,
      stepFour: false
    });
    this.resetPostlCodeValues();
  }

  resetPostlCodeValues() {
    this.setState({
      checkpostalCode: false,
      outletDetails: [],
      CHOOSE_POSTALCODE: false,
      dyZoneError: "",
      ordertakenOutlet: "",
      ordertakenOutletAddress: "",
      postalCode: "",
      rSelected: ""
    });
  }

  deliveryStepTwo() {
    const outletID = this.state.rSelected;
    if (typeof outletID === "undefined" || outletID === "") {
      this.setState({ CHOOSE_OUTLET: true });
      return false;
    }

    this.setState({
      stepTwo: !this.state.stepTwo,
      stepOne: false,
      stepThree: false,
      stepFour: false,
      CHOOSE_OUTLET: false
    });
  }
  deliveryStepThree() {
    const postalCode = this.state.postalCode;
    const outletId = this.state.rSelected;

    if (
      typeof postalCode === "undefined" ||
      postalCode === "" ||
      postalCode.length < 5
    ) {
      this.setState({
        CHOOSE_POSTALCODE: true,
        dyZoneError: CHOOSE_POSTALCODE
      });
      return false;
    }
    const appId = returnValue(cookie.load(APP_ID));
    const API_URL =
      cookie.load(HOST_URL) +
      "apiv2/outlets/findOutletZone/?app_id=" +
      appId +
      "&availability_id=" +
      DELIVERY_ID +
      "&postal_code=" +
      postalCode +
      "&outlet_id=" +
      outletId +
      "&skip_timing=Yes";
    this.setState({ checkpostalCode: true });
    axios.get(API_URL).then(res => {
      this.setState({ checkpostalCode: false });
      if (res.data.status === "ok") {
        const postalcodeInfo = res.data.result_set.postal_code_information;
        this.setState({
          outletDetails: res.data.result_set,
          stepThree: !this.state.stepThree,
          stepOne: false,
          stepTwo: false,
          stepFour: false,
          CHOOSE_POSTALCODE: false,
          ordertakenOutlet: res.data.result_set.outlet_name,
          ordertakenOutletAddress:
            postalcodeInfo.zip_buno +
            " " +
            postalcodeInfo.zip_sname +
            ", Singapore " +
            postalcodeInfo.zip_code
        });
        var tatvalnew = (res.data.result_set.outlet_delivery_tat !== '') ? parseInt( res.data.result_set.outlet_delivery_tat) : 0;
        var dtnowobj = new Date();
          dtnowobj.setMinutes(dtnowobj.getMinutes() + tatvalnew);
        var currentTimetat = dtnowobj.getHours();
        var currentMinstat = dtnowobj.getMinutes(); 
        var Crt_Time = currentTimetat+"."+currentMinstat;
        if(PIE_APP_ID === getCookies("app_id")){
        currentMinstat = Math.ceil(currentMinstat/ 60) * 60;
        }else{
        currentMinstat = Math.ceil(currentMinstat/ 15) * 15;
        }
        if(currentMinstat == 60){
          currentTimetat = currentTimetat + 1;
        }      
        var  timeStart = setHours(setMinutes(new Date(), currentMinstat), currentTimetat);

        this.setState({
              startTime: timeStart,
        })


      } else {
        this.setState({
          CHOOSE_POSTALCODE: true,
          dyZoneError: res.data.message
        });
      }
    });
  }
  componentDidUpdate() {
    const allDatas = store.getState();
    const zoneDetails = allDatas.zone;
  }

  deliveryStepFour() {
    const unitOne = this.state.unitOne;
    const unitTwo = this.state.unitTwo;
    if (
      typeof unitTwo === "undefined" ||
      unitTwo === "" ||
      (typeof unitOne === "undefined" || unitOne === "")
    ) {
      this.setState({ UNIT_NUMBER_ERROR: true });
      return false;
    }

    this.setState({
      stepFour: !this.state.stepFour,
      stepOne: false,
      stepTwo: false,
      stepThree: false
    });
  }

  closeAll() {
    this.setState({
      stepFour: false,
      stepOne: false,
      stepTwo: false,
      stepThree: false
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.deliveryOne !== this.props.deliveryOne) {
      this.deliveryStepOne();
    }
  }

  handleDateChange(date) {

    this.setState({
      startDate: date
    });

  }

  handleTimeChange(date) {
    var dateVal = new Date(date);

    this.setState({
      startTime: dateVal
    });
  }
  componentUnMount() { }
  showDeliveryOutlets(outletResult) {
    return outletResult.map((out, index) => (
      <React.Fragment key={index}>
        {out.oa_availability_id === DELIVERY_ID && (
          <ListGroupItem>
            <InputGroup>
              <Input
                onClick={() => this.onRadioBtnClick(out.oa_outlet_id)}
                type="radio"
                id={"radio_" + out.oa_outlet_id}
                name="outletsZone"
                value={out.oa_outlet_id}
              />
              <Label htmlFor={"radio_" + out.oa_outlet_id}>
                <span className="outlet-name">
                  {striptags(out.outlet_name)}
                </span>
                <span className="outlet-address">
                  {striptags(out.outlet_address_line1) + " "}
                  {striptags(out.outlet_postal_code)}
                </span>
              </Label>
            </InputGroup>
          </ListGroupItem>
        )}
      </React.Fragment>
    ));
  }
  onRadioBtnClick(rSelected) {
    this.setState({ CHOOSE_OUTLET: false });
    this.setState({ rSelected: rSelected });
  }
  postalInput(e) {
    this.setState({
      CHOOSE_POSTALCODE: false,
      dyZoneError: ""
    });
    this.setState({ postalCode: e.target.value });
  }
  unitOneChange(e) {
    this.setState({ unitOne: e.target.value, UNIT_NUMBER_ERROR: false });
  }
  unitTwoChange(e) {
    this.setState({ unitTwo: e.target.value, UNIT_NUMBER_ERROR: false });
  }

  setDeliveryData() {
    const postalCode = this.state.postalCode;
    const outletId = this.state.rSelected;
    const outletDetails = this.state.outletDetails;
    const stateDate = this.state.startDate;
    const startTime = this.state.startTime;

    var deliveryDate = moment(stateDate).format("DD/MM/YYYY");
    var deliveryTime = moment(startTime).format("HH:mm");
    var deliveryTimeOrder = moment(startTime).format("hh:mm");

    if (
      typeof postalCode !== "undefined" &&
      postalCode !== "" &&
      (typeof outletId !== "undefined" && outletId !== "") &&
      (deliveryTime !== "" && deliveryDate !== "") &&
      _.isEmpty(outletDetails) === false
    ) {
      cookie.save("orderOutletId", outletId, { path: "/" });
      cookie.save("orderPostalCode", postalCode, { path: "/" });
      cookie.save("orderZoneID", outletDetails.zone_id, { path: "/" });
      cookie.save("orderOutletName", outletDetails.outlet_name, { path: "/" });
      cookie.save(
        "orderAddressOne",
        outletDetails.postal_code_information.zip_buno,
        { path: "/" }
      );
      cookie.save(
        "orderAddressTwo",
        outletDetails.postal_code_information.zip_sname,
        { path: "/" }
      );
      cookie.save("orderUnitOne", this.state.unitOne.replace('#',''), { path: "/" });
      cookie.save("orderUnitTwo", this.state.unitTwo.replace('#',''), { path: "/" });
      cookie.save("orderDeliveryDate", deliveryDate, { path: "/" });
      cookie.save("orderDeliveryTime", deliveryTime, { path: "/" });
      cookie.save("orderDeliveryTimeRequest", deliveryTimeOrder, { path: "/" });
      cookie.save("orderAvailability", DELIVERY_ID, { path: "/" });
      this.setState({ stepFour: false });
      history.push("/products");
    } else {
      alert("something went wrong(1)");
    }
  }

  render() {
    const closeAllBtn = (
      <button className="close" onClick={this.closeAll}>
        &times;
      </button>
    );
    const outletsData = this.props.outletsData;
    const outletResult = _.filter(outletsData, {
      oa_availability_id: DELIVERY_ID
    });
    const scrollHeight = (outletResult.length > 3) ? "height:300" : "height:200";
    return (
      <React.Fragment>
        {/* 1st modal */}
        <Modal
          size="lg"
          isOpen={this.state.stepOne}
          className="callcentre-modal pickup-location-modal"
        >
          <div className="modal-header">
            <div className="modal-top-icon">
              <img src={DeliveryBoyIcon} alt={"Shopping Bag"} />
              {closeAllBtn}
            </div>
            <div className="modal-title-block">
              <h1>Delivery Location</h1>
              <h5>Please select which outlet do you prefer</h5>
            </div>
          </div>
          <ModalBody>
            <Alert color="danger" isOpen={this.state.CHOOSE_OUTLET}>
              {CHOOSE_OUTLET}
            </Alert>
            <Scrollbars autoHeight
              autoHeightMin={0}
              autoHeightMax={300} >
              <Form>
                <ListGroup className="pickup-location-list">
                  {this.showDeliveryOutlets(outletResult)}
                </ListGroup>
              </Form>
            </Scrollbars>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.deliveryStepTwo.bind(this)}>
              Continue
            </Button>
          </ModalFooter>
        </Modal>

        {/* Delivery second modal */}
        <Modal
          size="lg"
          isOpen={this.state.stepTwo}
          className="callcentre-modal delivery-postalcode-modal"
        >
          <div className="modal-header">
            <div className="modal-top-icon">
              <img src={DeliveryBoyIcon} alt={"Shopping Bag"} />
              {closeAllBtn}
            </div>
            <div className="modal-title-block">
              <h1>Delivery Location</h1>
              <h5>Enter postal code to check availability </h5>
            </div>
          </div>
          <ModalBody>
            <Alert color="danger" isOpen={this.state.CHOOSE_POSTALCODE}>
              {typeof this.state.dyZoneError !== ""
                ? this.state.dyZoneError
                : CHOOSE_POSTALCODE}
            </Alert>
            <Form>
              <FormGroup>
                <Input
                  type="text"
                  name="postalcode"
                  id="postalCode"
                  placeholder="Postal Code"
                  value={this.state.postalCode}
                  onChange={this.postalInput}
                  maxLength="6"
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Spinner
              color="secondary"
              style={{
                display: this.state.checkpostalCode === true ? "block" : "none"
              }}
            />
            <Button
              color="secondary"
              onClick={this.deliveryStepOne}
              style={{
                display: this.state.checkpostalCode === true ? "none" : "block"
              }}
            >
              Go Back
            </Button>
            <Button
              color="primary"
              onClick={this.deliveryStepThree}
              style={{
                display: this.state.checkpostalCode === true ? "none" : "block"
              }}
            >
              Continue
            </Button>
          </ModalFooter>
        </Modal>

        {/* third modal */}
        <Modal
          size="lg"
          isOpen={this.state.stepThree}
          className="callcentre-modal postalcode-confirmation-modal"
        >
          <div className="modal-header">
            <div className="modal-top-icon">
              <img src={CheckedMarkIcon} alt={"Shopping Bag"} />
              {closeAllBtn}
            </div>
            <div className="modal-title-block">
              <h1>Awesome</h1>
              <h5>We can deliver to your location </h5>
            </div>
          </div>
          <ModalBody>
            <p>Order can deliver to this address</p>
            <h4>{this.state.ordertakenOutletAddress}</h4>
            <Alert color="danger" isOpen={this.state.UNIT_NUMBER_ERROR}>
              {UNIT_NUMBER_ERROR}
            </Alert>
            <Form>
              <FormGroup row>
                <Col sm={6} className="pr-0">
                  <Input
                    type="text"
                    name="unitOne"
                    id="unitOne"
                    placeholder="Floor No"
                    value={this.state.unitOne}
                    onChange={this.unitOneChange}
                  />
                </Col>
                <Col sm={6} className="pr-0">
                  <Input
                    type="text"
                    name="unitTwo"
                    id="unitTwo"
                    value={this.state.unitTwo}
                    placeholder="Unit No"
                    onChange={this.unitTwoChange}
                  />
                </Col>
              </FormGroup>
            </Form>
            <p>Order handled by :{this.state.ordertakenOutlet}</p>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.deliveryStepTwo}>
              Go Back
            </Button>
            <Button color="primary" onClick={this.deliveryStepFour}>
              Continue
            </Button>
          </ModalFooter>
        </Modal>

        {/* fourth  modal */}
        <Modal
          size="lg"
          isOpen={this.state.stepFour}
          className="callcentre-modal deliverytime-modal "
        >
          <div className="modal-header  datepicker-header">
            <div className="modal-top-icon">
              <img src={ClockIcon} alt={"Shopping Bag"} className="datepicker-icon" />
              {closeAllBtn}
            </div>
            <div className="modal-title-block">
              <h1 className="datepicker-h1">Delivery Time</h1>
              <h5 className="datepicker-h5">Earliest Delivery Date & Time </h5>
            </div>
          </div>
          <ModalBody>
            <Form>
              <FormGroup>
                <Row>
                  <Col xs={6}>
                    <DatePicker
                      className="form-control"
                      selected={this.state.startDate}
                      minDate={this.state.minDate}
                      onChange={this.handleDateChange}
                      dateFormat="dd/MM/yyyy"
                      maxDate={addDays(new Date(), 90)}
                    />
                  </Col>
                  <Col xs={6}>
                    {CEDELE_APP_ID === getCookies("app_id") && (
                      <DatePicker
                        className="form-control"
                        selected={this.state.startTime}
                        onChange={this.handleTimeChange}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                        timeCaption="Time"
                        minTime={setHours(setMinutes(new Date(), 0), 6)}
                        maxTime={setHours(setMinutes(new Date(), 0), 20)}
                      />
                    )}

                    {PIE_APP_ID == getCookies("app_id") && (
                      <DatePicker
                        className="form-control"
                        selected={this.state.startTime}
                        onChange={this.handleTimeChange}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={60}
                        dateFormat="h:mm aa"
                        timeCaption="Time"
                      />
                    )}

                    {CEDELE_APP_ID !== getCookies("app_id") && PIE_APP_ID !== getCookies("app_id") && (
                      <DatePicker
                        className="form-control"
                        selected={this.state.startTime}
                        onChange={this.handleTimeChange}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                        timeCaption="Time"
                      />
                    )}
                  </Col>
                </Row>
              </FormGroup>
            </Form>
            <p>Click time section to pick advanced order date & time</p>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.deliveryStepThree}>
              Go Back
            </Button>
            <Button color="primary" onClick={this.setDeliveryData}>
              View Menu
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
}

const mapStateToProp = state => ({
  outletsData: state.outlets.returnData,
  setStatus: state.outlets.isStatus,
  zoneDetails: state.zone.returnData,
  zoneError: state.zone.isError,
  zoneStatus: state.zone.isStatus
});
export default connect(
  mapStateToProp,
  { fetchalloutlets, findoutlet }
)(withCookies(Deliverypopup));
