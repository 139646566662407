import axios from "axios";
import { HOST_URL, APP_ID } from "../config/constans";
import cookie from "react-cookies";
import { customerId,returnValue,getCookies } from "../config/supporters";

export const GET_CUSTOMER_EARNED_REWARDPOINTS = "rewards/GET_CUSTOMER_EARNED_REWARDPOINTS";
export const GET_CUSTOMER_REDEEMED_REWARDPOINTS = "rewards/GET_CUSTOMER_REDEEMED_REWARDPOINTS";
export const GET_CUSTOMER_PROMOTIONS = "rewards/GET_CUSTOMER_PROMOTIONS";
export const GET_CUSTOMER_PROMOTION_DETAILS = "rewards/GET_CUSTOMER_PROMOTION_DETAILS";
const appId = returnValue(cookie.load(APP_ID));
const API_URL = getCookies('hostUrl');

const initialState = {
  customerPromotions: [],
  earnedRewards: [],
  redeemedRewards: [],
  promotionDetails: []

};

export default (state = initialState, action) => {
 
  switch (action.type) {

    case GET_CUSTOMER_EARNED_REWARDPOINTS:
      return {
        ...state,
        earnedRewards: action.earnedRewards,
      };
    case GET_CUSTOMER_REDEEMED_REWARDPOINTS:
      return {
        ...state,
        redeemedRewards: action.redeemedRewards,
      };
    case GET_CUSTOMER_PROMOTIONS:
      return {
        ...state,
        customerPromotions: action.customerPromotions,
      };
    case GET_CUSTOMER_PROMOTION_DETAILS:
    return {
      ...state,
      promotionDetails: action.customerPromotions,
    };
    default:
      return state;
  }
};

export const getEarnedRewards = (limitCount) => {
 
  let API = API_URL + "api/loyalty/customer_earned_rewardpoint_histroyv1";
  return function (dispatch) {
   
    axios
      .get(`${API}/?app_id=` + appId + "&customer_id="+ customerId() + "&limit="+ limitCount)
      .then(response => {
      
        dispatch({
          type: GET_CUSTOMER_EARNED_REWARDPOINTS,
          earnedRewards: response.data.result_set
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const getRedeemedRewards = (limitCount) => {
  let API = API_URL + "api/loyalty/customer_redeemed_rewardpoint_histroy";
  return function (dispatch) {

    axios
      .get(`${API}/?app_id=` + appId + "&customer_id=" + customerId() + "&limit="+ limitCount)
      .then(response => {
        dispatch({
          type: GET_CUSTOMER_REDEEMED_REWARDPOINTS,
          redeemedRewards: response.data.result_set
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const getPromotions = (limitCount) => {
  let API = API_URL + "api/promotion_api_v2/promotionlistWhitoutuniqcode";
  return function (dispatch) {

    axios
      .get(`${API}/?app_id=` + appId + "&customer_id=" + customerId() + "&limit="+ limitCount)
      .then(response => {       
        dispatch({
          type: GET_CUSTOMER_PROMOTIONS,
          customerPromotions: response.data.result_set.my_promo
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
};
export const getPromotionDetails = (promotionId) => {
  let API = API_URL + "api/promotion_api_v2/promotion_details";
  return function (dispatch) {

    axios
      .get(`${API}/?app_id=` + appId + "&promotion_id=" + promotionId)
      .then(response => {       
        dispatch({
          type: GET_CUSTOMER_PROMOTION_DETAILS,
          promotionDetails: response.data.result_set
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
};


