import axios from "axios";
import cookie from "react-cookies";
import { HOST_URL, APP_ID } from "../../config/constans";
import { returnValue } from "../../config/supporters";
export const GET_SETTINGS = "settings/GET_SETTINGS";

const initialState = {
  returnData: [],
  isFetching: true,
  isError: null,
  isStatus: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_SETTINGS:
      return {
        ...state,
        returnData: action.returnData,
        isStatus: action.isStatus,
        isFetching: false
      };

    default:
      return state;
  }
}
export function getSetings(pageslug = null) {
  let appId = returnValue(cookie.load(APP_ID));
  let API_URL = cookie.load(HOST_URL) + "apiv2/settings/getCommonSettings";
  let availability = "";
  let postalFilter = "No";

  return function(dispatch) {
    axios
      .get(
        `${API_URL}/?app_id=` +
          appId +
          "&availability=" +
          availability +
          "&postalFilter=" +
          postalFilter		  +
          "&disabled_availability=callcenter"  
      )
      .then(response => {
        console.log(response);
        dispatch({
          type: GET_SETTINGS,
          returnData: response.data.result_set,
          isStatus: response.data.status,
          isFetching: false
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
}
