import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";

import classnames from "classnames";
import {
  Alert,
  Nav,
  NavItem,
  NavLink,
  Container,
  Row,
  Col,
  TabContent, TabPane, Table
} from "reactstrap";
import { getEarnedRewards, getRedeemedRewards } from "../../stores/rewards";
import { getPromoCount } from "../../stores/user";
var _ = require("lodash");
/*const filterCurrentOrder = "rewardspoints";
const filterPastOrder = "redeempoints"; */

class Myrewards extends Component {
  constructor(props) {
    super(props);
    this.handleChangeFilterRewards = this.handleChangeFilterRewards.bind(this);
    this.handleChangeFilterRedeem = this.handleChangeFilterRedeem.bind(this);
    this.toggleRewardsTab = this.toggleRewardsTab.bind(this)
    this.state = {
      startDate: new Date(),
      cartData: [],
      cartItems: [],
      status: 'Loading',
      activeRewardsTab: 'RE'
    };

  }
  componentWillMount() {

    this.props.getEarnedRewards();
    this.props.getRedeemedRewards();
    this.props.getPromoCount();
  }
  toggleRewardsTab(tab) {
    if (this.state.activeRewardsTab !== tab) {
      this.setState({
        activeRewardsTab: tab
      });
    }
  }

  handleChangeFilterRewards(e) {
    this.props.getEarnedRewards(e.target.value);
  }
  handleChangeFilterRedeem(e) {
    this.props.getRedeemedRewards(e.target.value);
  }
  render() {
    const getEarnedRewards = this.props.earnedRewards;
    const getRedeemedRewards = this.props.redeemedRewards;
    const rewardCount = this.props.rewardCount;

    return (
      <React.Fragment>


        <Container fluid>
          <Row>
            <Col className="p0">
              <div className="address">
                <div className="rewards">
                  <div className="points">
                    <p>Point Balance</p>
                    <p>{rewardCount} Points</p>
                    <p>{rewardCount ? "20 points will expire after 30 days": "" }</p>
                  </div>

                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeRewardsTab === 'RE' })}
                        onClick={() => { this.toggleRewardsTab('RE'); }}
                      >
                        REWARDS EARNED
                                </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeRewardsTab === 'PR' })}
                        onClick={() => { this.toggleRewardsTab('PR'); }}
                      >
                        POINTS REDEEMED
                               </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.activeRewardsTab}>
                    <TabPane tabId="RE">
                      {_.isEmpty(getEarnedRewards) === true &&
                      
                        <Alert color="warning" className="mt-3">
                        No Records Found !
                       </Alert>
                      }
                      {_.isEmpty(getEarnedRewards) === false && 
                       <React.Fragment>
                        <div className="pointsTable">
                          <Table className="m0">
                            <thead>
                              <tr>
                                <th>REWARD FROM</th>
                                <th>AVAILABILITY</th>
                                <th>EARNED POINTS</th>
                                <th>REDEEMED POINTS</th>
                                <th>BALANCE POINTS</th>
                                <th>EARNED ON</th>
                                <th>EXPIRED ON</th>
                              </tr>
                            </thead>
                            <tbody>

                              {_.isEmpty(getEarnedRewards) === false && getEarnedRewards.map((eRewards, ernIndex) =>
                                <tr key={ernIndex}>
                                  <td >#{eRewards.order_local_no}</td>
                                  <td>{eRewards.order_availability_name}</td>
                                  <td>S${eRewards.lh_credit_points} EARNED</td>
                                  <td>S${eRewards.lh_debit_points}</td>
                                  <td>S${eRewards.reward_points}</td>
                                  <td><span>{moment(eRewards.lh_created_on).format("DD-MM-YYYY")}</span><span>{moment(eRewards.lh_created_on).format("hh:mm A")}</span></td>
                                  <td><span>{moment(eRewards.lh_expiry_on).format("DD-MM-YYYY")}</span><span>{moment(eRewards.lh_expiry_on).format("hh:mm A")}</span></td>
                                </tr>
                              )}

                            </tbody>
                          </Table>


                        </div>
                        {/*<div className="tableBtm">
                          <div>Earned: <sup>s$</sup>16.76</div>
                          <div>Cancelled: <sup>s$</sup>16.76</div>
                          <div>Redeemed: <sup>s$</sup>16.76</div>
                          <div>Balance: <sup>s$</sup>16.76</div>
                          <div>Expired: <sup>s$</sup>16.76</div>
                              </div> */}
                        </React.Fragment>
                      }
                    </TabPane>
                    <TabPane tabId="PR">
                    {_.isEmpty(getRedeemedRewards) === true &&
                      <Alert color="warning" className="mt-3">
                        No Records Found !
                       </Alert>
                        }
                        {_.isEmpty(getRedeemedRewards) === false &&
 <React.Fragment>
                      <div className="pointsTable">
                        

                          <Table className="m0">
                            <thead>
                              <tr>
                                <th>REWARD FROM</th>
                                <th>AVAILABILITY</th>
                                <th>REDEEMED POINTS</th>
                                <th>EARNED ON</th>

                              </tr>
                            </thead>
                            <tbody>

                              {_.isEmpty(getRedeemedRewards) === false && getRedeemedRewards.map((eRedeemedRewards, erdIndex) =>
                                <tr key={erdIndex}>
                                  <td >#{eRedeemedRewards.order_local_no}</td>
                                  <td>{eRedeemedRewards.order_availability_name}</td>
                                  <td>S${eRedeemedRewards.lh_redeem_point} EARNED</td>
                                  <td><span>{moment(eRedeemedRewards.lh_created_on).format("DD-MM-YYYY")}</span><span>{moment(eRedeemedRewards.lh_created_on).format("hh:mm A")}</span></td>

                                </tr>
                              )}


                            </tbody>
                          </Table>
                        
                      </div>
                     {/*} <div className="tableBtm">
                        <div>Earned: <sup>s$</sup>16.76</div>
                        <div>Cancelled: <sup>s$</sup>16.76</div>
                        <div>Redeemed: <sup>s$</sup>16.76</div>
                        <div>Balance: <sup>s$</sup>16.76</div>
                        <div>Expired: <sup>s$</sup>16.76</div>
                              </div> {*/}
                      </React.Fragment>
                      }
                    </TabPane>
                  </TabContent>

                </div>
              </div>
            </Col>
          </Row>
        </Container>
        }
      </React.Fragment>
    );
  }

}

const mapStateToProps = ({ rewards, user }) => ({
  earnedRewards: rewards.earnedRewards,
  redeemedRewards: rewards.redeemedRewards,
  rewardCount: user.rewardCount,
  promoCount: user.promoCount,


});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getEarnedRewards,
      getRedeemedRewards,
      getPromoCount

    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Myrewards);
