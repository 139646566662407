import React, { Component } from 'react';
import axios from "axios";
import cookie from 'react-cookies';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getUserData, getSecondaryAddress, removeCustomerAddress } from "../../stores/user";
import {
    Input,
    Alert,
    FormGroup,
    Container,
    Row,
    Col

} from "reactstrap";
/*import siteSettingLogo from "../../assets/img/icons/site-settings.png"; 
import lockLogo from "../../assets/img/icons/lock.png";
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
 */

import {
    customerId,
    showInfo,
    returnValue
} from "../../config/supporters";

import { history } from "../../store";
import moment from "moment";
import MaskedInput from 'react-text-mask';
import {
    APP_ID, HOST_URL
} from "../../config/constans";

const $ = window.$;
var _ = require('lodash');
const appId = returnValue(cookie.load(APP_ID));
const API_URL = cookie.load(HOST_URL);

class Accountdetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: {},
            errors: {},
            notifyChecked: false,
            secondaryAddressList: {}
        };
        this.onChangeGender = this.onChangeGender.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.destroyUserSession = this.destroyUserSession.bind(this);
        this.handleRemoveAddress = this.handleRemoveAddress.bind(this);
    }


    componentDidMount() {
        this.props.getUserData();
        this.props.getSecondaryAddress();
        
    }
    componentWillUpdate(nextProps) {
        
        if (
            nextProps.secondaryAddressList !== this.props.secondaryAddressList
          ) {
           
            // this.props.getSecondaryAddress();
          }
    }
    destroyUserSession() {
        cookie.remove("UserId");
        cookie.remove("UserFname");
        cookie.remove("UserLname");
        cookie.remove("UserMobile");
        cookie.remove("UserEmail");
        cookie.remove("userAccountType");
        cookie.remove("UserDefaultAddress");
        cookie.remove("UserDefaultPostalCode");
        cookie.remove("UserDefaultUnitOne");
        cookie.remove("UserDefaultUnitTwo");

        history.push("/");
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        var userPhoneNumber = fields["phonenumber"];


        //First Name
        if (!fields["firstname"]) {
            formIsValid = false;
            errors["firstname"] = "This field is required.";
        }

        if (typeof fields["firstname"] !== "undefined") {
            if (!fields["firstname"].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                errors["firstname"] = "First Name must contain only letters.";
            }
        }
        //Last Name
        if (!fields["lastname"]) {
            formIsValid = false;
            errors["lastname"] = "This field is required.";
        }

        if (typeof fields["lastname"] !== "undefined") {
            if (!fields["lastname"].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                errors["lastname"] = "Last Name must contain only letters.";
            }
        }
        //Phone Number
        if (!userPhoneNumber) {
            formIsValid = false;
            errors["phonenumber"] = "This field is required.";
        }

        if (typeof userPhoneNumber !== "undefined") {
            if (!userPhoneNumber.match(/^[0-9]+$/)) {
                formIsValid = false;
                errors["phonenumber"] = "Invalid Mobile number";
            } else if (userPhoneNumber.length < 8) {
                formIsValid = false;
                errors["phonenumber"] = "Mobile number must be of minimum 8 characters length.";
            }
        }
        //Email
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "This field is required.";
        }

        if (typeof fields["email"] !== "undefined") {
            let lastAtPos = fields["email"].lastIndexOf('@');
            let lastDotPos = fields["email"].lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
                formIsValid = false;
                errors["email"] = "Email is not valid.";
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;

        this.setState({ fields, errors: {} });
        if (field === "phonenumber") {
            var mobileNo = e.target.value;
            if (mobileNo !== "") {
                this.phoneNumberExist(mobileNo);
            }

        }

        if (field === "email") {
            var email = e.target.value;
            if (email !== "" && this.emailIsValid(email) === true) {
                this.emailExist(email);
            }

        }
    }
    onChangeGender(fieldName, field) {

        let fields = this.state.fields;
        fields[fieldName] = field.value;
        this.setState({ fields, errors: {} });
        return field;
    }


    phoneNumberExist(mobileNo) {
        let mobileNumber = mobileNo

        var qs = require('qs');
        var postObject = {
            "app_id": appId,
            "type": "web",
            "customer_phone": mobileNumber
        };
        axios.post(API_URL + "api/customer/phoneno_exist", qs.stringify(postObject)).then(response => {
            if (response.data.status === "ok") {
                return true;
            } else {
                let fields = this.state.fields;
                fields["phonenumber"] = "";
                this.setState({ fields, errors: { "phonenumber": "Phone no already exists" } });

            }
        });
    }

    emailExist(email) {

        var qs = require('qs');
        var postObject = {
            "app_id": appId,
            "type": "web",
            "customer_email": email
        };
        axios.post(API_URL + "api/customer/email_exist", qs.stringify(postObject)).then(response => {
            if (response.data.status === "ok") {
                return true;
            } else {
                let fields = this.state.fields;
                fields["email"] = "";
                this.setState({ fields, errors: { "email": "Email address already exists" } });

            }
        });
    }

    emailIsValid(email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }
    getUserDataByField(defaultValue, field) {

        return (typeof this.state.fields[field] !== "undefined" ? this.state.fields[field] : defaultValue);
    }
    componentWillReceiveProps(nextProps) {
        let fields = this.state.fields;
        if ((_.isEmpty(nextProps.userData) === false) && nextProps.userStatus !== "error") {

            if (nextProps.userData.customer_birthdate !== null && nextProps.userData.customer_birthdate !== '0000-00-00' && nextProps.userData.customer_birthdate !== '') {
                var birthdate = moment(nextProps.userData.customer_birthdate).format("DD-MM-YYYY");
            } else {
                birthdate = "";
            }
            fields['firstname'] = (nextProps.userData.customer_first_name !== null ? nextProps.userData.customer_first_name : '');
            fields['lastname'] = (nextProps.userData.customer_last_name !== null ? nextProps.userData.customer_last_name : '');
            fields['phonenumber'] = (nextProps.userData.customer_phone !== null ? nextProps.userData.customer_phone : '');
            fields['email'] = (nextProps.userData.customer_email !== null ? nextProps.userData.customer_email : '');
            fields['nickname'] = (nextProps.userData.customer_nick_name !== null ? nextProps.userData.customer_nick_name : '');
            fields['email'] = (nextProps.userData.customer_email !== null ? nextProps.userData.customer_email : '');
            fields['mobile'] = (nextProps.userData.customer_phone !== null ? nextProps.userData.customer_phone : '');
            fields['photo'] = (nextProps.userData.customer_photo !== null && nextProps.userData.customer_photo !== '' ? nextProps.userCommonData.image_source + nextProps.userData.customer_photo : '');
            fields['postal'] = (nextProps.userData.customer_postal_code !== null ? nextProps.userData.customer_postal_code : '');
            fields['unitnumber2'] = (nextProps.userData.customer_address_line2 !== null ? nextProps.userData.customer_address_line2 : '');
            fields['unitnumber1'] = (nextProps.userData.customer_address_line1 !== null ? nextProps.userData.customer_address_line1 : '');
            fields['address'] = (nextProps.userData.customer_address_name !== null ? nextProps.userData.customer_address_name : '');
            fields['address1'] = (nextProps.userData.customer_address_name2 !== null ? nextProps.userData.customer_address_name2 : '');
            fields['company'] = (nextProps.userData.customer_company_name !== null ? nextProps.userData.customer_company_name : '');
            fields['fbid'] = nextProps.userData.customer_fb_id;
            fields['birthdate'] = birthdate;
            fields['image_source'] = nextProps.userCommonData.image_source;
            fields['juicedid'] = (nextProps.userData.customer_unique_id !== null ? nextProps.userData.customer_unique_id : '');
            fields['joinedOn'] = (nextProps.userData.customer_created_on !== null ? nextProps.userData.customer_created_on : '');
            fields['gender'] = (nextProps.userData.customer_gender !== null ? nextProps.userData.customer_gender : 'U');
            this.setState({ fields });

        }
        if ((_.isEmpty(nextProps.secondaryAddressList) === false)) {
            this.setState({
                secondaryAddressList: nextProps.secondaryAddressList
            });
        }

    }
    updateProfileSubmit(e) {
        e.preventDefault();

        if (this.handleValidation()) {

            $("#c_loading").show();

            const formPayload = this.state.fields;

            var qs = require('qs');
            $("#c_loading").show();
            var postObject = {
                "app_id": appId,
                "type": "web",
                "customer_first_name": formPayload.firstname,
                "customer_last_name": formPayload.lastname,
                "customer_nick_name": formPayload.nickname,
                "customer_phone": formPayload.phonenumber,
                "customer_email": formPayload.email,
                "customer_birthdate": moment(formPayload.birthdate).format("DD-MM-YYYY"),
                'customer_postal_code': formPayload.postal,
                'customer_address_name': formPayload.address,
                'customer_address_name2': formPayload.address1,
                'customer_company_name': formPayload.company,
                'customer_gender': (formPayload.gender !== '' && typeof formPayload.gender !== 'undefined') ? formPayload.gender : "U",
                'customer_address_line1': formPayload.unitnumber1,
                'customer_address_line2': formPayload.unitnumber2,
                'customer_id': customerId()
            };

            axios.post(API_URL + "api/customer/updateprofile", qs.stringify(postObject))
                .then(response => {
                    $("#c_loading").hide();

                    if (response.data.status === "ok") {
                        //this.setStateLoading('ok');
                        cookie.save("UserFname", response.data.result_set.customer_first_name);
                        cookie.save("UserLname", response.data.result_set.customer_last_name);
                        cookie.save("UserMobile", response.data.result_set.customer_phone);

                        cookie.save("UserDefaultAddress", response.data.result_set.customer_address_name);
                        cookie.save("UserDefaultUnitOne", response.data.result_set.customer_address_line1);
                        cookie.save("UserDefaultUnitTwo", response.data.result_set.customer_address_line2);
                        cookie.save("UserDefaultPostalCode", response.data.result_set.customer_postal_code);

                        showInfo(response.data.message, 'Success');

                        /* Redirect to checkout page .. */
                        if (cookie.load('isChecoutRedirect') === "Yes" && cookie.load('cartValue') === "Yes") {
                            cookie.save('isChecoutRedirect', 'No');
                            //hashHistory.push("/checkout");
                        }

                    } else {

                        if (response.data.form_error) {
                            $('.myaccount-error').html('<div class="alert alert_danger" style="display:block">' + response.data.form_error + '</div>');
                        } else {
                            $('.guest-chk-error').html('<div class="alert alert_danger" style="display:block">' + response.data.message + '</div>');
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        }
    }
    /* Remove Address Book */
    handleRemoveAddress(addressId) {
        this.props.removeCustomerAddress(addressId);
        
    }
    render() {

        const userData = this.props.userData;
       
        //const commonData =  (  this.props.userCommonData !== "undefined" && this.props.userCommonData !== '' ) ? this.props.userCommonData :"";
       
        const imageSource = ""; //( (typeof commonData.image_source !== "undefined"  && typeof commonData.image_source !== "") ? commonData.image_source : "");
        
       /* const secondaryAddressData = this.state.secondaryAddressList; */
        const joined_date = (  typeof userData.customer_created_on !== "undefined" && userData.customer_created_on !== '' ? moment(userData.customer_created_on).format('DD/MM/YYYY') : '');
        const userPhoto = (userData.customer_photo !== '' && typeof userData.customer_photo !== "undefined") ? userData.customer_photo : (null);
        const userPhotoStyle = { backgroundImage: "url("+imageSource+userPhoto+")", backgroundSize: 'cover' };
        return (

            <React.Fragment>
                {_.isEmpty(userData) === true &&
                    <Alert color="warning" className="mt-3">
                        No Records Found !
                       </Alert>
                }
                {_.isEmpty(userData) === false &&
                    <Container fluid>
                        <Row>
                            <Col className="p0">
                                <div className="personal">

                                    <h5>PERSONAL DETAILS</h5>
                                    <div className="personalDetails">
                                        <div className="profileImg">
                                            <div className="imgUser" style={userPhotoStyle}>
                                            
                                            </div>
                                            <p className="txt1">JOIN DATE</p>
                                            <p className="txt2">{joined_date}</p>
                                            {/* <p className="txt3">ID: ABC123456</p> */}
                                        </div>
                                        <div className="profileDetails">
                                            <Row form>
                                                {this.state.fields["phonenumber"] ? (
                                                    <Col md={6}>
                                                        <FormGroup>

                                                            <Input type="tel" maxLength={8} name="phonenumber" id="phonenumber" pattern="\d*" placeholder="Phone Number" onChange={this.handleChange.bind(this, "phonenumber")} value={this.state.fields["phonenumber"] || ''} />

                                                        </FormGroup>
                                                    </Col>
                                                ) : (null)}
                                                {this.state.fields["email"] ? (
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Input type="text" ref="user_email" id="user_email" name="user_email" value={this.state.fields["email"] || ''} placeholder="Email Address" />
                                                        </FormGroup>
                                                    </Col>
                                                ) : (null)}
                                                {this.state.fields["firstname"] ? (
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Input type="input" name="firstname" id="firstname" onChange={this.handleChange.bind(this, "firstname")} value={this.state.fields["firstname"] || ''} placeholder="First Name" />
                                                        </FormGroup>
                                                    </Col>
                                                ) : (null)}
                                                {this.state.fields["lastname"] ? (
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Input type="input" name="lastname" id="lastname" onChange={this.handleChange.bind(this, "lastname")} value={this.state.fields["lastname"] || ''} placeholder="Last Name" />

                                                        </FormGroup>
                                                    </Col>
                                                ) : (null)}
                                                {this.state.fields["nickname"] ? (
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Input type="input" name="nickname" id="nickname" onChange={this.handleChange.bind(this, "nickname")} value={this.state.fields["nickname"] || ''} placeholder="Nick Name" />
                                                        </FormGroup>
                                                    </Col>
                                                ) : (null)}
                                                {this.state.fields["birthdate"] ? (
                                                    <Col md={6}>
                                                        <FormGroup>

                                                            <MaskedInput
                                                                mask={[/[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                                className="form-control"
                                                                placeholder="DD/MM/YYYY"
                                                                guide={false}
                                                                value={this.state.birthDate}
                                                                id="my-input-id"
                                                                onChange={this.handleChange.bind(this, "birthdate")} value={this.state.fields["birthdate"] || ''}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                ) : (null)}
                                                {this.state.fields["gender"] ? (
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <div className="custom-select-control">
                                                                <Input className="form-control" id="gender" value={this.state.fields['gender']} onChange={this.handleChange.bind(this, "gender")} />

                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                ) : (null)}
                                            </Row>
                                        </div>
                                    </div>
                                </div>
								{/* <div className="address">
                                    <h5>ADDRESS BOOK</h5>
                                    <div className="addressBook">
                                        <div>
                                            <Row className="m0">
                                                {_.isEmpty(secondaryAddressData) === true &&
                                                    <Alert color="warning" className="mt-3">
                                                        No Records Found !
                                                    </Alert>
                                                }
                                                {_.isEmpty(secondaryAddressData) === false && secondaryAddressData.map((secondaryAddress, addressIndex) =>
                                                    <Col xs="12" sm="6" className="p0" key={addressIndex}><div className="addressBlocks"><p>
                                                        {addressFormat(
                                                            secondaryAddress.unit_code,
                                                            secondaryAddress.unit_code2,
                                                            secondaryAddress.address,
                                                            secondaryAddress.country,
                                                            secondaryAddress.postal_code
                                                        )}
                                                    </p> <span onClick={this.handleRemoveAddress.bind(this,secondaryAddress.secondary_address_id)}>x</span></div></Col>
                                                )}
                                            </Row>
                                        </div>
                                    </div>
                                </div> */}
                                {/*  <div className="others">
                                <h5>OTHER SETTINGS</h5>
                                <div className="otherSetting">
                                    <Row>
                                        <Col xs="12" sm="4"><p> <img src={lockLogo} alt="cedeleLogo" />{" "} Change Password</p></Col>
                                       {/* <Col xs="12" sm="8"><p><input type="checkbox" defaultChecked={this.state.notifyChecked} id="switch" onClick={this.handleNotificationSetting} /><label for="switch">Toggle</label>Opt In For Promotions</p></Col> 
                                        <Col xs="12" sm="6"><p> <img src={siteSettingLogo} alt="cedeleLogo" />{" "}Settings</p></Col>
                                    </Row>
                                </div>
        </div> */}
                            </Col>
                        </Row>
                    </Container>

                }
            </React.Fragment>
        );
    }

}


const mapStateToProps = ({ user }) => ({
    userData: user.returnData,
    userStatus: user.isStatus,
    userCommonData: user.commoData,
    secondaryAddressList: user.secondaryAddressList

});
const mapDispatchToProps = dispatch =>
    bindActionCreators({ getUserData, getSecondaryAddress, removeCustomerAddress }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Accountdetails);



