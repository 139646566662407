import React from 'react'
import { Route} from 'react-router-dom';
import Login from '../login';
import dashboard from '../dashboard';
import products from '../products';
import orders from '../orders';
import { checkAuth } from "../../config/supporters";
 
if (checkAuth() === false) {
 
}else {
  
}
const App = () => {
  var mainID = "";
  if (checkAuth() === false) {
     mainID = "contentLogin";
  }else {
     mainID = "content";
  }
  return (
  <div className="wrapper">    
    <main id={mainID}> 
      <Route  path="/access/:appId" component={Login} />
	      <Route  exact path="/dashboard/:orderId" component={dashboard} />
	      <Route  exact path="/dashboard/:orderId/:orderdraft" component={dashboard} />
      <Route  exact path="/dashboard" component={dashboard} />
	
      <Route  path="/products" component={products} />
      <Route  path="/orders" component={orders} />
    </main>
  </div>
)};
export default App
