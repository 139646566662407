import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import { fetchMenuNavigation } from "../../stores/products/menu";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import  { history } from "../../store";
import { CEDELE_APP_ID } from "../../config/apps";
import {
  checkAuth,
  appId,
  hostUrl,
  stripslashes,
  getAliasName,
  getReferenceID,
  customerId,
  getCookies
} from "../../config/supporters";
import noImage from "../../assets/img/no-image.png";
import {
  SUCCESS_ALERT,
  FAILURE_ALERT
} from "../../config/constans";

import {
  Container,
  Row,
  Col,
  Button,
  ListGroup,
  ListGroupItem,
  Card,
  CardText,
  CardBody,
  CardTitle,
  TabContent,
  TabPane,
  Alert,
  Spinner
} from "reactstrap";
/*import { Scrollbars } from 'react-custom-scrollbars';
import ProductImage from '../../assets/img/product-thumbnail.png'; 
var _ = require('lodash');
import Datepickerpopup from "../layout/datepickerpopup";
*/
import cookie from "react-cookies";
import SideBar from "../layout/sidebar";
import Header from "../layout/header";
import classnames from "classnames";
import Addtocartpopup from "../layout/addtocartpopup";
import Addtocartcombopopup from "../layout/addtocartcombopopup"; 

/*import Customerpopup from "../layout/customerpopup";  */
 
import Newmenupopup from "../layout/newmenupopup"; 
var _ = require("lodash");
var qs = require("qs");
const $ = window.$;
var moment = require("moment");
class Dashboard extends Component {
  constructor(props) {
    super(props);

    /* validate auth token */
    if (checkAuth() === false) {
      this.props.history.push("/");
    }

    this.addtoCartPopup = this.addtoCartPopup.bind(this);
	 this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.state = {
      width: 0,
      height: 500,
      activeTab: 0,
      subactiveTab: 0,
      products: [],
      openaddtocart: false,
	  openaddtocartcombo: false,
	  productSlug: "",
	  productSlugCombo : "",
      defaultQty: 1,
      cartAlert: false,
      cartAlertMsg: "",
      alertType: FAILURE_ALERT,
      addCustomer: false,
      cartAction: false,
      addnewmenu: false,
    };
    this.toggleTabs = this.toggleTabs.bind(this);
	
	/* Vliadte page view */
	const outletId = getCookies("orderOutletId");
	const availability = getCookies("orderAvailability");
	const postalCode = getCookies("orderPostalCode");
	 
	if(outletId === "" ||  availability === ""  ||  postalCode ==="") {
		  history.push('/dashboard');
	}
	
  }
  toggleTabs(tab, cateSlug, subcateSlug) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
        subactiveTab: 0
      });
      this.getProductDetails(cateSlug, subcateSlug);
    }
  }
  addtoCartPopup(primaryId, productSlug, productType) {
/*var customerID = customerId();
if (customerID === "" || typeof customerID === "undefined") {
  this.setState({ addCustomer: true });
  setTimeout(
    function() {
      this.setState({ addCustomer: false });
    }.bind(this),
    1000
  );

  return false;
} */

if(parseInt(productType) === 4) { 
    this.setState({
      openaddtocart: !this.state.openaddtocart, productSlug : productSlug
    });
	
	 setTimeout(
        function() {
          this.setState({ openaddtocart: false });
        }.bind(this),
        1000
      );
  }
  
  if(parseInt(productType) === 2) {   
    this.setState({
      openaddtocartcombo: !this.state.openaddtocartcombo, productSlugCombo : productSlug
    });
	
	 setTimeout(
        function() {
          this.setState({ openaddtocartcombo: false });
        }.bind(this),
        1000
      );
  }


 
  }
  
  newmenupopup(){
	  
	this.setState({ addnewmenu: true });
	  setTimeout(
		function() {
		  this.setState({ addnewmenu: false });
		}.bind(this),
		1000
	);
	
  }
  
  addToCart(productID) {
   /*  var customerID = customerId();
    if (  customerID === "" || typeof customerID === "undefined") {
      this.setState({ addCustomer: true });
      setTimeout(
        function() {
          this.setState({ addCustomer: false });
        }.bind(this),
        1000
      );

      return false;
    } */

    this.setState({ defaultQty: 1 });
    $(".pro_qty_btn").hide();
    $(".add_to_btn_cart").show();
    $("." + productID).show();
    $("." + productID)
      .next(".add_to_btn_cart")
      .hide();
  }
  componentWillMount() {
    this.props.fetchMenuNavigation();
  }

  componentDidMount() {
    this.loadInitialProducts(this.props.navigation);
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    $(".products-container").mCustomScrollbar({
      theme:"dark",
      scrollbarPosition: "inside"
    });
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.menuStatus !== this.props.menuStatus) {
      if (_.isEmpty(nextProps.navigation) === false) {
        const navItems = nextProps.navigation;
        this.loadInitialProducts(navItems);
      }
    }
  }

  loadInitialProducts(navItems) {
    if (_.isEmpty(navItems) === false) {
      const firstItems = navItems[0];
      const firstCate = firstItems.pro_cate_slug;
      const firstSubcate = firstItems.pro_subcate_slug;
      this.getProductDetails(firstCate, firstSubcate);
    }
  }

  decQty() {
    if (this.state.defaultQty !== 1) {
      this.setState({ defaultQty: this.state.defaultQty - 1 });
    }
  }

  incQty() {
    //console.log(this.state.defaultQty);
    this.setState({ defaultQty: this.state.defaultQty + 1 });
  }

  addToCartAction(productId) {
    const host = hostUrl();
    const APP_ID = appId();
    var postObject = {};
	const customerID = getCookies("customerId");
	 
    postObject = {
      app_id: APP_ID,
      product_id: productId,
      product_qty: this.state.defaultQty,
      pageToken: "$%%&(ZCWRT#^FG$%^#D$",
      reference_id: (customerID  === "" ? getReferenceID() : "") ,
      customer_id: customerID,
      availability_name: "Delivery",
      availability_id: getCookies("orderAvailability"),
      product_type: 1,
      outletzone_id: getCookies("orderZoneID"),
      outlet_id:  getCookies("orderOutletId") 
    };
    $("#proLoading").show();
    axios
      .post(host + "apiv2/cart/simpleCartInsert", qs.stringify(postObject))
      .then(res => {
        $("#proLoading").hide();
		
		 this.setState({ cartAction: true });
      setTimeout(
        function() {
          this.setState({ cartAction: false });
        }.bind(this),
        1000
      );
		
        if (res.data.status === "ok") {
          $(".pro_qty_btn").hide();
          $(".add_to_btn_cart").show();
          this.setState({
            defaultQty: 1,
            cartAlert: true,
            cartAlertMsg: res.data.message,
            alertType: SUCCESS_ALERT
          });
        } else if (res.data.status === "error") {
          this.setState({
            cartAlert: true,
            cartAlertMsg: res.data.message,
            alertType: FAILURE_ALERT
          });
        }
      });
  }

  getsubCate(cateSlug, subCateSlug, subIndex) {
    if (this.state.subactiveTab !== subIndex) {
      this.setState({
        subactiveTab: subIndex
      });
    }
    this.getProductDetails(cateSlug, subCateSlug);
  }

  getProductDetails(cateSlug, subCateSlug) {
    cateSlug =
      typeof cateSlug !== "undefined" && cateSlug !== null ? cateSlug : "";
    subCateSlug =
      typeof subCateSlug !== "undefined" && subCateSlug !== null
        ? subCateSlug
        : "";
		 var  outletId =
      typeof getCookies("orderOutletId") !== "undefined" && getCookies("orderOutletId") !== null
        ? getCookies("orderOutletId")
        : "";
    const APP_ID = appId();
    const HOST_URL = hostUrl();
    $("#proLoading").show();
    axios
      .get(
        HOST_URL +
          "apiv2/products/getAllProducts?app_id=" +
          APP_ID +
          "&availability=" +
          getCookies("orderAvailability") +
          "&category_slug=" +
          cateSlug +
          "&subcate_slug=" +
          subCateSlug  + 
		  "&outletId="+outletId
          + "&source_type=callcenter"
      )
      .then(res => {
        $("#proLoading").hide();
        if (res.data.status === "ok") {
          let subcate = res.data.result_set[0];
          let subcateDetails = subcate.subcategorie[0];
		  if(_.isEmpty(subcateDetails) === false) {
		       this.setState({ products: (subcateDetails.products? subcateDetails.products : []) });	  
		  }else {
			   this.setState({ products: [] });	  
		  }
			  
        } else {
          //this.setState({Status: res.data.status});
        }
      });
  }

  render() {
 
  const APP_ID_DET = appId();
  var hidecate = false;
  if(APP_ID_DET  === CEDELE_APP_ID) {
	  		var open_time = Date.parse("2019-12-23T23:59");
		var close_time = Date.parse("2019-12-25T23:59");
		//var check_val = Date.parse("2011-10-10T02:30");

		 const dateVal = new Date( moment(getCookies("orderDeliveryDate"), "DD/MM/YYYY")); 
		 const convertedDate = moment(dateVal).format("YYYY-MM-DD");
		 const timeVal = new Date(moment(cookie.load("orderDeliveryTime"), "HH:mm"));
		 const convertedTime = moment(timeVal).format("HH:mm");
		 var check_val = Date.parse(convertedDate+"T"+convertedTime);
     
		if( check_val > open_time && check_val < close_time ) {
			  var hidecate = true;
		}
  }
 
	var proImsgsrc = hostUrl()+"/media/dev_team/products/main-image/";
   // const availability = getCookies("orderAvailability");
    var productContainerHeight = this.state.height - 50;
     
    return (
      <div>
        <Header cartAction={this.state.cartAction} />
        <SideBar />
        <Container fluid className="main-container">
		{/* <Row className="m-0 delivery-address-date-block">
            <Col
              xs="12"
              sm="12"
              md="12"
              lg="6"
              className="pr-0 delivery-address-colm"
            >
			{/*   <Jumbotron>
			  { availability === DELIVERY_ID &&   <p className="lead mb-0">Delivery Address</p> }
			  { availability === PICKUP_ID &&   <p className="lead mb-0">Pickup Address</p> }
                <h6 className="mb-0">
                  {addressFormat(
                    getCookies("orderUnitOne"),
                    getCookies("orderUnitTwo"),
                    getCookies("orderAddressOne"),
                    getCookies("orderAddressTwo"),
                    getCookies("orderPostalCode")
                  )}
				  {/*   <Button className="p-0" color="link">
                    <i
                      className="fa fa-fw fa-edit"
                      style={{ fontSize: "1em" }}
                    />
                  </Button>  
                </h6>
                <p className="lead mb-0">
                  Order handled by : {getCookies("orderOutletName")}
                </p>
              </Jumbotron> 
            </Col>
            <Col
              xs="12"
              sm="12"
              md="12"
              lg="6"
              className="pl-0 delivery-date-colm"
            >
              <Jumbotron>
            { availability === DELIVERY_ID &&    <p className="lead mb-0">Order Delivery Date & Time</p> }
			  { availability === PICKUP_ID &&  <p className="lead mb-0">Order Pickup Date & Time</p> }
                <h6 className="mb-0">
				{getCookies("orderDeliveryDate")} /  {getCookies("orderDeliveryTime")}
					{/*  <Button className="p-0" color="link">
                    <i
                      className="fa fa-fw fa-edit"
                      style={{ fontSize: "1em" }}
                    />
					</Button> 
                </h6>
              </Jumbotron>
            </Col>
			</Row> */}
          <Alert color={this.state.alertType} isOpen={this.state.cartAlert}>
            {this.state.cartAlertMsg}
          </Alert>
          <Row className="m-0">
            <Col
              xs="12"
              md="3"
              sm="4"
              lg="3"
              xl="2"
              className="prod-cat-items-list pl-0"
            >
              <h3>Choose Menu</h3>
              <ListGroup>
                {_.isEmpty(this.props.navigation) === false &&
                  this.props.navigation.map((cate, cIndex) => (
                 (  hidecate === true && parseInt(cate.pro_cate_primary_id) === 1463
                          ?  "" :    <ListGroupItem
                      key={cIndex}
                      tag="button"
                      value={
                        cate.is_sub_list === "Yes" ? cate.pro_cate_slug : ""
                      }
                      className={
                        classnames({
                          active: this.state.activeTab === cIndex
                        }) + (cIndex === 0 ? " first_category" : "")
                      }
                      onClick={() => {
                        this.toggleTabs(
                          cIndex,
                          cate.pro_cate_slug,
                          cate.pro_subcate_slug
                        );
                      }}
                    >
                      {stripslashes(
                        typeof cate.subcategory_name !== "undefined" &&
                          cate.subcategory_name !== null
                          ? cate.subcategory_name
                          : cate.category_name
                      )}
                    </ListGroupItem> )
                  ))}
                 <ListGroupItem
                      
                      tag="button"
                      value={
                        'newmenu'
                      }
                      className={"cmenu"}
                      onClick={() => {
                        this.newmenupopup();
                      }}
                    >
                     Add New Menu
                    </ListGroupItem> 
              </ListGroup>
              
            </Col>
            <Col
              xs="12"
              md="9"
              sm="8"
              lg="9"
              xl="10"
              className="products-container"  style={{ height: productContainerHeight }} >
              <TabContent activeTab={this.state.activeTab}>
                {_.isEmpty(this.props.navigation) === false &&
                  this.props.navigation.map((loopCate, loopIndex) => (
                    <TabPane tabId={loopIndex} key={loopIndex}>
                      <Row>
                        <Col
                          xs="12"
                          sm="12"
                          md="4"
                          lg="4"
                          xl="3"
                          className="prod-sub-cat-items-list "
                        >
                          <ListGroup>
                            {_.isEmpty(
                              loopCate.subcat_list_arr.sub_result_set
                            ) === false &&
                              loopCate.subcat_list_arr.sub_result_set.map(
                                (subCate, subIndex) => (
                                  <ListGroupItem
                                    onClick={() => {
                                      this.getsubCate(
                                        subCate.pro_cate_slug,
                                        subCate.pro_subcate_slug,
                                        subIndex
                                      );
                                    }}
                                    value={subCate.pro_subcate_slug}
                                    className={
                                      (subIndex === 0
                                        ? "first_sub_cate_slug_" + loopIndex
                                        : "sub_cate_slug") +
                                      (this.state.subactiveTab === subIndex
                                        ? " active"
                                        : "")
                                    }
                                    tag="button"
                                    key={subCate.pro_subcate_id}
                                  >
                                    {stripslashes(subCate.pro_subcate_name)}
                                  </ListGroupItem>
                                )
                              )}
                          </ListGroup>
                        </Col>
                        <Col
                          xs="12"
                          sm="12"
                          md="8"
                          lg="8"
                          xl="9"
                          className="products-list-block"
                        >
                          {/* <h5 className="text-center prod-subcategory-title">Cheese Cakes</h5> */}

                          <Row>
                            {this.state.activeTab === loopIndex &&
                              _.isEmpty(this.state.products) === false &&
                              this.state.products.map((pro, proIndex) => (
                                <Col
                                  xs="12"
                                  sm="6"
                                  md="6"
                                  lg="4"
                                  xl="3"
                                  className="product-item-colm "
                                  key={proIndex}
                                >
                                  <Card className="product-item">
                                   
									
									 <LazyLoadImage
    alt={"product Images"}
    effect="blur"
    src={( pro.product_thumbnail !== ""
                                          ? proImsgsrc + pro.product_thumbnail
                                          : noImage)} />
                                    <CardBody>
                                      <CardTitle>
                                        {stripslashes(
                                          getAliasName(
                                            pro.product_alias,
                                            pro.product_name
                                          )
                                        )}
                                      </CardTitle>
                                      <CardText className="price-tag">
                                        ${pro.product_price}
										    
                                      </CardText>
                                      <div
                                        style={{ display: "none" }}
                                        id={pro.product_primary_id}
                                        className={
                                          pro.product_primary_id +
                                          " pro_qty_btn"
                                        }
                                      >
                                        {" "}
                                        <div className="prod-chosen-qty-block">
                                          <div className="qty-box">
                                            <span
                                              className="qty-minus"
                                              onClick={this.decQty.bind(this)}
                                            >
                                              _
                                            </span>
                                            <input
                                              type="text"
                                              readOnly
                                              value={this.state.defaultQty}
                                            />
                                            <span
                                              className="qty-plus"
                                              onClick={this.incQty.bind(this)}
                                            >
                                              +
                                            </span>
                                          </div>
                                        </div>
										 
                                        <Button
                                          color="secondary"
                                          block
                                          onClick={this.addToCartAction.bind(
                                            this,
                                            pro.product_id
                                          )}
                                        >
                                          Done
                                        </Button>
                                      </div>
								 { (parseInt(pro.product_type) === 1) &&	  
                                      <Button
                                        color="primary"
                                        className="add_to_btn_cart"
                                        block
                                        onClick={this.addToCart.bind(
                                          this,
                                          pro.product_primary_id,
                                          pro.product_id,
										  pro.product_type
                                        )}
                                      >
                                        Add to cart
                                      </Button>  
								 }
									   { (parseInt(pro.product_type) === 2 || (parseInt(pro.product_type) === 4) ) &&
                                      <Button
                                        color="primary"
                                        className="add_to_btn_cart"
                                        block
                                        onClick={this.addtoCartPopup.bind(
                                          this,
                                          pro.product_primary_id,
                                          pro.product_slug,
										  pro.product_type
                                        )}
                                      >
                                        Add to cart
                                      </Button> }
                                    </CardBody>
                                  </Card>
                                </Col>
                              ))}
                          </Row>
                        </Col>
                      </Row>
                    </TabPane>
                  ))}
              </TabContent>
            </Col>
          </Row>
        </Container>
        <span id="customer_error_val" className="customer_error_vals"></span>
        <Addtocartpopup isOpen={this.state.openaddtocart} isproductId={this.state.productSlug} />
			<Addtocartcombopopup isOpen={this.state.openaddtocartcombo} isproductId={this.state.productSlugCombo}  /> 
				{/*    <Customerpopup open_customerform={this.state.addCustomer} /> */}
        <Newmenupopup open_addmenuform={this.state.addnewmenu} />
		
        <div
          id="proLoading"
          style={{
            display: "none",
            bottom: 0,
            left: 0,
            position: "fixed",
            right: 0,
            top: 0,
            margin: "auto",
            zIndex: 9999,
            background: "rgba(255, 255, 255, 0.8)"
          }}
        >
          <Spinner
            color="secondary"
            style={{
              width: "5rem",
              height: "5rem",
              bottom: 0,
              left: 0,
              position: "absolute",
              right: 0,
              top: 0,
              margin: "auto"
            }}
          />
        </div>
      </div>
    );
  }
	
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
}

const mapStateToProp = state => ({
  navigation: state.menu.items,
  menuStatus: state.menu.isStatus,
  cartItems: state.cart.returnData,
  cartStatus: state.cart.isStatus
});
export default connect(
  mapStateToProp,
  { fetchMenuNavigation }
)(withCookies(Dashboard));
