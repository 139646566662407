import axios from 'axios';
import cookie from "react-cookies";
import { HOST_URL, APP_ID,DELIVERY_ID,PICKUP_ID,DINEIN_ID } from "../../config/constans";
import { returnValue } from "../../config/supporters";
export const FETCH_DELIVERY_OUTLETS = 'promotions/FETCH_DELIVERY_OUTLETS';
export const FETCH_ALL_OUTLETS = 'promotions/FETCH_ALL_OUTLETS'
const initialState = {
  returnData: [],
  isFetching: false,
  isError: null,
  isStatus: null,
   allOutlets: [],
}
 
export default function(state = initialState , action) {
  
   switch (action.type){
       case FETCH_DELIVERY_OUTLETS:
        return {
            ...state,
            returnData: action.payload,
			isFetching : false,
			isError : null, 
			isStatus : action.isStatus
        }
              
			  case FETCH_ALL_OUTLETS:
        return {
            ...state,
            allOutlets: action.payload,
		 
        }
       default:
       return state;
   }
}
export function fetchalloutlets(){
 
  let appId = returnValue(cookie.load(APP_ID));
  let API_URL = cookie.load(HOST_URL) + "apiv2/outlets/getAllOutles/?app_id="+appId+"&availability_id="+DELIVERY_ID+";"+PICKUP_ID+";"+DINEIN_ID;
 
    return function (dispatch) {
        axios.get(API_URL)
        .then(response => {
          dispatch({
            type: FETCH_DELIVERY_OUTLETS,
            payload: response.data.result_set,
			loading : false,
			isStatus : response.data.status
          });
        })
        .catch((error) => {
          console.log(error);
        })
       
    }
}

export function getAllOutlets(){
 
  let appId = returnValue(cookie.load(APP_ID));
  let API_URL = cookie.load(HOST_URL) + "api/callcenterapi/get_all_outlets/?app_id="+appId ;
 
    return function (dispatch) {
        axios.get(API_URL)
        .then(response => {
          dispatch({
            type: FETCH_ALL_OUTLETS,
            payload: response.data.result_set,
			 
          });
        })
        .catch((error) => {
          console.log(error);
        })
       
    }
}
