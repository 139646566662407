import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { history } from "../../store";
import {
  DINEIN_ID,
} from "../../config/constans";
import cookie from "react-cookies";
import { SPRING_APP_ID, PIE_APP_ID } from "../../config/apps";
import {
  CHOOSE_OUTLET,
} from "../../config/messages";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import { fetchalloutlets } from "../../stores/delivery/deliveryoutlets";
//import { getSetings } from "../../stores/settings";
import { findoutlet } from "../../stores/delivery/findzone";
import {
  appId,
  hostUrl,
  getCookies
} from "../../config/supporters";
import ClockIcon from "../../assets/img/icons/clock.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays, setHours, setMinutes } from "date-fns";
import {
  Button,
  Modal,
  Input,
  ModalBody,
  ModalFooter,
  ListGroup,
  ListGroupItem,
  InputGroup,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  Alert
} from "reactstrap";
import { Scrollbars } from "react-custom-scrollbars";
/* two */

import DineinpopupIcon from "../../assets/img/icons/dine-popup-icon.png";

var _ = require("lodash");
var striptags = require("striptags");
const $ = window.$;
var moment = require('moment');
class Dineinpopup extends Component {
  constructor(props) {
    super(props);
    this.dineinStepOne = this.dineinStepOne.bind(this);
    this.deliveryStepTwo = this.deliveryStepTwo.bind(this);
    this.closeAll = this.closeAll.bind(this);
    this.checkOutlet = this.checkOutlet.bind(this);
    this.chooseTableno = this.chooseTableno.bind(this);
    this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.setDeliveryData = this.setDeliveryData.bind(this);


    /* Rounding to the nearest 15 minute interval */
    const start = moment();
    const remainder = 15 - (start.minute() % 15);
    /*const currentTime = moment(start).add(remainder, "minutes"); */

    /* To change the date after 2 PM */
    var CurrentDate = this.calcTime("Singapore", "+8");
    var currentHrs = CurrentDate.getHours();
  
    const appid = getCookies("app_id");
    let dateStart = addDays(new Date(), 0);
    let timeStart = setHours(setMinutes(new Date(), 0), currentHrs);
    if (SPRING_APP_ID === appid) {
      dateStart = currentHrs >= 14 ? addDays(new Date(), 0) : addDays(new Date(), 0);
      timeStart = setHours(setMinutes(new Date(), 0), 11);
    }

    this.state = {
      dineInModalOpen: false,
      stepOne: false,
      stepTwo: false,
      stepThree: false  ,
      outletDetails: [],
      CHOOSE_OUTLET: false,
      tableData: [],
      CHOOSE_TABLE: false,
      table_name: "",
      rSelected: "",
      startDate: dateStart,
      startTime: timeStart,
      pickupOutlettat: ''
    };


  }
  dineinStepOne() {
    this.setState({
      stepOne: !this.state.stepOne,

    });

  }
  /* Convert Timezone */
  calcTime(city, offset) {
    var d = new Date();
    var utc = d.getTime() + d.getTimezoneOffset() * 60000;
    var nd = new Date(utc + 3600000 * offset);
    return nd;
  }

  setDeliveryData() {
    const postalCode = this.state.pickupOutletPostalCode;
    const outletId = this.state.rSelected;
    const outletName = this.state.pickupOutletName;
    const outletNameAddressOne = this.state.pickupOutletAddress;
    const stateDate = this.state.startDate;
    const startTime = this.state.startTime;
    const tableNo = this.state.table_name;
    //console.log(tableNo); return false;
    if (startTime === null) {

      alert("Please choose pikup date and time");
      return false
    }

    var deliveryDate = moment(stateDate).format("DD/MM/YYYY");
    var deliveryTime = moment(startTime).format("hh:mm A");
    var deliveryTimeOrder = moment(startTime).format("hh:mm");


    if ((typeof postalCode !== "undefined" && postalCode !== "") &&
      (typeof outletId !== "undefined" && outletId !== "") && (deliveryTime !== "" && deliveryDate !== "")) {
      cookie.save("orderOutletId", outletId, { path: '/' });
      cookie.save("orderPostalCode", postalCode, { path: '/' });
      cookie.save("orderOutletName", outletName, { path: '/' });
      cookie.save("orderAddressOne", outletNameAddressOne, { path: '/' });
      cookie.save("orderAddressTwo", "", { path: '/' });
      cookie.save("orderDeliveryDate", deliveryDate, { path: '/' });
      cookie.save("orderDeliveryTime", deliveryTime, { path: '/' });
      cookie.save("orderAvailability", DINEIN_ID, { path: '/' });
      cookie.save("orderDeliveryTimeRequest", deliveryTimeOrder, { path: '/' });
      // cookie.save("orderTableNo", tableNo ,{ path: '/' });
      this.setState({ stepThree: false });
      history.push('/products');
    } else {
      alert("something went wrong(1)");
    }


  }
  deliveryStepTwo() {

    const outletID = this.state.rSelected;
    if (typeof outletID === "undefined" || outletID === "") {
      this.setState({ CHOOSE_OUTLET: true });
      return false;
    }

    const APP_ID = appId();
    const HOSTURL = hostUrl();
    axios
      .get(
        HOSTURL +
        "api/tablemanagement/get_table?app_id=" +
        APP_ID +

        "&outlet_id=" + outletID

      )
      .then(res => {

        if (res.data.status === "ok") {

          this.setState({
            stepTwo: !this.state.stepTwo,
            stepOne: false,
            CHOOSE_OUTLET: false,
            tableData: res.data.result_set
          });

        } else {
          this.setState({
            stepTwo: !this.state.stepTwo,
            stepOne: false,
            CHOOSE_OUTLET: false,

          });
        }
      });




  }
  closeAll() {
    this.setState({
      stepOne: false,
      stepTwo: false,
    });
  }

  handleDateChange(date) {
    this.setState({
      startDate: date
    });
  }

  handleTimeChange(date) {
    var dateVal = new Date(date);
    this.setState({
      startTime: dateVal
    });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.openDineinPopup !== this.props.openDineinPopup) {
      this.dineinStepOne();
    }
  }
  showDeliveryOutlets(outletResult) {
    return outletResult.map((out, index) => (
      <React.Fragment key={index}>

        <ListGroupItem>

          <InputGroup>
            <Input
              onClick={() => this.onRadioBtnClick(out.oa_outlet_id, striptags(out.outlet_name), striptags(out.outlet_address_line1), striptags(out.outlet_postal_code), out.outlet_dine_tat)}
              type="radio"
              id={"radio_" + out.oa_outlet_id}
              name="outletsZone"
              value={out.oa_outlet_id}
            />
            <Label htmlFor={"radio_" + out.oa_outlet_id}>
              <span className="outlet-name">{striptags(out.outlet_name)}</span>
              <span className="outlet-address">
                {striptags(out.outlet_address_line1) + " "}
                {striptags(out.outlet_postal_code)}
              </span>
            </Label>
          </InputGroup>

        </ListGroupItem>

      </React.Fragment>
    ));
  }
  onRadioBtnClick(rSelected, pickupOutletName, pickupOutletAddress, pickupOutletPostalCode, tat) {
    this.setState({ CHOOSE_OUTLET: false });
    // console.log(rSelected);   pickupOutletName : "',

    this.setState({
      rSelected: rSelected,
      pickupOutletName: pickupOutletName, pickupOutletAddress: pickupOutletAddress, pickupOutletPostalCode: pickupOutletPostalCode, pickupOutlettat: tat
    });
  }

  chooseTableno = (rSelected) => {
    this.setState({ CHOOSE_TABLE: false });
    //console.log(rSelected);   

    this.setState({ table_name: rSelected })

  }

  checkOutlet() {
    const outletID = this.state.rSelected;
    const tableName = this.state.table_name;

    if ((typeof outletID === "undefined" || outletID === "") || (typeof tableName === "undefined" || tableName === "")) {
      this.setState({ CHOOSE_TABLE: true });
      return false;
    }

    var tatvalnew = (this.state.pickupOutlettat !== '') ? parseInt( this.state.pickupOutlettat) : 0;

    var dtnowobj = new Date();
      dtnowobj.setMinutes(dtnowobj.getMinutes() + tatvalnew);
    var currentTimetat = dtnowobj.getHours();
    var currentMinstat = dtnowobj.getMinutes(); 
    var Crt_Time = currentTimetat+"."+currentMinstat;
    if(PIE_APP_ID === getCookies("app_id")){
      currentMinstat = Math.ceil(currentMinstat/ 60) * 60;
    }else{
      currentMinstat = Math.ceil(currentMinstat/ 15) * 15;
    } 
    if(currentMinstat == 60){
      currentTimetat = currentTimetat + 1;
    }      
    var  timeStart = setHours(setMinutes(new Date(), currentMinstat), currentTimetat);

    this.setState({
          startTime: timeStart,
    })


    this.setState({
      stepOne: false,
      stepTwo: false,
      stepThree: true
    });
  }


  tableList(tableValue) {
    $(".customscrollbar").mCustomScrollbar({
      theme: "dark",
      scrollbarPosition: "inside"
    });

    return (
      <div className="customscrollbar" style={{ height: 260 }}>
        <Form>
          <ListGroup className="pickup-location-list dinein-table-list align-content-around flex-wrap">

            {_.isEmpty(tableValue) === false &&
              tableValue.map((tab, tabindex) => (
                <ListGroupItem key={tabindex}>

                  <InputGroup>
                    <Input
                      onClick={() => this.chooseTableno(tab.tm_table_number, striptags(tab.outlet_name), striptags(tab.outlet_address_line1), striptags(tab.outlet_postal_code))}
                      type="radio"
                      id={"radio_" + tabindex}
                      name="dineinTableNumber"
                      value={tab.tm_table_number}
                    />
                    <Label htmlFor={"radio_" + tabindex}>
                      <span className="outlet-name">{tab.tm_table_number}</span>
                      {/* <span className="outlet-address">
                          4 Seats
              </span> */}
                    </Label>
                  </InputGroup>

                </ListGroupItem>

              ))}

          </ListGroup>
        </Form>
      </div>

    );
  }

  render() {

    const excludeTime = [
      setHours(setMinutes(new Date(), 0), 11),
      setHours(setMinutes(new Date(), 30), 11),
      setHours(setMinutes(new Date(), 0), 12),
      setHours(setMinutes(new Date(), 30), 12),
      setHours(setMinutes(new Date(), 0), 13),
      setHours(setMinutes(new Date(), 30), 13),
      setHours(setMinutes(new Date(), 0), 14),

      setHours(setMinutes(new Date(), 30), 17),
      setHours(setMinutes(new Date(), 0), 18),
      setHours(setMinutes(new Date(), 30), 18),
      setHours(setMinutes(new Date(), 0), 19),
      setHours(setMinutes(new Date(), 30), 19),
      setHours(setMinutes(new Date(), 0), 20),
      setHours(setMinutes(new Date(), 30), 20),
      setHours(setMinutes(new Date(), 0), 21),
      setHours(setMinutes(new Date(), 30), 21),

    ]

    const closeAllBtn = (
      <button className="close" onClick={this.closeAll}>
        &times;
      </button>
    );
    const outletsData = this.props.outletsData;
    const outletResult = _.filter(outletsData, {
      oa_availability_id: DINEIN_ID
    });

    return (
      <React.Fragment>

        {/* 1st modal */}
        <Modal
          size="lg"
          isOpen={this.state.stepOne}
          className="callcentre-modal pickup-location-modal"
        >
          <div className="modal-header">
            <div className="modal-top-icon">

              {closeAllBtn}
            </div>
            <div className="modal-title-block">
              <h1>Dine in Location</h1>
              <h5>Select your Dine in location</h5>
            </div>
          </div>
          <ModalBody>
            <Alert color="danger" isOpen={this.state.CHOOSE_OUTLET}>
              {CHOOSE_OUTLET}
            </Alert>
            <Scrollbars autoHeight
              autoHeightMin={0}
              autoHeightMax={300}>
              <Form>
                <ListGroup className="pickup-location-list">
                  {this.showDeliveryOutlets(outletResult)}
                </ListGroup>
              </Form>
            </Scrollbars>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.deliveryStepTwo}>
              Continue
            </Button>
          </ModalFooter>
        </Modal>

        {/* 2nd  modal */}
        <Modal
          size="lg"
          isOpen={this.state.stepTwo}
          className="callcentre-modal dinein-table-list-modal"
        >
          <div className="modal-header">
            <div className="modal-top-icon">
              <img src={DineinpopupIcon} alt={"Dine in Table"} />
              {closeAllBtn}
            </div>
            <div className="modal-title-block">
              <h5 className="mt-3">Select your dine in table</h5>
              {/*this.tableList(this.state.tableData)*/}
              <Dineintablelist dineintableList={this.state.tableData} selectedTable={this.chooseTableno} />
            </div>
          </div>
          <ModalBody>
            <Alert color="danger" isOpen={this.state.CHOOSE_TABLE}>
              "Please choose table"
            </Alert>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.checkOutlet.bind()}>
              Continue
            </Button>
            {/*  <Link to="#" className="skip-link">Skip For Later</Link> */}
          </ModalFooter>
        </Modal>


        {/* fourth  modal */}
        <Modal
          size="lg"
          isOpen={this.state.stepThree}
          className="callcentre-modal deliverytime-modal"
        >
          <div className="modal-header  datepicker-header">
            <div className="modal-top-icon">
              <img src={ClockIcon} alt={"Shopping Bag"} className="datepicker-icon"  />
              {closeAllBtn}
            </div>
            <div className="modal-title-block">
              <h1  className="datepicker-h1">Dine in Time</h1>
              <h5 className="datepicker-h5">Earliest Dine in Date & Time </h5>
            </div>
          </div>
          <ModalBody>
            <Form>
              <FormGroup>
                <Row>
                  <Col xs={6}>
                    <DatePicker className="form-control"
                      selected={this.state.startDate}
                      minDate={this.state.startDate}
                      onChange={this.handleDateChange}
                      dateFormat="dd/MM/yyyy"
                    />


                  </Col>
                  <Col xs={6}>

                    {SPRING_APP_ID === getCookies("app_id") && (
                      <DatePicker
                        className="form-control"
                        selected={this.state.startTime}
                        onChange={this.handleTimeChange}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        dateFormat="h:mm aa"
                        timeCaption="Time"
                        includeTimes={excludeTime}
                      />
                    )}
                    {PIE_APP_ID === getCookies("app_id") && (<DatePicker className="form-control"
                      selected={this.state.startTime}
                      onChange={this.handleTimeChange}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={60}
                      dateFormat="h:mm aa"
                      timeCaption="Time"
                    />)}
                    {SPRING_APP_ID !== getCookies("app_id") && PIE_APP_ID !== getCookies("app_id") && (<DatePicker className="form-control"
                      selected={this.state.startTime}
                      onChange={this.handleTimeChange}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      dateFormat="h:mm aa"
                      timeCaption="Time"
                    />)}
                  </Col>
                </Row>
              </FormGroup>
            </Form>
            <p>Click time section to pick advanced order date & time</p>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.deliveryStepTwo}>
              Go Back
            </Button>
            <Button color="primary" onClick={this.setDeliveryData}>
              View Menu
            </Button>
          </ModalFooter>
        </Modal>

      </React.Fragment>
    );
  }

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
}
/* Table List Component */
class Dineintablelist extends Component {
  constructor(props) {
    super(props);

  }
  sendSelectedTable = (tableValue) => {
    this.props.selectedTable(tableValue);
  }
  render() {

    ;

    $(".customscrollbar").mCustomScrollbar({
      theme: "dark",
      scrollbarPosition: "inside"
    });
    const tableValue = this.props.dineintableList;
    return (
      <div className="customscrollbar" style={{ height: 260 }}>
        <Form>
          <ListGroup className="pickup-location-list dinein-table-list align-content-around flex-wrap">

            {_.isEmpty(tableValue) === false &&
              tableValue.map((tab, tabindex) => (
                <ListGroupItem key={tabindex}>

                  <InputGroup>
                    <Input
                      onClick={() => this.sendSelectedTable(tab.tm_table_number)}
                      type="radio"
                      id={"radio_" + tabindex}
                      name="dineinTableNumber"
                      value={tab.tm_table_number}
                    />
                    <Label htmlFor={"radio_" + tabindex}>
                      <span className="outlet-name">{tab.tm_table_number}</span>
                      {/* <span className="outlet-address">
                          4 Seats
              </span> */}
                    </Label>
                  </InputGroup>

                </ListGroupItem>

              ))}

          </ListGroup>
        </Form>
      </div>

    );
  }


}


const mapStateToProp = state => ({
  outletsData: state.outlets.returnData,
  setStatus: state.outlets.isStatus,
  zoneDetails: state.zone.returnData,
  zoneError: state.zone.isError,
  zoneStatus: state.zone.isStatus
});
export default connect(
  mapStateToProp,
  { fetchalloutlets, findoutlet }
)(withCookies(Dineinpopup));
