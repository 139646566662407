import axios from 'axios'
export const POST_LOGIN_DATA = 'login/POST_LOGIN'
export const LOGIN_SUCCESS = 'login/LOGIN_SUCCESS'
var qs = require('qs')
const initialState = {
  loading: false,
  returnstatus: '',
  returnData: {},
  message: null
}

export default function(state = initialState, action) {
  switch (action.type) {
    case POST_LOGIN_DATA:
      return {
        ...state,
        returnData: action.payload,
        returnstatus: action.returnstatus,
        message: action.message,
        loading: action.loading
      }

    case LOGIN_SUCCESS:
      return {
        ...state,
        returnData: {},
        returnstatus: '',
        message: '',
        loading: false
      }

    default:
      return state
  }
}
export function postlogin(data) {
  return function(dispatch) {
    dispatch({ type: POST_LOGIN_DATA, loading: true })
    var postValue = {
      app_id: data.appId,
      username: data.username,
      password: data.password,
      token: '#$$%XCXCXVHH&^&^CV!@@#CDE%$DFhdf632934'
    }
    axios
      .post(data.server + 'callcenterapi/login/', qs.stringify(postValue))
      .then(function(response) {        
        dispatch({
          type: POST_LOGIN_DATA,
          payload: response.data.result_set,
          message: response.data.message,
          loading: false,
          returnstatus: response.data.status
        })
      })
      .catch(function(error) {
        console.log(error)
      })
  }
}

export function successlogin(data) {
  return function(dispatch) {
    dispatch({ type: LOGIN_SUCCESS })
  }
}
