import axios from "axios";
import cookie from "react-cookies";
import { HOST_URL, APP_ID } from "../../config/constans";
import { returnValue,customerId,getReferenceID} from "../../config/supporters";
export const GET_CARTITEMS = "settings/GET_CARTITEMS";

const initialState = {
  returnData: [],
  isFetching: true,
  isError: null,
  isStatus: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_CARTITEMS:
      return {
        ...state,
        returnData: action.returnData,
        isStatus: action.isStatus,
        isFetching: false
      };

    default:
      return state;
  }
}
export function getCartItems(pageslug = null) {
  let appId = returnValue(cookie.load(APP_ID));
  let API_URL = cookie.load(HOST_URL) + "apiv2/cart/contents";
  let reference_id =getReferenceID();
    let customer_Id =customerId();

  return function(dispatch) {
    axios
      .get(
        `${API_URL}/?app_id=` +
          appId +
          "&reference_id=" +
          ((typeof customer_Id === "undefined" || customer_Id === "" )? reference_id : "" )  +
          "&customer_id=" +
          (typeof customer_Id !== "undefined" &&  customer_Id !==""? customer_Id : "")
      )
      .then(response => {
        dispatch({
          type: GET_CARTITEMS,
          returnData: response.data.result_set,
          isStatus: response.data.status,
          isFetching: false
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
}
