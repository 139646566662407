import axios from 'axios';
 import { appId, hostUrl,getCookies } from "../../config/supporters";
 
export const FETCH_MENU_NAV = 'promotions/FETCH_MENU_NAV'

const initialState = {
    items: [],
    loading: false,
	isStatus : null
}
 
export default function(state = initialState , action) {
  
   switch (action.type){
       case FETCH_MENU_NAV:
        return {
            ...state,
            items: action.payload,
			loading : action.loading,
			isStatus : action.isStatus
        }
              
       default:
       return state;
   }
}
export function fetchMenuNavigation(){

var HOST = hostUrl();  
var APP_ID = appId();
var avId = getCookies("orderAvailability");
const API_URL =  HOST+"apiv2/products/getMenuNavigation?app_id="+APP_ID+"&availability="+avId; 
 
    return function (dispatch) {
		// dispatch({loading : true });
        axios.get(API_URL)
        .then(response => {
          dispatch({
            type: FETCH_MENU_NAV,
            payload: response.data.result_set,
			loading : false,
			isStatus : response.data.status
          });
        })
        .catch((error) => {
          console.log(error);
        })
       
    }
}
