import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { history } from "../../store";
import { getCartItems } from "../../stores/cart";
import cookie from "react-cookies";
import {
  Button,
  Modal,
  Input,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Col,
  Row,
  Media,
  Jumbotron,
  Container,
  Badge,
  Spinner
} from "reactstrap";
import {
  appId,
  hostUrl,
  getCookies,
  stripslashes,
  showInfo,
  getReferenceID,
  customerId,
  getAliasName
} from "../../config/supporters";
import {
  DELIVERY_ID,
  PICKUP_ID,
  SUCCESS_ALERT,
  FAILURE_ALERT,
  MARS_BASE_URL
} from "../../config/constans";
import { Scrollbars } from "react-custom-scrollbars";
import ProductPopupImage from "../../assets/img/product-popup-image.jpg";
import Select from "react-select";
//import 'react-select/dist/react-select.css';
const $ = window.$;
var _ = require("lodash");
var qs = require("qs");
var parse = require("html-react-parser");

class Addtocartcombopopup extends Component {
  constructor(props) {
    super(props);
    this.handleChangeDescription = this.handleChangeDescription.bind(this);
    this.openProductAddtoCart = this.openProductAddtoCart.bind(this);
    this.closeAll = this.closeAll.bind(this);
    this.state = {
      openproduct_addtocart: false,
      tagsInputValue: "",
      tags: [],
      detailstatus: "loading",
      prDetails: {},
      prCommon: {},
      defaultQty: 1,
      allowCart: "Yes",
      currentProdcut: "",
      specialMsg: "",
      modifierLenght: 0,
      selected_modifier_value: "",
      multi: false,
      detailstatus: "loading",
      productSlug: "",
      remaining: 50,
      prDetails: [],
      prCommon: [],
      cartCount: 1,
      featuredStatus: "",
      bestStatus: "",
      bestSellers: [],
      featuredProducts: [],
      status: "Loading",
      allowCart: "Yes",
      productPrice: "",
      productUnitPrice: 0,
      itemType: "Simple",
      ModifierCount: "",
      MinMaxSel: 0,
      extraComboPrice: 0,
      selected_value: "",
      productName: "",
      product_remarks: "",
      selectedFile: "",
      giftContent: "Text"
    };

    /* set default cookie values */
    cookie.save("modiferCount", 0,{ path: '/' });
    cookie.save("itemType", "Simple",{ path: '/' });
    cookie.save("subquantity", 0,{ path: '/' });
    cookie.save("notsubquantity", "",{ path: '/' });
  }

  handleChangeDescription(event) {
    this.setState({ product_remarks: event.target.value });
    this.setState({ remaining: 50 - event.target.value.length });
  }
  openProductAddtoCart() {
    this.setState({
      openproduct_addtocart: !this.state.openproduct_addtocart
    });
  }

  closeAll() {
    this.setState({
      openproduct_addtocart: false
    });
  }

  addTag = tag => {
    if (tag === "") return;

    tag = tag.trim();

    if (!(this.state.tags.indexOf(tag) > -1)) {
      let tags = this.state.tags.concat([tag]);
      this.updateTags(tags);
    }

    this.updateTagValue("");
  };

  updateTagValue = value => {
    if (value === " ") {
      return;
    }
    this.setState({
      tagsInputValue: value
    });
  };

  removeTag = removeTag => {
    let tags = this.state.tags.filter(tag => tag !== removeTag);
    this.updateTags(tags);
  };

  updateTags = tags => {
    this.setState({
      tags
    });
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.isOpen !== this.props.isOpen &&
      nextProps.isOpen === true &&
      nextProps.isproductId !== ""
    ) {
      this.setState({
        openproduct_addtocart: true
      });

      const productSlug = nextProps.isproductId;
      const appID = appId();
      const apiUrl = hostUrl();
      const availabilityId = getCookies("orderAvailability");
      axios
        .get(
          apiUrl +
            "api/products/products_list?app_id=" +
            appID +
            "&availability=" +
            availabilityId +
            "&product_slug=" +
            productSlug
        )
        .then(res => {
          if (res.data.status === "ok") {
            this.setState({
              detailstatus: "ok",
              prDetails: res.data.result_set[0],
              prCommon: res.data.common,
              productPrice: res.data.result_set[0].product_price,
              productUnitPrice: res.data.result_set[0].product_price,
              MinMaxSel: parseInt(
                res.data.result_set[0].product_apply_minmax_select
              ),
              productName: res.data.result_set[0].product_name
            });
          } else {
            this.props.history.push("/");
          }
        });

      for (var m = 0, length = 20; m < length; m++) {
        this.setState({ [m]: "" });
        this.setState({ ["modifier" + m]: "" });
      }
    }
  }

  /* add to cart */
  addToCart(
    productId,
    proName,
    prosku,
    proPrice,
    proQty,
    proImage,
    allowCart,
    applyMinmaxSelect,
    Validate_mode
  ) {
    if (Validate_mode === "Validate") {
      console.log("tetttt");
      $(".cart_add_div").hide();
      $(".cart_update_div").show();
      return false;
    }

    //alert(applyMinmaxSelect);
    this.setState({ productId: productId });
    this.setState({ proName: proName });
    this.setState({ prosku: prosku });
    this.setState({ proPrice: proPrice });
    this.setState({ proQty: proQty });
    this.setState({ proImage: proImage });
    this.setState({ allowCart: allowCart });
    this.setState({ applyMinmaxSelect: parseInt(applyMinmaxSelect) });

    var modifier_missing =
      "product combination is wrong. Please check your combination";
    /* check add to cart permission */
    if (allowCart === "No") {
      showInfo(modifier_missing);

      return false;
    }

    /* set meat products array */
    var combo = [];
    var modifier = [];
    var comboSelction = "Fail";
    var modifierSelction = "Fail";
    var overAllQty = 0;
    var totalMinSelect = 0;

    if (Validate_mode !== "Addon") {
      if (
        cookie.load("itemType") === "Combo" &&
        cookie.load("comboCount") > 0
      ) {
        for (var m = 0, length = cookie.load("comboCount"); m < length; m++) {
          var _items = document.getElementsByClassName("hiddenInput_" + m);
          var selCurQty = 0;
          var _cartQty = 0;
          var selMincount = 0;
          var selMaxcount = 0;
          var selMinSetName = "";

          if (_items.length > 0) {
            var _itemCount = _items.length;
            for (var i = 0; i < _itemCount; i++) {
              selCurQty = parseInt(
                document.getElementsByClassName("combinput_" + m)[i].innerHTML
              );
              _cartQty = _cartQty + selCurQty;
            }
          } else {
            selCurQty = parseInt($(".combinput_" + m).html());

            _cartQty = _cartQty + selCurQty;
          }

          overAllQty = overAllQty + _cartQty;
          /* validate min max setup */
          selMincount = parseInt($(".combo-min-" + m).val());

          selMinSetName = $(".combo-min-name" + m).val();
          selMaxcount = parseInt($(".combo-max-" + m).val());

          totalMinSelect = totalMinSelect + selMincount;
          // console.log(_cartQty + "//" + selMincount);
          if (_cartQty < selMincount) {
            showInfo(
              "Please select the min number of items for " + selMinSetName
            );

            return false;
          }

          if (_cartQty > selMaxcount) {
            showInfo("Maximum selection limit exceed for " + selMinSetName);

            return false;
          }

          /* add products  */
          if (overAllQty <= 0 && totalMinSelect > 0) {
            showInfo("Please choose any combo product combination");

            return false;
          }

          var modifierrequired = $(
            "#comboOptionValueModifierRequired_" + m
          ).val();
          var modifierselected = $("#comboOptionValueModifier_" + m).val();

          if (modifierrequired === "YES" && modifierselected === "NO") {
            showInfo("Please choose any one shape");

            return false;
          }

          var combovalues = $("#comboOptionValue_" + m).val();

          if (combovalues !== "") {
            var combodetails = JSON.parse(combovalues);
            combo.push(combodetails);
          }
        }
      }
    }

    var totalPrice = proQty * proPrice;

    const appID = appId();
    const apiUrl = hostUrl();
    const availabilityId = getCookies("orderAvailability");
    var postObject = {};
		const customerID = getCookies("customerId");
    postObject = {
      app_id: appID,
      product_id: productId,
      subquantity: cookie.load("subquantity"),
      notsubquantity: cookie.load("notsubquantity"),
      product_name: stripslashes(proName),
      product_sku: prosku,
      reference_id: (customerID  === "" ? getReferenceID() : ""),
      customer_id: customerID,
      product_image: proImage,
      availability_id: availabilityId,
      product_unit_price: proPrice,
      product_qty: proQty,
      product_total_price: totalPrice,
      modifiers: JSON.stringify(modifier),
      menu_set_component: JSON.stringify(combo),
      product_remarks: this.state.product_remarks
    };

    /* 	"cart_item_min_qty" : this.state.cart_minimum_qty, */

    var qs = require("qs");
    /*add loading */
    $("#proLoading").show();

    let API_ENDPOINT = apiUrl + "api/cart/insert";
    if(MARS_BASE_URL ===  apiUrl) {
      API_ENDPOINT = apiUrl + "ninjapro/cartv2/insert";
    }

    axios
      .post(API_ENDPOINT, qs.stringify(postObject))
      .then(res => {
        /* this.resetPromotion(); */
        /*cookie.save('cartValueUpdated','Yes');
			
			if (typeof(Storage) !== "undefined") {
			  localStorage.setItem("cartValueUpdated", "Yes");
			}  

			$('#dvLoading').fadeOut(1500);
			this.setState({ status: 'ok' });

			$('.cart-success-msg').html('');
			$('.cart-error-msg').html(''); */
        $("#proLoading").hide();
        if (res.data.status === "ok") {
          /* cookie.save('cartValue','Yes');
				cookie.save('cartValueUpdated','Yes');
				cookie.save('carttotalitems', res.data.contents.cart_details.cart_total_items);
				cookie.save('cartsubtotal', res.data.contents.cart_details.cart_sub_total);
				  find previous page 

				if (Validate_mode === 'Addon') {
					
					showInfo( 'Great choice! Item added to your cart.');
					 
				} else {
					
					  $('.cart_add_div').show();
					$('.cart_update_div').hide();
					//this.setState({ cartCount:  1 });
					$('.cart-success-msg').html('Great choice! Item added to your cart.');
					$('.cart-success-msg').show();
					setTimeout(function () { $('.cart-success-msg').fadeOut(); }, 3000);  
				} */
          cookie.remove("modiferCount");
          cookie.remove("comboCount");
          cookie.remove("itemType");
          this.setState({
            cartCount: 1,
            cartAlert: true,
            cartAlertMsg: res.data.message,
            alertType: SUCCESS_ALERT,
            openproduct_addtocart: false
          });

          this.props.getCartItems();
          showInfo(res.data.message);
        } else if (res.data.status === "error") {
          showInfo("Warning", res.data.message);
        }
      });
  }

  showModifiers(modVal, productId) {
    if (modVal) {
      var modLen = modVal.length;
      return modVal.map((item, index) => (
        <FormGroup className="" key={index}>
          <Label for="productSize">
            {stripslashes(item.pro_modifier_name)}
          </Label>
          <div className="custome-select-control">
            <Input
              type="select"
              name={"modVal_" + index}
              id={"modVal_" + index}
              onChange={this.validateModifier.bind(this, modLen, productId)}
              className="form-control"
            >
              <option value="">
                {"Select " + stripslashes(item.pro_modifier_name)}{" "}
              </option>
              {this.showModifierItems(
                item.modifiers_values,
                index,
                modLen,
                item.pro_modifier_name,
                item.pro_modifier_id
              )}
            </Input>
          </div>
        </FormGroup>
      ));
    }
  }

  isDefault(select) {
    return select === "Yes" ? "selected" : "";
  }

  showModifierItems(modVal, mIndex, length, modName, modId) {
    cookie.save("modiferCount", length, { path: "/" });
    cookie.save("itemType", "Modifier", { path: "/" });
    return modVal.map((item, malIndex) => (
      <option
        key={malIndex}
        selected={this.isDefault(item.pro_modifier_value_is_default)}
        value={
          modName +
          "~" +
          modId +
          "~" +
          item.pro_modifier_value_name +
          "~" +
          item.pro_modifier_value_id +
          "~" +
          item.pro_modifier_value_price
        }
      >
        {/*id={item.pro_modifier_value_id}*/}
        {item.pro_modifier_value_name}
      </option>
    ));
  }

  /* checkActive */
  checkActive() {}

  decQty() {
    if (
      this.state.cartCount !== 1 &&
      this.state.product_minimum_qty !== this.state.cartCount
    ) {
      this.setState({ cartCount: this.state.cartCount - 1 });
      /*this.setState({productPrice:  this.state.productPricefixed*(this.state.cartCount-1)});*/
    }
  }

  incQty() {
    this.setState({ cartCount: this.state.cartCount + 1 });
    /*this.setState({productPrice:  this.state.productPricefixed*(this.state.cartCount+1)});*/
  }

  /* ############################# ##############################*/

  /* show combo product option */
  showComboOptions(products, minMaxSelect) {
    cookie.save("comboCount", products.length,{ path: '/' });
    cookie.save("itemType", "Combo",{ path: '/' });

    if (products.length > 0) {
      const html = products.map((item, index) => (
        <div
          className={
            parseInt(item.menu_component_modifier_apply) === 0 &&
            parseInt(minMaxSelect) === 1
              ? "product_chosen_item_left product_chosen_item_left_full"
              : "product_chosen_item_left product_chosen_item_left_full"
          }
          key={index}
        >
          <div
            className={
              parseInt(item.menu_component_modifier_apply) === 0 &&
              parseInt(minMaxSelect) === 1
                ? "product_chosen_addons"
                : ""
            }
          >
            <div className="product_chosen_hea">
              <h6>{stripslashes(item.menu_set_component_name)}</h6>
              <span></span>
            </div>
          </div>
          {item.menu_component_min_select >= 1 && (
            <div
              className="max-min-bar"
              style={{
                display: this.showMinmaxselectionBar(
                  parseInt(item.menu_component_modifier_apply),
                  parseInt(minMaxSelect)
                )
              }}
            >
              Select {item.menu_component_min_select} Option
              {item.menu_component_min_select > 1 ? "s" : ""}
            </div>
          )}
          <div
            className="max-min-bar"
            style={{
              display: this.showMinmaxBar(
                parseInt(item.menu_component_modifier_apply),
                parseInt(minMaxSelect)
              )
            }}
          >
            You've chosen <span className={"minSele-" + index}>0</span>{" "}
            {item.menu_component_min_select > 1 &&
              "out of " + item.menu_component_min_select}{" "}
            (Max. {item.menu_component_max_select}){" "}
          </div>
          <input
            type="hidden"
            className={"combo-min-" + index}
            value={item.menu_component_min_select}
          />
          <input
            type="hidden"
            className={"combo-min-name" + index}
            value={stripslashes(item.menu_set_component_name)}
          />
          <input
            type="hidden"
            className={"combo-max-" + index}
            value={item.menu_component_max_select}
          />
          {parseInt(item.menu_component_modifier_apply) === 1 &&
            this.showComboAsDropDown(
              item.product_details,
              index,
              item.menu_set_component_name,
              item.menu_set_component_id,
              item.menu_component_pieces_count,
              item.menu_component_min_select,
              item.menu_component_max_select,
              item.menu_component_apply_price
            )}
          {parseInt(item.menu_component_modifier_apply) === 0 &&
            parseInt(minMaxSelect) === 0 &&
            this.showComboAsDropDown(
              item.product_details,
              index,
              item.menu_set_component_name,
              item.menu_set_component_id,
              item.menu_component_pieces_count,
              item.menu_component_min_select,
              item.menu_component_max_select,
              item.menu_component_apply_price
            )}
          {parseInt(item.menu_component_modifier_apply) === 0 &&
            parseInt(minMaxSelect) === 1 &&
            this.showComboAsCount(
              item.product_details,
              index,
              item.menu_set_component_name,
              item.menu_set_component_id,
              item.menu_component_pieces_count,
              item.menu_component_min_select,
              item.menu_component_max_select,
              item.menu_component_apply_price
            )}
        </div>
      ));
      return html;
    }
  }

  /*  hide minmax bar worksapce */
  showMinmaxBar(val1, val2) {
    return val1 === 0 && val2 === 1 ? "block" : "none";
  }

  /*  hide minmax bar worksapce */
  showMinmaxselectionBar(val1, val2) {
    if (val1 === 1) {
      return "block";
    } else if (val1 === 0 && val2 === 1) {
      return "block";
    } else {
      return "none";
    }
  }

  /* show combo product option */
  showCombomodifierName(modifiers, mIndex) {
    if (modifiers) {
      var comboModifier = [];
      $.each(modifiers[0].modifiers_values, function(index, item) {
        var combomodifiervalues = [];
        var combomodifiername = [];
        combomodifiervalues.push({
          modifier_value_id: item.pro_modifier_value_id,
          modifier_value_qty: "1",
          modifier_value_name: item.pro_modifier_value_name,
          modifier_value_price: item.pro_modifier_value_price
        });
        combomodifiername.push({
          modifier_id: modifiers[0].pro_modifier_id,
          modifier_name: modifiers[0].pro_modifier_name
            .replace(" (Square)", "")
            .replace(" (Both Shapes)", "")
            .replace(" (Round)", ""),
          modifiers_values: combomodifiervalues
        });
        comboModifier.push({
          mIndex: mIndex,
          modifier: combomodifiername,
          value:
            modifiers[0].pro_modifier_name +
            "~" +
            modifiers[0].pro_modifier_id +
            "~" +
            item.pro_modifier_value_name +
            "~" +
            item.pro_modifier_value_id +
            "~" +
            item.pro_modifier_value_modifier_id +
            "~" +
            item.pro_modifier_value_price,
          label: item.pro_modifier_value_name
        });
      });
      var modifierHtml = (
        <Select
          value={this.state.selected_modifier_value}
          onChange={this.handleChangeModifier}
          options={comboModifier}
        />
      );

      const html = modifiers.map((item, index) => (
        <div key={index}>
          <div className="product_chosen_hea">
            <h6>
              {stripslashes(
                item.pro_modifier_name
                  .replace(" (Square)", "")
                  .replace(" (Both Shapes)", "")
                  .replace(" (Round)", "")
              )}
            </h6>
            <span></span>
          </div>
          <div className="re_select"> {modifierHtml}</div>
        </div>
      ));
      return html;
    } else {
      return "";
    }
  }

  /* show combo product option */
  showCombomodifierOptions(products, mIndex) {
    if (products.length > 0) {
      const html = products.map((item, index) => (
        <div
          style={{ display: "none" }}
          className={"select_modifier select_modifier_" + item[0].product_id}
          key={index}
        >
          {this.showCombomodifierName(item[0].modifiers, mIndex)}
        </div>
      ));
      return html;
    }
  }

  /* Combo with Count options */
  showComboAsCount(
    products,
    mIndex,
    comboName,
    comboId,
    combopieces,
    minSelect,
    maxSelect,
    applyPrice
  ) {
    var comboascountlist = [];
    $.each(products, function(index, item) {
      var product_details = {
        product_id: item[0].product_id,
        product_name:
          item[0].product_alias !== ""
            ? stripslashes(item[0].product_alias)
            : stripslashes(item[0].product_name),
        product_sku: item[0].product_sku,
        product_qty: "1",
        product_price: item[0].product_price,
        modifiers: []
      };
      var combodetails = {
        menu_component_id: comboId,
        menu_component_name: comboName,
        menu_component_pieces: combopieces
      };
      comboascountlist.push({
        combodetails: combodetails,
        product_details: product_details
      });
    });

    const html = products.map((item, index) => (
      <div className="chosen_adn" key={index}>
        <div className="bb-txt2 margin-15 chosen_adn_left">
          <span>
            {item[0].product_alias !== ""
              ? stripslashes(item[0].product_alias)
              : stripslashes(item[0].product_name)}
          </span>
        </div>
        <div className="radio  pull-right combo-inc-parent chosen_adn_right">
          <div className="qty_bx">
            <span
              className="qty_minus combo_inc"
              onClick={this.incComboQty.bind(
                this,
                minSelect,
                maxSelect,
                mIndex,
                item[0].product_id + "_" + mIndex + "_" + index,
                "remove",
                item[0].product_price,
                item[0].product_id,
                applyPrice,
                comboascountlist[index]
              )}
            >
              -
            </span>

            <label
              type="text"
              id={item[0].product_id + "_" + mIndex + "_" + index}
              disabled
              value="0"
              className={"combo-input-label combo-input combinput_" + mIndex}
            >
              0
            </label>
            <input type="hidden" className={"comboprice_" + mIndex} />
            <input
              type="hidden"
              className={"hiddenInput_" + mIndex}
              defaultValue={item[0].product_price}
            />

            <input
              className={
                item[0].product_id + "_" + mIndex + "_" + index + " css-radio"
              }
              type="hidden"
              value={
                comboName +
                "~" +
                comboId +
                "~" +
                stripslashes(
                  item[0].product_alias !== ""
                    ? stripslashes(item[0].product_alias)
                    : stripslashes(item[0].product_name)
                ) +
                "~" +
                item[0].product_id +
                "~" +
                item[0].product_sku +
                "~" +
                item[0].product_price
              }
            />

            <span
              className="qty_plus combo_dec"
              onClick={this.incComboQty.bind(
                this,
                minSelect,
                maxSelect,
                mIndex,
                item[0].product_id + "_" + mIndex + "_" + index,
                "add",
                item[0].product_price,
                item[0].product_id,
                applyPrice,
                comboascountlist[index]
              )}
            >
              +
            </span>
          </div>
        </div>
      </div>
    ));

    const countoption = (
      <div>
        {html}
        <input type="hidden" id={"comboOptionValue_" + mIndex} />{" "}
        <input type="hidden" id={"comboOptionValuePrice_" + mIndex} />
      </div>
    );
    return countoption;
  }

  /* Show only Dropdown options */
  showComboAsDropDown(
    products,
    mIndex,
    comboName,
    comboId,
    combopieces,
    minSelect,
    maxSelect,
    applyPrice
  ) {
    var comboProducts = [];
    var modLen = 0;
    var multi = false;
    if (maxSelect > 1) {
      multi = true;
    }

    var modifierHtml = "";
    $.each(products, function(index, item) {
      modLen = item[0].modifiers !== null ? item[0].modifiers.length : 0;
      var shwPriceHtml = "";
      if (parseFloat(item[0].product_price) > 0 && parseInt(applyPrice) === 1) {
        shwPriceHtml = " (+$" + item[0].product_price + ")";
      }

      var product_details = {
        product_id: item[0].product_id,
        product_name:
          item[0].product_alias !== ""
            ? stripslashes(item[0].product_alias)
            : stripslashes(item[0].product_name),
        product_sku: item[0].product_sku,
        product_qty: "1",
        product_price: item[0].product_price,
        modifiers: []
      };
      var combodetails = {
        menu_component_id: comboId,
        menu_component_name: comboName,
        menu_component_pieces: combopieces
      };
      comboProducts.push({
        subquantity: item[0].product_sub_modifier_qty,
        product_id: item[0].product_id,
        product_details: product_details,
        combodetails: combodetails,
        value:
          comboName +
          "~" +
          comboId +
          "~" +
          (item[0].product_alias !== ""
            ? stripslashes(item[0].product_alias)
            : stripslashes(item[0].product_name)) +
          "~" +
          item[0].product_id +
          "~" +
          item[0].product_sku +
          "~" +
          applyPrice +
          "~" +
          item[0].product_price,
        label:
          item[0].product_alias !== ""
            ? stripslashes(item[0].product_alias) + shwPriceHtml
            : stripslashes(item[0].product_name) + shwPriceHtml,
        modLen: modLen,
        id: item[0].product_id + "_" + mIndex + "_" + index,
        mIndex: mIndex,
        refVal:
          minSelect +
          "~" +
          maxSelect +
          "~" +
          mIndex +
          "~" +
          item[0].product_id +
          "_" +
          mIndex +
          "_" +
          index +
          "~" +
          item[0].product_price +
          "~" +
          item[0].product_id +
          "~" +
          applyPrice
      });
    });

    var modifierHtml = this.showCombomodifierOptions(products, mIndex);
    var dropdown = (
      <Select
        isMulti ={multi}
        value={this.state.selected_value}
        onChange={this.handleChange}
        options={comboProducts}
      />
    );
    return (
      <div>
        <div className="form-group">
          <div className={"re_select re_select_" + mIndex}>
            {dropdown}{" "}
            <div className="pmodifier_ddlist">
              <ul>{this.state[mIndex]}</ul>
            </div>
            <label
              style={{ display: "none" }}
              type="text"
              className={"combo-input combinput_" + mIndex}
            >
              0
            </label>
            <input
              type="hidden"
              id={"comboOptionValueModifierRequired_" + mIndex}
            />
            <input type="hidden" id={"comboOptionValueModifier_" + mIndex} />
            <input type="hidden" id={"comboOptionValue_" + mIndex} />
            <input type="hidden" id={"comboOptionValuePrice_" + mIndex} />
          </div>
        </div>
        <div className="form-group form-group-modifier">
          <div className={"re_select_modifier re_select_modifier_" + mIndex}>
            {modifierHtml}
            <div className="pmodifier_ddlist">
              <ul>{this.state["modifier" + mIndex]}</ul>
            </div>
          </div>
        </div>
      </div>
    );
  }

  updatePriceComboCount() {
    if (cookie.load("itemType") === "Combo" && cookie.load("comboCount") > 0) {
      var comboprices = 0;
      for (var m = 0, length = cookie.load("comboCount"); m < length; m++) {
        if ($("#comboOptionValuePrice_" + m).val()) {
          comboprices += parseFloat($("#comboOptionValuePrice_" + m).val());
        }
      }

      console.log(this.state.productUnitPrice + "//" + comboprices);
      var currentPrice =
        parseFloat(this.state.productUnitPrice) + parseFloat(comboprices);
      this.setState({ productPrice: currentPrice.toFixed(2) });
    }
  }
  /* check min max setup */
  incComboQty(
    minSelect,
    maxSelect,
    setCount,
    inputId,
    action,
    unitPrice,
    productId,
    applyPrice,
    selectedvalue
  ) {
    var productprice = 0;
    var currentPrice = 0;
    var newQty = 0;
    var getQty = parseInt($("#" + inputId).text());
    /* disable if click values is zero*/
    if (getQty <= 0 && action === "remove") {
      return false;
    }
    /* get selected input qty values */
    var items = document.getElementsByClassName("hiddenInput_" + setCount);
    var itemCount = items.length;
    var cartQty = 0;
    for (var i = 0; i < itemCount; i++) {
      cartQty =
        cartQty +
        parseInt(
          document.getElementsByClassName("combinput_" + setCount)[i].innerHTML
        );
    }

    var addCartQty = cartQty + 1;

    var removeCartQty = cartQty > 0 ? cartQty - 1 : 0;

    if (addCartQty > maxSelect && action === "add") {
      return false;
    }

    if (action === "add") {
      $(".minSele-" + setCount).html(addCartQty);
    } else if (action === "remove") {
      $(".minSele-" + setCount).html(removeCartQty);
    }
    /*  increase or decrease  product item qty */
    if (action === "remove") {
      newQty = getQty > 0 ? getQty - 1 : 0;
    } else {
      newQty = getQty + 1;
    }

    $("#" + inputId).text(parseInt(newQty));

    if (applyPrice) {
      var itemprice = parseFloat(unitPrice) * parseInt(newQty);
      $("#" + inputId)
        .next(".comboprice_" + setCount)
        .val(itemprice);
      var lengthcnt = $(".comboprice_" + setCount).length;

      var itemprice = 0;
      for (var i = 0; i < lengthcnt; i++) {
        itemprice += parseFloat(
          document.getElementsByClassName("comboprice_" + setCount)[i].value
        );
      }
      console.log(itemprice);
      $(".comboOptionValuePrice_" + setCount).val(itemprice);

      this.updatePriceComboCount();
    }

    var selected = [];
    var combodetails = [];
    var productexist = 0;

    var combovalues = $("#comboOptionValue_" + setCount).val();

    if (combovalues) {
      combodetails = JSON.parse(combovalues);
      if (combodetails.product_details.length) {
        for (var i = 0; i < combodetails.product_details.length; i++) {
          if (combodetails.product_details[i].product_id === productId) {
            productexist = i + 1;
          }
        }

        if (productexist) {
          productexist = parseInt(productexist) - 1;
          combodetails.product_details[productexist].product_qty =
            parseInt(combodetails.product_details[productexist].product_qty) +
            1;
        } else {
          combodetails.product_details.push(selectedvalue.product_details);
        }
      } else {
        combodetails = selectedvalue.combodetails;
        selected.push(selectedvalue.product_details);
        combodetails.product_details = selected;
      }
    } else {
      combodetails = selectedvalue.combodetails;
      selected.push(selectedvalue.product_details);
      combodetails.product_details = selected;
    }

    $("#comboOptionValue_" + setCount).val(JSON.stringify(combodetails));
  }

  handleChangeModifier = event => {
    this.updateCombomodifierSelection(event);
  };

  handleChange = event => {
    this.setState({ selected_value: null });
    console.log(event.mIndex);
    if (event.mIndex !== undefined) {
      this.updateComboSelection(event.mIndex, event);
    } else {
      this.updateComboSelection(event[0].mIndex, event);
    }
  };

  removeComboItem(i, applyPrice, indexval) {
    var combodetails = [];
    var combovalues = $("#comboOptionValue_" + indexval).val();
    if (combovalues) {
      combodetails = JSON.parse(combovalues);
      if (combodetails.product_details.length) {
        if (applyPrice) {
          var product_price =
            parseInt(combodetails.product_details[i].product_qty) *
            parseFloat(combodetails.product_details[i].product_price);
          var oldPrice = $("#comboOptionValuePrice_" + indexval).val();
          var productprice = parseFloat(oldPrice) - product_price;
          $("#comboOptionValuePrice_" + indexval).val(parseFloat(productprice));
        }
        combodetails.product_details.splice(i, 1);
        var productQty = this.updateProductQty(combodetails.product_details);
        $(".combinput_" + indexval).html(parseInt(productQty));
        $("#comboOptionValue_" + indexval).val(JSON.stringify(combodetails));
      }
    }
    var selectedcombo = this.showSelectedValue(
      combodetails.product_details,
      parseInt(applyPrice),
      indexval
    );
    this.setState({ [indexval]: selectedcombo });
    this.updatePriceComboCount();
  }

  removeComboModifierItem(indexval) {
    var combodetails = [];
    var combovalues = $("#comboOptionValue_" + indexval).val();
    if (combovalues) {
      combodetails = JSON.parse(combovalues);
      if (combodetails.product_details.length) {
        combodetails.product_details[0].modifier = [];
        $("#comboOptionValue_" + indexval).val(JSON.stringify(combodetails));
        $("#comboOptionValueModifierRequired_" + indexval).val("NO");
        $("#comboOptionValueModifier_" + indexval).val("NO");
      }
      this.setState({ ["modifier" + indexval]: "" });
    }
  }

  updateComboSelection(indexvalues, selectedvalues) {
    var selected = [];
    var combodetails = [];
    var productprice = 0;
    var currentPrice = 0;
    var productexist = 0;

    if (selectedvalues.length || selectedvalues.refVal !== undefined) {
      var indexval =
        selectedvalues[0] === undefined
          ? selectedvalues.mIndex
          : selectedvalues[0].mIndex;
      var combodetails =
        selectedvalues[0] === undefined
          ? selectedvalues.combodetails
          : selectedvalues[0].combodetails;
      if (selectedvalues[0] === undefined) {
        console.log(selectedvalues.subquantity);
        if (selectedvalues.subquantity != null) {
          cookie.save("subquantity", selectedvalues.subquantity,{ path: '/' });
          cookie.save("notsubquantity", selectedvalues.product_id,{ path: '/' });
        }
        var refVal = selectedvalues.refVal.split("~");
        if (parseInt(refVal[6])) {
          productprice += parseFloat(refVal[4]);
        }
        selected.push(selectedvalues.product_details);
        combodetails.product_details = selected;
        var selectedcombo = this.showSelectedValue(
          combodetails.product_details,
          parseInt(refVal[6]),
          indexval
        );
        this.setState({ [indexval]: selectedcombo });

        if (selectedvalues.modLen > 0) {
          $(".re_select_modifier_" + indexval)
            .find(".select_modifier")
            .hide();
          //$(".re_select_modifier_"+indexval).find(".pmodifier_ddlist").hide();
          $("#comboOptionValueModifierRequired_" + indexval).val("YES");
          $("#comboOptionValueModifier_" + indexval).val("NO");
          this.setState({ ["modifier" + indexval]: "" });
          $(".select_modifier_" + selectedvalues.product_id).show();
        } else {
          $(".re_select_modifier_" + indexval)
            .find(".select_modifier")
            .hide();
          $("#comboOptionValueModifierRequired_" + indexval).val("NO");
          $("#comboOptionValueModifier_" + indexval).val("NO");
          this.setState({ ["modifier" + indexval]: "" });
        }
      } else {
        selectedvalues.forEach(selvalue => {
          var refVal = selvalue.refVal.split("~");
          if (parseInt(refVal[6])) {
            productprice += parseFloat(refVal[4]);
          }
          var productId = refVal[5];
          var combovalues = $("#comboOptionValue_" + indexval).val();
          if (combovalues) {
            combodetails = JSON.parse(combovalues);
            if (combodetails.product_details.length) {
              for (var i = 0; i < combodetails.product_details.length; i++) {
                if (combodetails.product_details[i].product_id === productId) {
                  productexist = i + 1;
                }
              }

              if (productexist) {
                productexist = parseInt(productexist) - 1;
                combodetails.product_details[productexist].product_qty =
                  parseInt(
                    combodetails.product_details[productexist].product_qty
                  ) + 1;
              } else {
                combodetails.product_details.push(selvalue.product_details);
              }
            } else {
              combodetails = selvalue.combodetails;
              selected.push(selvalue.product_details);
              combodetails.product_details = selected;
            }
          } else {
            combodetails = selvalue.combodetails;
            selected.push(selvalue.product_details);
            combodetails.product_details = selected;
          }

          var selectedcombo = this.showSelectedValue(
            combodetails.product_details,
            parseInt(refVal[6]),
            indexval
          );
          this.setState({ [indexval]: selectedcombo });
        });
      }
      $("#comboOptionValuePrice_" + indexval).val(parseFloat(productprice));
      var productQty = this.updateProductQty(combodetails.product_details);
      $(".combinput_" + indexval).html(parseInt(productQty));
      $("#comboOptionValue_" + indexval).val(JSON.stringify(combodetails));
      this.updatePriceComboCount();
    } else {
      currentPrice =
        parseFloat(this.state.productUnitPrice) + parseFloat(productprice);
      $("#comboOptionValuePrice_" + indexvalues).val(0);
      $("#comboOptionValue_" + indexvalues).val("");
      $(".combinput_" + indexvalues).html(0);
      this.updatePriceComboCount();
    }
  }

  updateCombomodifierSelection(selectedvalues) {
    var selected = [];
    var combodetails = [];
    var indexval = selectedvalues.mIndex;

    var combovalues = $("#comboOptionValue_" + indexval).val();
    if (combovalues) {
      combodetails = JSON.parse(combovalues);

      combodetails.product_details[0].modifiers = selectedvalues.modifier;

      $("#comboOptionValue_" + indexval).val(JSON.stringify(combodetails));
      $("#comboOptionValueModifier_" + indexval).val("YES");

      var selectedcombomodifier = this.showSelectedmodifierValue(
        selectedvalues.modifier[0].modifiers_values,
        indexval
      );
      this.setState({ ["modifier" + indexval]: selectedcombomodifier });
    }
  }

  updateProductQty(products) {
    var productQty = 0;
    for (var i = 0; i < products.length; i++) {
      productQty += parseInt(products[i].product_qty);
    }
    return productQty;
  }

  showSelectedmodifierValue(item, indexval) {
    return item.map((item, index) => (
      <li key={index}>
        <span>
          <span>{item.modifier_value_name}</span>
          <a
            href="javascript:;"
            onClick={this.removeComboModifierItem.bind(this, indexval)}
            className="pmodifier_close"
          ></a>
        </span>
      </li>
    ));
  }

  showSelectedValue(products, applyPrice, indexval) {
    return products.map((item, index) => (
      <li key={index + 100}>
        <span>
          <span>
            {item.product_name}
            {item.product_qty > 1 && " (" + item.product_qty + ")"}{" "}
            {applyPrice && item.product_price > 0
              ? " +$" + item.product_price * item.product_qty
              : ""}
          </span>
          <a
            href="javascript:;"
            onClick={this.removeComboItem.bind(
              this,
              index,
              applyPrice,
              indexval
            )}
            className="pmodifier_close"
          ></a>
        </span>
      </li>
    ));
  }

  showProductTags(tags) {
    return tags.map((item, index) => (
      <li key={index}>
        <a href={"#/products/tag/" + item.tag_id} target="_blank">
          {stripslashes(item.pro_tag_name)}{" "}
          {index + 1 !== tags.length ? "/" : ""}{" "}
        </a>
      </li>
    ));
  }

  render() {
    const closeAllBtn = (
      <button className="close" onClick={this.closeAll}>
        &times;
      </button>
    );
    var proImsgsrc = hostUrl() + "media/dev_team/products/main-image/";
    const { tagsInputValue, tags } = this.state;
    const details = this.state.prDetails;
    const cartImage =
      details.product_thumbnail !== "" &&
      typeof details.product_thumbnail !== "undefined"
        ? proImsgsrc + details.product_thumbnail
        : "";

    return (
      <React.Fragment>
        {/* 1st modal */}
        <Modal
          size="lg"
          isOpen={this.state.openproduct_addtocart}
          toggle={this.openProductAddtoCart}
          className="callcentre-modal addtocart-modal"
        >
          <div className="modal-header">
            <div className="modal-top-icon">{closeAllBtn}</div>
          </div>

          <ModalBody>
            <Media>
              {details.product_thumbnail !== "" &&
                typeof details.product_thumbnail !== "undefined" && (
                  <Media left href="#">
                    <img
                      style={{ width: "300px", height: "auto" }}
                      src={proImsgsrc + details.product_thumbnail}
                      alt=""
                    />
                  </Media>
                )}
              <Media body>
                <Media heading>
                  {stripslashes(
                    getAliasName(details.product_alias, details.product_name)
                  )}
                </Media>
                {parse(stripslashes(details.product_long_description))}
              </Media>
            </Media>
            <Jumbotron fluid>
              <Container fluid>
                {/* <Scrollbars style={ {width: '100%', height: '100%', minHeight: 400} }> */}

                <Row className="addtocart-form-block">
                  <Col xs="6" key="4">
                    {details.product_is_combo === "Yes" &&
                      this.showComboOptions(
                        details.set_menu_component,
                        parseInt(details.product_apply_minmax_select)
                      )}

                    {/*    <FormGroup className="">
          <Label for="productSize">Choose Product Size</Label>
          <div className="custome-select-control">
          <Input type="select" name="select" id="productSize">
            <option>8"</option>
            <option>9"</option>
            <option>10"</option>
            <option>12"</option>
            <option>14"</option>
          </Input>
          </div>
					   </FormGroup> */}

                    {/*  <FormGroup>
          <Label for="productPlatters">Choose Platters</Label>
          <div className="custome-select-control">
          <Input type="select" name="select" id="productPlatters" value={tagsInputValue} onChange={(e) => {
            this.addTag(e.target.value);
			    this.updateTagValue(e.target.value);
}} >
            <option>Platter 1</option>
            <option>Platter 2</option>
            <option>Platter 3</option>
            <option>Platter 4</option>
            <option>Platter 5</option>
          </Input>
          </div>
          <div>
        {tags && tags.map((tag, index) => 
        
        <Badge className="platter-tag" key={index} href="#" color="danger" onClick={() => this.removeTag(tag)} pill>{tag}</Badge>
          
		)} 
</div>
        </FormGroup> */}
                    {/* <FormGroup>
          <Label for="productPlatters">Choose Addons</Label>
        </FormGroup>
        <FormGroup row className="candles-form-group">
          <Label for="bigCandles" className="pl-5 text-capitalize" sm={7}>Big Candles</Label>
          <Col sm={5}>
          <div className="prod-chosen-qty-block">
            <div className="qty-box"><span className="qty-minus">_</span>
            <input type="text" id="bigCandles" readOnly value="99" /><span className="qty-plus">+</span>
            </div>
            </div>
          </Col>
        </FormGroup>
        <FormGroup row className="candles-form-group">
          <Label for="exampleEmail" className="pl-5 text-capitalize" sm={7}>Small Candles</Label>
          <Col sm={5}>
          <div className="prod-chosen-qty-block">
            <div className="qty-box"><span className="qty-minus">_</span>
            <input type="text" readOnly value="1" /><span className="qty-plus">+</span>
            </div>
            </div>
          </Col>
        </FormGroup> */}
                  </Col>
                  <Col xs="6">
                    <FormGroup className="product-description-block">
                      <Label for="productDescription">
                        Product description
                      </Label>
                      <Input
                        type="textarea"
                        name="text"
                        id="productDescription"
                        maxLength="50"
                        value={this.state.product_remarks}
                        onChange={this.handleChangeDescription}
                        placeholder="Enter your special message here"
                      />
                      <span className="float-right text-uppercase font-italic">
                        {this.state.remaining} Characters remaining
                      </span>
                    </FormGroup>
                  </Col>
                </Row>

                {/*    </Scrollbars> */}
              </Container>
            </Jumbotron>
          </ModalBody>
          <ModalFooter>
            <div className="product-price-tag">
              <h3>
                <sup>$</sup>
                {this.state.productPrice}
              </h3>
            </div>
            <div className="prod-chosen-qty-block">
              <div className="qty-box">
                <span className="qty-minus" onClick={this.decQty.bind(this)}>
                  _
                </span>
                <input type="text" readOnly value={this.state.cartCount} />
                <span className="qty-plus" onClick={this.incQty.bind(this)}>
                  +
                </span>
              </div>
            </div>
            <Button
              color="primary"
              id="addModCart"
              onClick={this.addToCart.bind(
                this,
                details.product_id,
                details.product_alias !== ""
                  ? stripslashes(details.product_alias)
                  : stripslashes(details.product_name),
                details.product_sku,
                this.state.productPrice,
                this.state.cartCount,
                cartImage,
                this.state.allowCart,
                parseInt(details.product_apply_minmax_select),
                "Insert"
              )}
            >
              Add to cart
            </Button>
          </ModalFooter>
        </Modal>
        <div
          id="modLoading"
          style={{
            display: "none",
            bottom: 0,
            left: 0,
            position: "fixed",
            right: 0,
            top: 0,
            margin: "auto",
            zIndex: 9999,
            background: "rgba(255, 255, 255, 0.8)"
          }}
        >
          <Spinner
            color="secondary"
            style={{
              width: "5rem",
              height: "5rem",
              bottom: 0,
              left: 0,
              position: "absolute",
              right: 0,
              top: 0,
              margin: "auto"
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProp = state => ({});
export default connect(
  mapStateToProp,
  { getCartItems }
)(Addtocartcombopopup);
