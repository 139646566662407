import React, { Component } from "react";
import { connect } from "react-redux";

import {

  Nav,
  NavItem,
  Input,
  ModalBody,
  FormGroup,
  Button,
  NavLink,
  Container,
  Row,
  Col,
  TabContent, TabPane, Table, ButtonGroup
} from "reactstrap";

import Accountdetails from "../myaccount/accountdetails";
import Myorders from "../myaccount/myorders";
import Myrewards from "../myaccount/myrewards";
import Promotions from "../myaccount/promotions";
import classnames from "classnames";
var _ = require("lodash");
var qs = require("qs");
var parse = require('html-react-parser');
const $ = window.$;
class Customerdashboard extends Component {
  constructor(props) {
    super(props);


    this.state = {
      activeTab: '1'
     

    };

    this.tabToggle = this.tabToggle.bind(this);   

  }

  tabToggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  
  componentWillMount() {

  }


  render() {

    return (
      <React.Fragment>


        <ModalBody className="p0">

          <Container fluid className="main-container orders-list-page customerList">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => { this.tabToggle('1'); }}
                >
                  CUSTOMER DETAILS
            </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.tabToggle('2'); }}
                >
                  ORDERS
            </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '3' })}
                  onClick={() => { this.tabToggle('3'); }}
                >
                  REWARDS POINTS
            </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '4' })}
                  onClick={() => { this.tabToggle('4'); }}
                >
                  PROMOTIONS
            </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane className="orders-list-tab customerList-tab" tabId="1">

                <Accountdetails />
              </TabPane>
              <TabPane className="orders-list-tab customerList-tab" tabId="2">
                <Myorders />
              </TabPane>
              <TabPane tabId="3">
               <Myrewards />
              </TabPane>
              <TabPane tabId="4">
                <Promotions />
                </TabPane>
            </TabContent>
          </Container>
        </ModalBody>

      </React.Fragment>);
  }
}

const mapStateToProp = state => ({

});
export default connect(
  mapStateToProp, {}
)(Customerdashboard);
