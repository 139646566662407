import React, { Component } from "react";
import { connect } from "react-redux";
import store, { history } from "../../store";
import {
  PICKUP_ID,
  APP_ID
} from "../../config/constans";
import {
  CHOOSE_OUTLET
} from "../../config/messages";
import { instanceOf } from "prop-types";
import { fetchalloutlets } from "../../stores/delivery/deliveryoutlets";
//import { getSetings } from "../../stores/settings";
import { findoutlet } from "../../stores/delivery/findzone";
import cookie from "react-cookies";
import { getCookies } from "../../config/supporters";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Alert, Spinner } from "reactstrap";
import { CEDELE_APP_ID, SPRING_APP_ID, PIE_APP_ID } from "../../config/apps";
import { addDays, setHours, setMinutes } from "date-fns";
import {
  Button,
  Modal,
  Input,
  ModalBody,
  ModalFooter,
  ListGroup,
  ListGroupItem,
  InputGroup,
  Form,
  FormGroup,
  Label,
  Row,
  Col
} from "reactstrap";
import { Scrollbars } from "react-custom-scrollbars";
/* two 
import CheckedMarkIcon from "../../assets/img/icons/checked-mark.png"; */
import DeliveryBoyIcon from "../../assets/img/icons/shopping-bag.png";
import ClockIcon from "../../assets/img/icons/clock.png";
import TickIcon from "../../assets/img/icons/checked-mark.png";
//import { fetchAllOutlets } from "../../stores/delivery/deliveryoutlets";
var _ = require("lodash");
var moment = require("moment");
var striptags = require("striptags");

class Pickupflow extends Component {
  constructor(props) {
    super(props);
    this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
    this.deliveryStepOne = this.deliveryStepOne.bind(this);
    this.deliveryStepTwo = this.deliveryStepTwo.bind(this);
    this.deliveryStepFour = this.deliveryStepFour.bind(this);
    this.closeAll = this.closeAll.bind(this);
    this.setDeliveryData = this.setDeliveryData.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.filterOutlet = this.filterOutlet.bind(this);
    /* Rounding to the nearest 15 minute interval */
    const start = moment();
    const remainder = 15 - (start.minute() % 15);

    /* To change the date after 2 PM */
    var CurrentDate = this.calcTime("Singapore", "+8");
    var currentHrs = CurrentDate.getHours();
    if (currentHrs >= 16) {
      cookie.save("minDay", 3,{ path: "/" });
    } else {
      cookie.save("minDay", 2,{ path: "/" });
    }
    const appid = getCookies("app_id");
    let dateStart = addDays(new Date(), 0);
    let timeStart = setHours(setMinutes(new Date(), 0), currentHrs);
    if (CEDELE_APP_ID === appid) {
      dateStart = addDays(new Date(), (cookie.load("minDay") !== "" ? cookie.load("minDay") : 0));
      timeStart = setHours(setMinutes(new Date(), 15), 9);
    } else if (SPRING_APP_ID === appid) {
      dateStart = currentHrs >= 14 ? addDays(new Date(), 0) : addDays(new Date(), 0);
      timeStart = setHours(setMinutes(new Date(), 0), 11);
    }


    this.state = {
      stepOne: false,
      stepTwo: false,
      stepThree: false,
      stepFour: false,
      rSelected: "",
      postalCode: "",
      CHOOSE_OUTLET: false,
      CHOOSE_POSTALCODE: false,
      UNIT_NUMBER_ERROR: false,
      dyZoneError: "",
      ordertakenOutlet: "",
      ordertakenOutletAddress: "",
      unitOne: "",
      unitTwo: "",
      startDate: dateStart,
    minDate: dateStart,
      startTime: timeStart,
      checkpostalCode: false,
      outletDetails: [],
      pickupOutletName: "",
      pickupOutletAddress: "",
      pickupOutletPostalCode: "",
      pickupOutlettat: '',
    filteroutlet : ""
    };
  }
  componentWillMount() {
    this.props.fetchalloutlets();
  }
  
  filterOutlet(e){
    this.setState({ filteroutlet: e.target.value });
  }
  
  deliveryStepOne() {
    this.setState({
      stepOne: !this.state.stepOne,
      stepTwo: false,
      stepThree: false,
      stepFour: false
    });
    this.resetPostlCodeValues();
  }
  /* Convert Timezone */
  calcTime(city, offset) {
    var d = new Date();
    var utc = d.getTime() + d.getTimezoneOffset() * 60000;
    var nd = new Date(utc + 3600000 * offset);
    return nd;
  }
  resetPostlCodeValues() {
    this.setState({
      checkpostalCode: false,
      outletDetails: [],
      CHOOSE_POSTALCODE: false,
      dyZoneError: "",
      ordertakenOutlet: "",
      ordertakenOutletAddress: "",
      postalCode: "",
      rSelected: ""
    });
  }

  deliveryStepTwo() {
    const outletID = this.state.rSelected;
    if (typeof outletID === "undefined" || outletID === "") {
      this.setState({ CHOOSE_OUTLET: true });
      return false;
    }

    var tatvalnew = (this.state.pickupOutlettat !== '') ? parseInt( this.state.pickupOutlettat) : 0;

    var dtnowobj = new Date();
      dtnowobj.setMinutes(dtnowobj.getMinutes() + tatvalnew);
    var currentTimetat = dtnowobj.getHours();
    var currentMinstat = dtnowobj.getMinutes(); 
    var Crt_Time = currentTimetat+"."+currentMinstat;
    if(PIE_APP_ID === getCookies("app_id")){
    currentMinstat = Math.ceil(currentMinstat/ 60) * 60;
    }else{
    currentMinstat = Math.ceil(currentMinstat/ 15) * 15;
    }
    if(currentMinstat == 60){
      currentTimetat = currentTimetat + 1;
    }      
    var  timeStart = setHours(setMinutes(new Date(), currentMinstat), currentTimetat);

    this.setState({
          startTime: timeStart,
    })

    this.setState({
      stepTwo: !this.state.stepTwo,
      stepOne: false,
      stepThree: false,
      stepFour: false,
      CHOOSE_OUTLET: false
    });
  }

  componentDidUpdate() {
    const allDatas = store.getState();
    const zoneDetails = allDatas.zone;
  }

  deliveryStepFour() {
    this.setState({
      stepFour: !this.state.stepFour,
      stepOne: false,
      stepTwo: false,
      stepThree: false
    });
  }

  closeAll() {
    this.setState({
      stepFour: false,
      stepOne: false,
      stepTwo: false,
      stepThree: false
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.openPickupPopup !== this.props.openPickupPopup) {
      this.deliveryStepOne();
    }
  }

  handleDateChange(date) {
    this.setState({
      startDate: date
    });
  }

  handleTimeChange(date) {
    var dateVal = new Date(date);
  //console.log(dateVal); return false;
    this.setState({
      startTime: dateVal
    });
  }
  componentUnMount() { }
  showDeliveryOutlets(outletResult) {
    return outletResult.map((out, index) => (
      <React.Fragment key={index}>
        <ListGroupItem>
          <InputGroup>
            <Input
              onClick={() =>
                this.onRadioBtnClick(
                  out.oa_outlet_id,
                  striptags(out.outlet_name),
                  striptags(out.outlet_address_line1),
                  striptags(out.outlet_postal_code),
                  out.outlet_pickup_tat
                )
              }
              type="radio"
              id={"radio_" + out.oa_outlet_id}
              name="outletsZone"
              value={out.oa_outlet_id}
            />
            <Label htmlFor={"radio_" + out.oa_outlet_id}>
              <span className="outlet-name">{striptags(out.outlet_name)}</span>
              <span className="outlet-address">
                {striptags(out.outlet_address_line1) + " "}
                {striptags(out.outlet_postal_code)}
              </span>
            </Label>
          </InputGroup>
        </ListGroupItem>
      </React.Fragment>
    ));
  }
  onRadioBtnClick(
    rSelected,
    pickupOutletName,
    pickupOutletAddress,
    pickupOutletPostalCode,
    tat
  ) {
    this.setState({ CHOOSE_OUTLET: false });
    // console.log(rSelected);   pickupOutletName : "',

    this.setState({
      rSelected: rSelected,
      pickupOutletName: pickupOutletName,
      pickupOutletAddress: pickupOutletAddress,
      pickupOutletPostalCode: pickupOutletPostalCode,
      pickupOutlettat: tat
    });
  }

  setDeliveryData() {
    const postalCode = this.state.pickupOutletPostalCode;
    const outletId = this.state.rSelected;
    const outletName = this.state.pickupOutletName;
    const outletNameAddressOne = this.state.pickupOutletAddress;
    const stateDate = this.state.startDate;
    const startTime = this.state.startTime;

    var deliveryDate = moment(stateDate).format("DD/MM/YYYY");
    var deliveryTime = moment(startTime).format("HH:mm");
   
    var deliveryTimeOrder = moment(startTime).format("hh:mm");
  
  /* No pickups in CBD outlets (From Dec 24 – 3pm to Dec 25 -  midnight) */

    const appid = getCookies("app_id");
    if (CEDELE_APP_ID === appid) {
      var open_time = Date.parse("2019-12-24T14:59");
      var close_time = Date.parse("2019-12-25T23:59");
      const dateVal = new Date(moment(deliveryDate, "DD/MM/YYYY"));
      const convertedDate = moment(dateVal).format("YYYY-MM-DD");
      const timeVal = new Date(moment(deliveryTime, "HH:mm"));
      const convertedTime = moment(timeVal).format("HH:mm");
      var check_val = Date.parse(convertedDate + "T" + convertedTime);
      var shutdown_outlet = ['232','236','234','238','174','175','178','192','237','182','230'];
      var isInArray = shutdown_outlet.includes(outletId);
      
      
      if (check_val > open_time && check_val < close_time && isInArray === true) {
        alert("Sorry, no pickups in this outlet from Dec 24 – 3pm to Dec 25 -  midnight");
      return false;
      }
    }

    if (
      typeof postalCode !== "undefined" &&
      postalCode !== "" &&
      (typeof outletId !== "undefined" && outletId !== "") &&
      (deliveryTime !== "" && deliveryDate !== "")
    ) {
      cookie.save("orderOutletId", outletId, { path: "/" });
      cookie.save("orderPostalCode", postalCode, { path: "/" });
      cookie.save("orderOutletName", outletName, { path: "/" });
      cookie.save("orderAddressOne", outletNameAddressOne, { path: "/" });
      cookie.save("orderAddressTwo", "", { path: "/" });
      cookie.save("orderDeliveryDate", deliveryDate, { path: "/" });
      cookie.save("orderDeliveryTime", deliveryTime, { path: "/" });
      cookie.save("orderAvailability", PICKUP_ID, { path: "/" });
      cookie.save("orderDeliveryTimeRequest", deliveryTimeOrder, { path: "/" });
      this.setState({ stepFour: false });
      history.push("/products");
    } else {
      alert("something went wrong(1)");
    }
  }

  render() {

    const excludeTime = [
      setHours(setMinutes(new Date(), 0), 11),
      setHours(setMinutes(new Date(), 30), 11),
      setHours(setMinutes(new Date(), 0), 12),
      setHours(setMinutes(new Date(), 30), 12),
      setHours(setMinutes(new Date(), 0), 13),
      setHours(setMinutes(new Date(), 30), 13),
      setHours(setMinutes(new Date(), 0), 14),

      setHours(setMinutes(new Date(), 30), 17),
      setHours(setMinutes(new Date(), 0), 18),
      setHours(setMinutes(new Date(), 30), 18),
      setHours(setMinutes(new Date(), 0), 19),
      setHours(setMinutes(new Date(), 30), 19),
      setHours(setMinutes(new Date(), 0), 20),
      setHours(setMinutes(new Date(), 30), 20),
      setHours(setMinutes(new Date(), 0), 21),
      setHours(setMinutes(new Date(), 30), 21),

    ];
    const closeAllBtn = (
      <button className="close" onClick={this.closeAll}>
        &times;
      </button>
    );
    const outletsData = this.props.outletsData;
    var outletResultData = _.filter(outletsData, {
      oa_availability_id: PICKUP_ID
    });
  
  if(this.state.filteroutlet !== "") {
    var value = this.state.filteroutlet;
    var matches = outletResultData.filter(function (item) {
            return item.outlet_address_line1.substring(0, value.length).toLowerCase() === value || item.outlet_postal_code.substring(0, value.length).toLowerCase() === value || item.outlet_name.substring(0, value.length).toLowerCase() === value;
        });
         
     outletResultData = matches;
  }
  
    const outletResult = outletResultData;
  

    return (
      <React.Fragment>
        {/* 1st modal */}
        <Modal
          size="lg"
          isOpen={this.state.stepOne}
          className="callcentre-modal pickup-location-modal"
        >
          <div className="modal-header" style={{paddingBottom: "0px"}}>
            <div className="modal-top-icon">
              <img src={DeliveryBoyIcon} alt={"Shopping Bag"} />
              {closeAllBtn}
            </div>
            <div className="modal-title-block">
              <h1>Pickup Location</h1>
              <h5>Select your pickup location</h5>
        
        <Form style={{margin: "25px 8px -15px 8px"}}>
              <FormGroup>
                <Input
                  type="text"
                  name="search_outlet"
                  id="search_outlet"
                  placeholder="Search Outlet"
                  value={this.state.filteroutlet}
  onChange={this.filterOutlet}
                  
                />
              </FormGroup>
            </Form>
            </div>
          </div>
          <ModalBody>
            <Alert color="danger" isOpen={this.state.CHOOSE_OUTLET}>
              {CHOOSE_OUTLET}
            </Alert>
            <Scrollbars autoHeight
              autoHeightMin={0}
              autoHeightMax={300}>
              <Form>
                <ListGroup className="pickup-location-list">
                  {this.showDeliveryOutlets(outletResult)}
                </ListGroup>
              </Form>
            </Scrollbars>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.deliveryStepTwo}>
              Continue
            </Button>
          </ModalFooter>
        </Modal>

        {/* Delivery second modal */}
        <Modal
          size="lg"
          isOpen={this.state.stepTwo}
          className="callcentre-modal pickup-confirmation-modal"
        >
          <div className="modal-header">
            <div className="modal-top-icon">
              <img src={TickIcon} alt={"Shopping Bag"} />
              {closeAllBtn}
            </div>
            <div className="modal-title-block">
              <h1>Awesome</h1>
              <h5>You can pickup your food </h5>
            </div>
          </div>
          <ModalBody>
            <p>Order handled by</p>
            <h4>{this.state.pickupOutletName}</h4>
            <h5>
              {this.state.pickupOutletAddress}, SINGAPORE{" "}
              {this.state.pickupOutletPostalCode}
            </h5>
          </ModalBody>
          <ModalFooter>
            <Spinner
              color="secondary"
              style={{
                display: this.state.checkpostalCode === true ? "block" : "none"
              }}
            />
            <Button
              color="secondary"
              onClick={this.deliveryStepOne}
              style={{
                display: this.state.checkpostalCode === true ? "none" : "block"
              }}
            >
              Go Back
            </Button>
            <Button
              color="primary"
              onClick={this.deliveryStepFour}
              style={{
                display: this.state.checkpostalCode === true ? "none" : "block"
              }}
            >
              Continue
            </Button>
          </ModalFooter>
        </Modal>

        {/* third modal */}

        {/* fourth  modal */}
        <Modal
          size="lg"
          isOpen={this.state.stepFour}
          className="callcentre-modal deliverytime-modal"
        >
          <div className="modal-header datepicker-header">
            <div className="modal-top-icon">
              <img src={ClockIcon} alt={"Shopping Bag"}  className="datepicker-icon" />
              {closeAllBtn}
            </div>
            <div className="modal-title-block">
              <h1 className="datepicker-h1">Pickup Time</h1>
              <h5 className="datepicker-h5">Earliest pickup Date & Time </h5>

            </div>
          </div>
          <ModalBody>
            <Form>
              <FormGroup>
                <Row>
                  <Col xs={6}>
                    <DatePicker
                      className="form-control"
                      selected={this.state.startDate}
                      minDate={this.state.minDate}
                      onChange={this.handleDateChange}
                      dateFormat="dd/MM/yyyy"
            maxDate={addDays(new Date(), 90)}
                    />
                  </Col>
                  <Col xs={6}>
                    {CEDELE_APP_ID === getCookies("app_id") && (
                      <DatePicker
                        className="form-control tes"
                        dropdownMode="select"
                        selected={this.state.startTime}
                        onChange={this.handleTimeChange}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                        timeCaption="Time"
                        minTime={setHours(setMinutes(new Date(), 0), 6)}
                        maxTime={setHours(setMinutes(new Date(), 0), 20)}
                        tabIndex={1}
          
                      />
                    )}

                    {SPRING_APP_ID === getCookies("app_id") && (
                      <DatePicker
                        className="form-control"
                        selected={this.state.startTime}
                        onChange={this.handleTimeChange}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        dateFormat="h:mm aa"
                        timeCaption="Time"
                        includeTimes={excludeTime}
                      />
                    )}

                    {(PIE_APP_ID === getCookies("app_id")) && (
                      <DatePicker
                        className="form-control"
                        selected={this.state.startTime}
                        onChange={this.handleTimeChange}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={60}
                        dateFormat="h:mm aa"
                        timeCaption="Time"
                      />
                    )}


                    {(CEDELE_APP_ID !== getCookies("app_id") && SPRING_APP_ID !== getCookies("app_id")) && PIE_APP_ID !== getCookies("app_id") &&(
                      <DatePicker
                        className="form-control"
                        selected={this.state.startTime}
                        onChange={this.handleTimeChange}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                        timeCaption="Time"
                      />
                    )}
                  </Col>
                </Row>
              </FormGroup>
            </Form>
            <p>Click time section to pick advanced order date & time</p>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.deliveryStepTwo}>
              Go Back
            </Button>
            <Button color="primary" onClick={this.setDeliveryData}>
              View Menu
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProp = state => ({
  outletsData: state.outlets.returnData,
  setStatus: state.outlets.isStatus,
  zoneDetails: state.zone.returnData,
  zoneError: state.zone.isError,
  zoneStatus: state.zone.isStatus
});
export default connect(
  mapStateToProp,
  { fetchalloutlets, findoutlet }
)(Pickupflow);
