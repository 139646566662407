import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { getCartItems } from '../../stores/cart'
import { getSetings } from '../../stores/settings'
import { zonedetails } from '../../stores/delivery/findzonedetails'
import CustomerIcon from '../../assets/img/icons/customer.png'
import ShoppingCartIcon from '../../assets/img/icons/shopping-cart.png'
import ProductImage from '../../assets/img/product-popup-image.jpg'
import { Link } from 'react-router-dom'
import { LOGO_URL } from '../../config/config'
import { history } from '../../store'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { fetchalloutlets } from '../../stores/delivery/deliveryoutlets'
import { findoutlet } from '../../stores/delivery/findzone'
import cookie from 'react-cookies'
import { returnValue, adminId } from '../../config/supporters'
import { getStripeRef } from '../../config/stripeconfig'
import Customerdashboard from './customerdashboard'
import { CEDELE_APP_ID, SPRING_APP_ID } from '../../config/apps'
import { getPromoCount } from '../../stores/user'
import StripeCheckout from 'react-stripe-checkout'
import Customerpopup from '../layout/customerpopup'

import {
  getCookies,
  addressFormat,
  getReferenceID,
  customerId,
  appId,
  hostUrl,
  stripslashes,
  getLogo,
  deleteOrderCookie,
  getGstValue,
  showInfo,
  confirmAlert,
  getAvailabilityName
} from '../../config/supporters'
import {
  Navbar,
  Nav,
  NavItem,
  Modal,
  ModalFooter,
  Input,
  ModalBody,
  CustomInput,
  FormGroup,
  Button,
  InputGroupAddon,
  InputGroup,
  NavLink,
  Jumbotron,
  Progress,
  Badge,
  Container,
  Row,
  Col,
  Spinner,
  Alert,
  Form,
  ListGroup,
  ListGroupItem,
  Label
} from 'reactstrap'
import {
  DELIVERY_ID,
  PICKUP_ID,
  DINEIN_ID,
  SUCCESS_ALERT,
  FAILURE_ALERT,
  HOST_URL,
  APP_ID,
  stripeCurrency,
  MARS_BASE_URL
} from '../../config/constans'
import { addDays, setHours, setMinutes } from 'date-fns'

import ClockIcon from '../../assets/img/icons/clock.png'
import {
  CHOOSE_OUTLET,
  CHOOSE_POSTALCODE,
  UNIT_NUMBER_ERROR
} from '../../config/messages'
import { Scrollbars } from 'react-custom-scrollbars'
var _ = require('lodash')
var striptags = require('striptags')
var qs = require('qs')
var parse = require('html-react-parser')
const $ = window.$
var moment = require('moment')

class Header extends Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.input = React.createRef()

    /* To change the date after 2 PM */
    var CurrentDate = this.calcTime('Singapore', '+8')
    var currentHrs = CurrentDate.getHours()
    if (currentHrs >= 16) {
      cookie.save('minDay', 3, { path: '/' })
    } else {
      cookie.save('minDay', 2, { path: '/' })
    }
    const appid = getCookies('app_id')
    let dateStart = addDays(new Date(), 0)
    let timeStart = setHours(setMinutes(new Date(), 0), currentHrs)
    if (CEDELE_APP_ID === appid) {
      dateStart = addDays(
        new Date(),
        cookie.load('minDay') !== '' ? cookie.load('minDay') : 0
      )
      timeStart = setHours(setMinutes(new Date(), 15), 9)
    } else if (SPRING_APP_ID === appid) {
      dateStart =
        currentHrs >= 14 ? addDays(new Date(), 0) : addDays(new Date(), 0)
      timeStart = setHours(setMinutes(new Date(), 0), 11)
    }

    //alert(promocode);

    this.state = {
      isOpen: false,
      modal: false,
      customerModal: false,
      orderSubTotal: '',
      orderGrandTotal: '',
      orderDeliveryCharge: 0,
      addOrderDeliveryCharge: 0,
      orderGstPercentage: 0,
      orderGstAmount: 0,
      alertType: '',
      cartAlertMsg: '',
      orderAlert: false,
      paymentMode: '',
      promotionApplied: 'No',
      promotionAmount: 0,
      promoIsDelivery: 'No',
      promotionType: '',
      promocode: '',
      appliedPromocode: '',
      customerPoints: 0,
      confirmationAlert: false,
      editdateAlert: false,
      editoutletAlert: false,
      pickupoutletalert: false,
      cartItemPrice: 0,
      priceObject: {},
      startDate1: new Date(),
      startTime1: new Date(),
      TimeRequest: getCookies('orderDeliveryTimeRequest'),
      outletDetails: [],
      rSelected: '',
      pSelected: '',
      changedDelivery: 0,
      changedAdditionDelivery: 0,

      CHOOSE_OUTLET: false,
      CHOOSE_POSTALCODE: false,
      UNIT_NUMBER_ERROR: false,
      dyZoneError: '',
      pickupOutletName: '',
      pickupOutletAddress: '',
      pickupOutletPostalCode: '',
      changeDate: false,
      stripeMode: false,
      changeDateStart: new Date(
        moment(getCookies('orderDeliveryDate'), 'DD/MM/YYYY')
      ),
      changeTimeStart: new Date(
        moment(cookie.load('orderDeliveryTime'), 'HH:mm')
      ),

      stripeSubTotal: '',
      stripeFinalTotal: '',
      stripeDeliveryFee: '',
      stripeAdditionaldelFee: '',
      stripeGstPercentage: '',
      stripeGstDisplay: '',
      stripeMinCart: '',
      stripeDineInInitAmount: '',
      orderValidFail: 0,
      addCustomer: false,
      billing: false,
      billing_address: '',
      billing_postalcode: '',
      billing_unitOne: '',
      billing_unitTwo: '',
      orderRemarks: '',
      startDate: dateStart,
      minDate: dateStart,
      startTime: timeStart,
      payment_ref_id: ''
    }

    this.checkoutToggle = this.checkoutToggle.bind(this)
    this.submitOrder = this.submitOrder.bind(this)
    this.onChangePayment = this.onChangePayment.bind(this)
    this.changeItemPrice = this.changeItemPrice.bind(this)
    this.changedelPrice = this.changedelPrice.bind(this)
    this.changeadddelPrice = this.changeadddelPrice.bind(this)
    this.draftOrder = this.draftOrder.bind(this)
    this.handleDateChange1 = this.handleDateChange1.bind(this)
    this.handleTimeChange = this.handleTimeChange.bind(this)
    this.customerModalToggle = this.customerModalToggle.bind(this)
    this.changeDateAction = this.changeDateAction.bind(this)
    this.handleChangeDate = this.handleChangeDate.bind(this)
    this.handleChangeTime = this.handleChangeTime.bind(this)
    this.updateDateValue = this.updateDateValue.bind(this)
    this.applyPoints = this.applyPoints.bind(this)
    this.showCustomerModal = this.showCustomerModal.bind(this)
    this.changeBillingAddress = this.changeBillingAddress.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleChangePostalCode = this.handleChangePostalCode.bind(this)
    this.handleChangeDescription = this.handleChangeDescription.bind(this)

    /* get customer points */
    const hostURL = hostUrl()
    const app = appId()
    axios
      .get(
        hostURL +
          'api/customer/customerdetail?app_id=' +
          app +
          '&refrence=' +
          getCookies('customerId')
      )
      .then(res => {
        if (res.data.status === 'ok') {
          this.setState({
            customerPoints: parseInt(res.data.result_set.customer_reward_point)
          })
        }
      })

    cookie.remove('deliveryfee')
    cookie.remove('additionaldelFee')
  }

  /* Convert Timezone */
  calcTime(city, offset) {
    var d = new Date()
    var utc = d.getTime() + d.getTimezoneOffset() * 60000
    var nd = new Date(utc + 3600000 * offset)
    return nd
  }
  changeBillingAddress() {
    this.setState({ billing: !this.state.billing })
  }

  handleChangeDescription(e) {
    // console.log(e.target.value);
    this.setState({ orderRemarks: e.target.value })
    cookie.save('orderRemarks', e.target.value, { path: '/' })
  }

  handleChangeDate(datevalue) {
    this.setState({ changeDateStart: datevalue })
  }
  handleChange(e) {
    //console.log(e.target.value,e.target.name);
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleChangePostalCode(e) {
    var postlCode = e.target.value
    this.setState({ billing_postalcode: e.target.value })
    if (postlCode.length > 5) {
      const host = hostUrl()
      const APPID = appId()

      const API_URL =
        host +
        'api/settings/get_address?app_id=' +
        APPID +
        '&zip_code=' +
        postlCode

      axios.get(API_URL).then(res => {
        if (res.data.status === 'ok') {
          this.setState({
            billing_address:
              res.data.result_set.zip_buno + ' ' + res.data.result_set.zip_sname
          })
        }
      })
    }
  }

  showCustomerModal() {
    const cartItems = this.props.cartItems

    if (
      _.isEmpty(cartItems) === true ||
      _.isEmpty(cartItems.cart_details) === true ||
      _.isEmpty(cartItems.cart_items) === true
    ) {
      showInfo('Please add products')
      return false
    }

    var customerID = customerId()
    if (customerID === '' || typeof customerID === 'undefined') {
      this.setState({ addCustomer: true })
      setTimeout(
        function() {
          this.setState({ addCustomer: false })
        }.bind(this),
        1000
      )
    }
  }

  handleChangeTime(timevalue) {
    //   console.log("handleChangeTime");
    var updatedTime = new Date(timevalue)
    this.setState({
      changeTimeStart: updatedTime
    })
  }

  updateDateValue() {
    //console.log("updateDateValue");
    var dateval = new Date(this.state.changeDateStart)
    dateval = moment(dateval).format('DD/MM/YYYY')
    var timeval = new Date(this.state.changeTimeStart)
    var deliveryTimeOrder = moment(timeval).format('HH:mm')
    var timeval1 = moment(timeval).format('HH:mm')
    var timeval2 = moment(timeval).format('hh:mm A')

    /* No pickups in CBD outlets (From Dec 24 – 3pm to Dec 25 -  midnight) */

    const appid = getCookies('app_id')
    if (CEDELE_APP_ID === appid) {
      var open_time = Date.parse('2019-12-24T14:59')
      var close_time = Date.parse('2019-12-25T23:59')
      const deliveryDate = new Date(moment(dateval, 'DD/MM/YYYY'))
      const convertedDate = moment(deliveryDate).format('YYYY-MM-DD')
      const deliveryTime = new Date(moment(timeval1, 'HH:mm'))
      const convertedTime = moment(deliveryTime).format('HH:mm')
      var check_val = Date.parse(convertedDate + 'T' + convertedTime)
      var shutdown_outlet = [
        '232',
        '236',
        '234',
        '238',
        '174',
        '175',
        '178',
        '192',
        '237',
        '182',
        '230'
      ]
      var isInArray = shutdown_outlet.includes(getCookies('orderOutletId'))
      if (
        check_val > open_time &&
        check_val < close_time &&
        isInArray === true
      ) {
        alert(
          'Sorry, no pickups in this outlet from Dec 24 – 3pm to Dec 25 -  midnight'
        )
        return false
      }
    }

    cookie.save('orderDeliveryDate', dateval, { path: '/' })
    cookie.save('orderDeliveryTime', timeval1, { path: '/' })
    cookie.save('orderDeliveryTimeShow', timeval2, { path: '/' })
    cookie.save('orderDeliveryTimeRequest', deliveryTimeOrder, { path: '/' })
    this.setState({ changeDate: false })
  }

  onChangePayment(value) {
    this.setState({
      paymentMode: value,
      stripeMode: value === 3 ? true : false
    })
  }

  changeDateAction() {
    this.setState({ changeDate: !this.state.changeDate })

    // console.log(this.state.changeDate === false);
    this.setState({
      changeDateStart: new Date(
        moment(getCookies('orderDeliveryDate'), 'DD/MM/YYYY')
      ),
      changeTimeStart: new Date(
        moment(cookie.load('orderDeliveryTime'), 'HH:mm')
      )
    })
  }

  showDeliveryOutlets(outletResult) {
    return outletResult.map((out, index) => (
      <React.Fragment key={index}>
        {out.oa_availability_id === DELIVERY_ID && (
          <ListGroupItem>
            <InputGroup>
              <Input
                onClick={() => this.onRadioBtnClick(out.oa_outlet_id)}
                type="radio"
                id={'radio_' + out.oa_outlet_id}
                name="outletsZone"
                value={out.oa_outlet_id}
              />
              <Label htmlFor={'radio_' + out.oa_outlet_id}>
                <span className="outlet-name">
                  {striptags(out.outlet_name)}
                </span>
                <span className="outlet-address">
                  {striptags(out.outlet_address_line1) + ' '}
                  {striptags(out.outlet_postal_code)}
                </span>
              </Label>
            </InputGroup>
          </ListGroupItem>
        )}
      </React.Fragment>
    ))
  }

  showPickupOutlets(outletResult) {
    return outletResult.map((out, index) => (
      <React.Fragment key={index}>
        <ListGroupItem>
          <InputGroup>
            <Input
              onClick={() =>
                this.onRadioPickClick(
                  out.oa_outlet_id,
                  striptags(out.outlet_name),
                  striptags(out.outlet_address_line1),
                  striptags(out.outlet_postal_code)
                )
              }
              type="radio"
              id={'radio_' + out.oa_outlet_id}
              name="outletsZone"
              value={out.oa_outlet_id}
            />
            <Label htmlFor={'radio_' + out.oa_outlet_id}>
              <span className="outlet-name">{striptags(out.outlet_name)}</span>
              <span className="outlet-address">
                {striptags(out.outlet_address_line1) + ' '}
                {striptags(out.outlet_postal_code)}
              </span>
            </Label>
          </InputGroup>
        </ListGroupItem>
      </React.Fragment>
    ))
  }

  onRadioPickClick(
    pSelected,
    pickupOutletName,
    pickupOutletAddress,
    pickupOutletPostalCode
  ) {
    this.setState({ CHOOSE_OUTLET: false })
    // console.log(rSelected);   pickupOutletName : "',

    this.setState({
      pSelected: pSelected,
      pickupOutletName: pickupOutletName,
      pickupOutletAddress: pickupOutletAddress,
      pickupOutletPostalCode: pickupOutletPostalCode
    })
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }
  checkoutToggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
      customerModal: false
    }))
  }
  customerModalToggle() {
    this.setState(prevState => ({
      customerModal: !prevState.customerModal,
      modal: false
    }))
  }

  componentWillMount() {
    this.props.getCartItems()
    this.props.getSetings()
    this.props.getPromoCount()

    if (getCookies('orderAvailability') === DELIVERY_ID) {
      this.props.zonedetails()
    }

    this.props.fetchalloutlets()
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.cartAction !== this.props.cartAction &&
      nextProps.cartAction === true
    ) {
      this.props.getCartItems()
      this.props.getPromoCount()
    }

    if (
      nextProps.cartshow !== this.state.modal &&
      typeof nextProps.cartshow !== 'undefined'
    ) {
      //console.log(nextProps.cartshow, this.state.modal );
      this.setState({ modal: nextProps.cartshow })
    }
  }

  changeItemPrice(cartItemId, event) {
    var priceObj = this.state.priceObject
    priceObj[cartItemId] = event.target.value
    this.setState({ priceObj: priceObj })
    /* let cartItemPrice = { [cartItemId]: event.target.value,
    };     postObject["reference_id"] = refId;
    this.setState(prevState => ({ priceObject: [...prevState.priceObject, cartItemPrice] })); */
  }
  changedelPrice(del, event) {
    //console.log(event.target.value);

    //let delfee = (event.target.value == '') ? 0 : event.target.value;

    cookie.save('deliveryfee', event.target.value)
    this.setState({ changedDelivery: event.target.value })
  }

  changeadddelPrice(add, event) {
    console.log(event.target.value)
    cookie.save('additionaldelFee', event.target.value)
    this.setState({ changedAdditionDelivery: event.target.value })
  }
  getDelPrice(actPrice) {
    var changedDelivery = this.state.changedDelivery

    return changedDelivery > 0 ? changedDelivery : actPrice
  }

  getAddDelPrice(actPrice) {
    var changedAdditionDelivery = this.state.changedAdditionDelivery

    return changedAdditionDelivery > 0 ? changedAdditionDelivery : actPrice
  }

  getPrice(actPrice, id) {
    var priceObj = this.state.priceObject

    return typeof priceObj[id] !== 'undefined' ? priceObj[id] : actPrice
  }

  updateCartprice() {
    const host = hostUrl()
    const app = appId()
    let itemprice = this.state.priceObj

    var postObject = {}
    postObject = {
      app_id: app,
      cartitemsprice: itemprice
    }

    if (typeof itemprice !== 'undefined') {
      $('#cartLoading').show()
      axios
        .post(host + '/api/cart/updatecartprice', qs.stringify(postObject))
        .then(res => {
          $('#cartLoading').hide()
          if (res.data.status === 'ok') {
            this.props.getCartItems()
            this.setState({ priceObject: {} })
          } else if (res.data.status === 'error') {
            alert(res.data.message)
          }
        })
    }
  }

  incQty(cartItemId, qty, productId, type) {
    if (type === 'add') {
      var cartQty = Number(qty) + 1
    } else {
      if (Number(qty) === 1) {
        return false
      } else {
        cartQty = Number(qty) - 1
      }
    }
    const customId = customerId()
    const refId = getReferenceID()
    const host = hostUrl()
    const app = appId()
    var postObject = {}
    postObject = {
      app_id: app,
      cart_item_id: cartItemId,
      product_id: productId,
      product_qty: cartQty
    }
    if (typeof customId === 'undefined' || customId === null) {
      postObject['reference_id'] = refId
    } else {
      postObject['customer_id'] = customId
    }

    $('#cartLoading').show()
    axios
      .post(host + '/api/cart/update', qs.stringify(postObject))
      .then(res => {
        $('#cartLoading').hide()
        if (res.data.status === 'ok') {
          this.props.getCartItems()
          this.resetPromo()
        } else if (res.data.status === 'error') {
          alert(res.data.message)
        }
      })
  }

  deleteCartItem(cartItemId) {
    const customId = customerId()
    const refId = getReferenceID()
    const host = hostUrl()
    const app = appId()
    var postObject = {}
    postObject = { app_id: app, cart_item_id: cartItemId }

    if (typeof customId === 'undefined') {
      postObject['reference_id'] = refId
    } else {
      postObject['customer_id'] = customId
    }
    axios.post(host + 'api/cart/delete', qs.stringify(postObject)).then(res => {
      if (res.data.status === 'ok') {
        if (res.data.contents) {
          this.props.getCartItems()
        } else {
          deleteOrderCookie()
          history.push('/dashboard')
        }
      } else if (res.data.status === 'error') {
        alert(res.data.message)
      }
    })
  }

  destroyCart(deletevar) {
    const customId = getCookies('customerId')
    const refId = getReferenceID()
    const host = hostUrl()
    const app = appId()
    var postObject = {}
    postObject = { app_id: app }

    if (typeof customId === 'undefined') {
      postObject['reference_id'] = refId
    } else {
      postObject['customer_id'] = customId
    }

    axios
      .post(host + 'api/cart/destroy', qs.stringify(postObject))
      .then(res => {
        //cookie.remove('promotion_code');
        if (res.data.status === 'ok') {
          deleteOrderCookie()
          this.props.getCartItems()
          this.setState({ modal: false })
          if (deletevar === 'Yes') {
            history.push('dashboard')
          }
        } else if (res.data.status === 'error') {
          /*  alert(res.data.message); */
        }
      })
  }

  showAlert(type, msg) {
    this.setState({
      orderAlert: true,
      cartAlertMsg: msg,
      alertType: type
    })

    setTimeout(
      function() {
        this.setState({ orderAlert: false })
      }.bind(this),
      3000
    )
  }

  closeAlert() {
    this.setState(prevState => ({
      confirmationAlert: !prevState.confirmationAlert
    }))
  }

  proceedAlert() {
    this.destroyCart('Yes')
  }

  editdateAlert() {
    this.setState(prevState => ({
      editdateAlert: !prevState.editdateAlert
    }))

    /*var order_date = getCookies("orderDeliveryDate");
	  var order_time = getCookies("orderDeliveryTime");
	  var order_timerequest = getCookies("orderDeliveryTimeRequest");
	  
	  const userbirthdate = new Date(order_date);
      const userdate = moment(userbirthdate).format("DD/MM/YYYY");
        
	  console.log(order_date);
	  //return false;
	  this.setState({
		 startDate1: userdate 
	  }, function () {console.log(this.state.startDate1);});*/
  }

  editoutletAlert() {
    this.setState(prevState => ({
      editoutletAlert: !prevState.editoutletAlert
    }))
  }

  pickupoutletAlert() {
    this.setState(prevState => ({
      pickupoutletAlert: !prevState.pickupoutletAlert
    }))
  }

  handleDateChange1(date) {
    this.setState({
      startDate1: date
    })
  }

  handleTimeChange(date) {
    var dateVal = new Date(date)
    this.setState({
      startTime1: dateVal
    })
  }

  setDatetime() {
    const stateDate = this.state.startDate1
    const startTime = this.state.startTime1

    var deliveryDate = moment(stateDate).format('DD/MM/YYYY')
    var deliveryTime = moment(startTime).format('hh:mm A')
    var deliveryTimeOrder = moment(startTime).format('HH:mm')

    cookie.save('orderDeliveryDate', deliveryDate, { path: '/' })
    cookie.save('orderDeliveryTime', deliveryTime, { path: '/' })
    cookie.save('orderDeliveryTimeRequest', deliveryTimeOrder, { path: '/' })

    this.editdateAlert()
  }

  onRadioBtnClick(rSelected) {
    this.setState({ CHOOSE_OUTLET: false })
    // console.log(rSelected);
    this.setState({ rSelected: rSelected })
  }

  changeoutlet() {
    const outletId = this.state.rSelected
    if (typeof outletId === 'undefined' || outletId === '') {
      this.setState({ CHOOSE_OUTLET: true })
      return false
    }

    const postalCode = getCookies('orderPostalCode')
    cookie.save('orderOutletId', outletId, { path: '/' })

    const appId = returnValue(cookie.load(APP_ID))
    const API_URL =
      cookie.load(HOST_URL) +
      'apiv2/outlets/findOutletZone/?app_id=' +
      appId +
      '&availability_id=' +
      DELIVERY_ID +
      '&postal_code=' +
      postalCode +
      '&outlet_id=' +
      outletId +
      '&skip_timing=Yes'
    this.setState({ checkpostalCode: true })
    axios.get(API_URL).then(res => {
      this.setState({ checkpostalCode: false })
      if (res.data.status === 'ok') {
        const postalcodeInfo = res.data.result_set.postal_code_information
        this.setState(
          {
            outletDetails: res.data.result_set,
            stepThree: !this.state.stepThree,
            stepOne: false,
            stepTwo: false,
            stepFour: false,
            CHOOSE_POSTALCODE: false,
            ordertakenOutlet: res.data.result_set.outlet_name,
            ordertakenOutletAddress:
              postalcodeInfo.zip_buno +
              ' ' +
              postalcodeInfo.zip_sname +
              ', Singapore ' +
              postalcodeInfo.zip_code
          },
          function() {
            this.setoutletinfo()
          }.bind(this)
        )

        this.setState(prevState => ({
          editoutletAlert: !prevState.editoutletAlert
        }))
      } else {
        this.setState({
          CHOOSE_POSTALCODE: true,
          dyZoneError: res.data.message
        })
      }
    })
  }

  setoutletinfo() {
    const outletDetails = this.state.outletDetails

    cookie.save('orderZoneID', outletDetails.zone_id, { path: '/' })
    cookie.save('orderOutletName', outletDetails.outlet_name, { path: '/' })
    cookie.save(
      'orderAddressOne',
      outletDetails.postal_code_information.zip_buno,
      { path: '/' }
    )
    cookie.save(
      'orderAddressTwo',
      outletDetails.postal_code_information.zip_sname,
      { path: '/' }
    )
    cookie.save('orderAvailability', DELIVERY_ID, { path: '/' })
  }

  changepickupoutlet() {
    cookie.save('orderOutletId', this.state.pSelected, { path: '/' })
    cookie.save('orderPostalCode', this.state.pickupOutletPostalCode, {
      path: '/'
    })
    cookie.save('orderOutletName', this.state.pickupOutletName, { path: '/' })
    cookie.save('orderAddressOne', this.state.pickupOutletAddress, {
      path: '/'
    })
    //cookie.save("orderAddressTwo", outletDetails.postal_code_information.zip_sname,{ path: '/' });

    this.pickupoutletAlert()
  }
  /*
      subTotal,
                              finalTotal,
                              this.getDelPrice(deliveryFee),
                              this.getAddDelPrice(additionaldelFee),
                              additionaldelFee,
                              gstPercentage,
                              gstDisplay,
                              minCart
  */

  orderValidationFail() {
    this.showAlert(
      'Something went wrong. Your order details or personal information is wrong. Please check and try again.'
    )
    history.push('/')
  }
  submitOrder(
    subTotal,
    finalTotal,
    deliveryFee,
    additionaldelFee,
    gstPercentage,
    gstDisplay,
    minCart,
    stripeDineInInitAmount,
    paymentId,
    validation = 'No',
    captureID,
    payGetWayType = ''
  ) {
    const custome_id = customerId()
    const appid = appId()
    const availability = getCookies('orderAvailability')
    const hostURL = hostUrl()
    const orderSubTotal = subTotal
    const orderFinalTotal = finalTotal
    const orderDeliveryFee = deliveryFee
    const orderAdditionaldelFee = additionaldelFee
    const orderGstPercentage = gstPercentage
    const orderGstDisplay = gstDisplay
    const orderMinCart = minCart
    const paymentMode = this.state.paymentMode
    let dineInInitAmount = ''

    if (custome_id == '' || custome_id == undefined) {
      // history.push("dashboard");
      //return false;
      this.showCustomerModal()
      return false
      // this.showAlert(FAILURE_ALERT, 'Please enter cutomer details')
      // return false
    }

    const cartItems = this.props.cartItems
    /* console.log(cartItems)
    return false */

    if (
      _.isEmpty(cartItems) === true ||
      _.isEmpty(cartItems.cart_details) === true ||
      _.isEmpty(cartItems.cart_items) === true
    ) {
      history.push('dashboard')
      return false
    }

    if (availability === DELIVERY_ID) {
      if (orderSubTotal < orderMinCart) {
        this.showAlert(
          FAILURE_ALERT,
          'You must have an order with a minimum of $' +
            orderMinCart +
            ' to place your order, your current order total is $' +
            orderSubTotal +
            '.'
        )
        return false
      }
    }

    var paymentArray = [1, 3, 6, 7]
    if (CEDELE_APP_ID === getCookies('app_id')) {
      paymentArray = [1, 3, 6, 7, 8, 9, 10, 11]
    }

    /* if (
      paymentMode !== 11 &&
      paymentMode !== 10 &&
      paymentMode !== 9 &&
      paymentMode !== 8 &&
      paymentMode !== 1 &&
      paymentMode !== 3 &&
      paymentMode !== 6 &&
      paymentMode !== 7
    )  */
    if (!paymentArray.includes(paymentMode)) {
      this.showAlert(FAILURE_ALERT, 'Please choose payment method.')
      return false
    }

    // return false;
    var modifierdata = []
    var products = []
    var row = cartItems.cart_items.map(
      function(item, i) {
        modifierdata = []
        var comboData = []
        var ComboLen = item.set_menu_component.length
        var modifierLen = item.modifiers.length
        if (item.cart_item_type === 'Modifier' && modifierLen > 0) {
          for (var l = 0; l < modifierLen; l++) {
            var modifierVal = []
            modifierVal.push({
              modifier_value_id:
                item.modifiers[l].modifiers_values[0].cart_modifier_id,
              modifier_value_qty:
                item.modifiers[l].modifiers_values[0].cart_modifier_qty,
              modifier_value_name:
                item.modifiers[l].modifiers_values[0].cart_modifier_name,
              modifier_value_price:
                item.modifiers[l].modifiers_values[0].cart_modifier_price
            })
            modifierdata.push({
              modifier_id: item.modifiers[l].cart_modifier_id,
              modifier_name: item.modifiers[l].cart_modifier_name,
              modifiers_values: modifierVal
            })
          }
        } else if (item.cart_item_type === 'Component' && ComboLen > 0) {
          /* combo product setup */

          for (var m = 0; m < ComboLen; m++) {
            var combomodifierdata = []

            /* build  modifier array */
            var comboModifier =
              item.set_menu_component[m].product_details[0].modifiers

            var combomodifierdata = []
            if (comboModifier.length > 0) {
              /* new version product setup */

              for (var p = 0; p < comboModifier.length; p++) {
                // alert(comboModifier[p].modifiers_values[0].cart_modifier_id); return false;
                var comboModifierVal = []
                comboModifierVal.push({
                  modifier_value_id:
                    comboModifier[p].modifiers_values[0].cart_modifier_id,
                  modifier_value_qty:
                    comboModifier[p].modifiers_values[0].cart_modifier_qty,
                  modifier_value_name:
                    comboModifier[p].modifiers_values[0].cart_modifier_name,
                  modifier_value_price:
                    comboModifier[p].modifiers_values[0].cart_modifier_price
                })
                combomodifierdata.push({
                  modifier_id: comboModifier[p].cart_modifier_id,
                  modifier_name: comboModifier[p].cart_modifier_name,
                  modifiers_values: comboModifierVal
                })
              }
            }

            /*	comborVal.push( { /* commented for new version setup  /
                        product_id:item.set_menu_component[m].product_details[0].cart_menu_component_product_id ,
                        product_name:item.set_menu_component[m].product_details[0].cart_menu_component_product_name,
                        product_sku:item.set_menu_component[m].product_details[0].cart_menu_component_product_sku,
                        modifiers:combomodifierdata});
                        comboData.push( {
                                    menu_component_id:item.set_menu_component[m].menu_component_id,
                                    menu_component_name:item.set_menu_component[m].menu_component_name,
                                    product_details:comborVal}) */

            var comborVal = []
            var comboProductDetailslen =
              item.set_menu_component[m].product_details.length
            if (comboProductDetailslen > 0) {
              for (
                var j = 0, lengthComboPro = comboProductDetailslen;
                j < lengthComboPro;
                j++
              ) {
                comborVal.push({
                  product_id:
                    item.set_menu_component[m].product_details[j]
                      .cart_menu_component_product_id,
                  product_name:
                    item.set_menu_component[m].product_details[j]
                      .cart_menu_component_product_name,
                  product_sku:
                    item.set_menu_component[m].product_details[j]
                      .cart_menu_component_product_sku,
                  product_qty:
                    item.set_menu_component[m].product_details[j]
                      .cart_menu_component_product_qty,
                  product_price:
                    item.set_menu_component[m].product_details[j]
                      .cart_menu_component_product_price,
                  modifiers: combomodifierdata
                })
              }
            }

            comboData.push({
              menu_component_id: item.set_menu_component[m].menu_component_id,
              menu_component_name:
                item.set_menu_component[m].menu_component_name,
              product_details: comborVal
            })
          }
        }

        products.push({
          product_name: item.cart_item_product_name,
          product_unit_price: item.cart_item_unit_price,
          product_total_amount: item.cart_item_total_price,
          product_sku: item.cart_item_product_sku,
          product_image: item.cart_item_product_image,
          product_id: item.cart_item_product_id,
          product_qty: item.cart_item_qty,
          condiments: '',
          modifiers: modifierdata,
          bakers_modifiers: '',
          menu_set_components: comboData,
          baby_pack: '',
          product_special_notes: item.cart_item_special_notes
        })

        return products
      }.bind(this)
    )
    /* console.log(products)
    return false */
    var orderDate = ''
    var cookieDate = getCookies('orderDeliveryDate')
    var splitedDate = cookieDate.split('/')

    var selDate = splitedDate[0]
    var selMonth = splitedDate[1]
    var selYear = splitedDate[2]
    var formatedDate = selYear + '-' + selMonth + '-' + selDate
    var orderTime =
      typeof getCookies('orderDeliveryTime') === 'undefined' ||
      getCookies('orderDeliveryTime') === ''
        ? ''
        : getCookies('orderDeliveryTime')
    orderDate = formatedDate + ' ' + orderTime

    const orderedit_avail = getCookies('order_edit_avail')
    const orderedit_local = getCookies('order_edit_localno')
    const orderedit_primid = getCookies('order_edit_primid')
    var stripeReference = getStripeRef(appId)
    var postObject = {}
    postObject = {
      /* cart details */
      app_id: appid,
      availability_id: availability,
      sub_total: orderSubTotal,
      grand_total: orderFinalTotal,
      outlet_id: getCookies('orderOutletId'),
      table_number: getCookies('orderTableNo'),
      order_status: 1,
      order_source: 'CallCenter',
      call_center_admin_id: getCookies('callAdminId'),
      call_center_admin_outlet_id: getCookies('call_permission_outlet'),
      order_date: orderDate,
      order_pickup_time_slot_from: '',
      order_pickup_time_slot_to: '',
      order_is_timeslot: '',
      order_remarks: $('#productDescription').val()
        ? $('#productDescription').val()
        : '',
      products: JSON.stringify(products),

      /* customer  Details */
      customer_id: custome_id,
      customer_address_id: '',
      customer_fname: getCookies('orderCustomerfName'),
      customer_lname: getCookies('orderCustomerlName'),
      customer_mobile_no: getCookies('orderCustomerMobile'),
      customer_email: getCookies('orderCustomerEmail'),
      customer_address_line1: getCookies('orderAddressOne'),
      customer_address_line2: getCookies('orderAddressTwo'),
      customer_postal_code: getCookies('orderPostalCode'),
      customer_unit_no1: getCookies('orderUnitOne'),
      customer_unit_no2: getCookies('orderUnitTwo'),
      /*  "customer_tower_number": (typeof cookie.load('towerNo') === "undefined" ? '' : cookie.load('towerNo')),*/

      /* Payment */
      payment_mode: paymentMode /*paymentMode, */,
      payment_reference: stripeReference,
      stripe_envir:
        parseInt(this.props.settings.client_stripe_mode) === 1 ? 'live' : 'dev',
      payment_type: paymentMode === 3 ? 'Stripe' : '',
      order_capture_token: typeof captureID !== 'undefined' ? captureID : '',
      order_payment_getway_type: payGetWayType,
      order_payment_getway_status: 'Yes',
      order_local_payment_reference:
        paymentMode === 3 ? this.state.payment_ref_id : '',

      /* additional paras */
      delivery_charge: orderDeliveryFee,
      additional_delivery: orderAdditionaldelFee,
      order_tat_time: '',
      tax_charge: orderGstPercentage,
      order_tax_calculate_amount: orderGstDisplay,
      order_data_encode: 'Yes',

      /* discount */
      order_discount_applied: '',
      order_discount_amount: '',
      order_discount_type: '',

      /* validation purpose*/
      promotion_applied_text: this.state.promotionApplied,
      promo_applied_delivery_text: this.state.promoIsDelivery,
      reward_point_status: 'Yes',

      /*order edit */
      orderedit_avail: orderedit_avail,
      orderedit_local: orderedit_local,
      orderedit_primid: orderedit_primid
    }
    /* console.log(postObject)
    return false */

    /*  promo code object - start */
    if (
      this.state.promotionApplied === 'Yes' &&
      this.state.promotionType === 'PROMO'
    ) {
      postObject['coupon_applied'] = 'Yes'
      postObject['promo_code'] = this.state.promocode
      postObject['cart_quantity'] = cartItems.cart_details.cart_total_items
      postObject['category_id'] = this.getPromoCategory(cartItems.cart_items)
      postObject['coupon_amount'] = this.state.promotionAmount
      postObject[
        'promotion_delivery_charge_applied'
      ] = this.state.promoIsDelivery
    } else if (
      this.state.promotionApplied === 'Yes' &&
      this.state.promotionType === 'POINTS'
    ) {
      postObject['redeem_point'] = this.state.promoPoints //redeem_applied
      postObject['redeem_applied'] = 'Yes' //redeem_amount
      postObject['redeem_amount'] = this.state.promotionAmount
    }

    if (appid === SPRING_APP_ID) {
      postObject['order_is_advanced_payment'] = 'Yes'
      postObject['order_advanced_percentage'] = '100'
      postObject['order_advanced_amount'] = '0'
      postObject['order_remaining_amount'] = orderFinalTotal
    }

    if (this.state.billing === true) {
      postObject['order_customer_billing_postal_code'] =
        _.isEmpty(this.state.billing_postalcode) === true
          ? ''
          : this.state.billing_postalcode
      postObject['order_customer_billing_address_line1'] =
        _.isEmpty(this.state.billing_address) === true
          ? ''
          : this.state.billing_address
      postObject['order_customer_billing_unit_no1'] =
        _.isEmpty(this.state.billing_unitOne) === true
          ? ''
          : this.state.billing_unitOne
      postObject['order_customer_billing_unit_no2'] =
        _.isEmpty(this.state.billing_unitTwo) === true
          ? ''
          : this.state.billing_unitTwo
    }

    //  console.log(postObject);
    //return false;
    if (validation === 'Yes') {
      postObject['validation_only'] = 'Yes'
    }

    if (paymentMode !== 3) {
      postObject['closePayment'] = 'Yes'
    }

    $('#cartLoading').show()

    var OrderAPIUrl = hostURL + 'api/ordersv1/submit_order'

    if (MARS_BASE_URL === hostURL) {
      //OrderAPIUrl = hostURL + "api/ordersv1/submit_order";
      // OrderAPIUrl = hostURL + "call-center/ordersv1/submit_order";
    }

    /* if (
      hostURL == 'https://ccpl.ninjaos.com/' &&
      cookie.load('app_id') == 'F4AF85A4-F1BA-4842-81D9-76E868B3B18A'
    ) {
      OrderAPIUrl = hostURL + 'api/ordersv1/submit_order_enterprice'
    } */
    if (
      hostURL == 'https://ccpl.ninjaos.com/' ||
      hostURL == 'https://jupiter.ninjaos.com/'
    ) {
      OrderAPIUrl = hostURL + 'api/ordersv1/submit_order_enterprice'
    }

    /* console.log(postObject)
    console.log(OrderAPIUrl)
    return false */

    axios.post(OrderAPIUrl, qs.stringify(postObject)).then(res => {
      $('#cartLoading').hide()
      /* console.log(res)
      return false */
      if (res.data.status === 'ok') {
        this.setState({ orderValidFail: 0 })

        if (validation !== 'Yes') {
          var localOrderNo = res.data.common.local_order_no
          if (payGetWayType === 'Stripe') {
            this.captureAmount(
              captureID,
              res.data.common.order_primary_id,
              localOrderNo
            )
          } else {
            //  $("#cartLoading").hide();
            // this.showSuccessPage(localOrderNo);
            cookie.save('orderValid', 1)
            this.destroyCart()
            deleteOrderCookie()
            history.push('/dashboard/' + res.data.common.local_order_no)
          }
        } else if (validation === 'Yes' && payGetWayType === 'Stripe') {
          $('.stripe-checkout').trigger('click')
        }
      } else if (res.data.status === 'error') {
        this.setState({ orderValidFail: 1 })
        if (validation !== 'Yes') {
          let msgTxt =
            typeof res.data.form_error !== 'undefined' &&
            res.data.form_error != ''
              ? parse(res.data.form_error)
              : res.data.message
          this.showAlert(FAILURE_ALERT, msgTxt)
        } else {
          /* this.orderValidationFail(); */
          let msgTxt =
            typeof res.data.form_error !== 'undefined' &&
            res.data.form_error != ''
              ? parse(res.data.form_error)
              : res.data.message
          this.showAlert(FAILURE_ALERT, msgTxt)
        }
      }
    })
  }

  /*  get promo category details  */
  getPromoCategory(cartItemsList) {
    var product_cartid = ''
    var cartItems = cartItemsList
    var cartCount = cartItems.length
    if (cartCount > 0) {
      for (var p = 0; p < cartCount; p++) {
        if (product_cartid !== '' && cartItems[p].cart_item_product_id !== '') {
          product_cartid += ';'
        }
        product_cartid +=
          cartItems[p].cart_item_product_id +
          '|' +
          cartItems[p].cart_item_total_price +
          '|' +
          cartItems[p].cart_item_qty
      }
    }

    return product_cartid
  }

  /*apply coupon  function*/
  applyCoupon(SubTotal) {
    const hostURL = hostUrl()
    const APPID = appId()

    var promoObject = {}
    var promocode = this.state.appliedPromocode

    if (promocode === '') {
      showInfo('The Promo code field is Required.')
      return false
    }

    $('#cartLoading').show()

    const cartItemsData = this.props.cartItems
    const cartItemsList = cartItemsData.cart_items
    const cartDetails = cartItemsData.cart_details
    var product_cartid = ''
    var cartItems = cartItemsList
    var cartCount = cartItems.length
    if (cartCount > 0) {
      for (var p = 0; p < cartCount; p++) {
        ///alert(cartItems[p].cart_item_product_id);
        if (product_cartid !== '' && cartItems[p].cart_item_product_id !== '') {
          product_cartid += ';'
        }
        product_cartid +=
          cartItems[p].cart_item_product_id +
          '|' +
          cartItems[p].cart_item_total_price
      }
    }

    var cartSubToatl = parseFloat(cartDetails.cart_sub_total)
    var cartItemsCount = cartDetails.cart_total_items
    promoObject = {
      /* cart details */
      app_id: APPID,
      reference_id: getCookies('customerId'),
      promo_code: promocode,
      cart_amount: parseFloat(cartSubToatl),
      cart_quantity: cartItemsCount,
      category_id: this.getPromoCategory(cartItemsList),
      availability_id: getCookies('orderAvailability')
    }
    //this.setState({status:"Loading"});
    axios
      .post(
        hostURL + 'api/promotion_api_v2/apply_promotion',
        qs.stringify(promoObject)
      )
      .then(res => {
        $('#cartLoading').hide()

        if (res.data.status === 'success') {
          if (
            res.data.result_set.promotion_delivery_charge_applied === 'Yes' &&
            this.state.cartDetails.cart_availability_id !== DELIVERY_ID
          ) {
            this.resetPromo()
            showInfo('This promo code only for delivery orders.')
            return false
          }

          this.setState({
            promotionApplied: 'Yes',
            promotionAmount: parseFloat(res.data.result_set.promotion_amount),
            promocode: res.data.result_set.promotion_code,
            promoIsDelivery:
              res.data.result_set.promotion_delivery_charge_applied,
            promotionId: res.data.result_set.promotion_id,
            promotionType: 'PROMO'
          })
          //var retun_data = this.postOrder(2,'Yes');

          showInfo('Promo code applied successfully')
        } else {
          this.resetPromo()
          showInfo(res.data.message)
        }
      })
  }

  changePromoVal(e) {
    this.setState({ appliedPromocode: e.target.value })
  }

  componentDidMount() {}
  resetPromo() {
    this.setState({
      promotionApplied: 'No',
      promotionAmount: 0,
      promocode: '',
      promoIsDelivery: 'No',
      promotionId: '',
      promotionType: '',
      appliedPromocode: '',
      points: ''
    })
    $('#point_input').val('')
  }

  changeAddress() {
    confirmAlert('Confirmation')
    return false
  }

  returnPrice(id, price) {
    return price
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length
    var comboLen = combo.length
    var html = "<ul className='table_list cart_sub_items mod'>"

    if (itemType === 'Modifier' && len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]['cart_modifier_name']
        var modval = modifiers[i]['modifiers_values'][0]['cart_modifier_name']
        html +=
          "<li className='sub-item'>" + modName + ' : ' + modval + '</li> '
      }
      html += '</ul>'
      var reactElement = parse(html)
      return reactElement
    } else if (itemType === 'Component' && comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]['menu_component_name']
        //var comboVal =combo[i]['product_details'][0]['cart_menu_component_product_name'];
        var comboVal = this.showComboProducts(combo[i]['product_details'])
        html +=
          " <li className='sub-item'><b>" +
          comboName +
          ' :</b>' +
          comboVal +
          ' ' +
          this.showComboModifiers(combo[i]['product_details'][0]['modifiers']) +
          '</li> '

        //html += "<li className='sub-item'><b>"+comboName+" :</b>"+comboVal+" "+ "</li> ";
      }
      html += '</ul>'
      var reactElement = parse(html)
      return reactElement
    }
  }
  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length
    var html = ' '
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        //console.log(combos[0]['modifiers']);
        var comboPro = combos[r]['cart_menu_component_product_name']
        var comboQty = combos[r]['cart_menu_component_product_qty']
        var comboPrice = combos[r]['cart_menu_component_product_price']
        var newPrice = comboPrice > 0 ? ' (+' + comboPrice + ')' : ''
        html +=
          "<li className='sub-item'>" +
          comboQty +
          ' X ' +
          comboPro +
          newPrice +
          ' </li> '
      }
      return html
    }
    return ''
  }
  showComboModifiers(modifiers) {
    //console.log(modifiers);
    var lenMod = modifiers.length
    var html = "<ul className='table_list cart_sub_items combo'>"
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]['cart_modifier_name']
        var modval = modifiers[i]['modifiers_values'][0]['cart_modifier_name']
        var modValPrice =
          modifiers[i]['modifiers_values'][0]['cart_modifier_price']
        var newModValPrice = modValPrice > 0 ? ' (+' + modValPrice + ')' : ''

        html +=
          "<li className='sub-item'>" +
          modName +
          ': ' +
          modval +
          newModValPrice +
          '</li> '
      }
      html += '</ul>'
      // var reactElement = htmlToReactParserTo.parse(html );
      return html
    }

    return ''
  }

  draftOrder(
    subTotal,
    finalTotal,
    deliveryFee,
    additionaldelFee,
    gstPercentage,
    gstDisplay,
    minCart
  ) {
    const custome_id = customerId()
    const appid = appId()
    const availability = getCookies('orderAvailability')
    const hostURL = hostUrl()
    const orderSubTotal = subTotal
    const orderFinalTotal = finalTotal
    const orderDeliveryFee = deliveryFee
    const orderAdditionaldelFee = additionaldelFee
    const orderGstPercentage = gstPercentage
    const orderGstDisplay = gstDisplay
    const orderMinCart = minCart
    const paymentMode = this.state.paymentMode

    if (custome_id == '' || custome_id == undefined) {
      history.push('dashboard')
      return false
    }

    const cartItems = this.props.cartItems

    if (
      _.isEmpty(cartItems) === true ||
      _.isEmpty(cartItems.cart_details) === true ||
      _.isEmpty(cartItems.cart_items) === true
    ) {
      history.push('dashboard')
      return false
    }

    if (availability === DELIVERY_ID) {
      if (orderSubTotal < orderMinCart) {
        this.showAlert(
          FAILURE_ALERT,
          'You must have an order with a minimum of $' +
            orderSubTotal +
            ' to place your order, your current order total is $' +
            orderMinCart +
            '.'
        )
        return false
      }
    }

    // return false;

    var products = []
    var row = cartItems.cart_items.map(
      function(item, i) {
        var modifierdata = []
        var comboData = []
        var ComboLen = item.set_menu_component.length
        var modifierLen = item.modifiers.length
        if (item.cart_item_type === 'Modifier' && modifierLen > 0) {
          for (var l = 0; l < modifierLen; l++) {
            var modifierVal = []
            modifierVal.push({
              modifier_value_id:
                item.modifiers[l].modifiers_values[0].cart_modifier_id,
              modifier_value_qty:
                item.modifiers[l].modifiers_values[0].cart_modifier_qty,
              modifier_value_name:
                item.modifiers[l].modifiers_values[0].cart_modifier_name,
              modifier_value_price:
                item.modifiers[l].modifiers_values[0].cart_modifier_price
            })
            modifierdata.push({
              modifier_id: item.modifiers[l].cart_modifier_id,
              modifier_name: item.modifiers[l].cart_modifier_name,
              modifiers_values: modifierVal
            })
          }
        } else if (item.cart_item_type === 'Component' && ComboLen > 0) {
          /* combo product setup */

          for (var m = 0; m < ComboLen; m++) {
            var combomodifierdata = []

            /* build  modifier array */
            var comboModifier =
              item.set_menu_component[m].product_details[0].modifiers

            var combomodifierdata = []
            if (comboModifier.length > 0) {
              /* new version product setup */

              for (var p = 0; p < comboModifier.length; p++) {
                // alert(comboModifier[p].modifiers_values[0].cart_modifier_id); return false;
                var comboModifierVal = []
                comboModifierVal.push({
                  modifier_value_id:
                    comboModifier[p].modifiers_values[0].cart_modifier_id,
                  modifier_value_qty:
                    comboModifier[p].modifiers_values[0].cart_modifier_qty,
                  modifier_value_name:
                    comboModifier[p].modifiers_values[0].cart_modifier_name,
                  modifier_value_price:
                    comboModifier[p].modifiers_values[0].cart_modifier_price
                })
                combomodifierdata.push({
                  modifier_id: comboModifier[p].cart_modifier_id,
                  modifier_name: comboModifier[p].cart_modifier_name,
                  modifiers_values: comboModifierVal
                })
              }
            }

            /*	comborVal.push( { /* commented for new version setup  /
                        product_id:item.set_menu_component[m].product_details[0].cart_menu_component_product_id ,
                        product_name:item.set_menu_component[m].product_details[0].cart_menu_component_product_name,
                        product_sku:item.set_menu_component[m].product_details[0].cart_menu_component_product_sku,
                        modifiers:combomodifierdata});
                        comboData.push( {
                                    menu_component_id:item.set_menu_component[m].menu_component_id,
                                    menu_component_name:item.set_menu_component[m].menu_component_name,
                                    product_details:comborVal}) */

            var comborVal = []
            var comboProductDetailslen =
              item.set_menu_component[m].product_details.length
            if (comboProductDetailslen > 0) {
              for (
                var j = 0, lengthComboPro = comboProductDetailslen;
                j < lengthComboPro;
                j++
              ) {
                comborVal.push({
                  product_id:
                    item.set_menu_component[m].product_details[j]
                      .cart_menu_component_product_id,
                  product_name:
                    item.set_menu_component[m].product_details[j]
                      .cart_menu_component_product_name,
                  product_sku:
                    item.set_menu_component[m].product_details[j]
                      .cart_menu_component_product_sku,
                  product_qty:
                    item.set_menu_component[m].product_details[j]
                      .cart_menu_component_product_qty,
                  product_price:
                    item.set_menu_component[m].product_details[j]
                      .cart_menu_component_product_price,
                  modifiers: combomodifierdata
                })
              }
            }

            comboData.push({
              menu_component_id: item.set_menu_component[m].menu_component_id,
              menu_component_name:
                item.set_menu_component[m].menu_component_name,
              product_details: comborVal
            })
          }
        }

        products.push({
          product_name: item.cart_item_product_name,
          product_unit_price: item.cart_item_unit_price,
          product_total_amount: item.cart_item_total_price,
          product_sku: item.cart_item_product_sku,
          product_image: item.cart_item_product_image,
          product_id: item.cart_item_product_id,
          product_qty: item.cart_item_qty,
          condiments: '',
          modifiers: modifierdata,
          bakers_modifiers: '',
          menu_set_components: comboData,
          baby_pack: '',
          product_special_notes: item.cart_item_special_notes
        })

        return products
      }.bind(this)
    )

    var orderDate = ''
    var cookieDate = getCookies('orderDeliveryDate')
    var splitedDate = cookieDate.split('/')

    var selDate = splitedDate[0]
    var selMonth = splitedDate[1]
    var selYear = splitedDate[2]
    var formatedDate = selYear + '-' + selMonth + '-' + selDate
    var orderTime =
      typeof getCookies('orderDeliveryTimeRequest') === 'undefined' ||
      getCookies('orderDeliveryTimeRequest') === ''
        ? ''
        : getCookies('orderDeliveryTimeRequest')
    orderDate = formatedDate + ' ' + orderTime

    var postObject = {}
    postObject = {
      /* cart details */
      app_id: appid,
      availability_id: availability,
      sub_total: orderSubTotal,
      grand_total: orderFinalTotal,
      outlet_id: getCookies('orderOutletId'),
      table_number: getCookies('orderTableNo'),
      order_status: 1,
      order_source: 'CallCenter',
      call_center_admin_id: getCookies('callAdminId'),
      order_date: orderDate,
      order_pickup_time_slot_from: '',
      order_pickup_time_slot_to: '',
      order_is_timeslot: '',
      order_remarks: '',
      products: JSON.stringify(products),

      /* customer  Details */
      customer_id: custome_id,
      customer_address_id: '',
      customer_fname: getCookies('orderCustomerfName'),
      customer_lname: getCookies('orderCustomerlName'),
      customer_mobile_no: getCookies('orderCustomerMobile'),
      customer_email: getCookies('orderCustomerEmail'),
      customer_address_line1: getCookies('orderAddressOne'),
      customer_address_line2: getCookies('orderAddressTwo'),
      customer_postal_code: getCookies('orderPostalCode'),
      customer_unit_no1: getCookies('orderUnitOne'),
      customer_unit_no2: getCookies('orderUnitTwo'),
      /*  "customer_tower_number": (typeof cookie.load('towerNo') === "undefined" ? '' : cookie.load('towerNo')),*/

      /* Payment */
      payment_mode: paymentMode /*paymentMode, */,
      payment_reference: '',
      stripe_envir: '',
      payment_type: '',
      order_capture_token: '',
      order_payment_getway_type: '',
      order_payment_getway_status: 'Yes',

      /* additional paras */
      delivery_charge: orderDeliveryFee,
      additional_delivery: orderAdditionaldelFee,
      order_tat_time: '',
      tax_charge: orderGstPercentage,
      order_tax_calculate_amount: orderGstDisplay,
      order_data_encode: 'Yes',

      /* discount */
      order_discount_applied: '',
      order_discount_amount: '',
      order_discount_type: ''
    }

    $('#cartLoading').show()
    var OrderAPIUrl = hostURL + 'api/orders/draft_order'
    axios.post(OrderAPIUrl, qs.stringify(postObject)).then(res => {
      $('#cartLoading').hide()
      if (res.data.status === 'ok') {
        cookie.save('orderValid', 1)
        deleteOrderCookie()
        this.destroyCart()
        history.push('/dashboard/' + res.data.common.local_order_no + '/draft')
      } else if (res.data.status === 'error') {
        let msgTxt =
          typeof res.data.form_error !== 'undefined' &&
          res.data.form_error != ''
            ? parse(res.data.form_error)
            : res.data.message
        this.showAlert(FAILURE_ALERT, msgTxt)
      }
    })
  }

  applyPoints(subTotal) {
    var pointsObject = {}
    var pointsVal = parseInt($('#point_input').val())
    const appid = appId()
    const availability = getCookies('orderAvailability')
    const hostURL = hostUrl()

    if (pointsVal > 0) {
      const cartSubToatl = subTotal
      pointsObject = {
        app_id: appid,
        reference_id: customerId(),
        redeem_point: pointsVal,
        cart_amount: parseFloat(cartSubToatl)
      }
      $('#cartLoading').show()
      axios
        .post(
          hostURL + 'api/loyalty/apply_loyalityv1',
          qs.stringify(pointsObject)
        )
        .then(res => {
          $('#cartLoading').hide()
          if (res.data.status === 'success') {
            showInfo(res.data.message)
            this.setState({
              promotionApplied: 'Yes',
              promotionAmount: parseInt(res.data.result_set.points_amount),
              promotionType: 'POINTS',
              promoPoints: parseInt(res.data.result_set.points_used)
            })

            //var retun_data = this.postOrder(2,'Yes');
          } else {
            this.resetPromoState()
            showInfo(res.data.message)
          }
        })
    } else {
      this.resetPromoState()
      showInfo('Please enter a valid point value')
      return false
    }
  }

  /*  reset prmo state values */
  resetPromoState() {
    this.setState({
      promotionApplied: 'No',
      promotionAmount: 0,
      promocode: '',
      promotionCategory: '',
      promotionId: '',
      promoQty: 0,
      promotionType: '',
      promoPoints: 0
    })

    $('#point_input').val('')
    $('#promo_input').val('')
  }

  stripeDescription() {
    //userName
    if (
      typeof cookie.load('orderCustomerlName') !== 'undefined' &&
      cookie.load('orderCustomerlName') !== ''
    ) {
      return (
        cookie.load('orderCustomerfName') +
        ' ' +
        cookie.load('orderCustomerlName')
      )
    } else {
      return cookie.load('orderCustomerfName')
    }
  }

  /* post stripe account */
  onToken = token => {
    //  console.log(token);
    //  load process html
    var postObject = {}
    const appId = returnValue(cookie.load(APP_ID))
    const API_URL = cookie.load(HOST_URL)
    var stripeReference = getStripeRef(appId)
    var avText = getAvailabilityName(getCookies('orderAvailability'))
    const orderDate =
      getCookies('orderDeliveryDate') +
      ' ' +
      moment(getCookies('orderDeliveryTime'), 'HH:mm:ss').format('hh:mm A')

    var metaData =
      'Callcenter|' +
      orderDate +
      '|' +
      avText +
      '|ID:' +
      this.state.payment_ref_id
    postObject = {
      app_id: appId,
      token: token.id,
      stripe_envir:
        parseInt(this.props.settings.client_stripe_mode) === 1 ? 'live' : 'dev',
      stripe_key: this.props.settings.stripe_public_key,
      customer_id: customerId(),
      paid_amount: this.state.stripeFinalTotal,
      outlet_name: metaData,
      payment_reference: stripeReference
    }

    const stripeSubTotal = this.state.stripeSubTotal
    const stripeFinalTotal = this.state.stripeFinalTotal
    const stripeDeliveryFee = this.state.stripeDeliveryFee
    const stripeAdditionaldelFee = this.state.stripeAdditionaldelFee
    const stripeGstPercentage = this.state.stripeGstPercentage
    const stripeGstDisplay = this.state.stripeGstDisplay
    const stripeMinCart = this.state.stripeMinCart
    const stripeDineInInitAmount = this.state.stripeDineInInitAmount

    /*  this.submitOrder(
      stripeSubTotal,
      stripeFinalTotal,
      stripeDeliveryFee,
      stripeAdditionaldelFee,
      stripeGstPercentage,
      stripeGstDisplay,
      stripeMinCart,
      stripeDineInInitAmount,
      "3",
      "Yes",
      "",
      "Stripe"
    ); */

    if (this.state.orderValidFail === 0) {
      $('#cartLoading').show()

      var PayApiUrl = 'ninjapro/paymentV3/stripeTokenPay'

      if (appId === CEDELE_APP_ID) {
        PayApiUrl = 'api/payment/stripeTokenPay'
      }

      axios
        .post(API_URL + PayApiUrl, qs.stringify(postObject))
        .then(res => {
          $('#cartLoading').hide()
          if (res.data.status === 'ok') {
            var captureID = res.data.result_set.payment_reference_1
            // this.setState({ validateimage: tickImage });
            this.submitOrder(
              stripeSubTotal,
              stripeFinalTotal,
              stripeDeliveryFee,
              stripeAdditionaldelFee,
              stripeGstPercentage,
              stripeGstDisplay,
              stripeMinCart,
              stripeDineInInitAmount,
              '3',
              'No',
              captureID,
              'Stripe'
            )
          } else if (res.data.status === 'error') {
            let msgTxt =
              typeof res.data.form_error !== 'undefined' &&
              res.data.form_error != ''
                ? parse(res.data.form_error)
                : res.data.message
            this.showAlert(FAILURE_ALERT, msgTxt)
            return false
          }
        })
        .catch(error => {
          return false
        })
    } else {
      return false
    }
  }

  onOpened(
    subTotal,
    finalTotal,
    deliveryFee,
    additionaldelFee,
    gstPercentage,
    gstDisplay,
    minCart,
    dineInInitAmount
  ) {
    /* get customer points */
    const hostURL = hostUrl()
    const app = appId()
    axios
      .get(hostURL + 'api/callcenterapi/get_payment_reference_id?app_id=' + app)
      .then(res => {
        if (res.data.status === 'ok') {
          this.setState({
            stripeSubTotal: subTotal,
            stripeFinalTotal: finalTotal,
            stripeDeliveryFee: deliveryFee,
            stripeAdditionaldelFee: additionaldelFee,
            stripeGstPercentage: gstPercentage,
            stripeGstDisplay: gstDisplay,
            stripeMinCart: minCart,
            stripeDineInInitAmount: dineInInitAmount,
            payment_ref_id: res.data.payment_ref_id
          })
        }
      })
  }

  /* Capture amount */
  captureAmount(captureID, orderPrimaryId, localOrderNo) {
    var cabtureObjects = {}
    var captureParams = captureID + '~' + orderPrimaryId + '~' + localOrderNo
    const appId = returnValue(cookie.load(APP_ID))
    var stripeReference = getStripeRef(appId)
    //const API_URL = cookie.load(HOST_URL);
    $('#cartLoading').show()
    cabtureObjects = {
      payment_reference: stripeReference,
      stripe_envir:
        parseInt(this.props.settings.client_stripe_mode) === 1 ? 'live' : 'dev',
      customer_id: cookie.load('UserId'),
      app_id: appId,
      token: captureID,
      order_id: orderPrimaryId
    }
    const API_URL = cookie.load(HOST_URL)

    var PayApiUrl = 'ninjapro/paymentV3/captureAmount'

    if (appId === CEDELE_APP_ID) {
      PayApiUrl = 'api/payment/captureAmount'
    }

    axios
      .post(API_URL + PayApiUrl, qs.stringify(cabtureObjects))
      .then(captureRes => {
        $('#cartLoading').hide()
        if (captureRes.data.status === 'ok') {
          //this.showSuccessPage(localOrderNo);

          this.destroyCart()
          deleteOrderCookie()
          history.push('/dashboard/' + localOrderNo)
        } else if (captureRes.data.status === 'error') {
          showInfo('Your order was not successful.', 'Error')
        } else {
          showInfo('Your order was not successful.', 'Error')
        }
      })
      .catch(error => {})
  }

  handleAddCustomerSuccess = customerSuccess => {
    this.setState({ modal: customerSuccess })
    showInfo('Customer information collected successfully', '')
  }

  render() {
    // console.log(this.state.changeTimeStart , "test");
    const excludeTime = [
      setHours(setMinutes(new Date(), 0), 11),
      setHours(setMinutes(new Date(), 30), 11),
      setHours(setMinutes(new Date(), 0), 12),
      setHours(setMinutes(new Date(), 30), 12),
      setHours(setMinutes(new Date(), 0), 13),
      setHours(setMinutes(new Date(), 30), 13),
      setHours(setMinutes(new Date(), 0), 14),

      setHours(setMinutes(new Date(), 30), 17),
      setHours(setMinutes(new Date(), 0), 18),
      setHours(setMinutes(new Date(), 30), 18),
      setHours(setMinutes(new Date(), 0), 19),
      setHours(setMinutes(new Date(), 30), 19),
      setHours(setMinutes(new Date(), 0), 20),
      setHours(setMinutes(new Date(), 30), 20),
      setHours(setMinutes(new Date(), 0), 21),
      setHours(setMinutes(new Date(), 30), 21)
    ]

    const logoName = getLogo()
    const hostURL = hostUrl()
    const cartItems = this.props.cartItems
    const zoneDetails =
      this.props.zoneStatus === 'ok' ? this.props.zoneData[0] : []
    const settings = this.props.setStatus === 'ok' ? this.props.settings : []
    const availability = getCookies('orderAvailability')
    let dineInInitAmount = ''
    if (
      _.isEmpty(settings) === false &&
      _.isEmpty(cartItems) === false &&
      _.isEmpty(cartItems.cart_details) === false &&
      _.isEmpty(cartItems.cart_items) === false
    ) {
      /* set promo */
      const myaccountPromo = getCookies('myaccount-promocode')
      var promocodeVal = ''
      if (typeof myaccountPromo !== 'undefined' && myaccountPromo !== '') {
        promocodeVal = myaccountPromo
        cookie.save('myaccount-promocode', '')
        this.setState({ appliedPromocode: promocodeVal })
      }

      /* get All values */
      let deliveryFee = 0
      let tempDelivery = 0
      let additionaldelFee = 0
      let freeDelivery = 0
      let minCart = 0
      let balanceAmount = 0
      let percentage = 0
      let subTotal = parseFloat(cartItems.cart_details.cart_sub_total)
      let actualSubTotal = parseFloat(cartItems.cart_details.cart_sub_total)
      if (availability === DELIVERY_ID) {
        tempDelivery = parseFloat(zoneDetails.zone_delivery_charge)
        additionaldelFee = parseFloat(
          zoneDetails.zone_additional_delivery_charge
        )

        freeDelivery = parseFloat(zoneDetails.zone_free_delivery)
        minCart = parseFloat(zoneDetails.zone_min_amount)
        /* for free delivery and min cart  */
        if (
          CEDELE_APP_ID === getCookies('app_id') &&
          availability === DELIVERY_ID
        ) {
          //var open_time = Date.parse("2019-12-21T16:59");
          //var close_time = Date.parse("2019-12-26T23:59");
          //var check_val = Date.parse("2011-10-10T02:30");

          var open_time = Date.parse('2020-01-24T00:01')
          var close_time = Date.parse('2020-01-27T23:59')

          const dateVal = new Date(
            moment(getCookies('orderDeliveryDate'), 'DD/MM/YYYY')
          )
          const convertedDate = moment(dateVal).format('YYYY-MM-DD')
          const timeVal = new Date(
            moment(cookie.load('orderDeliveryTime'), 'HH:mm')
          )
          const convertedTime = moment(timeVal).format('HH:mm')
          var check_val = Date.parse(convertedDate + 'T' + convertedTime)

          if (check_val > open_time && check_val < close_time) {
            console.log('freeDelivery', freeDelivery)
            freeDelivery = 500
            minCart = 200

            /* for del and add del fee */
            var currentDate = moment().format('YYYY-MM-DD')
            var del_open_time_one = Date.parse(currentDate + 'T08:59')
            var del_close_time_one = Date.parse(currentDate + 'T20:00')
            var del_check_val_one = Date.parse(
              currentDate + 'T' + convertedTime
            )

            var del_open_time_two = Date.parse(currentDate + 'T05:59')
            var del_close_time_two = Date.parse(currentDate + 'T08:59')

            if (
              del_check_val_one > del_open_time_one &&
              del_check_val_one < del_close_time_one
            ) {
              tempDelivery = 80
              adddeliveryFeeTmp = 0
            } else if (
              del_check_val_one > del_open_time_two &&
              del_check_val_one < del_close_time_two
            ) {
              tempDelivery = tempDelivery
              adddeliveryFeeTmp = 0
            }
          }

          /* *** ******Hard code for additional delivery charge*******  ***** */

          var currentDate_Two = moment().format('YYYY-MM-DD')
          var del_check_val_one_two = Date.parse(
            currentDate_Two + 'T' + convertedTime
          )
          var del_open_time_two_two = Date.parse(currentDate_Two + 'T05:59')
          var del_close_time_two_two = Date.parse(currentDate_Two + 'T08:59')

          if (
            del_check_val_one_two > del_open_time_two_two &&
            del_check_val_one_two < del_close_time_two_two
          ) {
            additionaldelFee = 15
          }
        }

        if (cookie.load('deliveryfee') !== undefined) {
          tempDelivery =
            getCookies('deliveryfee') !== ''
              ? parseFloat(getCookies('deliveryfee'))
              : 0
          deliveryFee = getCookies('deliveryfee')
        } else {
          deliveryFee = tempDelivery
        }

        if (cookie.load('additionaldelFee') !== undefined) {
          adddeliveryFeeTmp =
            getCookies('additionaldelFee') !== ''
              ? parseFloat(getCookies('additionaldelFee'))
              : 0
          additionaldelFee = getCookies('additionaldelFee')
        }

        if (minCart > 0) {
          deliveryFee = freeDelivery <= subTotal ? 0 : tempDelivery
          let formulaValue = Math.round((subTotal * 100) / freeDelivery)
          percentage = formulaValue <= 100 ? formulaValue : 100
          let remaingformula = freeDelivery - subTotal
          balanceAmount = remaingformula > 0 ? remaingformula : 0
        }
      }

      /* promo concept */
      let promotionAmount = 0

      if (
        this.state.promotionApplied === 'Yes' &&
        (this.state.promotionAmount > 0 ||
          this.state.promoIsDelivery === 'Yes') &&
        (this.state.promotionType === 'PROMO' ||
          this.state.promotionType === 'POINTS')
      ) {
        promotionAmount =
          this.state.promotionAmount > 0 ? this.state.promotionAmount : 0
        if (
          this.state.promotionType === 'PROMO' &&
          availability === DELIVERY_ID &&
          this.state.promoIsDelivery === 'Yes'
        ) {
          promotionAmount = tempDelivery
        }
      }

      subTotal = subTotal - promotionAmount

      /* promo concept */

      const gst = getGstValue(
        settings.client_tax_enable,
        settings.client_tax_surcharge,
        subTotal,
        ''
      )
      const gstDisplay = gst.toFixed(2)
      const gstPercentage =
        typeof settings.client_tax_surcharge !== 'undefined' &&
        settings.client_tax_surcharge !== ''
          ? settings.client_tax_surcharge
          : 0

      let deliveryFeeTmp = deliveryFee !== '' ? parseFloat(deliveryFee) : 0
      let adddeliveryFeeTmp =
        additionaldelFee !== '' ? parseFloat(additionaldelFee) : 0
      const grandTotal = parseFloat(
        subTotal + deliveryFeeTmp + adddeliveryFeeTmp + gst
      )

      const finalTotal = grandTotal.toFixed(2)

      const orderedit_avail = getCookies('order_edit_avail')
      let editdateaction = this.closeAlert.bind(this)
      let editoutletaction = this.closeAlert.bind(this)
      if (orderedit_avail == 'Yes') {
        editdateaction = this.editdateAlert.bind(this)
        if (availability === DELIVERY_ID) {
          editoutletaction = this.editoutletAlert.bind(this)
        } else if (availability === PICKUP_ID) {
          editoutletaction = this.pickupoutletAlert.bind(this)
        }
      }

      const outletsData = this.props.outletsData
      const outletResult = _.filter(outletsData, {
        oa_availability_id: DELIVERY_ID
      })

      const pickupoutletResult = _.filter(outletsData, {
        oa_availability_id: PICKUP_ID
      })

      const closeAllBtn = (
        <button className="close" onClick={this.changeDateAction}>
          &times;
        </button>
      )

      const customerID = customerId()

      return (
        <React.Fragment>
          <Customerpopup
            open_customerform={this.state.addCustomer}
            onCustomerSuccess={this.handleAddCustomerSuccess}
          />
          <div className="top-header">
            <Navbar className="header-nav" light expand="md">
              <Link to="/dashboard" className="navbar-brand">
                {logoName === '' && (
                  <img
                    src="https://ccpl.ninjaos.com/lib/theme/images/logo.png"
                    alt="Ninja OS"
                  />
                )}
                {logoName !== '' && (
                  <img
                    style={{ width: '80px', height: 'auto' }}
                    src={hostURL + LOGO_URL + logoName}
                    alt="Ninja OS"
                  />
                )}
              </Link>

              <Nav className="ml-auto user-menu" navbar>
                <NavItem className="customer-menu">
                  {typeof customerID !== 'undefined' && customerID !== '' && (
                    <NavLink href="#" onClick={this.customerModalToggle}>
                      <img src={CustomerIcon} alt="Customer" />
                      <span className="nav-txt">Customer</span>
                    </NavLink>
                  )}

                  {typeof customerID === 'undefined' && (
                    <NavLink
                      href="#"
                      onClick={this.showCustomerModal.bind(this)}>
                      <img src={CustomerIcon} alt="Customer" />
                      <span className="nav-txt">Customer</span>
                    </NavLink>
                  )}
                </NavItem>
                <NavItem className="cart-menu">
                  <NavLink href="#" onClick={this.checkoutToggle}>
                    <Badge color="danger" pill>
                      {_.isEmpty(cartItems) === false &&
                      _.isEmpty(cartItems.cart_details) === false
                        ? cartItems.cart_details.cart_total_items
                        : 0}
                    </Badge>
                    <img src={ShoppingCartIcon} alt="Cart" />
                  </NavLink>
                </NavItem>
              </Nav>
            </Navbar>
            {/* Customer info modal */}
            <Modal
              isOpen={this.state.customerModal}
              toggle={this.customerModalToggle}
              modalClassName="right"
              className="callcentre-modal checkout-modal customerdashboard-modal">
              <div className="modal-header">
                <div className="customer-right-modal   col-12">
                  <Nav className="ml-auto user-menu float-right">
                    <NavItem className="customer-menu active">
                      <NavLink href="#" onClick={this.customerModalToggle}>
                        <img src={CustomerIcon} alt="Customer" />
                        <span className="nav-txt">Customer</span>
                      </NavLink>
                    </NavItem>
                    <NavItem className="cart-menu">
                      <NavLink href="#" onClick={this.checkoutToggle}>
                        <img src={ShoppingCartIcon} alt="Cart" />
                        <Badge color="danger" pill>
                          {_.isEmpty(cartItems) === false &&
                          _.isEmpty(cartItems.cart_details) === false
                            ? cartItems.cart_details.cart_total_items
                            : 0}
                        </Badge>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </div>
              <Customerdashboard />
            </Modal>
            {_.isEmpty(cartItems) === false &&
              _.isEmpty(cartItems.cart_items) === false && (
                <Modal
                  isOpen={this.state.modal}
                  toggle={this.checkoutToggle}
                  modalClassName="right"
                  className="callcentre-modal checkout-modal">
                  <div className="modal-header">
                    <div className="customer-right-modal   col-12">
                      <Nav className="ml-auto user-menu float-right">
                        <NavItem className="customer-menu">
                          {typeof customerID !== 'undefined' &&
                            customerID !== '' && (
                              <NavLink
                                href="#"
                                onClick={this.customerModalToggle}>
                                <img src={CustomerIcon} alt="Customer" />
                                <span className="nav-txt">Customer</span>
                              </NavLink>
                            )}

                          {typeof customerID === 'undefined' && (
                            <NavLink
                              href="#"
                              onClick={this.showCustomerModal.bind(this)}>
                              <img src={CustomerIcon} alt="Customer" />
                              <span className="nav-txt">Customer</span>
                            </NavLink>
                          )}
                        </NavItem>
                        <NavItem className="cart-menu active">
                          <NavLink href="#" onClick={this.checkoutToggle}>
                            <img src={ShoppingCartIcon} alt="Cart" />
                            <Badge color="danger" pill>
                              {_.isEmpty(cartItems) === false &&
                              _.isEmpty(cartItems.cart_details) === false
                                ? cartItems.cart_details.cart_total_items
                                : 0}
                            </Badge>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </div>

                  <ModalBody>
                    <Jumbotron fluid className="customer-info-jumbotron">
                      <Container fluid>
                        <Row>
                          <Col xs="12" sm="5" className="pr-md-0">
                            <div className="customer-info-1">
                              <h6>
                                {getCookies('orderCustomerfName') +
                                  ' ' +
                                  getCookies('orderCustomerlName')}
                              </h6>
                              {getCookies('orderCustomerEmail') && (
                                <span className="customer-id">
                                  ID - {getCookies('orderCustomerEmail')}
                                </span>
                              )}
                            </div>
                          </Col>
                          <Col xs="12" sm="7" className="mt-xs-2">
                            <div className="customer-info-mobile float-left">
                              <h6>{getCookies('orderCustomerMobile')}</h6>
                            </div>
                            {/*  <Link
                              className="edit-action-btn float-right"
                              to="/dashboard"
                              onClick={e => {
                                e.preventDefault();
                              }}
                            >
                              <i
                                className="fa fa-fw fa-edit"
                                style={{ fontSize: "1rem" }}
                              />
                              Edit
                            </Link> */}
                          </Col>
                        </Row>
                      </Container>
                    </Jumbotron>
                    <Jumbotron
                      fluid
                      className="customer-delivery-info-jumbotron">
                      {availability === DELIVERY_ID && (
                        <h6 className="title">Delivery Details</h6>
                      )}
                      {availability === PICKUP_ID && (
                        <h6 className="title">Pickup Details</h6>
                      )}
                      {availability === DINEIN_ID && (
                        <h6 className="title">Dine in Details</h6>
                      )}
                      <Container fluid>
                        <Row>
                          <Col sm="6">
                            <Row>
                              <div className="customer-delivery-address col-10 pr-0">
                                {availability === DELIVERY_ID && (
                                  <h6>Delivery Address</h6>
                                )}
                                {availability === PICKUP_ID && (
                                  <h6>Pickup Address</h6>
                                )}
                                <h5>
                                  {addressFormat(
                                    getCookies('orderUnitOne'),
                                    getCookies('orderUnitTwo'),
                                    getCookies('orderAddressOne'),
                                    getCookies('orderAddressTwo'),
                                    getCookies('orderPostalCode')
                                  )}
                                </h5>
                                <span className="order-handled-by">
                                  Order handled by :
                                  {getCookies('orderOutletName')}
                                </span>
                                {availability === DELIVERY_ID && (
                                  <h6 className="mt-2">Billing Address</h6>
                                )}
                                {availability === DELIVERY_ID && (
                                  <FormGroup check>
                                    <CustomInput
                                      type="checkbox"
                                      id="exampleCustomCheckbox"
                                      label="Same as delivery address"
                                      checked={
                                        this.state.billing === true
                                          ? false
                                          : true
                                      }
                                      onClick={this.changeBillingAddress.bind(
                                        this
                                      )}
                                    />
                                  </FormGroup>
                                )}
                              </div>
                              <a
                                className="edit-action-btn float-right col-2"
                                style={{ color: '#007bff', cursor: 'pointer' }}
                                onClick={editoutletaction}>
                                <i
                                  className="fa fa-fw fa-edit"
                                  style={{ fontSize: '1rem' }}
                                />
                                Edit
                              </a>
                            </Row>
                          </Col>
                          <Col sm="6">
                            <Row>
                              <div className="customer-delivery-time col-10 pr-0">
                                {availability === DELIVERY_ID && (
                                  <h6>Delivery Date & Time</h6>
                                )}
                                {availability === PICKUP_ID && (
                                  <h6>Pickup Date & Time</h6>
                                )}
                                <h5>
                                  <span className="cd-date">
                                    {getCookies('orderDeliveryDate')}
                                  </span>
                                  <span className="cd-time">
                                    {moment(
                                      getCookies('orderDeliveryTime'),
                                      'HH:mm:ss'
                                    ).format('hh:mm A')}
                                  </span>
                                </h5>
                              </div>
                              <a
                                className="edit-action-btn float-right col-2"
                                style={{ color: '#007bff', cursor: 'pointer' }}
                                onClick={this.changeDateAction.bind(this)}>
                                <i
                                  className="fa fa-fw fa-edit"
                                  style={{ fontSize: '1rem' }}
                                />
                                Edit
                              </a>
                            </Row>
                          </Col>
                        </Row>

                        <Row
                          style={{
                            display:
                              this.state.billing === true ? 'block' : 'none'
                          }}>
                          <Col sm="12">
                            <input
                              type="text"
                              name="billing_address"
                              value={this.state.billing_address}
                              onChange={this.handleChange.bind(this)}
                              className="bill_address"
                              placeholder="Address Line 1"
                            />
                          </Col>
                        </Row>
                        <Row
                          style={{
                            display:
                              this.state.billing === true ? 'block' : 'none'
                          }}>
                          <Col sm="12">
                            {' '}
                            <input
                              type="text"
                              name="billing_postalcode"
                              maxLength="6"
                              value={this.state.billing_postalcode}
                              onChange={this.handleChangePostalCode.bind(this)}
                              className="bill_address"
                              placeholder="Postal Code"
                            />{' '}
                            <input
                              type="text"
                              name="billing_unitOne"
                              value={this.state.billing_unitOne}
                              onChange={this.handleChange.bind(this)}
                              className="bill_unit_no"
                              placeholder="Floor No"
                            />{' '}
                            <input
                              type="text"
                              name="billing_unitTwo"
                              value={this.state.billing_unitTwo}
                              onChange={this.handleChange.bind(this)}
                              placeholder="Unit No 2"
                              className="bill_unit_no"
                            />{' '}
                          </Col>
                        </Row>
                      </Container>
                    </Jumbotron>
                    <Jumbotron fluid className="customer-order-info-jumbotron">
                      <h6 className="title">
                        Order Details
                        <span className="clear-items float-right">
                          <a
                            style={{
                              cursor: 'pointer',
                              textDecoration: 'none'
                            }}
                            onClick={this.destroyCart.bind(this, 'Yes')}>
                            Clear items
                            <i
                              className="fa fa-fw fa-trash-o"
                              style={{ fontSize: '1.25rem' }}
                            />
                          </a>
                        </span>
                      </h6>

                      <Container fluid>
                        <div className="calctrcart_table">
                          <div className="calctrcart_body">
                            {_.isEmpty(cartItems.cart_items) === false &&
                              cartItems.cart_items.map((cart, cartIndex) => (
                                <div className="calctrcart_row" key={cartIndex}>
                                  <div className="row">
                                    <div className="col-12 col-sm-7 calctrcart_left">
                                      {cart.cart_item_product_image !== '' && (
                                        <div className="calctrcart_img">
                                          <img
                                            className="img-responsive"
                                            src={cart.cart_item_product_image}
                                            alt="Call center"
                                          />
                                        </div>
                                      )}
                                      <div className="calctrcart_info">
                                        <h5>
                                          {stripslashes(
                                            cart.cart_item_product_name
                                          )}
                                        </h5>
                                        <div className="calctrcart_extrainfo">
                                          {this.loadModifierItems(
                                            cart.cart_item_type,
                                            cart.modifiers,
                                            cart.set_menu_component
                                          )}
                                          {/* <span className="product-attr">Size:&nbsp; Large</span>    
                            <span className="product-attr">Add on:&nbsp; Name..</span>  */}
                                        </div>
                                      </div>
                                      {cart.cart_item_special_notes !== '' && (
                                        <p className="product-instruction">
                                          {stripslashes(
                                            cart.cart_item_special_notes
                                          )}
                                        </p>
                                      )}
                                    </div>
                                    <div className="col-12 col-sm-3 calctrcart_right text-right">
                                      <div className="prod-chosen-qty-block">
                                        <div className="qty-box">
                                          <span
                                            className="qty-minus"
                                            onClick={this.incQty.bind(
                                              this,
                                              cart.cart_item_id,
                                              cart.cart_item_qty,
                                              cart.cart_item_product_id,
                                              'remove'
                                            )}>
                                            _
                                          </span>
                                          <input
                                            type="text"
                                            readOnly
                                            value={cart.cart_item_qty}
                                          />
                                          <span
                                            className="qty-plus"
                                            onClick={this.incQty.bind(
                                              this,
                                              cart.cart_item_id,
                                              cart.cart_item_qty,
                                              cart.cart_item_product_id,
                                              'add'
                                            )}>
                                            +
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-12 col-sm-2 calctrcart_price">
                                      <input
                                        className="form-control"
                                        type="text"
                                        onChange={this.changeItemPrice.bind(
                                          this,
                                          cart.cart_item_id
                                        )}
                                        value={this.getPrice(
                                          cart.cart_item_total_price,
                                          cart.cart_item_id
                                        )}
                                        name={
                                          'cartItemPrice_' + cart.cart_item_id
                                        }
                                      />

                                      <a
                                        onClick={this.deleteCartItem.bind(
                                          this,
                                          cart.cart_item_id
                                        )}
                                        className="calctrcart_remove"
                                      />
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                          <div
                            className="add-new-product"
                            style={{ padding: '10px 0px' }}>
                            <Input
                              type="textarea"
                              name="text"
                              id="productDescription"
                              placeholder="Please enter your special message here..."
                              onChange={this.handleChangeDescription}
                              value={
                                this.state.orderRemarks !== ''
                                  ? this.state.orderRemarks
                                  : getCookies('orderRemarks')
                              }
                            />
                          </div>
                          <div className="add-new-product">
                            <a href="/products">+ Add more products</a>
                          </div>

                          <div className="price-update-sec">
                            <p>
                              In case of changing product price please click
                              "UPDATE PRICE" before proceed checkout
                            </p>
                            <Button
                              color="secondary"
                              onClick={this.updateCartprice.bind(this)}>
                              Update Price
                            </Button>
                          </div>

                          <div className="calctrcart_footer">
                            <div className="calctrcart_row">
                              <div className="row">
                                <div className="col-8">
                                  <p className="text-uppercase">SUBTOTAL</p>
                                </div>
                                <div className="col-4 text-right">
                                  <span>${actualSubTotal.toFixed(2)}</span>
                                </div>
                              </div>
                            </div>
                            {availability === DELIVERY_ID && (
                              <div className="calctrcart_row">
                                <div className="row">
                                  <div className="col-8">
                                    <p className="text-uppercase">
                                      Delivery Charges
                                    </p>
                                  </div>
                                  <div className="col-4 text-right">
                                    $
                                    <input
                                      className="form-control inputDelcharge"
                                      type="text"
                                      onChange={this.changedelPrice.bind(
                                        this,
                                        deliveryFee
                                      )}
                                      name="del"
                                      value={this.getDelPrice(deliveryFee)}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}

                            {availability === DELIVERY_ID && (
                              <div className="calctrcart_row">
                                <div className="row">
                                  <div className="col-8">
                                    <p className="text-uppercase">
                                      Additional Delivery Charges
                                    </p>
                                  </div>
                                  <div className="col-4 text-right">
                                    $
                                    <input
                                      className="form-control inputDelcharge"
                                      type="text"
                                      onChange={this.changeadddelPrice.bind(
                                        this,
                                        additionaldelFee
                                      )}
                                      name="adddel"
                                      value={this.getAddDelPrice(
                                        additionaldelFee
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}

                            {this.state.promotionApplied === 'Yes' &&
                              (this.state.promotionType === 'PROMO' ||
                                this.state.promotionType === 'POINTS') && (
                                <div className="calctrcart_row">
                                  <div className="row">
                                    <div className="col-8">
                                      <p className="text-uppercase">
                                        Discount
                                        {this.state.promotionType === 'PROMO' &&
                                          '(' + this.state.promocode + ')'}
                                      </p>
                                    </div>
                                    <div className="col-4 text-right">
                                      <span>
                                        ${this.state.promotionAmount.toFixed(2)}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}

                            <div className="calctrcart_row">
                              <div className="row">
                                <div className="col-8">
                                  <p className="text-uppercase">
                                    GST (Inclusive)
                                  </p>
                                </div>
                                <div className="col-4 text-right">
                                  <span>${gstDisplay}</span>
                                </div>
                              </div>
                            </div>
                            <div className="calctrcart_row calctrcart_footer_totrow">
                              <div className="row">
                                <div className="col-8">
                                  <p className="text-uppercase">Total</p>
                                </div>
                                <div className="col-4 text-right">
                                  <span>${finalTotal}</span>
                                </div>
                              </div>
                            </div>
                            {getCookies('orderAvailability') === DELIVERY_ID &&
                              balanceAmount > 0 && (
                                <div className="calctrcart_row calctrcart_footer_progress">
                                  <Progress value={percentage} />
                                  <div className="text-left help-text">
                                    <span>
                                      ${parseFloat(balanceAmount)} more to FREE
                                      delivery!
                                    </span>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </Container>
                    </Jumbotron>
                    <Row className="redeem-promocode-row">
                      <Col sm={6} className="left-col">
                        <h4 className="text-uppercase">
                          Redeem Points
                          <Badge color="danger">
                            {this.props.rewardCount} Points Available
                          </Badge>
                        </h4>
                        <InputGroup>
                          <Input
                            placeholder="Enter your redeem point amount"
                            id="point_input"
                          />
                          <InputGroupAddon addonType="append">
                            {this.state.promotionApplied === 'No' && (
                              <Button
                                color="secondary"
                                onClick={this.applyPoints.bind(this, subTotal)}>
                                Apply
                              </Button>
                            )}
                            {this.state.promotionApplied === 'Yes' &&
                              this.state.promotionType === 'POINTS' && (
                                <Button
                                  color="secondary"
                                  onClick={this.resetPromo.bind(this)}>
                                  Remove
                                </Button>
                              )}
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>
                      <Col sm={6} className="right-col">
                        <h4 className="text-uppercase">
                          Promo code
                          <Badge color="danger">
                            {this.props.promoCount} Promotions Available
                          </Badge>
                        </h4>
                        <InputGroup>
                          <Input
                            placeholder="Add Your Promo Code Here"
                            value={this.state.appliedPromocode}
                            id="promocode"
                            onChange={this.changePromoVal.bind(this)}
                          />
                          <InputGroupAddon addonType="append">
                            {this.state.promotionApplied === 'No' &&
                              this.state.promotionType === '' && (
                                <Button
                                  color="secondary"
                                  onClick={this.applyCoupon.bind(
                                    this,
                                    subTotal
                                  )}>
                                  Apply
                                </Button>
                              )}
                            {this.state.promotionApplied === 'Yes' &&
                              this.state.promotionType === 'PROMO' && (
                                <Button
                                  color="secondary"
                                  onClick={this.applyCoupon.bind(
                                    this,
                                    subTotal
                                  )}
                                  onClick={this.resetPromo.bind(this)}>
                                  Remove
                                </Button>
                              )}
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>
                    </Row>

                    <div className="checkout-payment-actions">
                      <Alert
                        color={this.state.alertType}
                        isOpen={this.state.orderAlert}>
                        {this.state.cartAlertMsg}
                      </Alert>
                      {CEDELE_APP_ID === getCookies('app_id') && (
                        <FormGroup className="row">
                          <CustomInput
                            type="radio"
                            id="ptype_banktransfer"
                            name="customRadio"
                            label="Bank Transfer"
                            value="11"
                            onClick={() => this.onChangePayment(11)}
                          />
                          <CustomInput
                            type="radio"
                            id="ptype_giro"
                            name="customRadio"
                            label="GIRO"
                            value="10"
                            onClick={() => this.onChangePayment(10)}
                          />
                          <CustomInput
                            type="radio"
                            id="ptype_amex"
                            name="customRadio"
                            label="Amex"
                            value="9"
                            onClick={() => this.onChangePayment(9)}
                          />
                          <CustomInput
                            type="radio"
                            id="ptype_visa_master"
                            name="customRadio"
                            label="Visa / Master"
                            value="8"
                            onClick={() => this.onChangePayment(8)}
                          />
                        </FormGroup>
                      )}
                      <FormGroup className="row">
                        <CustomInput
                          type="radio"
                          id="ptype_paidcounter"
                          name="customRadio"
                          label="Paid Counter"
                          value="6"
                          onClick={() => this.onChangePayment(6)}
                        />

                        <CustomInput
                          type="radio"
                          id="ptype_company_cheque"
                          name="customRadio"
                          label="Company Cheque"
                          value="7"
                          onClick={() => this.onChangePayment(7)}
                        />
                        <CustomInput
                          type="radio"
                          id="ptype_credit_debit"
                          name="customRadio"
                          label="Credit / Debit"
                          value="3"
                          onClick={() => this.onChangePayment(3)}
                        />

                        <CustomInput
                          type="radio"
                          id="ptype_cod"
                          name="customRadio"
                          label="COD"
                          value="1"
                          onClick={() => this.onChangePayment(1)}
                        />
                        {/*  <CustomInput
                          className="paymnt-type-cards-opt"
                          type="radio"
                          id="ptype_cards"
                          name="customRadio"
                          label=""
                        /> */}
                      </FormGroup>

                      <div className="checkout-section">
                        <Button
                          color="primary"
                          className="btn-draft"
                          onClick={this.draftOrder.bind(
                            this,
                            subTotal,
                            finalTotal,
                            deliveryFee,
                            additionaldelFee,
                            gstPercentage,
                            gstDisplay,
                            minCart
                          )}>
                          Make Draft Order
                        </Button>
                        <Button
                          color="primary"
                          className="btn-checkout"
                          onClick={this.submitOrder.bind(
                            this,
                            actualSubTotal,
                            finalTotal,
                            this.getDelPrice(deliveryFee),
                            this.getAddDelPrice(additionaldelFee),

                            gstPercentage,
                            gstDisplay,
                            minCart,
                            actualSubTotal
                          )}
                          style={{
                            display:
                              this.state.stripeMode === true ? 'none' : 'block'
                          }}>
                          Checkout
                        </Button>

                        <Button
                          color="primary"
                          className="btn-checkout"
                          onClick={this.submitOrder.bind(
                            this,
                            actualSubTotal,
                            finalTotal,
                            this.getDelPrice(deliveryFee),
                            this.getAddDelPrice(additionaldelFee),

                            gstPercentage,
                            gstDisplay,
                            minCart,
                            actualSubTotal,
                            '3',
                            'Yes',
                            '',
                            'Stripe'
                          )}
                          style={{
                            display:
                              this.state.stripeMode === true ? 'block' : 'none'
                          }}>
                          Checkout
                        </Button>

                        {settings.stripe_public_key &&
                          cookie.load('UserMobile') != '' && (
                            <div
                              className="stripe_bn_div"
                              style={{
                                display:
                                  this.state.stripeMode === true
                                    ? 'none'
                                    : 'none'
                              }}>
                              <StripeCheckout
                                opened={this.onOpened.bind(
                                  this,
                                  actualSubTotal,
                                  finalTotal,
                                  this.getDelPrice(deliveryFee),
                                  this.getAddDelPrice(additionaldelFee),

                                  gstPercentage,
                                  gstDisplay,
                                  minCart,
                                  actualSubTotal
                                )}
                                name={'Call center'}
                                image={''}
                                description={this.stripeDescription()}
                                token={this.onToken}
                                stripeKey={settings.stripe_public_key}
                                amount={
                                  getCookies('orderAvailability') === DINEIN_ID
                                    ? dineInInitAmount * 100
                                    : finalTotal * 100
                                }
                                email={cookie.load('orderCustomerEmail')}
                                currency={stripeCurrency}>
                                <Button
                                  color="primary"
                                  className="btn-checkout stripe-checkout">
                                  Stripe Checkout
                                </Button>
                              </StripeCheckout>
                            </div>
                          )}
                      </div>
                    </div>
                  </ModalBody>
                </Modal>
              )}
            <div
              id="cartLoading"
              style={{
                display: 'none',
                bottom: 0,
                left: 0,
                position: 'fixed',
                right: 0,
                top: 0,
                margin: 'auto',
                zIndex: 9999,
                background: 'rgba(255, 255, 255, 0.8)'
              }}>
              <Spinner
                color="secondary"
                style={{
                  width: '5rem',
                  height: '5rem',
                  bottom: 0,
                  left: 0,
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  margin: 'auto'
                }}
              />
            </div>
          </div>

          <Modal
            size="lg"
            isOpen={this.state.confirmationAlert}
            className="callcentre-modal pickup-confirmation-modal">
            <div className="modal-header">
              <div className="modal-top-icon" />
              <div className="modal-title-block">
                <h2>Warning</h2>
              </div>
            </div>
            <ModalBody>
              <h5>
                By switching you are about to clear your cart. <br /> Do you
                wish to proceed ?
              </h5>
            </ModalBody>
            <ModalFooter>
              <Spinner
                color="secondary"
                style={{
                  display:
                    this.state.checkpostalCode === true ? 'block' : 'none'
                }}
              />
              <Button
                color="secondary"
                onClick={this.closeAlert.bind(this)}
                style={{
                  display:
                    this.state.checkpostalCode === true ? 'none' : 'block'
                }}>
                No
              </Button>
              <Button
                color="primary"
                onClick={this.proceedAlert.bind(this)}
                style={{
                  display:
                    this.state.checkpostalCode === true ? 'none' : 'block'
                }}>
                Yes
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            size="lg"
            isOpen={this.state.editdateAlert}
            className="callcentre-modal pickup-confirmation-modal">
            <div className="modal-header">
              <div className="modal-top-icon">
                <button
                  className="close"
                  onClick={this.editdateAlert.bind(this)}>
                  &times;
                </button>
              </div>
              <div className="modal-title-block">
                <h2>Change Date & Time</h2>
              </div>
            </div>

            <ModalBody>
              <Form>
                <FormGroup>
                  <Row>
                    <Col xs={6}>
                      <DatePicker
                        className="form-control"
                        selected={this.state.startDate1}
                        onChange={this.handleDateChange1}
                      />
                    </Col>
                    <Col xs={6}>
                      {CEDELE_APP_ID === getCookies('app_id') && (
                        <DatePicker
                          className="form-control"
                          selected={this.state.startTime1}
                          onChange={this.handleTimeChange}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          dateFormat="h:mm aa"
                          timeCaption="Time"
                          minTime={setHours(setMinutes(new Date(), 0), 6)}
                          maxTime={setHours(setMinutes(new Date(), 0), 20)}
                        />
                      )}
                      {CEDELE_APP_ID !== getCookies('app_id') && (
                        <DatePicker
                          className="form-control"
                          selected={this.state.startTime1}
                          onChange={this.handleTimeChange}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          dateFormat="h:mm aa"
                          timeCaption="Time"
                        />
                      )}
                    </Col>
                  </Row>
                </FormGroup>
              </Form>
              <p>Click time section to pick advanced order date & time</p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.setDatetime.bind(this)}>
                Update
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            size="lg"
            isOpen={this.state.editoutletAlert}
            className="callcentre-modal pickup-location-modal">
            <div className="modal-header">
              <div className="modal-top-icon">
                <button
                  className="close"
                  onClick={this.editoutletAlert.bind(this)}>
                  &times;
                </button>
              </div>
              <div className="modal-title-block">
                <h1>Delivery Location</h1>
                <h5>Please select which outlet do you prefer</h5>
              </div>
            </div>
            <ModalBody>
              <Alert color="danger" isOpen={this.state.CHOOSE_POSTALCODE}>
                {typeof this.state.dyZoneError !== ''
                  ? this.state.dyZoneError
                  : CHOOSE_POSTALCODE}
              </Alert>
              <Alert color="danger" isOpen={this.state.CHOOSE_OUTLET}>
                {CHOOSE_OUTLET}
              </Alert>
              <Scrollbars style={{ height: 300 }}>
                <Form>
                  <ListGroup className="pickup-location-list">
                    {this.showDeliveryOutlets(outletResult)}
                  </ListGroup>
                </Form>
              </Scrollbars>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.changeoutlet.bind(this)}>
                Update
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            size="lg"
            isOpen={this.state.pickupoutletAlert}
            className="callcentre-modal pickup-location-modal">
            <div className="modal-header">
              <div className="modal-top-icon">
                <button
                  className="close"
                  onClick={this.pickupoutletAlert.bind(this)}>
                  &times;
                </button>
              </div>
              <div className="modal-title-block">
                <h1>Pickup Location</h1>
                <h5>Select your pickup location</h5>
              </div>
            </div>
            <ModalBody>
              <Alert color="danger" isOpen={this.state.CHOOSE_OUTLET}>
                {CHOOSE_OUTLET}
              </Alert>
              <Scrollbars style={{ height: 300 }}>
                <Form>
                  <ListGroup className="pickup-location-list">
                    {this.showPickupOutlets(pickupoutletResult)}
                  </ListGroup>
                </Form>
              </Scrollbars>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={this.changepickupoutlet.bind(this)}>
                Continue
              </Button>
            </ModalFooter>
          </Modal>

          {/* Change date */}
          {/* fourth  modal */}
          <Modal
            size="lg"
            isOpen={this.state.changeDate}
            className="callcentre-modal deliverytime-modal">
            <div className="modal-header  datepicker-header">
              <div className="modal-top-icon">
                <img
                  src={ClockIcon}
                  alt={'Shopping Bag'}
                  className="datepicker-icon"
                />
                {closeAllBtn}
              </div>
              <div className="modal-title-block">
                {getCookies('orderAvailability') === DELIVERY_ID && (
                  <h1 className="datepicker-h1">Delivery Time</h1>
                )}
                {getCookies('orderAvailability') === PICKUP_ID && (
                  <h1 className="datepicker-h1">Pickup Time</h1>
                )}

                {getCookies('orderAvailability') === DELIVERY_ID && (
                  <h5 className="datepicker-h5">
                    Earliest Delivery Date & Time{' '}
                  </h5>
                )}
                {getCookies('orderAvailability') === PICKUP_ID && (
                  <h5 className="datepicker-h5">
                    Earliest Pickup Date & Time{' '}
                  </h5>
                )}
              </div>
            </div>
            <ModalBody>
              <Form>
                <FormGroup>
                  <Row>
                    <Col xs={6}>
                      <DatePicker
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                        minDate={this.state.minDate}
                        selected={this.state.changeDateStart}
                        onChange={this.handleChangeDate}
                        maxDate={addDays(new Date(), 90)}
                      />
                    </Col>
                    <Col xs={6}>
                      {CEDELE_APP_ID === getCookies('app_id') && (
                        <DatePicker
                          className="form-control"
                          selected={this.state.changeTimeStart}
                          onChange={this.handleChangeTime}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          dateFormat="h:mm aa"
                          timeCaption="Time"
                          minTime={setHours(setMinutes(new Date(), 0), 6)}
                          maxTime={setHours(setMinutes(new Date(), 0), 20)}
                        />
                      )}

                      {SPRING_APP_ID === getCookies('app_id') && (
                        <DatePicker
                          className="form-control"
                          selected={this.state.changeTimeStart}
                          onChange={this.handleChangeTime}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          dateFormat="hh:mm aa"
                          timeCaption="Time"
                          includeTimes={excludeTime}
                        />
                      )}

                      {CEDELE_APP_ID !== getCookies('app_id') &&
                        SPRING_APP_ID !== getCookies('app_id') && (
                          <DatePicker
                            className="form-control"
                            selected={this.state.changeTimeStart}
                            onChange={this.handleChangeTime}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            dateFormat="hh:mm aa"
                            timeCaption="Time"
                          />
                        )}
                    </Col>
                  </Row>
                </FormGroup>
              </Form>
              <p>Click time section to pick advanced order date & time</p>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.changeDateAction}>
                Close
              </Button>
              <Button color="primary" onClick={this.updateDateValue}>
                Update
              </Button>
            </ModalFooter>
          </Modal>
        </React.Fragment>
      )
    } else {
      const customerID = customerId()

      return (
        <React.Fragment>
          <div className="top-header">
            <Customerpopup open_customerform={this.state.addCustomer} />
            <Navbar className="header-nav" light expand="md">
              <Link to="/dashboard" className="navbar-brand">
                {logoName === '' && (
                  <img
                    src="https://ccpl.ninjaos.com/lib/theme/images/logo.png"
                    alt="Ninja OS"
                  />
                )}
                {logoName !== '' && (
                  <img
                    style={{ width: '80px', height: 'auto' }}
                    src={hostURL + LOGO_URL + logoName}
                    alt="Ninja OS"
                  />
                )}
              </Link>

              <Nav className="ml-auto user-menu" navbar>
                {/*   <NavItem className="header-outlet-dropdown d-none d-md-block">
                  <div className="custome-select-control">
                    <Input type="select" name="select" id="productSize">
                      <option>BUGGIS OUTLET</option>
                      <option>BUGGIS OUTLET</option>
                      <option>BUGGIS OUTLET</option>
                      <option>BUGGIS OUTLET</option>
                      <option>BUGGIS OUTLET</option>
                    </Input>
                  </div>
			  </NavItem> */}

                <NavItem className="customer-menu">
                  {typeof customerID !== 'undefined' && customerID !== '' && (
                    <NavLink href="#" onClick={this.customerModalToggle}>
                      <img src={CustomerIcon} alt="Customer" />
                      <span className="nav-txt">Customer</span>
                    </NavLink>
                  )}

                  {typeof customerID === 'undefined' && (
                    <NavLink
                      href="#"
                      onClick={this.showCustomerModal.bind(this)}>
                      <img src={CustomerIcon} alt="Customer" />
                      <span className="nav-txt">Customer</span>
                    </NavLink>
                  )}
                </NavItem>

                <NavItem className="cart-menu">
                  <NavLink href="#" onClick={this.checkoutToggle}>
                    <Badge color="danger" pill>
                      {_.isEmpty(cartItems) === false &&
                      _.isEmpty(cartItems.cart_details) === false
                        ? cartItems.cart_details.cart_total_items
                        : 0}
                    </Badge>
                    <img src={ShoppingCartIcon} alt="Cart" />
                  </NavLink>
                </NavItem>
              </Nav>
              {/*  <div className="header-outlet-dropdown d-md-none d-sm-block">
                <div className="custome-select-control">
                  <Input type="select" name="select" id="productSize">
                    <option>BUGGIS OUTLET</option>
                    <option>BUGGIS OUTLET</option>
                    <option>BUGGIS OUTLET</option>
                    <option>BUGGIS OUTLET</option>
                    <option>BUGGIS OUTLET</option>
                  </Input>
                </div>
              </div> */}
            </Navbar>
            {/* Customer info modal */}
            <Modal
              isOpen={this.state.customerModal}
              toggle={this.customerModalToggle}
              modalClassName="right"
              className="callcentre-modal checkout-modal customerdashboard-modal">
              <div className="modal-header">
                <div className="customer-right-modal   col-12">
                  <Nav className="ml-auto user-menu float-right">
                    {/* <NavItem className="customer-menu active">
                      <NavLink href="#" onClick={this.customerModalToggle}>
                        
                        <img src={CustomerIcon} alt="Customer" />
                        <span className="nav-txt">Customer</span>
                      </NavLink>
				  </NavItem> */}
                    <NavItem className="cart-menu">
                      <NavLink href="#" onClick={this.checkoutToggle}>
                        <img src={ShoppingCartIcon} alt="Cart" />
                        <Badge color="danger" pill>
                          {_.isEmpty(cartItems) === false &&
                          _.isEmpty(cartItems.cart_details) === false
                            ? cartItems.cart_details.cart_total_items
                            : 0}
                        </Badge>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </div>
              <Customerdashboard />
            </Modal>
            {_.isEmpty(cartItems) === false &&
              _.isEmpty(cartItems.cart_items) === false && (
                <Modal
                  isOpen={this.state.modal}
                  toggle={this.checkoutToggle}
                  modalClassName="right"
                  className="callcentre-modal checkout-modal">
                  <div className="modal-header">
                    <div className="customer-right-modal   col-12">
                      <Nav className="ml-auto user-menu float-right">
                        {/*   <NavItem className="customer-menu">
                          <NavLink href="#" onClick={this.customerModalToggle}>
                            
                            <img src={CustomerIcon} alt="Customer" />
                            <span className="nav-txt">Customer</span>
                          </NavLink>
					  </NavItem> */}
                        <NavItem className="cart-menu active">
                          <NavLink href="#" onClick={this.checkoutToggle}>
                            <img src={ShoppingCartIcon} alt="Cart" />
                            <Badge color="danger" pill>
                              {_.isEmpty(cartItems) === false &&
                              _.isEmpty(cartItems.cart_details) === false
                                ? cartItems.cart_details.cart_total_items
                                : 0}
                            </Badge>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </div>

                  <ModalBody>
                    <Jumbotron fluid className="customer-info-jumbotron">
                      <Container fluid>
                        <Row>
                          <Col xs="12" sm="5" className="pr-md-0">
                            <div className="customer-info-1">
                              <h6>
                                {getCookies('orderCustomerfName') +
                                  ' ' +
                                  getCookies('orderCustomerlName')}
                              </h6>
                              <span className="customer-id">
                                ID - {getCookies('orderCustomerEmail')}
                              </span>
                            </div>
                          </Col>
                          <Col xs="12" sm="7" className="mt-xs-2">
                            <div className="customer-info-mobile float-left">
                              <h6>{getCookies('orderCustomerMobile')}</h6>
                            </div>
                            <Link
                              className="edit-action-btn float-right"
                              to="/dashboard"
                              onClick={e => {
                                e.preventDefault()
                              }}>
                              <i
                                className="fa fa-fw fa-edit"
                                style={{ fontSize: '1rem' }}
                              />
                              Edit
                            </Link>
                          </Col>
                        </Row>
                      </Container>
                    </Jumbotron>
                  </ModalBody>
                </Modal>
              )}
            <div
              id="cartLoading"
              style={{
                display: 'none',
                bottom: 0,
                left: 0,
                position: 'fixed',
                right: 0,
                top: 0,
                margin: 'auto',
                zIndex: 9999,
                background: 'rgba(255, 255, 255, 0.8)'
              }}>
              <Spinner
                color="secondary"
                style={{
                  width: '5rem',
                  height: '5rem',
                  bottom: 0,
                  left: 0,
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  margin: 'auto'
                }}
              />
            </div>
          </div>
        </React.Fragment>
      )
    }
  }
}

const mapStateToProp = state => ({
  cartItems: state.cart.returnData,
  cartStatus: state.cart.isStatus,
  settings: state.settings.returnData,
  setStatus: state.settings.isStatus,
  zoneData: state.zoneDetails.returnData,
  zoneStatus: state.zoneDetails.isStatus,
  outletsData: state.outlets.returnData,
  promoCount: state.user.promoCount,
  rewardCount: state.user.rewardCount
})
export default connect(
  mapStateToProp,
  {
    getCartItems,
    getSetings,
    zonedetails,
    fetchalloutlets,
    findoutlet,
    getPromoCount
  }
)(Header)
