import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Alert } from "reactstrap";
import { CCPL_API_URL, MARS_API_URL, LOCAL_URL,JUPITER_URL } from "../../config/config";
import {
  ADMIN_ID,
  ADMIN_NAME,
  HOST_URL,
  APP_ID,
  CLIENT_LOGO
} from "../../config/constans";
 import cookie from "react-cookies";
import { instanceOf } from "prop-types";
import { CEDELE_APP_ID } from "../../config/apps";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Input,
  InputGroup,
  Row,
  FormFeedback
} from "reactstrap";

import { postlogin,successlogin } from "../../stores/account/login";
import NinjaLogo from "../../assets/img/ninja-logo.png";
class Login extends Component {
  constructor(props) {
    super(props);

    var appId =
      typeof this.props.match !== "undefined"
        ? this.props.match.params.appId
        : "";
		
    const server = (appId ===  CEDELE_APP_ID? JUPITER_URL : "" );		
    this.state = {
      fields: { appId: appId, server :  server },
      errors: {},
	  appID : appId
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitLoginform = this.submitLoginform.bind(this);
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields
    });

    this.validateForm(fields);
  }

  submitLoginform(e) {
    e.preventDefault();
    if (this.validateForm()) {
      this.props.postlogin(this.state.fields);
    }
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["username"]) {
      formIsValid = false;
      errors["username"] = "*Please enter your username.";
    }

    /*if (typeof fields["username"] !== "undefined") {
      if (!fields["username"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["username"] = "*Please enter alphabet characters only.";
      }
    }*/

    if (
      typeof fields["username"] !== "undefined" &&
      fields["username"].length < 6
    ) {
      formIsValid = false;
      errors["username"] = "*Username at least 6 characters";
    }

    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "*Please enter your password.";
    }
    if (
      typeof fields["password"] !== "undefined" &&
      fields["password"].length < 6
    ) {
      formIsValid = false;
      errors["password"] = "*Password at least 6 characters";
    }

    if (!fields["server"]) {
      formIsValid = false;
      errors["server"] = "*Please choose application.";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  componentDidMount() {
    
    let adminId = cookie.load(ADMIN_ID);
    let adminName = cookie.load(ADMIN_NAME);
    let appId = cookie.load(APP_ID);
    let hostUrl = cookie.load(HOST_URL);
    if (
      typeof adminId !== "undefined" &&
      adminId !== null &&
      (typeof adminName !== "undefined" && adminName !== null) &&
      (typeof appId !== "undefined" && appId !== null) &&
      (typeof hostUrl !== "undefined" && hostUrl !== null)
    ) {
		
      this.props.history.push("/dashboard");
	    this.props.successlogin();
    }
  }
  componentDidUpdate() {
    if (
      typeof this.props.returnstatus !== "undefined" &&
      this.props.returnstatus === "success" &&
      typeof this.props.returnData === "object"
    ) {
      if (typeof this.props.returnData.call_company_admin_id !== "undefined") {
     
         cookie.save('callAdminId', this.props.returnData.call_company_admin_id, { path: "/" });
         cookie.save('callAdminName', this.props.returnData.call_admin_fname, { path: "/" });
         cookie.save('app_id', this.props.returnData.call_company_app_id, { path: "/" });
        cookie.save('hostUrl', this.props.returnData.call_host, { path: "/" });
        //cookies.set(HOST_URL, 'http://192.168.2.89/ninjaos_master/', { path: "/" });
        cookie.save('client_logo', this.props.returnData.client_logo, { path: "/" }); 
	    cookie.save('call_permission_outlet', (typeof this.props.returnData.call_permission_outlet !== "undefined"? this.props.returnData.call_permission_outlet : ""), { path: "/" });
         this.props.history.push("/dashboard");
		  this.props.successlogin();
      }
    }
  }

  render() {
    const submitdisabled =
      typeof this.props.loading !== "undefined" && this.props.loading === true
        ? true
        : false;
    return (
      <React.Fragment>
        <div
          id="login-screen"
          className="app login-screen flex-row align-items-center"
        >
          <Container>
            <Row className="justify-content-center">
              <Col xs="12" sm="10" md="8" lg="6">
                <CardGroup>
                  <Card className="p-4 login-right-block">
                    <CardBody>
                      {typeof this.props.returnMssage !== "undefined" &&
                        this.props.returnMssage !== null && (
                          <Alert color="danger">
                            {this.props.returnMssage}
                          </Alert>
                        )}
                      <form
                        method="post"
                        name="loginform"
                        onSubmit={this.submitLoginform}
                      >
                      <div className="text-center">
                        <img src={NinjaLogo} alt="Ninja Logo" />
                        </div>
                        <h6 className="text-muted" style={{padding:"20px 0"}}><center>Sign in to your callcentre account</center></h6>
                        <InputGroup className="mb-3">
                          <Input
                            type="text"
                            placeholder="Username"
                            autoComplete="username"
                            name="username"
                            onInput={this.handleChange}
                          />
                          <FormFeedback>
                            {this.state.errors.username}
                          </FormFeedback>
                        </InputGroup>
                        <InputGroup className="mb-4">
                          <Input
                            type="password"
                            placeholder="Password"
                            autoComplete="current-password"
                            name="password"
                            /*value={this.state.fields.password} */
                            onInput={this.handleChange}
                          />
                          <FormFeedback>
                            {this.state.errors.password}
                          </FormFeedback>
                        </InputGroup>

                        <InputGroup className="mb-4">
                        <div className="custome-select-control">
                          <select
                            className="common-select form-control login_select"
                            name="server"
                            id="server"
                            onChange={this.handleChange}
                          >
						  		{ (this.state.appID !== CEDELE_APP_ID) &&  
                            
							<React.Fragment>	<option value="">Select Application</option>
					                         
						  <option value={CCPL_API_URL}>
                              Enterprise Application {this.state.appId}
                            </option>
                            <option value={MARS_API_URL}>
                              Ninjapro Application
								</option> </React.Fragment>		 }
								
						  	 <option value={JUPITER_URL}>
                              Jupiter Application
						</option>  
                          </select>
                            </div>
                          <FormFeedback>
                            {this.state.errors.server}
                          </FormFeedback>
                        </InputGroup>
                        <Row>
                          <Col xs="12">
                            {" "}
                            <Button
                              color="primary"
                              className="px-4"
                              disabled={submitdisabled}
                            >
                              Login
                            </Button>
                          </Col>
						  {/* <Col xs="12" className="text-right">
                            <Button color="link" className="px-0">
                              Forgot password?
                            </Button>
                          </Col> */}
                        </Row>
                      </form>
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }

 
}

const mapStateToProps = ({ login }) => ({
  returnData: login.returnData,
  returnstatus: login.returnstatus,
  loading: login.loading,
  returnMssage: login.message
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ postlogin,successlogin }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
