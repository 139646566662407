import { combineReducers } from 'redux'
import login from './account/login';
import menu from './products/menu';
import products from './products/products';
import settings from './settings/';
import outlets from './delivery/deliveryoutlets';
import zone from './delivery/findzone';
import zoneDetails from './delivery/findzonedetails';
import cart from './cart';
import current from './orders/current';
import advanced from './orders/advanced';
import draftorders from './orders/draftorders';
import user from './user';
import rewards from './rewards';

export default combineReducers({
  login,
  settings,
  outlets,
  zone,
  zoneDetails,
  menu,
  products,
  cart,
  current,
  advanced,
  draftorders,
  user,
  rewards
})
