import axios from 'axios';
import cookie from "react-cookies";
import { HOST_URL, APP_ID,DELIVERY_ID } from "../../config/constans";
import { returnValue } from "../../config/supporters";
export const FIND_DETAILS = 'delivery/FIND_DETAILS';


const initialState = {
  returnData: [],
  isFetching: false,
  isError: null,
  isStatus: null
}
 
export default function(state = initialState , action) {
  
   switch (action.type){
       case FIND_DETAILS:
        return {
            ...state,
            returnData: action.payload,
			isFetching : false,
			isError : action.isError, 
			isStatus : action.isStatus
        }
              
       default:
       return state;
   }
}
export function zonedetails(){
 
  let appId = returnValue(cookie.load(APP_ID));
  let zoneId = returnValue(cookie.load("orderZoneID"));
  let outletId = returnValue(cookie.load("orderOutletId"));
   
  let API_URL = cookie.load(HOST_URL) + "apiv2/outlets/getZoneDetails/?app_id="+appId+"&availability="+DELIVERY_ID+"&ZoneId="+zoneId+"&outletId="+outletId+"&skip_timing=Yes";
 
    return function (dispatch) {
        axios.get(API_URL)
        .then(response => {
          dispatch({
            type: FIND_DETAILS,
            payload: response.data.result_set,
			loading : false,
			isStatus : response.data.status,
			isError : response.data.message
          });
        })
        .catch((error) => {
          console.log(error);
        })
       
    }
}
