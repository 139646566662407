import cookie from "react-cookies";
import { ADMIN_ID, HOST_URL, APP_ID, CLIENT_LOGO,DELIVERY_ID,PICKUP_ID,DINEIN_ID } from "../config/constans";
import {  setHours, setMinutes } from "date-fns";
var strp = require("strp");
const $ = window.$;

export function checkAuth() {
  if (
    typeof cookie.load(APP_ID) !== "undefined" &&
    cookie.load(APP_ID) !== null &&
    (typeof cookie.load(HOST_URL) !== "undefined" &&
      cookie.load(HOST_URL) !== null) &&
    (typeof cookie.load(ADMIN_ID) !== "undefined" &&
      cookie.load(ADMIN_ID) !== null)
  ) {
    return true;
  } else {
    return false;
  }
}

export function appId() {
  let appId = cookie.load(APP_ID);
  return typeof appId !== "undefined" && appId !== null ? appId : "";
}

export function hostUrl() {
  let hostUrl = cookie.load(HOST_URL);
  return typeof hostUrl !== "undefined" && hostUrl !== null ? hostUrl : "";
}

export function adminId() {
  let adminId = cookie.load(ADMIN_ID);
  return typeof adminId !== "undefined" && adminId !== null ? adminId : "";
}

export function returnValue(dataval) {
  return typeof dataval !== "undefined" && dataval !== null ? dataval : "";
}

export function getLogo() {
  let logo = cookie.load(CLIENT_LOGO);
  return typeof logo !== "undefined" && logo !== null ? logo : "";
}

export const addressFormat = function(
  unitOne,
  unitTwo,
  addressOne,
  addressTwo,
  postCode
) {
  var unit =
    unitTwo !== "" && unitOne !== ""
      ? "#" + unitOne + "-" + unitTwo + ","
      : unitOne !== ""
      ? "#" + unitOne + ","
      : "";
  unit = addressOne !== "" ? unit + addressOne + "," : unit;
  unit = addressTwo !== "" ? unit + addressTwo + "," : unit;
  unit = postCode !== "" ? unit + "Singapore " + postCode : unit;
  return unit !== "" ? unit.replace(/,\s*$/, "") : "N/A";
};

export function stripslashes(value) {
  return typeof value !== "undefined" && value !== null
    ? strp.stripslashes(value)
    : "";
}

export const getAliasName = function(alias, productName) {
  return alias !== "" ? alias : productName;
};

export const randomId = function() {
  var text = "";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < 50; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};

export const getReferenceID = function() {
  if (typeof cookie.load("referenceId") === "undefined") {
    var randomKey = randomId();
    cookie.save("referenceId", randomKey,{ path: "/" });
    return randomKey;
  } else {
    return cookie.load("referenceId");
  }
};

export const customerId = function() {
  if (
    typeof cookie.load("customerId") !== "undefined" &&
    cookie.load("customerId") !== "" && cookie.load("orderValid") !== "1"
  ) {
    return cookie.load("customerId");
  }
};

export const getCookies = function(cookieName) {
  if (
    typeof cookie.load(cookieName) !== "undefined" &&
    cookie.load(cookieName) !== ""
  ) {
    return cookie.load(cookieName);
  }else { 
   return "";
  }
};

export const deleteOrderCookie = function(deleteOrderCookie) {
  cookie.remove("orderAddressOne", { path: '/' });
  cookie.remove("orderAddressTwo", { path: '/' });
  cookie.remove("orderOutletId", { path: '/' });
  cookie.remove("orderOutletName", { path: '/' });
  cookie.remove("orderPostalCode", { path: '/' });
  cookie.remove("orderUnitOne", { path: '/' });
  cookie.remove("orderUnitTwo", { path: '/' });
  cookie.remove("orderZoneID", { path: '/' });
  cookie.remove("orderDeliveryDate", { path: '/' });
  cookie.remove("orderDeliveryTime", { path: '/' });
  cookie.remove("orderAvailability", { path: '/' }); 
  cookie.remove("customerId", { path: '/' }); 
  cookie.remove("orderCustomerfName", { path: '/' }); 
  cookie.remove("orderCustomerlName", { path: '/' }); 
  cookie.remove("orderCustomerEmail", { path: '/' });
  cookie.remove("orderCustomerMobile", { path: '/' });  
  cookie.remove("orderDeliveryTimeRequest", { path: '/' });  
  cookie.remove("order_edit_avail", { path: '/' });  
  cookie.remove("orderRemarks", { path: '/' });
  cookie.remove("itemType", { path: '/' });
  cookie.remove("minDay", { path: '/' });
  cookie.remove("modiferCount", { path: '/' });
  cookie.remove("notsubquantity", { path: '/' });
  cookie.remove("orderDeliveryTimeShow", { path: '/' }); 
  
  cookie.remove("comboCount", { path: '/' });
  cookie.remove("orderValid", { path: '/' });
  cookie.remove("subquantity", { path: '/' });
 

};

export const getGstValue = function(enabled, gst,subtotal,format) { 

if(parseInt(enabled) === 1) { 
    gst = parseFloat(gst);
    subtotal = parseFloat(subtotal);
	var gstResult = 0;
   
    if(gst > 0) {
		gstResult  = (gst /100 ) * subtotal; 
	} 
	
	 if(format === "format"){
		  return gstResult.toFixed(2);
	 }else{
		  return gstResult;
	 } 
}else {
	 return 0;
}
 };
 
 
export const showInfo = function(alrtmessage,alertheades) {
	
	
$('.alrt_overlay').remove();
$('.custom_alert').remove();

var alertheader =  (typeof(alertheades) =="undefined" )?  "Information" :  alertheades;


	$('body').append('<div class="alrt_overlay"></div><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">'+ alertheader +'</div><div class="alert_body"> '+ alrtmessage +' <div class="alt_btns"><a href="javascript:;" class="btn btn-info reject" id="alt0">Close</a></div></div></div></div></div>');
	
        var actheight=$('.alert_height').height();
		$('.custom_alert').height(actheight+10);
    
	
	$('.alt_btns a').click(function(e) {
	   $('.alrt_overlay, .custom_alert').remove();
       
    });
	
};

export const confirmAlert = function(alrtmessage,alertheades) { 

$('.alrt_overlay').remove();
$('.custom_alert').remove();

var alertheader =  (typeof(alertheades) =="undefined"  )?  "Confirmation" :  alertheades;

	$('body').append('<div class="alrt_overlay"></div><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">'+ alertheader +'</div><div class="alert_body"> '+ alrtmessage +' <div class="alt_btns"><a href="javascript:;" class="btn btn-primary confirm" id="alt1">OK</a><a href="javascript:;" class="btn btn-info reject" id="alt0">Cancel</a></div></div></div></div></div>');
	
        var actheight=$('.alert_height').height();
		$('.custom_alert').height(actheight+10);
    
	
	$('.alt_btns a').click(function(e) {
	   $('.alrt_overlay, .custom_alert').remove();
       
    });
	
 };
 
 export const encode_value = function (string) {
	
	var Base64 = {

		_keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/",
		
		encode: function(input) {
			var output = "";
			var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
			var i = 0;

			input = Base64._utf8_encode(input);

			while (i < input.length) {

				chr1 = input.charCodeAt(i++);
				chr2 = input.charCodeAt(i++);
				chr3 = input.charCodeAt(i++);

				enc1 = chr1 >> 2;
				enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
				enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
				enc4 = chr3 & 63;

				if (isNaN(chr2)) {
					enc3 = enc4 = 64;
				} else if (isNaN(chr3)) {
					enc4 = 64;
				}

				output = output + this._keyStr.charAt(enc1) + this._keyStr.charAt(enc2) + this._keyStr.charAt(enc3) + this._keyStr.charAt(enc4);

			}

			return output;
		},


		decode: function(input) {
			var output = "";
			var chr1, chr2, chr3;
			var enc1, enc2, enc3, enc4;
			var i = 0;

			input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

			while (i < input.length) {

				enc1 = this._keyStr.indexOf(input.charAt(i++));
				enc2 = this._keyStr.indexOf(input.charAt(i++));
				enc3 = this._keyStr.indexOf(input.charAt(i++));
				enc4 = this._keyStr.indexOf(input.charAt(i++));

				chr1 = (enc1 << 2) | (enc2 >> 4);
				chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
				chr3 = ((enc3 & 3) << 6) | enc4;

				output = output + String.fromCharCode(chr1);

				if (enc3 != 64) {
					output = output + String.fromCharCode(chr2);
				}
				if (enc4 != 64) {
					output = output + String.fromCharCode(chr3);
				}

			}

			output = Base64._utf8_decode(output);

			return output;

		},

		_utf8_encode: function(string) {
			string = string.replace(/\r\n/g, "\n");
			var utftext = "";

			for (var n = 0; n < string.length; n++) {

				var c = string.charCodeAt(n);

				if (c < 128) {
					utftext += String.fromCharCode(c);
				}
				else if ((c > 127) && (c < 2048)) {
					utftext += String.fromCharCode((c >> 6) | 192);
					utftext += String.fromCharCode((c & 63) | 128);
				}
				else {
					utftext += String.fromCharCode((c >> 12) | 224);
					utftext += String.fromCharCode(((c >> 6) & 63) | 128);
					utftext += String.fromCharCode((c & 63) | 128);
				}

			}

			return utftext;
		},

		_utf8_decode: function(utftext) {
			var string = "";
			var i = 0;
			var c = 0;
			var c1 = 0;
			var c2 = 0;
			var c3 = 0;

			while (i < utftext.length) {

				c = utftext.charCodeAt(i);

				if (c < 128) {
					string += String.fromCharCode(c);
					i++;
				}
				else if ((c > 191) && (c < 224)) {
					c2 = utftext.charCodeAt(i + 1);
					string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
					i += 2;
				}
				else {
					c2 = utftext.charCodeAt(i + 1);
					c3 = utftext.charCodeAt(i + 2);
					string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
					i += 3;
				}

			}

			return string;
		}

	}
		
	 var outputstring = Base64.encode(string);
	 
	 return outputstring;
	
	
};

export const showStatus = function(status) {
	
	var status_arr = [];
	status_arr[1] = 'Received';
	status_arr[2] = 'Delivered';
	status_arr[3] = 'Processing';
	status_arr[4] = 'Completed';
	status_arr[5] = 'Cancelled';
		
	return status_arr[status];
	
};

export function springexcludeTime() {
    var excludeTime = [
      setHours(setMinutes(new Date(), 0), 11),
      setHours(setMinutes(new Date(), 30), 11),
      setHours(setMinutes(new Date(), 0), 12),
      setHours(setMinutes(new Date(), 30), 12),
      setHours(setMinutes(new Date(), 0), 13),
      setHours(setMinutes(new Date(), 30), 13),
      setHours(setMinutes(new Date(), 0), 14),

      setHours(setMinutes(new Date(), 30), 17),
      setHours(setMinutes(new Date(), 0), 18),
      setHours(setMinutes(new Date(), 30), 18),
      setHours(setMinutes(new Date(), 0), 19),
      setHours(setMinutes(new Date(), 30), 19),
      setHours(setMinutes(new Date(), 0), 20),
      setHours(setMinutes(new Date(), 30), 20),
      setHours(setMinutes(new Date(), 0), 21),
      setHours(setMinutes(new Date(), 30), 21),

    ];
	
	return excludeTime;
}


export const getAvailabilityName = function(id) { 
 var returndata = "";
	if(id === DELIVERY_ID) {
		returndata = "Delivery";
	}else if(id === PICKUP_ID) {
				returndata = "Self Collection";
	}else if(id === DINEIN_ID ){
		returndata = "Dine In";
	}
	
	 return returndata;
 };
 